import {
  Component, EventEmitter, Input, Output
} from '@angular/core';
import {APIService} from '../../api';


@Component({
  selector: 'app-edit-medal',
  templateUrl: './edit_medal.component.html',
  styleUrls: ['./edit_medal.css']
})
export class EditMedalComponent {
  loaded = true;
  @Input() data;
  @Output() close = new EventEmitter();

  constructor(private api: APIService) {

  }

  valid(value) {
    console.log(this.data);
    this.loaded = false;
    this.api.postMedal(this.data.junior.id, this.data.domain.id, value).then((res) => {
        this.close.next();
        for (const k in res.medals.medals) {
          if (res.medals.medals.hasOwnProperty(k)) {
            this.data.junior.medals[k] = res.medals.medals[k];
          }
        }
        this.data.junior.medal = res.medals.medal;
    }).finally(() => {
      this.loaded = false;
    });
  }
}
