<div class="card" style="padding:10px; align-items:center; min-width: 200px" (mouseenter)="modify=true" (mouseleave)="modify=false">
 <div style="  display: flex;
  align-items: center;
  justify-content: center;top:0; left:0;position:absolute;width:100%; height:100%;background: #FFFFFFAA" *ngIf="isEditable && modify">
   <a style="vertical-align: middle" (click)="updateManager()"
      class="edit btn-floating waves-effect waves-light red"><i class="material-icons">mode_edit</i></a>
 </div>
  <h5>{{user.firstName}} {{user.lastName}}</h5>
  <div>{{user.function}}</div>
  <img style="min-height: 120px;
    max-height: 120px;
    min-width: 120px;
    max-width: 120px;object-fit: cover" [src]="user.image ? (api.URL+user.image | secure | async) : 'assets/man-head.svg' ">
  <div *ngIf="isEditable">{{user.email}}</div>
  <div style="margin-top:30px; width:100%; text-align: right"><small>{{user.phone}}</small></div>
</div>
