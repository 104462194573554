<div *ngIf="!loaded" class="text-center">
  <i class="fas fa-circle-notch fa-spin fa-7x"></i>
</div>
<div *ngIf="loaded">

<div style="margin:auto;">
  <div *ngFor="let n of news" class="news-container">
    <h5 class="mb-1" style="text-transform: uppercase;font-weight:100;font-size: 12px">{{n.date | date:'d MMMM yyyy '}}</h5>
    <h5 class="mb-3" style="font-size: 25px">{{n.title}}</h5>
    <div *ngIf="n.image" class="mb-3 col-12" style="text-align: center">
      <div style="display: inline-block; text-align:center; max-width:500px; width: 100%">
        <img style="width: 100%;   border: 2px solid; border-image: linear-gradient(white, white) 2;" [src]="'https://juniorsgolfpayerne.ch/docs/'+n.image"/>
      </div>
    </div>
    <div class="col 12" style="text-align:center;">
      <div style="display: inline-block; text-align: justify">
        <div style="font-size: 17px; font-weight: 300" [innerHtml]="n.text">
        </div>
      </div>
    </div>
    <hr style="margin-top: 40px; margin-bottom:40px "/>
  </div>
</div>
</div>
