<div *ngIf="!loaded" class="text-center">
  <i class="fas fa-circle-notch fa-spin fa-7x"></i>
</div>
<div *ngIf="loaded" style="text-align: right">
  <div style="display: flex;width: 100%;align-items: center"><div style="font-weight:bold;text-align:right;padding-left:20px;padding-right:20px;">Publié</div>
    <div style="text-align: center">
      <label class="switch">
        <input type="checkbox" [(ngModel)]="api.club100Activated" (ngModelChange)="postVisible()">
        <span class="slider round"></span>
      </label>
    </div>

    <div style="width: 100%;text-align: right;float: right">
    </div>
  </div>
  <h4 class="text-center">En attente du paiement</h4>
  <app-club100-list (reload)="reload()" [persons]="unvalidatedPersons" [fields]="['firstName', 'lastName', 'society', 'submission', 'reference', 'mail', 'visible', 'recontact' ]"></app-club100-list>
  <h4 class="text-center">Membres</h4>
  <app-club100-list (reload)="reload()" [persons]="validatedPersons" [fields]="['firstName', 'lastName', 'society', 'validation', 'mail', 'visible', 'recontact']"></app-club100-list>
</div>
