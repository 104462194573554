import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { MenuComponent } from './menu/menu.component';
import { SponsorsHeaderComponent } from './sponsors-header/sponsors-header.component';
import {NewsComponent} from './news/news.component';
import {APIService} from './api';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {LoginComponent} from './login/login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthGuard} from './security/auth.guard';
import {ProgrammComponent} from './programm/programm.component';
import {SmallProgrammComponent} from './programm/small_programm.component';
import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import {ListJuniorsComponent} from './list_juniors/list_juniors.component';
import {ListHeaderComponent} from './list_juniors/list-header/list-header.component';
import {DateFormatPipe} from './DateFormatPipe';
import {GroupPipe} from './GroupPipe';
import {MedaillesComponent} from './medailles/medailles.component';
import {MedalsComponent} from './medals/medals.component';
import {EditMedalComponent} from './medals/edit_medal/edit_medal.component';
import {MedalIconComponent} from './medals/medal-icon/medal-icon.component';
import {OrdreMeriteComponent} from './ordre-merite/ordre-merite.component';
import {SponsorcardComponent} from './sponsors/sponsorcard/sponsorcard.component';
import {SponsorsComponent} from './sponsors/sponsors.component';
import {DocumentsComponent} from './documents/documents.component';
import {DocumentComponent} from './documents/document/document.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import {TimedInputComponent} from './components/timed-input/timed-input.component';
import {ProgramManagmentComponent} from './program-managment/program-managment.component';
import {ProgramEditionComponent} from './program-edition/program-edition.component';
import {ShowGroupComponent} from './show-groups/show-group.component';
import {TimePipe} from './pipes/TimePipe';
import {StaffComponent} from './staff/staff.component';
import {StaffCardComponent} from './staff/staff-card/staff-card.component';
import {AdhererComponent} from './adherer/adherer.component';
import {ContactComponent} from './contact/contact.component';
import {PhotosComponent} from './photos/photos.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {EditJuniorsComponent} from './edit_juniors/edit_juniors.component';
import {ProgrammFilterComponent} from './programm/programm-filter/programm-filter.component';

import { LOCALE_ID } from '@angular/core';

import { registerLocaleData } from '@angular/common';

import localeFr from '@angular/common/locales/fr';
import {EditGroupComponent} from './edit_group/edit_group.component';
import {GroupesComponent} from './groupes/groupes.component';
import {EditLessonsComponent} from './edit_lessons/edit_lessons.component';
import {EditNewsComponent} from './edit-news/edit-news.component';
import {NgxWigComponent} from './components/wig/ngx-wig.component';
import {NgxWigToolbarService} from './components/wig/ngx-wig-toolbar.service';
import {NgxWigModule} from './components/wig/ngx-wig.module';
import {CompetitionsComponent} from './competitions/competitions.component';
import {CompetitionComponent} from './competition/competition.component';
import {CloseCompetitionComponent} from './competition/close-competition/close-competition.component';
import {DeleteParticipantComponent} from './competition/delete-participant/delete-participant.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {TeeboxPipe} from './TeeboxPipe';
import {AddCompetitionComponent} from './competition/add-competition/add-competition.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatTabsModule} from '@angular/material/tabs';
import {StaffSortComponent} from "./staff/staff-sort/staff-sort.component";
import {StaffManagementComponent} from "./staff/staff-management/staff-management.component";
import {NgxImageGalleryModule} from "ngx-image-gallery";
import {ContactManagementComponent} from "./contact/contact-management/contact-management.component";
import {ManagerSelectComponent} from "./staff/manager-select/manager-select.component";
import {EditManagerComponent} from "./staff/edit-manager/edit-manager.component";
import {EditEventComponent} from "./programm/edit-event/edit-event.component";
import {CategoryInputComponent} from "./components/category-input/category-input.component";
import {EditLessonComponent} from "./edit_lesson/edit_lesson.component";
import {PrintPipe} from "./PrintPipe";
import {ActionButtonComponent} from "./components/action-button/action-button.component";
import {MedalEvolutionComponent} from "./medals/medal-evolution/medal-evolution.component";
import {ProgramComponent} from "./program/program.component";
import {NgInitDirective} from "./pipes/InitDirective";
import {ImageCropperModule} from "ngx-image-cropper";
import {AuthInterceptor} from "./pipes/AuthInterceptor";
import {SecurePipe} from "./pipes/SecurePipe";
import {PalmaresComponent} from "./palmares/palmares.component";
import {BoardComponent} from "./components/board/board.component";
import {DynamicRouteComponent} from "./dynamicRoute/dynamic-route.component";
import {PalmaresBoardComponent} from "./palmares/palmares-board/palmares-board.component";
import {Club100Component} from "./club100/club100.component";
import {Club100ManagementComponent} from "./club100/club100-management/club100-management.component";
import {Club100ListComponent} from "./club100/club100-list/club100-list.component";
import {Club100ListHeaderComponent} from "./club100/club100-list/header/club100-list-header.component";
import {Club100MemberEditComponent} from "./club100/club100-member-edit/club100-member-edit.component";
import { LightboxModule } from 'ngx-lightbox';
import { NgImageFullscreenViewModule } from "ng-image-fullscreen-view";
import { SurveyComponent } from "./survey/survey.component";
import { SurveyManagementComponent } from "./survey/survey-management/survey-management.component";
import {DataComponent} from "./data/data.component";
import {EditFieldComponent} from "./components/edit-field/edit-field.component";
import {DatePickerComponent} from "./components/date-picker/date-picker.component";
import {SubmenuComponent} from "./components/submenu/submenu.component";
import {Survey2Component} from "./survey2/survey2.component";
import {Survey2ManagementComponent} from "./survey2/survey-management/survey2-management.component";


registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MenuComponent,
    SponsorsHeaderComponent,
    NewsComponent,
    LoginComponent,
    ProgrammComponent,
    ProgramComponent,
    SmallProgrammComponent,
    ListJuniorsComponent,
    ListHeaderComponent,
    DateFormatPipe,
    PrintPipe,
    GroupPipe,
    MedaillesComponent,
    MedalsComponent,
    EditMedalComponent,
    MedalIconComponent,
    OrdreMeriteComponent,
    SponsorcardComponent,
    SponsorsComponent,
    DocumentsComponent,
    DocumentComponent,
    ConfigurationComponent,
    TimedInputComponent,
    ProgramManagmentComponent,
    ProgramEditionComponent,
    ShowGroupComponent,
    StaffComponent,
    StaffCardComponent,
    AdhererComponent,
    TimePipe,
    SecurePipe,
    ContactComponent,
    PhotosComponent,
    EditJuniorsComponent,
    ProgrammFilterComponent,
    EditGroupComponent,
    GroupesComponent,
    EditLessonsComponent,
    EditNewsComponent,
    CompetitionsComponent,
    CompetitionComponent,
    CloseCompetitionComponent,
    DeleteParticipantComponent,
    AddCompetitionComponent,
    TeeboxPipe,
    StaffSortComponent,
    StaffManagementComponent,
    ContactManagementComponent,
    ManagerSelectComponent,
    EditManagerComponent,
    EditEventComponent,
    CategoryInputComponent,
    EditLessonComponent,
    ActionButtonComponent,
    MedalEvolutionComponent,
    PalmaresComponent,
    BoardComponent,
    DynamicRouteComponent,
    PalmaresBoardComponent,
    Club100Component,
    Club100ManagementComponent,
    Club100ListComponent,
    Club100ListHeaderComponent,
    Club100MemberEditComponent,
    NgInitDirective,
    SurveyComponent,
    DataComponent,
    EditFieldComponent,
    DatePickerComponent,
    SurveyManagementComponent,
    SubmenuComponent,
    Survey2Component,
    Survey2ManagementComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NgxImageGalleryModule,
    NgxWigModule,
    NgSelectModule,
    DragDropModule,
    MatTabsModule,
    ImageCropperModule,
    LightboxModule,
    NgImageFullscreenViewModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [{provide: LOCALE_ID, useValue: 'fr-CA' },
              {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}, APIService, AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule {

}
