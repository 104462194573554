<div>
  <select [(ngModel)]="day">
    <option *ngFor="let value of days">
      {{value}}
    </option>
  </select>
  <select [(ngModel)]="month">
    <option *ngFor="let value of months">
      {{value}}
    </option>
  </select>
  <select [(ngModel)]="year">
    <option *ngFor="let value of years">
      {{value}}
    </option>
  </select>
</div>
