<div *ngIf="!loaded" style="width: 100%;height: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center">
  <div style="flex: 1 1 40%">
  </div>
  <div *ngIf="!loaded" class="text-center">
    <i class="fas fa-circle-notch fa-spin fa-7x"></i>
  </div>
  <div style="flex: 1 1 60%">
  </div>
</div>

<div *ngIf="loaded" style="padding: 10px">
  <div *ngIf="!selectedNews">
    <app-board [data]="news" (select)="select($event)"></app-board>
  </div>
  <div *ngIf="selectedNews">
<input placeholder="Titre" [(ngModel)]="selectedNews.title"  style="font-size: 25px;width: 100%" />
<img style="width: 100%;   border: 2px solid; border-image: linear-gradient(white, white) 2;" [src]="'https://juniorsgolfpayerne.ch/docs/'+selectedNews.image"/>
<ngx-wig #wgx [(ngModel)]="selectedNews.text"></ngx-wig>
  <button (click)="putText(selectedNews)">save</button>
  </div>
</div>

