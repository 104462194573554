import {
  AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, Output,
  ViewChild
} from '@angular/core';
import {Router} from '@angular/router';
import {APIService} from '../../api';

//TODO: rename because it is also use for open
@Component({
  selector: 'app-close-competition',
  templateUrl: './close-competition.component.html',
  styleUrls: ['./close-competition.component.css']
})
export class CloseCompetitionComponent {
  @Input() action;
  @Input() data;
  @Input() subject;
  @Output() close = new EventEmitter();
  loaded = true;

  constructor(private api: APIService, private router: Router) {

  }

  closeCompetition() {
    if (this.action === 'close') {
      this.loaded = false;
      this.api.postCompetitionClose(this.data.competition.id, this.data.competition.participants).then(
        () => {
          this.subject.next();
          this.loaded = false;
          this.api.showLoginWindow2 = null;
        });
    }
    if (this.action === 'open') {
      this.loaded = false;
      this.api.putCompetition(this.data.competition.id, {status: 'opened'}).then(
        () => {
          this.subject.next();
          this.loaded = false;
          this.api.showLoginWindow2 = null;
        });
    }
  }

  cancel() {
    this.api.showLoginWindow2 = null ;
  }
}
