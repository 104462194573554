import {Component, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-palmares',
  templateUrl: './palmares.component.html',
  styleUrls: ['./palmares.component.scss']
})
export class PalmaresComponent implements OnInit {

  @ViewChild('d') private d: any;

  selected = null;
  palmares = [{
    title: 'CHAMPIONNES JUNIORS',
    data: [
      {year: 1998, name: 'Amanda Roesinger'},
      {year: 1999, name: 'Amanda Roesinger'},
      {year: 2000, name: 'Amanda Roesinger'},
      {year: 2001, name: 'Amanda Roesinger'},
      {year: 2002, name: 'Amanda Roesinger'},
      {year: 2003, name: 'Anne-Laure Simonet'},
      {year: 2004, name: 'Anne-Laure Simonet'},
      {year: 2005, name: 'Anne-Laure Simonet'},
      {year: 2006, name: 'Cécile Burgy'},
      {year: 2007, name: 'Jenna Savary'},
      {year: 2008, name: 'Jenna Savary'},
      {year: 2009, name: 'Maud Perseghini'},
      {year: 2010, name: 'Jenna Savary'},
      {year: 2011, name: 'Jenna Savary'},
      {year: 2012, name: 'Cécile Burgy'},
      {year: 2013, name: 'Claudia Chautems'},
      {year: 2014, name: 'Claudia Chautems'},
      {year: 2015, name: 'Claudia Chautems'},
      {year: 2016, name: 'Céline Porchet'},
      {year: 2017, name: 'Céline Porchet'},
      {year: 2018, name: 'Céline Porchet'},
      {year: 2019, name: 'Céline Porchet'},
      {year: 2020, name: 'Céline Porchet'},
      {year: 2021, name: 'Céline Porchet'},
      {year: 2022, name: 'Axelle Martin'}
    ]
  },
  {
    title: 'CHAMPIONS JUNIORS',
    data: [
      {year: 1998, name: 'Philippe Marcuard'},
      {year: 1999, name: 'Martin Schoenenweid'},
      {year: 2000, name: 'Christophe Magnard'},
      {year: 2001, name: 'Christophe Magnard'},
      {year: 2002, name: 'Rémi Crausaz'},
      {year: 2003, name: 'Thibaut Vernay'},
      {year: 2004, name: 'Jérémie Nayak'},
      {year: 2005, name: 'Didier Crausaz'},
      {year: 2006, name: 'Marc Witkowski'},
      {year: 2007, name: 'Didier Crausaz'},
      {year: 2008, name: 'Didier Crausaz'},
      {year: 2009, name: 'Didier Crausaz'},
      {year: 2010, name: 'Cédric Caramello'},
      {year: 2011, name: 'Didier Crausaz'},
      {year: 2012, name: 'Cédric Caramello'},
      {year: 2013, name: 'Cédric Caramello'},
      {year: 2014, name: 'Marc Bucher'},
      {year: 2015, name: 'Cédric Caramello'},
      {year: 2016, name: 'Justin Brulhart'},
      {year: 2017, name: 'Justin Brulhart'},
      {year: 2018, name: 'Colin Brulhart'},
      {year: 2019, name: 'Colin Brulhart'},
      {year: 2020, name: 'Colin Brulhart'},
      {year: 2021, name: 'Colin Brulhart'},
      {year: 2022, name: 'Colin Brulhart'}
    ]
  },
    {
      title: 'ORDRE DU MÉRITE BRUT',
      categories: [
        {name: 'Girls',
          data: [
            {year: 2019, name: 'Céline Porchet'},
            {year: 2020, name: 'Céline Porchet'},
            {year: 2021, name: 'Céline Porchet'},
            {year: 2022, name: 'Axelle Martin'}
          ]},
        {name: 'Boys',
          data: [
            {year: 2019, name: 'Colin Brulhart'},
            {year: 2020, name: 'Léonard Péclat'},
            {year: 2021, name: 'Jan Porchet'},
            {year: 2022, name: 'Jan Porchet'}
          ]},
        {name: 'U12',
          data: [
            {year: 2019, name: 'Sean Martin'},
            {year: 2020, name: 'Sean Martin'},
            {year: 2021, name: 'Stanislas Crottaz'},
            {year: 2022, name: 'Stanislas Crottaz'}
          ]}
      ]
    },
    {
      title: 'ORDRE DU MÉRITE NET',
      categories: [
        {name: 'Girls',
          data: [
            {year: 2019, name: 'Céline Porchet'},
            {year: 2020, name: 'Céline Porchet'},
            {year: 2021, name: 'Candice Marcuard'},
            {year: 2022, name: 'Ophélie Légeret'}
          ]},
        {name: 'Boys',
          data: [
            {year: 2019, name: 'Léonard Péclat'},
            {year: 2020, name: 'Sean Martin'},
            {year: 2021, name: 'Gregory Fretz'},
            {year: 2022, name: 'Gregory Fretz'}
          ]},
        {name: 'U12',
          data: [
            {year: 2019, name: 'Sean Martin'},
            {year: 2020, name: 'Sean Martin'},
            {year: 2021, name: 'Nolan Schinz'},
            {year: 2022, name: 'Nolan Schinz'}
          ]}
      ]
    }];

  constructor() {

  }

  ngOnInit() {
    window.addEventListener('resize', this.setWindowSize);
  }


  setWindowSize() {
  }

  init(d, a) {
    const IMAGE_RATIO = 1.54074;
    const ratio = a.clientWidth / d.clientWidth;
    const tranformX = -d.clientWidth / 2 + a.clientWidth / 2;
    const transformY = -(d.clientWidth * IMAGE_RATIO) / 2 + a.clientWidth * IMAGE_RATIO / 2
    d.style.transform = 'translateX(' + tranformX + 'px) translateY(' + transformY + 'px)  scale( ' + ratio + ')';

  }


  clickoutside(d, a) {
    this.init(d, a);
  }


}
