import {AfterViewChecked, AfterViewInit, Component, ElementRef, HostListener, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {APIService} from '../api';
import {Router} from '@angular/router';


@Component({
  selector: 'app-edit-lessons',
  templateUrl: './edit_lessons.component.html',
  styleUrls: ['./edit_lessons.css']
})
export class EditLessonsComponent implements OnChanges{
  @Input() junior;
  @Input() subject;
  @ViewChild('scrollMe') private myScrollContainer: any;
  @ViewChild('d') private d: any;

  dataEdit: any = {}
  radioSelected = 'section'

  ngOnChanges(changes) {
    console.log(changes);
    this.dataEdit = {};
    if(changes.junior.currentValue) {
      this.dataEdit.datesLinked = changes.junior.currentValue.datesLinked;
      this.dataEdit.datesLinkedText = changes.junior.currentValue.datesLinkedText;
      this.dataEdit.name = changes.junior.currentValue.name;
      this.dataEdit.abbr = changes.junior.currentValue.abbr;
      this.lessonId = changes.junior.currentValue.id;
      if (this.dataEdit.inSection === false) {
        this.radioSelected = 'baby';
      }
      if (this.dataEdit.resignDate) {
        this.radioSelected = 'resigned';
      }
      console.log(this.dataEdit);
    }
  }

  loaded = false;
  lessonId;
  over;
  sorting = 'age';
  top;

  constructor(private api: APIService, private router: Router) {
    this.loaded = true;
  }


  detail(junior) {
    this.router.navigate(['/junior/' + junior.userId]);
  }

  edit(junior) {
    setTimeout(() => {
      this.api.showLoginWindow2 = true;
    }, 5);
  }

  valid() {
    if (!this.lessonId) {
      this.subject.next(this.dataEdit);
    }
    else {
      const junior = {...this.dataEdit};
      this.api.patchLesson(this.lessonId, this.dataEdit).then((res) => {
        if (this.dataEdit.name) {
          this.junior.name = this.dataEdit.name;
          let menuLesson = this.api.parameters.groups.find((x => x.id === this.lessonId));
          if (menuLesson) {
            menuLesson.name = this.dataEdit.name;
          }
          this.subject.next(res);
        }
        this.api.closePopup();
      });
    }

  }
}
