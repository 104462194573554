import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sponsors',
  templateUrl: './sponsors.component.html',
  styleUrls: ['./sponsors.component.scss']
})
export class SponsorsComponent implements OnInit {
  sponsors = ['assets/lacotel2.png', 'assets/raiffeisen.png', 'assets/candlandi.png', 'assets/buderus.jpg', 'assets/accreations2.png', 'assets/collaudcriblet.png', 'assets/karting.svg', 'assets/carrosseriebovet.svg', 'assets/geranceDG.png', 'assets/mobiliere2.svg', 'assets/codimmo.svg', 'assets/scan360.svg'];
  restaurateurs = ['assets/hoteldelagare.png', 'assets/port.png', 'assets/lacotel2.png', 'assets/karting.png', 'assets/hoteldelours.png', 'assets/maithai.png', 'assets/restaurantdesbains.png', 'assets/vieux_moulin.png', 'assets/rouvinez.jpg', 'assets/domaine_roses.png'];
  others = [ 'assets/sushimania.png', 'assets/club.png', 'assets/seniors.png'];
  constructor() {

  }

  ngOnInit() {
  }

}
