import {
  AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, Output,
  ViewChild
} from '@angular/core';
import {Router} from '@angular/router';
import {APIService} from '../../api';

//TODO: rename because it is also use for open
@Component({
  selector: 'app-add-competition',
  templateUrl: './add-competition.component.html',
  styleUrls: ['./add-competition.component.css']
})
export class AddCompetitionComponent {
  @Input() action;
  @Input() data;
  @Input() subject;
  @Output() close = new EventEmitter();
  loaded = true;
  events = [];
  date;
  selectedEvent;

  constructor(private api: APIService, private router: Router) {

  }

  getEvents() {
    this.api.getEvent(null,null,false, this.date ).then(event => {
      this.events = event;
      this.events = this.events.filter(x => x.category.countOM);
    });
  }

  dateValid() {
    console.log(this.date);
    return this.date;
  }

  cancel() {
    this.api.showLoginWindow2 = null ;
  }
}
