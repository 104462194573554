import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef, EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import * as $ from 'jquery';
import {Router} from '@angular/router';
import {APIService} from '../../api';
import {Medal} from '../../medals/medal.enum';
import {Subject} from "rxjs";


@Component({
  selector: 'app-club100-list',
  templateUrl: './club100-list.component.html',
  styleUrls: ['./club100-list.component.css']
})
export class Club100ListComponent implements AfterViewInit {
  @Input() persons;
  @Input() fields;
  @Input() sorting = 'age';
  @Output() reload = new EventEmitter();


  @ViewChild('scrollMe') private myScrollContainer: any;
  @ViewChild('d') private d: any;

  currentYear = this.api.parameters.year;

  checkboxSection = true;
  checkboxBaby = false;
  checkboxResign = false;

  orderBy = null;

  loaded = false;
  userId;
  listjuniors;
  currentlist;
  listJuniorsOrderByAge;
  categories = [21, 18, 16, 14, 12, 10];
  param = 'age';
  animating = false;
  over;
  innerHeight = 0;
  offset = 0;
  scrollLeft;
  top;
  bottom;
  lessons;


  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.innerHeight = window.innerHeight;
  }
  @HostListener("window:scroll", [])
  onWindowScroll() {
   this.offset = window.scrollY;
   if(this.myScrollContainer) {
     this.top = this.myScrollContainer.nativeElement.getBoundingClientRect().top;
     this.bottom = this.myScrollContainer.nativeElement.getBoundingClientRect().bottom;
   }
  }

  test2(e) {
    this.scrollLeft = this.myScrollContainer.nativeElement.scrollLeft;
  }

  constructor(public api: APIService, private router: Router) {

  }

  removeFromArray(array, value) {
    var idx = array.indexOf(value);
    if (idx !== -1) {
      array.splice(idx, 1);
    }
    return array;
  }

  isFirstOfCategory(junior) {
    if(this.param!=='age'){
      return false;
    }
    let index = this.listJuniorsOrderByAge.indexOf(junior);
    if (index === 0)
      return true;
    if (this.getCategory(this.listJuniorsOrderByAge [index-1]) !== this.getCategory(junior)) {
      return true;
    }
  }

  getCategory(junior) {
    let c = null;
    for (const category of this.categories) {
      if (junior.age > category) {
        return c;
      }
      c = category;
    }
    return c;
  }

  orderByAge() {
    const c = [...this.currentlist];
    c.sort( (a, b) => a.birthday > b.birthday ?  1 : -1);
    return c;
  }

  addCategory(list) {
    for (const junior of list) {
      const year = junior.birthday.getFullYear();
      junior.age = this.currentYear - year;
    }
  }

  orderByBirthday() {
    this.orderBy = 'birthday';
    const c = [...this.listjuniors];
    c.sort( (a, b) => {
      if (a.birthday && !b.birthday) {
        return -1;
      }
      if (!a.birthday && b.birthday) {
        return 1;
      }
      if (a.birthday !== null && b.birthday !== null) {
        if (a.birthday < b.birthday) {
          return -1;
        }
        if (a.birthday >= b.birthday) {
          return 1;
        }
      }
    });
    this.listjuniors = c;
  }

  orderByName() {
    this.orderBy = 'lastName';
    const c = [...this.listjuniors];
    c.sort( (a, b) => a.lastName.toUpperCase() > b.lastName.toUpperCase() ?  1 : (a.lastName.toUpperCase() === b.lastName.toUpperCase() && a.firstName.toUpperCase() > b.firstName.toUpperCase()) ? 1 : -1);
    this.listjuniors = c;
  }

  orderByGender() {
    this.orderBy = 'gender';
    const c = [...this.listjuniors];
    c.sort( (a, b) => {
      if (a.gender < b.gender) {
        return -1;
      }
      return 0;
    });
    this.listjuniors = c;
  }

  orderByFirstName() {
    this.orderBy = 'firstName';
    const c = [...this.listjuniors];
    c.sort( (a, b) => a.firstName.toUpperCase() > b.firstName.toUpperCase() ?  1 : (a.firstName.toUpperCase() === b.firstName.toUpperCase() && a.lastName.toUpperCase() > b.lastName.toUpperCase()) ? 1 : -1);
    this.listjuniors = c;
  }

  orderByHcp() {
    this.orderBy = 'hcp';
    const c = [...this.listjuniors];
    c.sort( (a, b) => {
      if (a.hcp && !b.hcp) {
        return -1;
      }
      if (!a.hcp && b.hcp) {
        return 1;
      }
      if (a.hcp !== null && b.hcp !== null) {
        if (a.hcp < b.hcp) {
          return -1;
        }
        if (a.hcp >= b.hcp) {
          return 1;
        }
      }
      if(a.medal === b.medal){
        return 0;
      }
      if (a.medal === 'G' && (!b.medal || b.medal !== 'G')) {
        return -1;
      }
      if (b.medal === 'G' && (!a.medal || a.medal !== 'G')) {
        return 1;
      }
      if (a.medal === 'S' && (!b.medal || b.medal !== 'S')) {
        return -1;
      }
      if (b.medal === 'S' && (!a.medal || a.medal !== 'S')) {
        return 1;
      }
      if (a.medal === 'B' && (!b.medal || b.medal !== 'B')) {
        return -1;
      }
      if (b.medal === 'B' && (!a.medal || a.medal !== 'B')) {
        return 1;
      }
      return 0;
    });
    this.listjuniors = c;
  }

  orderByLast() {
    if (this.orderBy === 'hcp') {
      this.orderByHcp();
    } else if (this.orderBy === 'firstName') {
      this.orderByFirstName();
    } else if(this.orderBy === 'lastName') {
      this.orderByName();
    }
  }

  orderByNbTours() {
    const c = [...this.listjuniors];
    c.sort( (a, b) => {
      if (a.nbTours < b.nbTours) {
        return 1;
      }
      if (a.nbTours > b.nbTours) {
        return -1;
      }
      if (a.hcp === undefined && b.hcp !== undefined) {
        return 1;
      }
      if (a.hcp !== undefined && b.hcp === undefined) {
        return -1;
      }
      if (a.hcp < b.hcp) {
        return -1;
      }
      if (a.hcp >= b.hcp) {
        return 1;
      }
      if (a.medal === undefined && b.medal !== undefined) {
        return 1;
      }
      if (a.medal !== undefined && b.medal === undefined) {
        return -1;
      }
      if (a.medal < b.medal) {
        return 1;
      }
      if (a.medal >= b.medal) {
        return -1;
      }
    });
    return c;
  }

  ngAfterViewInit() {
  }

  ngOnInit() {
    this.listjuniors = this.persons;
  }

    test() {
    if (this.animating) {
      return;
    }
    this.animating = true;
    let sortedList;
    if (this.sorting === 'name') {
      sortedList = this.orderByName();
    }
    else if (this.sorting === 'hcp') {
      sortedList = this.orderByHcp();
    }
    else if (this.sorting === 'nbtours') {
      sortedList = this.orderByNbTours();
    }
    else {
      sortedList = this.orderByAge();
    }

    const self = this;
    const list = self.listjuniors;
    const position_div_category = [];
    const height = $('.list_junior').height();
    $('.list_junior').css('height', height+'px');
    $('.category_title').each(function (index, value) {
      position_div_category.push($(this).position().top);
    });
      const position_div_juniors = [];
      $('.test').each(function (index, value) {
        position_div_juniors.push($(this).position().top);
      });

    $('.category_title').each(function (index, value) {
      $(this).css('position', 'absolute');
      $(this).css('top', position_div_category[index]);

      $(this).animate({'left': self.sorting === 'age' ? 0 : 150, 'opacity': self.sorting === 'age' ? 1 : 0}, 600, function(){
      });
    });
      $('.test').each(function (index, value) {
      const junior = list.find(j => j.userId === list[index].userId);
      const position = sortedList.findIndex(j => j.userId === list[index].userId)
      console.log(position);
      $(this).css('position', 'absolute');
      $(this).css('top', position_div_juniors[index]);
      let top = position * $(this).height();
      if (self.sorting === 'age'){
        top += (self.categories.indexOf(self.getCategory(junior)) + 1 ) * 45;
      }
        $(this).animate({'top': top}, 500, function() {

        });
    });

    $.when($('.test')).then(x => {
    this.animating = false;
    if (self.param === 'age') {
      self.param = self.sorting;
    }
    else {
      self.param = self.sorting;
      $('.category_title').each(function (index, value) {
        $(this).css('position', '');
        $(this).css('top', '');
      });
    }
        $('.list_junior').css('height', '');
        $('.test').each(function (index, value) {
          $(this).css('position', 'relative');
          $(this).css('top', '');
        });

    self.listjuniors = sortedList;

    }
      );

  }

  edit(junior) {
    const subject = new Subject();
    subject.subscribe((data: any) => {
      this.reload.next();
    });
    this.api.openPopup({type: 'edit_member_club100', subject, data: junior});
  }

  change() {
      this.listjuniors = this.currentlist.filter((x) => (!x.resignDate && x.inSection  && this.checkboxSection) || (!x.inSection && this.checkboxBaby) || (x.resignDate && this.checkboxResign) );
      this.sort(this.orderBy);
  }

  sort(d) {
    if (d === 'hcp') {
      this.orderByHcp();
    }
    else if (d==='lastName') {
      this.orderByName();
    }
    else if (d==='firstName') {
      this.orderByFirstName();
    }
    else if (d==='birthday') {
      this.orderByBirthday();
    }
    else if (d==='gender') {
      this.orderByGender();
    }
  }

  parseInt(n){
    return parseInt(n);
  }

}
