<div *ngIf="!delete" style="position:relative; overflow-y: hidden; padding: 20px;text-align: center">
  <div *ngIf="imageChangedEvent" style="background: white; height: 400px">
    <image-cropper
      [imageChangedEvent]="imageChangedEvent"
      [maintainAspectRatio]="true"
      [aspectRatio]="1"
      format="jpeg"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded($event)"
      (cropperReady)="cropperReady()"
      (loadImageFailed)="loadImageFailed()">
    </image-cropper>
  </div>

  <div *ngIf="!imageChangedEvent">
  <h1>
    <span *ngIf="!data.id">Ajout</span><span *ngIf="data.id">Modification</span> d'un membre du staff
  </h1>
  <div class="row">
    <div class="col-lg-6">
      <label>
        Nom
      </label>
      <input style="font-size:25px;width: 100%" type="text" [(ngModel)]="copyData.lastName"/>
    </div>
    <div class="col-lg-6">
      <label>
        Prénom
      </label>
      <input style="font-size:25px;width: 100%" [(ngModel)]="copyData.firstName"/>
    </div>
    <div class="col-lg-12" style="margin-bottom:5px;margin-top:5px;display: flex; justify-content: center;">
      <input #file style="display:none" type="file" (change)="fileChangeEvent($event)" />
      <div style="position: relative" (mouseenter)="imgMouseenter = true" (mouseleave)="imgMouseenter = false">
        <img style="min-height: 120px; max-height: 120px; min-width: 120px; max-width: 120px;object-fit:cover" [src]="copyData && copyData.image ? (copyData.image | secure | async) : 'assets/man-head.svg'" />
        <div *ngIf="imgMouseenter" style="display:flex;align-items:center;justify-content:center;position: absolute;width: 100%;height: 100%;top:0;background-color:#FFFFFFAA">
          <a style="vertical-align: middle" (click)="file.click()"
             class="edit btn-floating waves-effect waves-light red"><i class="material-icons">upload</i></a>
        </div>
        <div (click)="copyData.image=null" *ngIf="copyData.image && imgMouseenter" style="display:flex;position: absolute;top:0;">
          <img src="assets/error.svg" style="cursor: pointer; width: 20px;">
        </div>
      </div>
      </div>
    <div class="col-lg-6">
      <label>
        Fonction(s)
      </label>
      <input style="font-size:25px;width: 100%" [(ngModel)]="copyData.function"/>
    </div>
    <div class="col-lg-6">
      <label>
        Email
      </label>
      <input style="font-size:16px;width: 100%" type="email"  [(ngModel)]="copyData.email"/>
    </div>
    <div class="col-lg-6">
      <label>
        Téléphone
      </label>
      <input style="font-size:16px;width: 100%" type="email"  [(ngModel)]="copyData.phone"/>
    </div>

  </div>
  </div>
</div>
<div *ngIf="!delete" style="position: absolute;display:flex; bottom:-50px;left: 0; width:100% ;">
  <button *ngIf="data.id" (click)="delete=true;" style="background:#F44336; border: 2px solid #F44336; color:white;">Supprimer</button>
  <div style="width: 100%"></div>
  <a class="edit btn-floating waves-effect waves-light" style="min-width:40px; cursor:pointer; background-color: #F44336 !important" (click)="valid()"><i class="material-icons">save</i></a>
</div>

<div *ngIf="delete" style="overflow-y: auto; padding: 20px; height: 100%">

  <h1>
    <span>Etes-vous sûr de vouloir supprimer {{data.firstName+" "+data.lastName}} ?</span>
  </h1>
  <div style="display: flex;justify-content:center; align-items: center">
    <button *ngIf="data" (click)="sendDelete(data);" style="padding:10px; margin:10px; background:white; border: 2px solid black; color:black;">Oui</button>
    <button *ngIf="data" (click)="delete=false;" style="padding:10px; margin:10px;background:white; border: 2px solid black; color:black;">Non</button>
  </div>


</div>
