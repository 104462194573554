import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {APIService} from '../api';
import * as $ from 'jquery';
import {Router} from '@angular/router';
import {Medal} from '../medals/medal.enum';


@Component({
  selector: 'app-list-juniors',
  templateUrl: './list_juniors.component.html',
  styleUrls: ['./list_juniors.css']
})
export class ListJuniorsComponent implements AfterViewInit {
  @Input() type;
  @Input() fields;
  @Input() category = [];
  @Input() sorting = 'age';


  @ViewChild('scrollMe') private myScrollContainer: any;
  @ViewChild('d') private d: any;

  currentYear = this.api.parameters.year;

  checkboxSection = true;
  checkboxBaby = false;
  checkboxResign = false;

  orderBy = null;

  loaded = false;
  userId;
  listjuniors;
  currentlist;
  listJuniorsOrderByAge;
  categories = [21, 18, 16, 14, 12, 10];
  param = 'age';
  animating = false;
  over;
  innerHeight = 0;
  offset = 0;
  scrollLeft;
  top;
  lessons;

  medals2 = {B: Medal.BRONZE, S: Medal.SILVER, G: Medal.GOLD};


  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.innerHeight = window.innerHeight;
  }
  @HostListener("window:scroll", [])
  onWindowScroll() {
   this.offset = window.scrollY;
   if(this.myScrollContainer) {
     this.top = this.myScrollContainer.nativeElement.getBoundingClientRect().top;
   }
  }

  test2(e) {
    this.scrollLeft = this.myScrollContainer.nativeElement.scrollLeft;
  }

  constructor(public api: APIService, private router: Router) {

    this.api.updateGroup.subscribe((res) => {
      console.log('changegroup');
      let junior = this.currentlist.find((r) => r.id === res['junior']);
      let groups = [...junior.groups];
      let lesson = this.lessons.find((r) => r.id === res['lesson']);
        lesson.groups.map((group) => {console.log(group); let c= group.juniors.find((j)=>j.id === junior.id)
        console.log(c);
        if(c) {
          this.removeFromArray(group.juniors, c);
          console.log(group.juniors);
        }
      });

      this.removeFromArray(groups, groups.find((group) => group.lessonId === res['lesson']));
      if (res['group']) {
        let newGroup = lesson.groups.find((group)=>{return group.id === res['group'].id});
        newGroup.juniors.push({id:junior.id,firstName:junior.firstName,lastName:junior.lastName})
        groups.push(res['group']);
      }
      junior.groups = groups;

    });

    this.api.updatejunior.subscribe((res)=>{
      console.log('added');
      if(res) {
        this.currentlist.push(res);
      }
      this.orderByName(); //TODO: order the same than last one
      this.change();
    });

    this.getScreenSize();
    this.api.getLessons(true).then((l) => {
      console.log(l);
        this.lessons = l.filter(x => !x.archived);
        api.getJuniors().then(res => {
          this.listjuniors = res;
          this.currentlist = res;
          if(this.sorting==='birthday'){
            this.orderByBirthday();
          }
          else {
            this.orderByName();
          }
          this.currentlist = [...this.listjuniors];
          this.change();

          //this.listJuniorsOrderByAge = [...this.listjuniors];
          //this.addCategory(this.listjuniors);
          this.api.hasLoad('juniors');
          this.loaded = true;
        });
      });
  }

  removeFromArray(array, value) {
    var idx = array.indexOf(value);
    if (idx !== -1) {
      array.splice(idx, 1);
    }
    return array;
  }

  isFirstOfCategory(junior) {
    if(this.param!=='age'){
      return false;
    }
    let index = this.listJuniorsOrderByAge.indexOf(junior);
    if (index === 0)
      return true;
    if (this.getCategory(this.listJuniorsOrderByAge [index-1]) !== this.getCategory(junior)) {
      return true;
    }
  }

  getCategory(junior) {
    let c = null;
    for (const category of this.categories) {
      if (junior.age > category) {
        return c;
      }
      c = category;
    }
    return c;
  }

  orderByAge() {
    const c = [...this.currentlist];
    c.sort( (a, b) => a.birthday > b.birthday ?  1 : -1);
    return c;
  }

  addCategory(list) {
    for (const junior of list) {
      const year = junior.birthday.getFullYear();
      junior.age = this.currentYear - year;
    }
  }

  orderByBirthday() {
    this.orderBy = 'birthday';
    const c = [...this.listjuniors];
    c.sort( (a, b) => {
      if (a.birthday && !b.birthday) {
        return -1;
      }
      if (!a.birthday && b.birthday) {
        return 1;
      }
      if (a.birthday !== null && b.birthday !== null) {
        if (a.birthday < b.birthday) {
          return -1;
        }
        if (a.birthday >= b.birthday) {
          return 1;
        }
      }
    });
    this.listjuniors = c;
  }

  orderByName() {
    this.orderBy = 'lastName';
    const c = [...this.listjuniors];
    c.sort( (a, b) => a.lastName.toUpperCase() > b.lastName.toUpperCase() ?  1 : (a.lastName.toUpperCase() === b.lastName.toUpperCase() && a.firstName.toUpperCase() > b.firstName.toUpperCase()) ? 1 : -1);
    this.listjuniors = c;
  }

  orderByGender() {
    this.orderBy = 'gender';
    const c = [...this.listjuniors];
    c.sort( (a, b) => {
      if (a.gender < b.gender) {
        return -1;
      }
      return 0;
    });
    this.listjuniors = c;
  }

  orderByFirstName() {
    this.orderBy = 'firstName';
    const c = [...this.listjuniors];
    c.sort( (a, b) => a.firstName.toUpperCase() > b.firstName.toUpperCase() ?  1 : (a.firstName.toUpperCase() === b.firstName.toUpperCase() && a.lastName.toUpperCase() > b.lastName.toUpperCase()) ? 1 : -1);
    this.listjuniors = c;
  }

  orderByHcp() {
    this.orderBy = 'hcp';
    const c = [...this.listjuniors];
    c.sort( (a, b) => {
      if (a.hcp && !b.hcp) {
        return -1;
      }
      if (!a.hcp && b.hcp) {
        return 1;
      }
      if (a.hcp !== null && b.hcp !== null) {
        if (a.hcp < b.hcp) {
          return -1;
        }
        if (a.hcp >= b.hcp) {
          return 1;
        }
      }
      if(a.medal === b.medal){
        return 0;
      }
      if (a.medal === 'G' && (!b.medal || b.medal !== 'G')) {
        return -1;
      }
      if (b.medal === 'G' && (!a.medal || a.medal !== 'G')) {
        return 1;
      }
      if (a.medal === 'S' && (!b.medal || b.medal !== 'S')) {
        return -1;
      }
      if (b.medal === 'S' && (!a.medal || a.medal !== 'S')) {
        return 1;
      }
      if (a.medal === 'B' && (!b.medal || b.medal !== 'B')) {
        return -1;
      }
      if (b.medal === 'B' && (!a.medal || a.medal !== 'B')) {
        return 1;
      }
      return 0;
    });
    this.listjuniors = c;
  }

  orderByLast() {
    if (this.orderBy === 'hcp') {
      this.orderByHcp();
    } else if (this.orderBy === 'firstName') {
      this.orderByFirstName();
    } else if(this.orderBy === 'lastName') {
      this.orderByName();
    }
  }

  orderByNbTours() {
    const c = [...this.listjuniors];
    c.sort( (a, b) => {
      if (a.nbTours < b.nbTours) {
        return 1;
      }
      if (a.nbTours > b.nbTours) {
        return -1;
      }
      if (a.hcp === undefined && b.hcp !== undefined) {
        return 1;
      }
      if (a.hcp !== undefined && b.hcp === undefined) {
        return -1;
      }
      if (a.hcp < b.hcp) {
        return -1;
      }
      if (a.hcp >= b.hcp) {
        return 1;
      }
      if (a.medal === undefined && b.medal !== undefined) {
        return 1;
      }
      if (a.medal !== undefined && b.medal === undefined) {
        return -1;
      }
      if (a.medal < b.medal) {
        return 1;
      }
      if (a.medal >= b.medal) {
        return -1;
      }
    });
    return c;
  }

  ngAfterViewInit() {


  }


  test() {
    if (this.animating) {
      return;
    }
    this.animating = true;
    let sortedList;
    if (this.sorting === 'name') {
      sortedList = this.orderByName();
    }
    else if (this.sorting === 'hcp') {
      sortedList = this.orderByHcp();
    }
    else if (this.sorting === 'nbtours') {
      sortedList = this.orderByNbTours();
    }
    else {
      sortedList = this.orderByAge();
    }

    const self = this;
    const list = self.listjuniors;
    const position_div_category = [];
    const height = $('.list_junior').height();
    $('.list_junior').css('height', height+'px');
    $('.category_title').each(function (index, value) {
      position_div_category.push($(this).position().top);
    });
      const position_div_juniors = [];
      $('.test').each(function (index, value) {
        position_div_juniors.push($(this).position().top);
      });

    $('.category_title').each(function (index, value) {
      $(this).css('position', 'absolute');
      $(this).css('top', position_div_category[index]);

      $(this).animate({'left': self.sorting === 'age' ? 0 : 150, 'opacity': self.sorting === 'age' ? 1 : 0}, 600, function(){
      });
    });
      $('.test').each(function (index, value) {
      const junior = list.find(j => j.userId === list[index].userId);
      const position = sortedList.findIndex(j => j.userId === list[index].userId)
      console.log(position);
      $(this).css('position', 'absolute');
      $(this).css('top', position_div_juniors[index]);
      let top = position * $(this).height();
      if (self.sorting === 'age'){
        top += (self.categories.indexOf(self.getCategory(junior)) + 1 ) * 45;
      }
        $(this).animate({'top': top}, 500, function() {

        });
    });

    $.when($('.test')).then(x => {
    this.animating = false;
    if (self.param === 'age') {
      self.param = self.sorting;
    }
    else {
      self.param = self.sorting;
      $('.category_title').each(function (index, value) {
        $(this).css('position', '');
        $(this).css('top', '');
      });
    }
        $('.list_junior').css('height', '');
        $('.test').each(function (index, value) {
          $(this).css('position', 'relative');
          $(this).css('top', '');
        });

    self.listjuniors = sortedList;

    }
      );

  }

  detail(junior) {
    this.router.navigate(['/junior/' + junior.userId]);
  }

  edit(junior) {
      console.log(junior);
      document.body.classList.add("noScroll")
      this.api.showLoginWindow2 = {'type':'edit_junior','data':junior!=null?junior:{}};
  }

  editGroup(lesson, junior) {
    if (lesson) {
      this.api.openPopup(this.api.showLoginWindow2 = {type: 'edit_group', data: {lesson, junior}});
    }
  }

  change() {
      if (this.category.includes('baby')) {
        this.checkboxBaby = true;
      }
      this.listjuniors = this.currentlist.filter((x) => (!x.resignDate && x.inSection  && this.checkboxSection) || (!x.inSection && this.checkboxBaby) || (x.resignDate && this.checkboxResign) );
      this.sort(this.orderBy);
  }

  sort(d) {
    if (d === 'hcp') {
      this.orderByHcp();
    }
    else if (d==='lastName') {
      this.orderByName();
    }
    else if (d==='firstName') {
      this.orderByFirstName();
    }
    else if (d==='birthday') {
      this.orderByBirthday();
    }
    else if (d==='gender') {
      this.orderByGender();
    }
  }

  parseInt(n){
    return parseInt(n);
  }

}
