import {AfterViewChecked, AfterViewInit, Component, ElementRef, HostListener, Input, ViewChild} from '@angular/core';
import {APIService} from '../api';
import * as $ from 'jquery';
import {Router} from '@angular/router';


@Component({
  selector: 'app-edit-juniors',
  templateUrl: './edit_juniors.component.html',
  styleUrls: ['./edit_juniors.css']
})
export class EditJuniorsComponent {
  @Input() junior;
  @ViewChild('scrollMe') private myScrollContainer: any;
  @ViewChild('d') private d: any;

  juniorEdit;
  radioSelected = 'section'

  ngOnChanges(changes) {
    this.juniorEdit = {...changes.junior.currentValue};
    if(this.juniorEdit.inSection===false){
      this.radioSelected = 'baby';
    }
    if(this.juniorEdit.resignDate){
      this.radioSelected = 'resigned';
    }
    console.log(this.juniorEdit);
  }

  loaded = false;
  userId;
  over;
  sorting = 'age';
  top;

  constructor(private api: APIService, private router: Router) {
    this.loaded = true;
  }


  detail(junior) {
    this.router.navigate(['/junior/' + junior.userId]);
  }

  edit(junior) {
    setTimeout(() => {
      this.api.showLoginWindow2 = true;
    }, 5);
  }

  valid() {
    console.log('edit')
    const junior = {...this.juniorEdit};
    if(this.radioSelected === 'section'){
      junior['inSection'] = true;
      junior['resignDate'] = null;
    }
    if(this.radioSelected === 'baby'){
      junior['inSection'] = false;
    }
    if(this.radioSelected === 'resigned'){
      junior['resignDate'] = new Date();
    }
    console.log('edit junior')
    this.api.putJunior(junior).then((res)=>{
      for (let k in res) {
        console.log(k + "--" + res[k])
        this.junior[k] = res[k];
      }
      document.body.classList.remove('noScroll')
      this.api.showLoginWindow2 = null;
      this.api.updatejunior.next(!this.juniorEdit.id ? this.junior : null);
    });
  }
}
