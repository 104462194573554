<div>
  <div style="font-size: 20px;" [ngStyle]="{'margin-top': top}">
    <div style="font-weight:500; color:gray">{{field}}</div>
    <div *ngIf="!editMode" style="display: flex;" [ngStyle]="{'margin-left': editable? '0px': '0'}"><div *ngIf="editable || editable===null" (click)="edit()" style="cursor: pointer; display: flex;width: 20px; margin-right:20px"><img src="assets/edit.svg"/></div><div>{{answer}}</div></div>
    <div *ngIf="editMode" style="margin-left:32px; display: flex">
      <div style="display: flex;margin-left:-32px">
        <div (click)="close()" style="cursor: pointer; display: flex;width: 20px; margin-right:16px"><img style="width: 100%" src="assets/close.svg"/></div>
        <ng-container *ngIf="type=='text' || type=='option'">
          <input [type]="type" [autofocus]="" #element style="height: 30px" [(ngModel)]="changedAnswer" />
          <button class="buttonLink" style="padding-top:2px; padding-bottom:2px; font-size:13px; height:30px" (click)="editMode=false; answer = changedAnswer">Valider</button>
        </ng-container>
          <select *ngIf="type.name==='select'" [(ngModel)]="answer">
            <option *ngFor="let a of type.choices">{{a}}</option>
          </select>
        <app-date-picker *ngIf="type==='date'" [date]="changedAnswer"></app-date-picker>

      </div>
    </div>

  </div>
</div>
