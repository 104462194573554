<div style="position:sticky; position: -webkit-sticky; margin-bottom:10px;top:0; display: flex;width: 100%;background-color: lightgray;color:gray;align-items: center;
    height: 30px;text-align: left">
  <div [ngStyle]="{'left':scrollLeft || scrollLeft==0?null:0}" style="display: flex;position: sticky; position: -webkit-sticky;background-color: lightgray;">
    <div  style="min-width:70px;width: 70px;">
    </div>
    <div (click)="clickHeader('lastName')" [ngClass]="{'sorted':sorted==='lastName'}" class="sortable" ngClass="" style="min-width:150px;width: 150px;">
      <div class="cell">NOM</div>
    </div>
    <div (click)="clickHeader('firstName')" [ngClass]="{'sorted':sorted==='firstName'}" class="sortable" style="min-width:120px;width: 120px;">
      <div class="cell">PRENOM</div>
    </div>

  </div>
  <div [ngStyle]="{'margin-left':'-'+(scrollLeft || scrollLeft == 0?scrollLeft:0)+'px'}" style="background-color: lightgray;min-width:5px;width: 5px;">
  </div>

  <div style="background-color: lightgray;width: 10px;min-width: 10px;">
  </div>
  <div (click)="clickHeader('society')" [ngClass]="{'sorted':sorted==='society'}" class="sortable" style="background-color: lightgray;width: 180px;min-width: 180px;">
    <div class="cell">SOCIETE</div>
  </div>
  <div *ngIf="!fields || fields.includes('mail')" style="background-color:lightgray;width: 200px;min-width: 200px">
    <div class="cell">EMAIL</div>
  </div>
  <div *ngIf="!fields || fields.includes('submission')"  style="background-color: lightgray;width: 120px;min-width: 120px">
    <div class="cell">SOUMISSION</div>
  </div>
  <div *ngIf="!fields || fields.includes('validation')" style="background-color: lightgray;width: 120px;min-width: 120px">
    <div class="cell">VALIDATION</div>
  </div>
  <div *ngIf="!fields || fields.includes('visible')"  style="background-color: lightgray;width: 120px;min-width: 120px">
    <div class="cell">VISIBLE</div>
  </div>
  <div *ngIf="!fields || fields.includes('recontact')" style="background-color: lightgray;width: 120px;min-width: 120px">
    <div class="cell">RECONTACTER</div>
  </div>
  <div *ngIf="!fields || fields.includes('reference')" style="background-color: lightgray;width: 140px;min-width: 140px">
    <div class="cell">REFERENCE</div>
  </div>
  <div #scroll style="width: 100%;">
    <div class="cell" ></div>
  </div>
</div>
