import {
  AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {Router} from '@angular/router';
import {APIService} from '../../api';
import {ImageCroppedEvent, LoadedImage} from 'ngx-image-cropper';

@Component({
  selector: 'app-survey-management',
  templateUrl: './survey-management.component.html',
  styleUrls: ['./survey-management.component.css']
})
export class SurveyManagementComponent {

  loaded = false;
  loaded2 = false;
  answers = null;
  mode = 'summary';
  juniors = [];
  other = [];
  options = [
    {
      text: 'Polos',
      textP: 'polos',
      textS: 'polo',
      required: true,
      selected: true,
      choice: 'single',
      sizes: [
        {text: '110-120', selected: false, value: 0},
        {text: '130-140', selected: false, value: 0},
        {text: '150-160', selected: false, value: 0},
        {text: 'XS', selected: false, value: 0},
        {text: 'S', selected: false, value: 0},
        {text: 'M',   selected: false, value: 0},
        {text: 'L', selected: false, value: 0},
        {text: 'XL', selected: false, value: 0},
        {text: 'XXL', selected: false, value: 0},
        {text: 'XS Lady', selected: false, value: 0},
        {text: 'S Lady', selected: false, value: 0},
        {text: 'M Lady', selected: false, value: 0},
        {text: 'L Lady', selected: false, value: 0}
      ]
    },
    {
      text: 'Sweats',
      textP: 'sweats',
      textS: 'sweat',
      required: true,
      selected: true,
      sizes: [
        {text: '10A', selected: false, value: 0},
        {text: '12A', selected: false, value: 0},
        {text: 'S', selected: false, value: 0},
        {text: 'M', selected: false, value: 0},
        {text: 'L', selected: false, value: 0},
        {text: 'XL', selected: false, value: 0},
        {text: 'XXL', selected: false, value: 0},
      ]
    },
    {
      text: 'Gilets (facultatif)',
      textP: 'gilets',
      textS: 'gilet',
      selected: null,
      sizes: [
        {text: 'XXS', selected: false, value: 0},
        {text: 'XS', selected: false, value: 0},
        {text: 'S', selected: false, value: 0},
        {text: 'M', selected: false, value: 0},
        {text: 'L', selected: false, value: 0},
        {text: 'XL', selected: false, value: 0},
        {text: 'XXL', selected: false, value: 0}
      ]
    }
  ];

  constructor(public api: APIService, private router: Router) {

  }

  ngOnInit(){
    this.reload();
  }


  reload() {
    this.loaded2 = false;
    this.loaded = false;

    Promise.all([
    this.api.getJuniors().then(res => {
      this.loaded2 = true;
      this.juniors = res.filter(x => x.inSection === true && x.resignDate === null );
    }),
    this.api.getEquipementOrder().then((answers) => {
      this.api.changeTitleEvent.next("Commande d\'habits 2024");
      this.answers = answers;
      for (const answer of answers) {
        for (const data of answer.data) {
          const option = this.options.find((x) => x.text === data.type);
          for (const selection of data.selection) {
            const size = option.sizes.find(x => x.text === selection.text);
            console.log(size);
            size.value += selection.value;
          }
        }
      }
      console.log(this.options);
    })
    ]).then(() => {
      for (const answer of this.answers) {
        if (answer.userId) {
          const junior = this.juniors.find(x => x.id === answer.userId);
          if (junior) {
            if(!junior.order) {
              junior.order = [answer];
            }
            else{
              junior.order.push(answer);
            }
          } else {
            this.other.push(answer);
          }
        } else {
          this.other.push(answer);
        }
      }
      this.orderByFirstName();
      this.loaded = true;
    });
  }

  orderByFirstName() {
    const c = [...this.juniors];
    c.sort( (a, b) => a.lastName.toUpperCase() > b.lastName.toUpperCase() ?  1 : (a.lastName.toUpperCase() === b.lastName.toUpperCase() && a.firstName.toUpperCase() > b.firstName.toUpperCase()) ? 1 : -1);
    this.juniors = c;
  }

}
