import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  ViewChild
} from '@angular/core';
import {APIService} from '../../api';

@Component({
  selector: 'app-category-input',
  templateUrl: './category-input.component.html',
  styleUrls: ['./category-input.component.css'],
})
export class CategoryInputComponent implements OnChanges {
  @Input() category;
  @Output() categoryChange = new EventEmitter();
  @ViewChild('input2') input2: ElementRef;
  @ViewChild('categories') categoriesDiv: ElementRef;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    console.log('resize');
    setTimeout(() => {
      let top = (this.input2.nativeElement.getBoundingClientRect().top);
    }, 100);
  }

  focus = false;
  current: any = {name: ''};
  categories = [];
  selectedCategories = [];
  arrow = null;

  ngOnChanges(changes) {
    console.log(changes);
    if (changes.category && this.category) {
      console.log(this.category);
      const found = this.categories.find((c) => c.id === this.category);
      if (found) {
        this.current = found;
      }
    }
  }

  constructor(private api: APIService) {
    this.api.getEventCategories().then((data: any) => {
      this.categories = data;
      this.selectedCategories = [...this.categories];
      const found = this.categories.find((c) => c.id === this.category);
      if (found) {
        this.current = found;
      }
    });
  }


  removeFocus() {
    setTimeout(() => {
      this.focus = false;
      this.arrow = null;
    }, 0);
  }

  setCategory() {
    this.categoryChange.next(this.current.id);
  }

  select(s) {
    this.selectedCategories = this.categories.filter((c) => c.name.toLowerCase().includes(s.toLowerCase()));
    if (this.selectedCategories.length === 1) {
      this.current = this.selectedCategories[0];
      this.categoryChange.next(this.current.id);
      this.focus = false;
      console.log(s.toLowerCase());
      console.log(this.current.name.toLowerCase());
    }
    this.initArrow();
  }

  onKeyPressed(event: KeyboardEvent) {
    if (event.code === 'Backspace') {
      this.current = {name: ''};
      this.categoryChange.next(null);
      this.focus = true;
      this.arrow = null;
      this.select('');
    }
    else if (event.code === 'Tab' || event.code === 'Enter') {
      if (!this.category) {
        this.current = this.arrow;
        this.categoryChange.next(this.current.id);
        this.focus = false;
        this.arrow = null;
        event.preventDefault();
      }
    }
    else if (event.code === 'ArrowDown') {
      if (!this.category) {
        if (this.arrow) {
          const findIndex = this.selectedCategories.findIndex((x) => x === this.arrow);
          if (findIndex < this.selectedCategories.length - 1) {
            this.arrow = this.selectedCategories[findIndex + 1];
            this.categoriesDiv.nativeElement.scrollTop = (findIndex - 2) * 24;
          }
        }
      }
      event.preventDefault();
    }
    else if (event.code === 'ArrowUp') {
      if (!this.category) {
        if (this.arrow) {
          const findIndex = this.selectedCategories.findIndex((x) => x === this.arrow);
          if (findIndex > 0) {
            this.arrow = this.selectedCategories[findIndex - 1];
            this.categoriesDiv.nativeElement.scrollTop = (findIndex - 4) * 24;
          }
        }
        event.preventDefault();
      }
    }
    else if (this.category) {
      this.current = {name: ''};
      this.categoryChange.next(null);
      this.focus = true;
      this.arrow = null;
      this.select('');

    }
  }


  addFocus() {
    if (!this.category) {
      this.focus = true;
      this.initArrow();
    }
  }

  private initArrow() {
    if ((!this.arrow || !this.selectedCategories.includes(this.arrow)) && this.selectedCategories.length > 0) {
      this.arrow = this.selectedCategories[0];
    }
  }

  click(c) {
    console.log('clicked');
    this.current=c;
    this.focus=false;
    this.setCategory();
  }
}
