<div (click)="clickLogin()" *ngIf="api.showLoginWindow"
     style="position: absolute;background: rgba(0,0,0,0.8); height: 100%; width:100%;z-index:10">
  <div (click)="$event.stopPropagation()" class="arrow_box"
       style="right:7px; top: 60px; position: absolute; background-color: white; z-index:50;">
    <app-login (success)="clickLogin()"></app-login>
  </div>
</div>

<div *ngIf="api.showLoginWindow2"
     style="top:0; height:100%; width:100%; position: fixed; z-index:101;background: rgba(0,0,0,0.8); ">
  <div style="display:flex; align-items:center; justify-content: center; height:100%;">

    <div *ngIf="api.showLoginWindow2.type"
         style="display:flex; position:relative; width: calc(100% - 50px); max-height: calc(100% - 100px);max-width: 940px; ">
      <div (click)="onClickOutside()" style="position: absolute;top:-20px;left: -20px; z-index: 1;">
        <img style="cursor:pointer;width: 40px" src="assets/error.svg">
      </div>
      <div style="background-color: white; width:100%;overflow: auto">
        <app-program-edition *ngIf="api.showLoginWindow2.type==='edit_program'" [data]="api.showLoginWindow2.data" [subject]="api.showLoginWindow2.subject" [subjectDelete]="api.showLoginWindow2.subjectDelete"
                        (close)="this.api.closePopup()"></app-program-edition>
        <app-edit-medal *ngIf="api.showLoginWindow2.type==='edit_medal'" [data]="api.showLoginWindow2.data"
                        (close)="this.api.closePopup()"></app-edit-medal>
        <app-delete-participant *ngIf="api.showLoginWindow2.type==='deleteParticipant'" [data]="api.showLoginWindow2.data" [subject]="api.showLoginWindow2.subject"></app-delete-participant>
        <app-edit-lessons *ngIf="api.showLoginWindow2.type==='edit_lessons'" [subject]="api.showLoginWindow2.subject" [junior]="api.showLoginWindow2.data"></app-edit-lessons>
        <app-edit-lesson *ngIf="api.showLoginWindow2.type==='edit_lesson'" [data]="api.showLoginWindow2.data" [hasDatesLinked]="api.showLoginWindow2.hasDatesLinked" [subject]="api.showLoginWindow2.subject" [subjectDelete]="api.showLoginWindow2.subjectDelete"></app-edit-lesson>
        <app-edit-juniors *ngIf="api.showLoginWindow2.type==='edit_junior'" [junior]="api.showLoginWindow2.data"></app-edit-juniors>
        <app-edit-group *ngIf="api.showLoginWindow2.type==='edit_group'" [data]="api.showLoginWindow2.data"></app-edit-group>
        <app-programm-filter  *ngIf="api.showLoginWindow2.type==='filter'"  [data]="api.showLoginWindow2.data" [subject]="api.showLoginWindow2.subject"></app-programm-filter>
        <app-close-competition [action]="api.showLoginWindow2.type==='closeCompetition'?'close':'open'"  *ngIf="api.showLoginWindow2.type==='closeCompetition' || api.showLoginWindow2.type==='openCompetition'"  [data]="api.showLoginWindow2.data" [subject]="api.showLoginWindow2.subject"></app-close-competition>
        <app-add-competition [action]="api.showLoginWindow2.type==='addCompetition'?'close':'open'"  *ngIf="api.showLoginWindow2.type==='addCompetition'"  [data]="api.showLoginWindow2.data" [subject]="api.showLoginWindow2.subject"></app-add-competition>
        <app-staff-sort *ngIf="api.showLoginWindow2.type==='sortStaff'" [staff]="api.showLoginWindow2.data" [subject]="api.showLoginWindow2.subject" [print]="api.showLoginWindow2.print"></app-staff-sort>
        <app-manager-select *ngIf="api.showLoginWindow2.type==='managerSelect'" [data]="api.showLoginWindow2.data" [subject]="api.showLoginWindow2.subject"></app-manager-select>
        <app-edit-manager *ngIf="api.showLoginWindow2.type==='edit_manager'" [data]="api.showLoginWindow2.data" [subject]="api.showLoginWindow2.subject" [subjectDelete]="api.showLoginWindow2.subjectDelete"></app-edit-manager>
        <app-edit-event *ngIf="api.showLoginWindow2.type==='edit_event'" [data]="api.showLoginWindow2.data" [subject]="api.showLoginWindow2.subject" ></app-edit-event>
        <app-club100-member-edit *ngIf="api.showLoginWindow2.type==='edit_member_club100'" [data]="api.showLoginWindow2.data" [subject]="api.showLoginWindow2.subject" ></app-club100-member-edit>


        <div *ngIf="api.showLoginWindow2.type==='diaporama'" style="width:100%;overflow: scroll">
          <video width="100%" controls autoplay>
            <source src="https://juniorsgolfpayerne.ch/docs/charmeil2019.mp4" type="video/mp4">
            <p>Votre navigateur ne prend pas en charge les vidéos HTML5.
              Voici <a href="https://juniorsgolfpayerne.ch/docs/charmeil2019.mp4">un lien pour télécharger la vidéo</a>.</p>
          </video>
        </div>
      </div>
    </div>
  </div>
</div>


<div *ngIf="loaded && !api.parameters" style="margin: 10px">
  Le site est en maintenance. Merci de vous reconnecter plus tard.
</div>

<div *ngIf="api.parameters" style="display: flex; flex-direction: column; height: 100%">
  <app-menu [connected]="auth.isConnected" [year]="api.parameters.year" (disconnect)="auth.disconnect()" (login)="clickLogin()"></app-menu>
  <div style="position: relative; margin: 0;padding:0;">
      <div *ngIf="logo" class="logo big-only">
        <img src="assets/logo_color.svg">
      </div>
      <div [ngClass]="{'noprint':!print}" style="display: flex; justify-content: center"><h1 style="position: relative" *ngIf="title" class="title">{{title}}<span *ngIf="back" (click)="back.next()" style="position: absolute; cursor:pointer; left: -50px;"><img style="height:30px" src="assets/left-arrow.svg"></span> </h1></div>
  </div>
  <div class="site-container" style="display: flex; flex-direction:column" [ngStyle]="{'min-height':api.isSafari?'calc(100% - 126px)':null, 'height':api.isSafari?null:'100%'}">
    <router-outlet></router-outlet>
  </div>

</div>
