<div *ngIf="!loaded" class="text-center" style="height: 263px;display: flex; justify-content: center; align-items: center ">
  <i class="fas fa-circle-notch fa-spin fa-7x"></i>
</div>
<div *ngIf="loaded" style="overflow-y: hidden; padding: 20px;text-align: center">
  <h1>{{data.junior.lastName + " " + data.junior.firstName}}</h1>
  <div [ngStyle]="{'background-color': data.domain.color}" style="text-align:center; font-size: 30px; color:white">
    {{data.domain.name}}
  </div>
  <div style="margin-top: 10px">
    <div (click)="valid(0)"
      style="cursor:pointer; margin:10px;display:inline-block;vertical-align:middle;position:relative; width: 60px; height: 60px; border-radius: 60px; border:solid 1px gray; background-color: white">
      <div style="width:100%; height:100%; display:flex; justify-content:center; align-items:center">
        <i style="color:gray; font-size: 50px" class="material-icons">clear</i>
      </div>
    </div>
    <div (click)="valid(1)"
      style="cursor:pointer; margin:10px;vertical-align:middle;display:inline-block;width: 60px; height: 60px; border-radius: 60px; background-color: #b49e87"></div>
    <div (click)="valid(2)"
      style="cursor:pointer; margin:10px;vertical-align:middle;display:inline-block;width: 60px; height: 60px; border-radius: 60px; background-color: #929295"></div>
    <div (click)="valid(3)"
      style="cursor:pointer; margin:10px;vertical-align:middle;display:inline-block;width: 60px; height: 60px; border-radius: 60px; background-color: #dcc7a2"></div>
  </div>
</div>
