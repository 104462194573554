import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {APIService} from '../../api';

@Component({
  selector: 'app-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.css'],
})
export class ActionButtonComponent {
@Input() color ;
@Input() icon ;

}
