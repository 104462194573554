import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {APIService} from "../../api";
import {Subject} from "rxjs";

@Component({
  selector: 'app-staff-card',
  templateUrl: './staff-card.component.html',
  styleUrls: ['./staff-card.component.scss']
})
export class StaffCardComponent implements OnInit {
  @Input() user;
  @Input() isEditable;
  @Output() delete = new EventEmitter();

  modify = false;
  constructor( public api: APIService) {}

  ngChanges(changes) {
    console.log(changes);
  }

  ngOnInit() {
  }

  updateManager() {
    const subjectDelete = new Subject();
    subjectDelete.subscribe((data: any) => {
      this.api.deleteManager(data.id).then(() => {
        this.delete.emit(data.id);
        this.api.closePopup();
      });
    });
    this.api.openPopup({type: 'edit_manager', subjectDelete, data: this.user});


  }
}
