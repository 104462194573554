<div *ngIf="!loaded"
     style="width: 100%;height: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center">
  <div style="flex: 1 1 40%">
  </div>
  <div *ngIf="!loaded" class="text-center">
    <i class="fas fa-circle-notch fa-spin fa-7x"></i>
  </div>
  <div style="flex: 1 1 60%">
  </div>
</div>

<div *ngIf="loaded && data">

  <app-submenu (onchange)="changeSection($event)" [data]="data"></app-submenu>


  <div *ngIf="!validated && !summaryMode" style="padding-left:10px; padding-right:10px">
    <div style=" width: 100%; max-width: 500px; margin-left: auto; margin-right: auto; display: flex; flex-direction: column; justify-content: center">
      <div style="display:flex; background-color: #eed3aa; padding:10px; border-radius:10px; border: solid 1px orangered">
        <div style="display:flex;align-items: center; margin-right: 10px"><img src="assets/warning.svg"/></div>
        <div>Merci de confirmer les données pour cette section.</div>
      </div>
      <ng-container *ngFor="let entry of selectedSection.fields">
        <app-edit-field *ngIf="!entry.section" [field]="entry.field" [answer]="entry.answer" [type]="entry.type" [editable]="entry.editable!=false">
        </app-edit-field>
        <div *ngIf="entry.section">
          <div style="margin-top: 30px; margin-left: -40px; font-weight: 600; font-size: 24px; color: darkgreen">{{entry.section + (entry.info ? ' ('+entry.info+')' : '')}}</div>
          <app-edit-field *ngFor="let entry2 of entry.fields; index as i" [top]="i === 0 ? '15px' : '30px'" [field]="entry2.field" [answer]="entry2.answer" [type]="entry2.type" [editable]="entry2.editable!=false">
          </app-edit-field>
        </div>
      </ng-container>
    <button style="margin-top: 30px" class="buttonLink" (click)="continue()">Je confirme que ces données sont correctes et passer à la section suivante.</button>
    </div>

    <div style="text-align: center">
      <div *ngFor="let validation of validations" style="color:red">{{validation}}</div>
    </div>
  </div>

  <div *ngIf="!validated && summaryMode" style="padding-left:10px; padding-right:10px">
    <h4>Confirmation de commande</h4>
    <div *ngIf="summaryMode.length==0" style="text-align:center; font-size: 20px;">
      Aucun article commandé
    </div>
    <div *ngFor="let type of summaryMode">
      <div *ngFor="let selection of type.selection" style="min-width:300px; padding-left:20px">
        <div style="text-align:center; font-size: 20px;" *ngIf="selection.value > 0">
          {{selection.value}}x {{selection.value > 1 ? type.textP : type.textS}} - Taille : {{selection.text}}
        </div>
      </div>
    </div>
    <div style="text-align: center; margin-top:40px">
      <div *ngFor="let validation of validations" style="color:red">{{validation}}</div>
      <button style="margin-right: 20px" class="buttonLink" (click)="back()">Retour sans confirmer</button>
      <button class="buttonLink" (click)="confirm()">Confirmer</button>
    </div>
  </div>
</div>

<div *ngIf="validated"
     style="width: 100%;height: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center">
  <h2 style="text-align: center">Merci ! Nous avons enregistré votre commande.</h2>
  <div style="margin-top: 10px">
    <button class="buttonLink" (click)="finished()">Terminer</button>
  </div>
</div>
