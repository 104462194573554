import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {APIService} from "../../api";

@Component({
  selector: 'app-manager-select',
  templateUrl: './manager-select.component.html',
  styleUrls: ['./manager-select.component.scss']
})
export class ManagerSelectComponent implements OnInit {
  staff = [];
  @Input() data;
  @Input() subject;
  @Output() drop = new EventEmitter();
  constructor(private api: APIService) {}

  ngChanges(changes) {
    console.log(changes);
  }

  ngOnInit() {
    this.api.getManagers(true).then(res => {
      this.staff = res.managers.filter(x => !this.data.includes(x.id) && x.email);
    });
  }

  click(user) {
    this.subject.next(user);
  }

}
