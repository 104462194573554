import {Component} from '@angular/core';
import {ActivatedRoute, Route, Router, RoutesRecognized} from '@angular/router';

@Component({
  selector: 'app-medailles',
  templateUrl: './medailles.component.html',
  styleUrls: ['./medailles.component.css'],
})
export class MedaillesComponent {
  editable;
  constructor() {
    Array.from(document.styleSheets).forEach(ss => {
      try {
        Array.from(ss['rules'] || ss['cssRules']).forEach(rule => {
          if (rule instanceof CSSPageRule) {
            rule.style['size'] = 'A3 landscape';
            console.log('New rule size: ' + rule.style['size']);
          }
        });
      } catch (e) {}
    });
  }

}
