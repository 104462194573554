<div *ngIf="!loaded" class="text-center">
  <i class="fas fa-circle-notch fa-spin fa-7x"></i>
</div>
<ng-container *ngIf="loaded">
  <div> <!--style="width: 600px; overflow: scroll"-->
  <div> <!--style="width: 1300px" class="calendar"-->
    <div *ngIf="nbEvents==0">
      Il n'y a pas d'événements
    </div>
    <div *ngIf="nbEvents>0" class="row" style="font-size: 6.5pt; margin:0">
      <div class="month" style="padding: 0px;" [ngClass]="{'noevent':!date[month-1].last}" *ngFor="let month of array(nbMonth)">
        <div class="cell" style=" text-align: center; vertical-align: middle; font-size:20px; background-color: dimgray; color : white; display:table; width: 100%;  height: 20px">
          <div class="month-cell" style="display:table-cell; text-align: center; vertical-align: middle;">
            {{month_LIST[month-1]}}
          </div>
        </div>
        <div class="cell height{{date[month-1][monthNum] ? date[month-1][monthNum].size : 2}} heightp{{date[month-1][monthNum] ? (editMode ? 2 : date[month-1][monthNum].size) : 2}}" *ngFor="let monthNum of array(31)" [ngClass]="{'last':date[month-1].last==monthNum, 'noevent':!date[month-1][monthNum] || date[month-1][monthNum].events.length===0,'empty':monthNum>date[month-1].days}">
          <div *ngIf="date[month-1].days>=monthNum" style="display: table-row">
            <div class="month-num">
              <div style="width: 50px">{{monthNum}}</div>
            </div>
            <div class="day">
              <div style="width: 40px">
                {{day_LIST[getDay(month-1, monthNum)]}}
              </div>
            </div>
            <div style="display:table-cell; position:relative; text-align: center; vertical-align: middle; height: 100%; width: 100%;" >
              <div class="day_events" style="position:relative;display:table; text-align: center; vertical-align: middle;z-index: 0;justify-content:center;height:100%; width:100%;">
                <div class="e" [ngClass]="{'editMode':editMode,'selected':event.state=='selected'}" style="display: table-row" (click)="animateEvent(event)" *ngFor="let event of (date[month-1][monthNum] && date[month-1][monthNum].events && date[month-1][monthNum].events.length>0 ? date[month-1][monthNum].events : []); let i=index">
                  <div style="display: table-cell; position: relative; text-align: center; vertical-align: middle;" [ngClass]="{'editMode':editMode,'selected':event.state=='selected','cancelled':event.cancelled}" class="event event_{{date[month-1][monthNum].events[i].categoryId}}">
                    {{event.name}}
                    <div [@pastille]="event.state" class="pastille event_{{date[month-1][monthNum].events[i].categoryId}}"></div>
                  </div>
                </div>
              </div>
              <div style="position:absolute" [ngClass]="{'weekend':isWeekEnd(month-1, monthNum)}"></div>

            </div>
          </div>
          <div *ngIf="date[month-1].days<monthNum" style="display: table-row; height:100%; background-color:grey">
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</ng-container>

