import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  ViewChild
} from '@angular/core';
import {APIService} from '../api';
import {Router} from '@angular/router';


@Component({
  selector: 'app-edit-lesson',
  templateUrl: './edit_lesson.component.html',
  styleUrls: ['./edit_lesson.component.css']
})
export class EditLessonComponent implements OnChanges {
  @ViewChild('d') container;
  @Input() junior;
  @Input() subject;
  @Input() subjectDelete;
  @Input() data;
  @Input() hasDatesLinked;

  currentData: any = {name: ''};

  deleteValid = false;
  animate = false;
  width = 0;
  left = 0;
  i = 0;

  constructor(private cdRef: ChangeDetectorRef) {

  }


  ngOnChanges(changes) {
    if (changes.data && this.data) {
      this.currentData = {...this.data};
      this.currentData.timeStart = this.timeFormat(this.currentData.timeStart, ':');
      this.currentData.timeEnd = this.timeFormat(this.currentData.timeEnd, ':');
      this.currentData.monitors = this.currentData.monitors.join(', ');
    }
  }

  ngAfterViewInit(){
    this.i = this.currentData.color ? 0 : 1;
    const c = this.container.nativeElement;
    const d = this.container.nativeElement.children[this.i];
    this.selectColor(d, c, false);
    if (!this.currentData.color) {
      this.cdRef.detectChanges();
    }
  }

  // todo: deplace
  timeFormat(time, separator) {
    if (!time) {
      return null;
    }
    const minutes = (time % 60);
    let minutesText = '' + minutes;
    if (minutes <= 9 ) {
      minutesText = '0' + minutes;
    }
    const hour = Math.floor(time / 60);
    let hourText = '' + hour
    if (hour <= 9) {
      hourText = '0' + hour;
    }
    return hourText + separator + minutesText;
  }

  timeFormatToValue(timeFormat, separator) {
    if (!timeFormat) {
      return null;
    }
    const array = timeFormat.split(separator);
    return parseInt(array[0], 10) * 60 + parseInt(array[1], 10);
  }

  selectColor(container, parent, animate = true) {
    if (animate) {
      this.animate = true;
    }
    this.width = container.offsetWidth;
    this.left = container.getBoundingClientRect().x - parent.getBoundingClientRect().x;
    this.cdRef.detectChanges();
  }

  valid() {
    const obj = {...this.currentData};
    if (this.i === 1) {
      obj.color = null;
    }
    obj.timeStart = this.timeFormatToValue(this.currentData.timeStart, ':');
    obj.timeEnd = this.timeFormatToValue(this.currentData.timeEnd, ':');
    delete obj.juniors;
    this.subject.next(obj);
  }

  deleteGroup() {
    this.subjectDelete.next(this.data.id);
  }

  firstClic = false;

  test() {
    this.firstClic = true;
    if (!this.currentData.color) {
      this.currentData.color = '#fdd5fa';
    }
  }
}
