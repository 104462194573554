<div style="position:relative;overflow:hidden; padding:10px; margin:10px;width:50%;box-shadow: 0px 0px 16px 1px rgba(0,0,0,0.25);">


<h3>Programme</h3>
<div style="padding:20px;display: flex; flex-wrap:wrap; flex-direction: column;text-align: center">
  <div style="padding: 10px; ">
  <div style="font-weight:600 ">Année du programme</div>
  <div class="select-style" style="text-align: center">
    <select [(ngModel)]="year" (ngModelChange)="updateParameters({year:year})">
      <option [value]="api.parameters.year-1">{{api.parameters.year-1}}</option>
      <option [value]="api.parameters.year">{{api.parameters.year}}</option>
      <option [value]="api.parameters.year+1">{{api.parameters.year+1}}</option>
    </select>
  </div>
  </div>
  <div style="padding: 10px">
  <div style="font-weight:600 ">Publication du programme</div>
    <div style="text-align: center">
  <label class="switch">
    <input type="checkbox" [(ngModel)]="checkbox" (ngModelChange)="updateParameters({spread:checkbox})">
    <span class="slider round"></span>
  </label>
    </div>
</div>
  <div *ngIf="!checkbox" style="padding: 10px">
    <div style="font-weight:600 ">Message à l'utilisateur</div>
    <app-timed-input [(text)]="text" (textChange)="updateParameters({programUnavailabilityText:$event})" (updating)="update()"></app-timed-input>
  </div>

</div>
  <div *ngIf="sync=='current'" [@inOutAnimation]  style="position:absolute;  border: gray; padding-right:5px; padding-left: 10px; padding-top: 6px; padding-bottom:4px; bottom:0;right:0px;color:gray; border-top-left-radius: 20px; font-size: 12px">Synchronization en cours</div>
  <div *ngIf="sync==true" [@inOutAnimation] style="position:absolute; background-color: green; padding-right:5px; padding-left: 10px; padding-top: 6px; padding-bottom:4px; bottom:0;right:0px;color:white; border-top-left-radius: 20px; font-size: 12px">Synchronizé</div>
  <div *ngIf="sync==false" [@inOutAnimation] style="position:absolute; background-color: darkred; padding-right:5px; padding-left: 10px; padding-top: 6px; padding-bottom:4px; bottom:0;right:0px;color:white; border-top-left-radius: 20px; font-size: 12px">Non-synchronizé</div>
</div>

<div *ngIf="false" style="padding:10px; margin:10px;width:50%;box-shadow: 0px 0px 16px 1px rgba(0,0,0,0.25);">


  <h3>Ordre du mérite</h3>
  <div style="padding:20px;display: flex; flex-wrap:wrap; flex-direction: column;text-align: center">
    <div style="padding: 10px; ">
      <div style="font-weight:600 ">Date de blocage</div>
      <div class="select-style" style="text-align: center">
        <input type="date" [(ngModel)]="date" (ngModelChange)="test()">
      </div>
    </div>
  </div>
</div>

  <div *ngIf="false" style="position:relative;overflow:hidden; padding:10px; margin:10px;width:50%;box-shadow: 0px 0px 16px 1px rgba(0,0,0,0.25);">

  <h3>Groupes</h3>
  <div style="padding:20px;display: flex; flex-wrap:wrap; flex-direction: column;text-align: center">
    <div style="padding: 10px; ">
      <div style="font-weight:600 ">Diffusion des groupes</div>
      <div style="text-align: center">
        <label class="switch">
          <input type="checkbox" [(ngModel)]="checkbox2" (ngModelChange)="updateParameters2({groupAvailability:checkbox2})">
          <span class="slider round"></span>
        </label>
      </div>
    </div>
    <div *ngIf="!checkbox2" style="padding: 10px">
      <div style="font-weight:600 ">Message à l'utilisateur</div>
      <app-timed-input [(text)]="text2" (textChange)="updateParameters2({groupUnavailabilityText:$event})" (updating)="update2()"></app-timed-input>
    </div>
  </div>
  <div *ngIf="sync2=='current'" [@inOutAnimation]  style="position:absolute;  border: gray; padding-right:5px; padding-left: 10px; padding-top: 6px; padding-bottom:4px; bottom:0;right:0px;color:gray; border-top-left-radius: 20px; font-size: 12px">Synchronization en cours</div>
  <div *ngIf="sync2==true" [@inOutAnimation] style="position:absolute; background-color: green; padding-right:5px; padding-left: 10px; padding-top: 6px; padding-bottom:4px; bottom:0;right:0px;color:white; border-top-left-radius: 20px; font-size: 12px">Synchronizé</div>
  <div *ngIf="sync2==false" [@inOutAnimation] style="position:absolute; background-color: darkred; padding-right:5px; padding-left: 10px; padding-top: 6px; padding-bottom:4px; bottom:0;right:0px;color:white; border-top-left-radius: 20px; font-size: 12px">Non-synchronizé</div>
</div>
