import { Component, OnInit } from '@angular/core';
import {APIService} from '../api';
import {animate, animation, state, style, transition, trigger, useAnimation} from '@angular/animations';

export let slideAnimation = animation([
  animate('{{ time }}')
], { params: {time: '500ms' }});

@Component({
  selector: 'app-ordre-merite',
  templateUrl: './ordre_merite.component.html',
  styleUrls: ['./ordre-merite.scss'],
  animations: [
    trigger('slideIn', [
        state('transit', style({height: '0px'})),
        state('inactive', style({height: '0px'})),
        state('transit2', style({height: '*'})),
        state('active', style({height: '*'})),
        transition('active <=> inactive', [
          useAnimation(slideAnimation )]),

        transition('inactive <=> transit', [
          useAnimation(slideAnimation )]),

        transition('transit <=> active', [
          useAnimation(slideAnimation )]),
      ]
    )
  ]
})
export class OrdreMeriteComponent implements OnInit {

  year;

  juniors = [];
  lockDate;
  over = null;
  selected = null;
  form = 'net';
  gender = 1;
  loaded = false;


  constructor(private api: APIService) {
    this.year = this.api.parameters.programYear;
  }

  ngOnInit() {
    this.getResult();
  }

  setGender(gender) {
    this.gender = gender;
    this.getResult();
  }

  setForm(form) {
    this.form = form;
    this.getResult();
  }

  getResult() {
    this.loaded = false;
    this.api.getResults(1, this.year, this.form, this.gender).then((res) => {
      this.juniors = res.ranking;
      this.lockDate = res.lockDate;
      this.loaded = true;
    });
  }

  toggle(junior){
    if(this.selected === junior){
      this.selected = null;
    }
    else{
      this.selected = junior
    }
  }


}
