import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-list-header',
  templateUrl: './list-header.html',
  styleUrls: ['./list-header.css']
})
export class ListHeaderComponent {
  @Input() fields;
  @Input() scrollLeft = null;
  @Input() orderBy = 0;
  @Input() sorted;
  @Input() lessons;
  @Output() sortedChanged = new EventEmitter();


  clickHeader(e) {
    this.sortedChanged.emit(e);
  }
}

