import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-club100-list-header',
  templateUrl: './club100-list-header.component.html',
  styleUrls: ['./club100-list-header.component.css']
})
export class Club100ListHeaderComponent {
  @Input() fields;
  @Input() scrollLeft = null;
  @Input() orderBy = 0;
  @Input() sorted;
  @Input() lessons;
  @Output() sortedChanged = new EventEmitter();


  clickHeader(e) {
    this.sortedChanged.emit(e);
  }
}

