<div *ngIf="!delete" style="position:relative; overflow-y: hidden; padding: 20px;text-align: center">
  <div *ngIf="imageChangedEvent" style="background: white; height: 400px">
    <image-cropper
      [imageChangedEvent]="imageChangedEvent"
      [maintainAspectRatio]="true"
      [aspectRatio]="1"
      format="jpeg"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded($event)"
      (cropperReady)="cropperReady()"
      (loadImageFailed)="loadImageFailed()">
    </image-cropper>
  </div>

  <div *ngIf="!imageChangedEvent">
  <h1>
    <span *ngIf="!data.id">Ajout</span><span *ngIf="data.id">Modification</span> d'un membre du Club des 100
  </h1>
  <div class="row">
    <div class="col-lg-6">
      <label>
        Nom
      </label>
      <input style="font-size:25px;width: 100%" type="text" [(ngModel)]="copyData.lastname"/>
    </div>
    <div class="col-lg-6">
      <label>
        Prénom
      </label>
      <input style="font-size:25px;width: 100%" [(ngModel)]="copyData.firstname"/>
    </div>
    <div class="col-lg-6">
      <label>
        Société
      </label>
      <input style="font-size:25px;width: 100%" [(ngModel)]="copyData.society"/>
    </div>
    <div class="col-lg-6">
      <label>
        Payement validé
      </label>
      <div  style="text-align: center">
        <label class="switch">
          <input type="checkbox" [(ngModel)]="copyData.validation">
          <span class="slider round"></span>
        </label>
      </div>
      <div style="font-size: 12px; color:gray">{{copyData.reference}}</div>
    </div>
    <div class="col-lg-6">
      <label>
        Email
      </label>
      <input style="font-size:16px;width: 100%" type="email"  [(ngModel)]="copyData.mail"/>
    </div>
    <div class="col-lg-6">
    </div>
    <div class="col-lg-6">
      <label>
        Recontacter
      </label>
      <div  style="text-align: center">
        <label class="switch">
          <input type="checkbox" [(ngModel)]="copyData.recontact">
          <span class="slider round"></span>
        </label>
      </div>
    </div>
    <div class="col-lg-6">
      <label>
        Affiché publiquement
      </label>
      <div  style="text-align: center">
        <label class="switch">
          <input type="checkbox" [(ngModel)]="copyData.visibility">
          <span class="slider round"></span>
        </label>
      </div>
    </div>

  </div>
  </div>
</div>
<div *ngIf="!delete" style="position: absolute;display:flex; bottom:-50px;left: 0; width:100% ;">
  <!--<button *ngIf="data.id" (click)="delete=true;" style="background:#F44336; border: 2px solid #F44336; color:white;">Supprimer</button>-->
  <div style="width: 100%"></div>
  <a class="edit btn-floating waves-effect waves-light" style="min-width:40px; cursor:pointer; background-color: #F44336 !important" (click)="valid()"><i class="material-icons">save</i></a>
</div>

<div *ngIf="delete" style="overflow-y: auto; padding: 20px; height: 100%">

  <h1>
    <span>Etes-vous sûr de vouloir supprimer {{data.firstName+" "+data.lastName}} ?</span>
  </h1>
  <div style="display: flex;justify-content:center; align-items: center">
    <button *ngIf="data" (click)="sendDelete(data);" style="padding:10px; margin:10px; background:white; border: 2px solid black; color:black;">Oui</button>
    <button *ngIf="data" (click)="delete=false;" style="padding:10px; margin:10px;background:white; border: 2px solid black; color:black;">Non</button>
  </div>


</div>
