import {
  AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, Output,
  ViewChild
} from '@angular/core';
import {Router} from '@angular/router';
import {APIService} from '../../api';

//TODO: rename because it is also use for open
@Component({
  selector: 'app-edit-event',
  templateUrl: './edit-event.component.html',
  styleUrls: ['./edit-event.component.css']
})
export class EditEventComponent {
  @Input() action;
  @Input() data;
  @Input() subject;
  @Output() close = new EventEmitter();
  loaded = true;
  events = [];
  date;
  name;
  categoryId;
  endDate;
  selectedEvent;

  constructor(private api: APIService, private router: Router) {
    this.api.getEventCategories().then(() => {

    });
  }

  getEvents(){
  }

  cancel() {
    this.api.showLoginWindow2 = null ;
  }

  valid() {
    this.subject.next({date: this.date, endDate: this.endDate, name: this.name, categoryId: this.categoryId});
  }
}
