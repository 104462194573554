<div *ngIf="!loaded || !loaded2" style="width: 100%;height: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center">
  <div style="flex: 1 1 40%">
  </div>
  <div *ngIf="!loaded" class="text-center">
    <i class="fas fa-circle-notch fa-spin fa-7x"></i>
  </div>
  <div style="flex: 1 1 60%">
  </div>
</div>

<div *ngIf="loaded && loaded2" style="padding-left:10px; padding-right:10px">

  <div style="text-align: center; display:flex;border-bottom: 1px solid #EBEBEB; margin-bottom: 10px">
    <div (click)="mode='details'" style="cursor: pointer; font-size:20px; margin-left:10px; margin-right:10px; padding-left: 10px;padding-right: 10px" [ngStyle]="{'border-bottom': mode==='details'?'2px solid green':null}" [ngClass]="{'selected':mode==='details','notselected':mode!=='details'}">Détails
    </div>
  </div>
  <div *ngIf="mode==='details'">
    <h2>Juniors</h2>

    <ng-container  *ngFor="let junior of juniors">
      <div style="display: flex;" [ngStyle]="{'background-color': junior.answer?'rgba(218, 247, 166,0.2)':'rgba(255,204,203,0.2)'}">
        <div style="padding:5px; margin-bottom:-1px;margin-right:-1px;border:1px solid #ebebeb; vertical-align: top;display: inline-block; width:300px;" >{{junior.firstName}} {{junior.lastName}}</div>
      <div style="width:100%; margin-bottom:-1px; padding:5px;border:1px solid #ebebeb;  display: inline-block;">
        <ng-container *ngIf="junior.answer">
        <div *ngFor="let answer of junior.answer">
          <div *ngIf="answer !== junior.answer[0]" style="margin-top:10px; margin-bottom:10px; width: 50px; border-bottom: 1px solid black"></div>
          <ng-container *ngFor="let field of answer.data" style="min-width:300px; padding-left:20px">
            <div *ngIf="field.visible">
              {{field.summaryText}} : <b>{{field.value}}</b>
            </div>
          </ng-container>

       </div>
        </ng-container>
      </div>
      </div>
    </ng-container>
    <h2>Autre</h2>
  <div *ngFor="let answer of other">
    <div style="display: flex;" [ngStyle]="{'background-color': answer?'rgba(218, 247, 166,0.2)':'rgba(255,204,203,0.2)'}">

      <div style="padding:5px; margin-bottom:-1px;margin-right:-1px;border:1px solid #ebebeb; vertical-align: top;display: inline-block; width:300px;" >{{answer.firstname}} {{answer.lastname}} <div>{{answer.mail}}</div></div>
      <div style="width:100%; margin-bottom:-1px; padding:5px;border:1px solid #ebebeb;  display: inline-block;">


    <div *ngFor="let field of answer.data" >
      <div *ngIf="field.visible">
        {{field.summaryText}} : <b>{{field.value}}</b>
      </div>
    </div>
        <div style="font-style: italic">{{answer.comment}}</div>
      </div>
    </div>
  </div>

  </div>
</div>
