<div style="overflow-y: hidden; padding: 20px;text-align: center">

  <h1>{{data.lesson.name}}</h1>
  <h2>{{data.junior.lastName + " " + data.junior.firstName}}</h2>

  <div style="margin-top: 40px; display: flex">
    <div (click)="remove(data.lesson.id,data.junior.id)" style="font-weight: 700; margin:2px; border:1px solid black; width:100%; cursor:pointer;">
      Ne participe pas
    </div>
    <div [ngStyle]="{'background-color':group.color?group.color:null}" *ngFor="let group of data.lesson.groups" (click)="valid(data.lesson.id,group.id, data.junior.id,group)"
         style="margin:2px; border:1px solid black; width:100%; cursor:pointer;">
      <div style="font-weight: 700">{{group.name}}</div>
      <div *ngFor="let junior of group.juniors" style="font-size: 14px">{{junior.lastName + " " + junior.firstName}}</div>
    </div>
  </div>
</div>
