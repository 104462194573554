import {Component, Input, Output} from '@angular/core';
import {APIService} from '../api';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.scss']
})
export class StaffComponent {
  @Input() isEditable = false;
  error;
  loaded;
  staff;
  published = true;

  constructor(public api: APIService) {
    this.loaded = false;
  }

  ngOnInit(){
    this.api.getManagers(this.isEditable).then(res => {
      this.staff = res.managers;
      this.loaded = true;
    }).catch((x)=>{
      if (x.status === 401){
        this.api.redirect('/')
      }
    });
  }

  edit(e) {
    const subject = new Subject();
    subject.subscribe((data) => {
      this.api.putManagersOrder(this.staff.map(x => x.id)).then();
    });
    this.api.openPopup({type: 'sortStaff', subject, data: this.staff});
  }

  add(e) {
    const subject = new Subject();
    subject.subscribe((data: any) => {
        this.api.postManager(data).then();
        this.ngOnInit();
        this.api.closePopup();
    });
    this.api.openPopup({type: 'edit_manager', subject, data: {}});
  }

  postVisible() {
    this.api.patchManagersConfiguration(this.api.clubId, {module: {name: 'managers', published: this.api.managersActivated}}).then(() => {
    }).catch(
      () => {this.api.managersActivated = !this.api.managersActivated; }
    );
  }

  deleteManager(id) {
    const index = this.staff.findIndex(x => x.id === id);
    if (index > -1) {
      this.staff.splice(index, 1);
    }
  }
}
