<div *ngIf="!delete" style="overflow-y: auto; padding: 20px; height: 100%">

  <h1>
    <span *ngIf="!data.id">Ajout</span><span *ngIf="data.id">Modification</span> d'un événement
  </h1>

   <div style="margin-top:25px;margin-right: 12px; margin-bottom: 20px">
        <label>
          <input type="checkbox" [(ngModel)]="moreThanOneDay"> Se déroule sur plusieurs jours
        </label>
   </div>

  <div class="row">


    <div class="col-lg-6">
      <label>
        Date {{moreThanOneDay?'de début':''}}
      </label>
      <input style="font-size:25px;width: 100%" type="date" [(ngModel)]="date1"/>
    </div>
    <div class="col-lg-6" *ngIf="moreThanOneDay">
      <label>
        Date de fin
      </label>
      <input style="font-size:25px;width: 100%" type="date"  [(ngModel)]="date2"/>
    </div>
  </div>

<div class="row mt-3">
  <div class="col-lg-6">
    <label>
      Nom de l'événement <span style="color:red">{{tooLong? '(Trop long)': ''}}</span>    <div style="position:absolute;visibility:collapse; display: inline-block" #d>{{name}}</div>
    </label>
    <input style="font-size:25px;width: 100%" type="text" [(ngModel)]="name" (ngModelChange)="change()"/>

  </div>
  <div class="col-lg-6">
    <label>
      Catégorie
    </label>
    <app-category-input [(category)]="categoryId"></app-category-input>
  </div>
</div>

</div>
<div *ngIf="delete" style="overflow-y: auto; padding: 20px; height: 100%">

  <h1>
    <span>Etes-vous sûr de vouloir supprimer cet événement ?</span>
  </h1>
  <div style="display: flex;justify-content:center; align-items: center">
    <button *ngIf="data" (click)="sendDelete(data);" style="padding:10px; margin:10px; background:white; border: 2px solid black; color:black;">Oui</button>
    <button *ngIf="data" (click)="delete=false;" style="padding:10px; margin:10px;background:white; border: 2px solid black; color:black;">Non</button>
  </div>


</div>
<div *ngIf="!delete" style="position: absolute;display:flex; bottom:-50px;left: 0; width:100% ;">
  <button *ngIf="data.id" (click)="delete=true;" style="background:#F44336; border: 2px solid #F44336; color:white;">Supprimer</button>
  <div style="width: 100%"></div>
  <button [disabled]="!categoryId || !name || !date1" class="border-0 edit btn-floating waves-effect waves-light" style="min-width:40px; cursor:pointer;" [ngStyle]="{'background-color':'#F44336 !important'}" (click)="save()"><i class="material-icons">save</i></button>
</div>

