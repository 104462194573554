import {Component} from '@angular/core';
import {ActivatedRoute, Route, Router, RoutesRecognized} from '@angular/router';

@Component({
  selector: 'app-groups',
  templateUrl: './groupes.component.html',
  styleUrls: ['./groupes.component.css'],
})
export class GroupesComponent {
  editable = true;
  constructor() {
    Array.from(document.styleSheets).forEach(ss => {
      try {
        Array.from(ss['rules'] || ss['cssRules']).forEach(rule => {
          if (rule instanceof CSSPageRule) {
            rule.style['size'] = 'A3 landscape';
            console.log('New rule size: ' + rule.style['size']);
          }
        });
      } catch (e) {}
    });
  }

}
