<div style="overflow-y: auto; padding: 20px; height: 100%">
  <div *ngIf="!deleteValid" >
  <h1>
    <span *ngIf="!data">Ajout</span><span *ngIf="data">Modification</span> d'un groupe
  </h1>
  <div class="row">
    <div class="col-lg-12">
      <label>
        Nom du groupe
      </label>
      <input style="font-size:16px;width: 100%" type="text" [(ngModel)]="currentData.name"/>
    </div>
    <div *ngIf="!hasDatesLinked" class="col-lg-12">
      <label>
        Nom dans le programme
      </label>
      <input style="font-size:16px;width: 100%" type="text" [(ngModel)]="currentData.datesLinkedText"/>
    </div>
    <div class="col-lg-12 mt-2">
      <label>
        Couleur
      </label>
      <div style="position:relative;padding-bottom: 10px">
        <div #d style="display: flex;gap:3px">
        <div #e class="cp_wrapper" style="position: relative" (click)="selectColor(e,d);i=0" [ngStyle]="{'border':'1px solid gray'}">
          <input #tet id="a" [ngStyle]="{'visibility':i==0 || firstClic?null:'hidden'}" style="font-size:16px;width: 200%" type="color" [(ngModel)]="currentData.color"/>
          <label (click)="test()" style="cursor: pointer; position: absolute; left: 0; top:0;height: 100%; width: 100%;" for="a"><i *ngIf="!currentData.color" style="position:absolute;left:3px; top:3px;color:black;" class="material-icons">palette</i></label>
        </div>
        <div #f class="cp_wrapper" (click)="selectColor(f,d);i=1" [ngStyle]="{'border':'1px solid gray'}">
          <i class="material-icons" style="color:gray; font-size: 31px; transform: translateX(-0.5px)">clear</i>
        </div>
        </div>
        <div class="cursor" [ngClass]="{'animate':animate}" [ngStyle]="{'width':width+'px','left':+left+'px'}"></div>
     </div>
    </div>


    <div class="col-lg-6 mt-2">
      <label>
        Heure début - Heure fin
      </label>
      <div>
      <input type="time" [(ngModel)]="currentData.timeStart" />
      <input type="time" [(ngModel)]="currentData.timeEnd">
      </div>
    </div>
    <div class="col-lg-12 mt-2">
      <label>
        Responsables (séparer les noms par une virgule)
      </label>
      <input style="font-size:16px;width: 100%" type="text" [(ngModel)]="currentData.monitors"/>
    </div>
  </div>
  </div>
  <div *ngIf="deleteValid" class="row">
    <ng-container *ngIf="data.juniors.length>0">
    <h4>Vous ne pouvez pas supprimer un groupe qui contient des juniors. Veuillez vider le groupe d'abord.</h4>
    <div style="width:100%; display: flex;justify-content:center; align-items: center">
      <button *ngIf="data" (click)="deleteValid=false;" style="padding:10px; margin:10px;background:white; border: 2px solid black; color:black;">OK</button>
    </div>
    </ng-container>
    <ng-container *ngIf="data.juniors.length==0">
      <h4>Êtes-vous sur de vouloir supprimer ce groupe ?</h4>
      <div style="width:100%; display: flex;justify-content:center; align-items: center">
        <button *ngIf="data" (click)="deleteGroup()" style="padding:10px; margin:10px;background:white; border: 2px solid black; color:black;">Oui</button>
        <button *ngIf="data" (click)="deleteValid=false;" style="padding:10px; margin:10px;background:white; border: 2px solid black; color:black;">Non</button>
      </div>
    </ng-container>
  </div>
  <div *ngIf="!deleteValid" style="position: absolute;display:flex; bottom:-50px;left: 0; width:100% ;">
    <button *ngIf="data" (click)="deleteValid=true;" style="background:#F44336; border: 2px solid #F44336; color:white;">Supprimer</button>
    <div style="width: 100%"></div>
    <a class="edit btn-floating waves-effect waves-light" style="min-width:40px; cursor:pointer; background-color: #F44336 !important" (click)="valid()"><i class="material-icons">save</i></a>
  </div>
</div>
