import {AfterViewInit, Component, EventEmitter, HostListener, Output, ViewChild} from '@angular/core';
import {APIService} from '../api';
import {Router} from '@angular/router';
import {AuthGuard} from '../security/auth.guard';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],

})
export class LoginComponent implements AfterViewInit {
  @Output() success = new EventEmitter();

  login;
  password;

  constructor(private auth: AuthGuard, private api: APIService, public router: Router) {}
  @ViewChild('input1') input1;
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === 13) {
      this.submit();
    }
  }

  public submit() {
    this.api.logged = true;
    this.api.postToken(this.login, this.password).then(res => {

      this.auth.connect(res.access_token, res.refresh_token);

    });
    this.success.emit();

  }

  ngAfterViewInit() {
    this.input1.nativeElement.focus();
  }
}
