import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {APIService} from '../api';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-program-edition',
  templateUrl: './program-edition.component.html',
  styleUrls: ['./program-edition.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ transform: 'translateX(100%)' }),
            animate('0.5s ease-out',
              style({ transform: 'translateX(0%)' }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ transform: 'translateX(0%)'}),
            animate('0.5s ease-in',
              style({transform: 'translateX(100%)'}))
          ]
        )
      ]
    ),
    trigger(
      'outAnimation',
      [
        transition(
          ':leave',
          [
            style({ transform: 'translateX(0%)'}),
            animate('0.5s ease-in',
              style({transform: 'translateX(100%)'}))
          ]
        )
      ]
    )
  ]
})
export class ProgramEditionComponent {
  @Input() subject;
  @Input() subjectDelete;
  @Input() data = null;
  @ViewChild('d') private d: any;

  ngOnChanges(changes) {
    console.log(changes);
    if (changes.data && this.data) {
      this.date1 = this.data.date;
      this.name = this.data.name;
      if (this.data.endDate) {
        this.moreThanOneDay = true;
        this.date2 = this.data.endDate;
      }
      this.categoryId = this.data.categoryId;
    }
  }

  moreThanOneDay = false;
  date1;
  date2;
  name;
  categoryId;
  categories = [];
  selectedValue;
  tooLong = false;

  focus = false;
  current: any = {name: ''};
  delete = false;

  constructor(private api: APIService) {

  }

  save() {
    const data: any = {date: this.date1, endDate: this.date2, name: this.name, categoryId: this.categoryId };
    if (this.data) {
      data.id = this.data.id;
      data.categoryId = this.categoryId;
    }
    this.subject.next(data);
  }

  sendDelete(data) {
    this.subjectDelete.next(data);
  }


  change() {
    setTimeout(()=>{  this.tooLong = this.d.nativeElement.clientWidth > 166;console.log( this.d.nativeElement.clientWidth)
    }, 0)
  }
}
