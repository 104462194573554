<div *ngIf="!loaded" class="text-center">
  <i class="fas fa-circle-notch fa-spin fa-7x"></i>
</div>
  <div *ngIf="loaded">

    <div style="display:flex; flex-wrap: wrap; justify-content: space-between; margin-right: 20px; margin-left: 20px; margin-top: 0; background-color: white">
      <div id="stats" style="align-self:center; margin: 10px">
        <h5 style="display:inline-block"><strong id="nbjuniors">{{competitions.length}}</strong> compétition{{competitions.length>1?'s':''}}</h5>
      </div>
      <div style="display: flex;margin-right: 0;margin-left: auto; justify-content: flex-end;justify-items: center; align-items: center">
      <div class="select small" style="float:right; padding: 10px;">
        <select class="custom-select" [(ngModel)]="year" (ngModelChange)="getResult()" style=" text-align: center" name="year" id="orderOfMerite">
         <!--todo: optimiser-->
          <option value="2024">2024</option>
          <option value="2023">2023</option>
          <option value="2022">2022</option>
          <option value="2021">2021</option>
          <option value="2020">2020</option>
          <option value="2019">2019</option>
        </select>
        <div class="select_arrow">
        </div>
      </div>
        <div class="select small2" style="float:right; padding: 10px;">
          <select class="custom-select"  [(ngModel)]="category" (ngModelChange)="getResult()" style=" text-align: center" name="year" id="orderOfMerite">
            <option value="0">Comptant pour l'OM</option>
            <option value="1">Autres</option>
          </select>
          <div class="select_arrow">
          </div>
        </div>
        <a (click)="editCompetition(null)" class="edit btn-floating waves-effect waves-light" style="float:right; margin-right:20px; margin-bottom:20px; background-color: #F44336 !important">
          <i class="material-icons">add</i></a>
      </div>

    </div>

    <div class="header-bar">
        <div style="min-width:70px;width: 70px;">
          <div class="cell"></div>
        </div>
        <div class="sortable" ngClass="" style="min-width:150px;width: 150px;">
          <div class="cell">DATE</div>
        </div>
        <div class="sortable" style="min-width:350px;width: 100%;">
          <div class="cell">COMPETITION</div>
        </div>
      <div class="sortable" style="min-width:130px;width: 130px;">
        <div class="cell">LIEU</div>
      </div>
        <div class="sortable" style="min-width:120px;width: 120px;">
          <div class="cell">PARTICIPANTS</div>
        </div>
      </div>


    <div class="competition-rows">
    <div  *ngFor="let competition of competitions" (click)="detail(competition)" class="competition-row" style=" position:relative;">
      <div (mouseenter)="over=competition" (mouseleave)="over=null" [ngClass]="{'selected':over===competition}" style="width:100%; display:table; vertical-align: middle; height:32px; position: relative" class="test">
      <div class="competition-row1">
        <div class="edit" style="min-width:70px;width: 70px;">
            <a *ngIf="over===competition" class="edit btn-floating waves-effect waves-light red" style="position:absolute;top:-5px;left:10px"><i class="material-icons">mode_edit</i></a>
        </div>
        <div  ngClass="" style="min-width:150px;width: 150px;">
          {{(competition.date ? competition.date : competition.event.date) | dateFormat}}
        </div>
        <div  class="sortable" style="min-width:350px;width: 100%;">
          {{competition.name?competition.name:competition.event.name}}
        </div>
        <div style="min-width:130px;width: 130px;text-overflow: ellipsis;text-wrap: nowrap;">
          {{competition.course?.name}}
        </div>
        <div  class="nb-participants" style="display:flex; min-width:120px;width: 120px;">
          <span class="label">Participants:&nbsp;</span>{{competition.nbParticipants}}
        </div>
      </div>
    </div>
    </div>
  </div>
  </div>
