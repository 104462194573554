<div [ngClass]="{'navbar-juniors':menu}">
  <nav *ngIf="connected" style="padding-bottom:8px;z-index: 3;color:white;background-color: dimgray" class="paddingbar noprint navbar navbar-expand-lg navbar-dark">
  <!--<a class="navbar-brand" [routerLink]=""><img src="assets/logo.svg"></a>-->
  <button style="margin-top:10px" (click)="menu=!menu" class="navbar-toggler" type="button">
    <span class="navbar-toggler-icon">
</span>
  </button>
  <div [ngClass]="{'navbar-show':menu,'navbar-hide':!menu}" class="navbar-collapse noprint">
    <ul class="navbar-nav mr-auto">
      <li class="d-none d-lg-block nav-item logo-container" style="width:100px;margin-top: 13px; font-weight:600; font-size: 11px;">
        ZONE MEMBRE
      </li>
      <!--<li class="nav-item">
        <a class="noprint nav-link"  style="font-weight:700; margin-left: 100px" [routerLink]="'/'" [routerLink]="">Espace membre <span class="sr-only">(current)</span></a>
      </li>-->
      <li class="nav-item dropdown" >
        <a class="noprint nav-link dropdown-toggle" [routerLink]="" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Organisation
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" (click)="menu=false" [routerLink]="'/administration/programme'">Programme</a>
          <a class="dropdown-item" (click)="menu=false" [routerLink]="'/administration/groupes'">Cours & Groupes</a>
          <!--<a class="dropdown-item" (click)="menu=false" [routerLink]="'/administration/sondage/commande-habits'">Commande d'habits</a>-->
          <a class="dropdown-item" (click)="menu=false" [routerLink]="'/administration/sondage/2025'">Saison 2025</a>
          <!--<a class="dropdown-item"  (click)="menu=false" [routerLink]="">Camps</a>-->
          <!-- <div class="dropdown-divider"></div>
           <a class="dropdown-item" [routerLink]="">Something else here</a>-->
        </div>
      </li>
      <li class="nav-item dropdown">
        <a class="noprint nav-link dropdown-toggle" [routerLink]="" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Résultats
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" (click)="menu=false" [routerLink]="'/administration/competitions'">Compétitions</a>
          <!--<a class="dropdown-item" (click)="menu=false" [routerLink]="['/administration/medailles']">Médailles</a>-->
          <!-- <div class="dropdown-divider"></div>
           <a class="dropdown-item" [routerLink]="">Something else here</a>-->
        </div>

      </li>
      <li class="nav-item dropdown">
        <a class="noprint nav-link dropdown-toggle" [routerLink]="" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Gestion des personnes
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" (click)="menu=false" [routerLink]="'/juniors'">Juniors</a>
          <a class="dropdown-item" (click)="menu=false" [routerLink]="'/administration/managers'">Staff</a>
          <a class="dropdown-item" (click)="menu=false" [routerLink]="'/administration/club100'">Club des 100</a>
          <!--<a class="dropdown-item" (click)="menu=false" [routerLink]="">Sponsors</a>-->
          <!--<a class="dropdown-item" (click)="menu=false" [routerLink]="'/administration/configuration'">Configuration</a>-->

          <!-- <div class="dropdown-divider"></div>
           <a class="dropdown-item" [routerLink]="">Something else here</a>-->
        </div>
      </li>
      <li class="nav-item dropdown">
        <!--<a class="noprint nav-link dropdown-toggle" [routerLink]="" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Sondage
        </a>-->
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" (click)="menu=false" [routerLink]="'/administration/commande-equipement'">Commande d'équipement</a>
          <!--<a class="dropdown-item" (click)="menu=false" [routerLink]="">Sponsors</a>-->
          <!--<a class="dropdown-item" (click)="menu=false" [routerLink]="'/administration/configuration'">Configuration</a>-->

          <!-- <div class="dropdown-divider"></div>
           <a class="dropdown-item" [routerLink]="">Something else here</a>-->
        </div>
      </li>
      <!--<li class="nav-item dropdown">
         <a class="noprint nav-link dropdown-toggle" [routerLink]="" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Gestion du site
        </a>
       <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" (click)="menu=false" [routerLink]="'/administration/news'">News</a>
          --><!--<a class="dropdown-item" (click)="menu=false" [routerLink]="">Documents</a>
          <a class="dropdown-item" (click)="menu=false" [routerLink]="">Photos</a>-->
          <!--<a class="dropdown-item" (click)="menu=false" [routerLink]="'/administration/contacts'">Contacts</a>-->
          <!--<div class="dropdown-divider"></div>
           <a class="dropdown-item" [routerLink]="">Something else here</a>-->
        <!--</div>
      </li>-->
    </ul>
    <form class="noprint form-inline my-2 my-lg-0 pr-1">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item dropdown">
          <a class="noprint nav-link dropdown-toggle" [routerLink]="" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Compte administrateur
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a style="cursor: pointer" class="dropdown-item" (click)="clickDisconnect()">Déconnexion</a>
            <!-- <div class="dropdown-divider"></div>
             <a class="dropdown-item" [routerLink]="">Something else here</a>-->
          </div>
      </ul>
    </form>
  </div>
</nav>

<nav style="z-index: 2;background-color: rgba(255,255,255,0.9) !important;padding-right:5px" class="paddingbar navbar navbar-expand-lg navbar-light bg-light ">
  <!--<a class="navbar-brand" [routerLink]=""><img src="assets/logo.svg"></a>-->
  <button *ngIf="!connected" style="margin-top:10px; margin-bottom: 10px" (click)="menu=!menu" class="navbar-toggler" type="button" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon">
</span>
  </button>
  <div [ngClass]="{'navbar-show':menu,'navbar-hide':!menu}" class=" navbar-collapse noprint">
    <ul class="navbar-nav mr-auto">

      <!--<li class="nav-item">
        <a class="noprint nav-link"  style="font-weight:700; margin-left: 100px" [routerLink]="'/'" [routerLink]="">Espace membre <span class="sr-only">(current)</span></a>
      </li>-->
      <li class="nav-item">
        <a class="noprint nav-link marginmenu" (click)="menu=false" [routerLink]="'/'">Accueil <span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item">
        <a class="noprint nav-link" (click)="menu=false" [routerLink]="'/news'">News</a>
      </li>
      <li class="nav-item dropdown">
        <a class="noprint nav-link dropdown-toggle" style="cursor: pointer" id="navbarDropdown2" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          La section
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown2">
          <a class="dropdown-item" *ngIf="api.managersActivated" (click)="menu=false"  [routerLink]="'/staff'">Le staff</a>
          <a class="dropdown-item" (click)="menu=false"  [routerLink]="'/adherer'">Adhérer à la section</a>
      <a class="dropdown-item" (click)="menu=false"  [routerLink]="'/documents'" >Documents</a>
          <!-- <div class="dropdown-divider"></div>
           <a class="dropdown-item" [routerLink]="">Something else here</a>-->
        </div>
      </li>
      <li class="nav-item">
        <a class="noprint nav-link" (click)="menu=false"  [routerLink]="'/programme'">Programme</a>
      </li>
      <li *ngIf="api.parameters.groups.length>0" class="nav-item"[ngClass]="{'dropdown':api.parameters.groups.length>0}" >
        <a class="noprint nav-link"  [routerLink]="api.parameters.groups.length>0?null:'/groupes'"  [ngClass]="{'dropdown-toggle':api.parameters.groups.length>0}" style="cursor: pointer" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Groupes
        </a>
        <div [ngStyle]="{'display':api.parameters.groups.length>0?null:'none'}" class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a *ngFor="let group of api.parameters.groups" class="dropdown-item" (click)="menu=false"  [routerLink]="'/groupes/'+group.abbr">{{group.name}}</a>
          <!-- <div class="dropdown-divider"></div>
           <a class="dropdown-item" [routerLink]="">Something else here</a>-->
        </div>
      <li *ngIf="!api.club100Activated" class="nav-item">
        <a class="noprint nav-link" (click)="menu=false"  [routerLink]="'/sponsors'">Sponsors</a>
      </li>
      <li *ngIf="api.club100Activated" class="nav-item dropdown">
        <a class="noprint nav-link dropdown-toggle" style="cursor: pointer" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Soutiens
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" (click)="menu=false" [routerLink]="'/sponsors'">Sponsors</a>
          <a class="dropdown-item" (click)="menu=false" [routerLink]="'/club100'">Club des 100</a>
          <!-- <div class="dropdown-divider"></div>
           <a class="dropdown-item" [routerLink]="">Something else here</a>-->
        </div>
      </li>

      <li class="nav-item dropdown">
        <a class="noprint nav-link dropdown-toggle" style="cursor: pointer" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Résultats
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" (click)="menu=false" [routerLink]="'/ordre-merite'">Ordre du mérite</a>
<!--          <a class="dropdown-item" (click)="menu=false" [routerLink]="'/medailles'">Tableau des médailles</a>-->
          <!-- <div class="dropdown-divider"></div>
           <a class="dropdown-item" [routerLink]="">Something else here</a>-->
        </div>

      </li>
      <li class="nav-item">
        <!--<a class="noprint nav-link" (click)="menu=false"   [routerLink]="'/palmares'" >Palmarès</a>-->
      </li>
      <li class="nav-item">
        <!--<a class="noprint nav-link" *ngIf="api.contactsActivated" (click)="menu=false"   [routerLink]="'/contacts'" >Contacts</a>-->
        <a class="noprint nav-link" (click)="menu=false" [routerLink]="'/contacts'" >Contacts</a>
      </li>
      <li class="nav-item">
        <a class="noprint nav-link" (click)="menu=false"  [routerLink]="'/photos'" >Photos</a>
      </li>
    </ul>
    <form *ngIf="!connected" class="noprint form-inline my-2 my-lg-0 pr-1">
      <ul class="navbar-nav mr-auto">
        <li *ngIf="!connected" class="nav-item">
          <a class="noprint nav-link" style="padding-top:2px;cursor: pointer" (click)="clickLogin();menu=false">  <img class="logo" style="width: 25px" src="assets/padlock.svg">
          </a>
        </li>
      </ul>
    </form>
  </div>
</nav>
</div>
