import {
  AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {Router} from '@angular/router';
import {APIService} from '../../api';
import {ImageCroppedEvent, LoadedImage} from 'ngx-image-cropper';

@Component({
  selector: 'app-survey2-management',
  templateUrl: './survey2-management.component.html',
  styleUrls: ['./survey2-management.component.css']
})
export class Survey2ManagementComponent {

  loaded = false;
  loaded2 = false;
  answers = null;
  mode = 'details';
  juniors = [];
  other = [];
  options = [
    {
      id: 1,
      visible: true,
      value: null,
      text: 'Le·la junior·e participera-t-il·elle aux cours juniors de 2025 ?',
      summaryText: 'Participe cours',
      answer: {type: 'radio', choices:[{id:1,text:'Oui'}, {id:2,text:'Oui partiellement (absent une partie de l\'année)'}, {id:3, text:'Non'}]}
    },
    {
      id: 2,
      visible: false,
      text: 'Merci de préciser les périodes où le·la junior·e sera absent·e.',
      summaryText: 'Période d\'absence',
      condition: {id: 1, choiceId: [2]},
      answer: {required: false, type: 'textarea'}
    },
    {
      id: 3,
      visible: true,
      text: 'A partir de quelle heure, après école ou travail, le·la junior·e peut-il·elle se trouver au golf de Payerne le <b><u>lundi</u></b> pour la période <u>fin mars/début juillet</u> ?',
      condition: {id: 1, choiceId: [1, 2]},
      summaryText: 'Lundi',
      answer: {type: 'time', nullText: 'Pas disponible le lundi', nullSelected: false}
    },
    {
      id: 4,
      visible: true,
      text: 'A partir de quelle heure, après école ou travail, le·la junior·e peut-il·elle se trouver au golf de Payerne le <b><u>mercredi</u></b> pour la période <u>fin mars/début juillet</u> ?',
      condition: {id: 1, choiceId: [1, 2]},
      summaryText: 'Mercredi',
      answer: {type: 'time', nullText: 'Pas disponible le mercredi'}
    },
    {
      id: 5,
      visible: true,
      text: 'Ces horaires seront-ils les mêmes pour la période <u>fin août/début octobre</u> ?',
      condition: {id: 1, choiceId: [1, 2]},
      summaryText: 'Mêmes horaires en automne',
      answer: {type: 'radio', choices:[{id:1,text:'Oui'}, {id:2,text:'Probablement'}, {id:3, text:'Encore incertain'}, {id:4, text:'Non (précisez dans les commentaires)'}]}
    },
    {
      id: 6,
      visible: true,
      value: null,
      text: 'De quelles vacances le·la junior·e dispose il·elle ?',
      summaryText: 'Vacances',
      answer: {type: 'radio', choices:[{id:1,text:'Vaudoises'}, {id:2,text:'Fribourgoises'}, {id:3, text:'Autres'}]}
    },
    {
      id: 7,
      visible: false,
      text: 'En moyenne, combien de fois par semaine le·la junior·e prévoit-il·elle de s\'entraîner (ou jouer sur le parcours) en dehors des cours juniors ?',
      condition: {id: 1, choiceId: [1, 2]},
      summaryText: 'Entraînements',
      answer: {type: 'radio', choices:[{id:1,text:'0 fois'}, {id:2,text:'1 fois'}, {id:3, text:'2 fois'}, {id:4, text:'3 fois'}, {id:5, text:'Plus de 3 fois'}]}
    },
    {
      id: 8,
      visible: true,
      text: 'Le·la junior·e prévoit il·elle de participer aux compétitions juniors de Payerne ?',
      summaryText: 'Compétitions juniors internes',
      answer: {type: 'radio', choices:[{id:1,text:'Toutes dans la mesure du possible'}, {id:2,text:'Uniquement quelques unes'}, {id:3, text:'Non'}]}
    },
    {
      id: 9,
      visible: true,
      text: 'Le·la junior·e prévoit il·elle de participer aux compétitions adultes de Payerne ?',
      summaryText: 'Compétitions adultes internes',
      answer: {type: 'radio', choices:[{id:1,text:'Toutes dans la mesure du possible'}, {id:2,text:'Uniquement quelques unes'}, {id:3, text:'Non'}]}
    },
    {
      id: 10,
      visible: true,
      text: 'Le·la junior·e prévoit il·elle de participer aux compétitions juniors SwissGolf à l\'extérieur U12 / U14 / JTE ?',
      summaryText: 'Compétitions swissgolfs',
      answer: {type: 'radio', choices:[{id:1,text:'Toutes dans la mesure du possible'}, {id:2,text:'Uniquement quelques unes'}, {id:3, text:'Non'}]}
    },
    {
      id: 11,
      visible: true,
      text: 'Le·la junior·e prévoit il·elle de participer aux compétitions du Challenge Romand ?',
      summaryText: 'Compétitions challenge romand',
      answer: {type: 'radio', choices:[{id:1,text:'Toutes dans la mesure du possible'}, {id:2,text:'Uniquement quelques unes'}, {id:3, text:'Non'}]}
    },
    {
      id: 12,
      visible: true,
      text: 'Problèmes de santé / Allergies (à remplir si c\'est une information pouvant être nécessaire à un responsable)',
      summaryText: 'Santé',
      answer: {required: false, type: 'textarea', placeholder: 'Facultatif'}
    },
    {
      id: 13,
      visible: true,
      text: 'Autres commentaires',
      summaryText: 'Commentaires',
      answer: {required: false, type: 'textarea', placeholder: 'Facultatif'}
    }
  ];

  constructor(public api: APIService, private router: Router) {

  }

  ngOnInit(){
    this.reload();
  }


  reload() {
    this.loaded2 = false;
    this.loaded = false;

    Promise.all([
    this.api.getJuniors().then(res => {
      this.loaded2 = true;
      this.juniors = res.filter(x => x.inSection === true && x.resignDate === null );
    }),
    this.api.getSurvey('disponibilites2025').then((answers) => {
      this.api.changeTitleEvent.next("Sondage juniors 2025");
      this.answers = answers;
      for (const answer of answers) {
        for (const data of answer.data) {
          const option = this.options.find((x) => x.id === data.id);
          data.summaryText = option.summaryText;
          if (data.answer.type === 'radio' && data.visible) {
            data.value = option.answer.choices.find(x => x.id === data.value).text;
          }
          if (data.answer.type === 'time' && data.visible) {
            if(!data.value)
            data.value = option.answer.nullText;
          }
        }
      }
      console.log(this.options);
    })
    ]).then(() => {
      for (const answer of this.answers) {
        if (answer.userId) {
          const junior = this.juniors.find(x => x.id === answer.userId);
          if (junior) {
            if(!junior.answer) {
              junior.answer = [answer];
            }
            else{
              junior.answer.push(answer);
            }
          } else {
            this.other.push(answer);
          }
        } else {
          this.other.push(answer);
        }
      }
      this.orderByFirstName();
      this.loaded = true;
    });
  }

  orderByFirstName() {
    const c = [...this.juniors];
    c.sort( (a, b) => a.lastName.toUpperCase() > b.lastName.toUpperCase() ?  1 : (a.lastName.toUpperCase() === b.lastName.toUpperCase() && a.firstName.toUpperCase() > b.firstName.toUpperCase()) ? 1 : -1);
    this.juniors = c;
  }

}
