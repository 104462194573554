import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {APIService} from '../api';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  @Input() connected;
  @Output() disconnect = new EventEmitter();
  @Output() login = new EventEmitter();
  @Input() year;
  managers = false;

  menu = false; // isMenu enabled for screen (xs-sm-md)

  constructor(public api: APIService) {
    if (this.api.parameters.module) {
      let d = this.api.parameters.module.find(x => x.name === 'managers');
      if(d) {
        this.managers = d.published;
      }
    }

  }

  ngOnInit() {
  }

  clickDisconnect() {
    this.disconnect.emit();
  }

  clickLogin() {
    this.login.emit();
  }

}
