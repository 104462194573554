import {AfterViewInit, Component, OnInit} from '@angular/core';
import * as $ from 'jquery';
import {APIService} from '../api';
import {ActivatedRoute, Router} from '@angular/router';
import {animate, animateChild, query, state, style, transition, trigger, useAnimation} from '@angular/animations';
import {isNumber} from 'util';
import {slideAnimation} from '../medals/medals.component';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-competitions',
  templateUrl: './competition.component.html',
  styleUrls: ['./competition.css'],
  animations: [
    trigger(
      'nested',
      [
        transition(
          ':enter', [
            animateChild(),
            animateChild(),
          ])]),
    trigger('slideIn', [
      state('transit', style({height: '0px'})),
      state('inactive', style({height: '0px'})),
      state('transit2', style({height: '*'})),
      state('active', style({height: '*'})),
        transition('active <=> inactive', [
          useAnimation(slideAnimation )]),

      transition('inactive <=> transit', [
        useAnimation(slideAnimation )]),

      transition('transit <=> active', [
        useAnimation(slideAnimation )]),
      ]
    )
  ]
})
export class CompetitionComponent implements AfterViewInit {

  isCourseUploaded = false;
  loaded = false;
  transit = false;
  selected;
  competition;
  id;
  over;
  currentCard;
  course;
  slope;
  sorting = 'net';

  simpleItems;
  selectedSimpleItem;
  editHandicap = false;
  editHole = false;
  editHoleTee = false;
  editHoleSelection = 0;
  editHoleTeeValue = -1;
  editHandicapValue;

  selectedTeebox;

  brut;
  net;

  timeout;

  ngAfterViewInit() {

  }

  constructor(private api: APIService, private router: Router, private route: ActivatedRoute) {
    // TODO: Promise all (with get Competition) - get only small liste

    Array.from(document.styleSheets).forEach(ss => {
      try {
        Array.from(ss['rules'] || ss['cssRules']).forEach(rule => {
          if (rule instanceof CSSPageRule) {
            rule.style['size'] = 'A4 portrait';
            console.log('New rule size: ' + rule.style['size']);
          }
        });
      } catch (e) {}
    });

    this.route.params.subscribe(params => {
      console.log('ddd');
      this.id = +params['id']; // (+) converts string 'id' to a number

      this.api.getCompetition(this.id).then(res => {
        this.competition = res;
        let back = new Subject();
        back.subscribe(() => {
          this.router.navigate(['administration/competitions/'], {state: { year: 2019 }});
        });
        this.api.changeTitleEvent.next({back: back, name: 'Compétitions', print: false});
        this.api.getJuniors('for_competition').then(
          res2 => {
            const participant_id = this.competition.participants.map(x => x.juniorId);
            this.simpleItems = res2
            for (const item of this.simpleItems) {
              item.name = item.firstName + ' ' + item.lastName;
              if(participant_id.indexOf(item.id) >= 0) {
                item.disabled = true;
              }
            }
          }
        );
        this.initCompetition();
        for (const participant of this.competition.participants) {
          this.initParticipant(participant);
        }
        //TODO: a sort method with no animation
        const sortedList = this.orderByNet([...this.competition.participants]);
        this.competition.participants = [...sortedList];
        this.loaded = true;
      });
    });
  }

  initCompetition() {
    if(this.competition.course) {
      this.competition.course.par = [];
      this.competition.course.par[0] = 0;
      this.competition.course.par[1] = 0;

      if (this.competition.course.holes.length > 0) {
        this.isCourseUploaded = true;
      }

      for (const hole of this.competition.course.holes) {
        if (hole.number <= 9) {
          this.competition.course.par[0] += hole.par;
        } else {
          this.competition.course.par[1] += hole.par;
        }
      }
    }

  }


  change_handicap(participant, hcp){
    console.log(hcp);
    this.api.putParticipantHandicap(participant.id, hcp).then(
      res => {
        participant.hcp = hcp;
        this.editHandicap = false;
        this.loadNbShot(participant);
      });
  }

  change_mode(participant, mode) {
    this.api.putParticipantMode(participant.id,  mode === 1 ? 'front' : (mode === 2 ? 'back' : null)).then(
      res => {
        participant.mode = mode === 1 ? 'front' : (mode === 2 ? 'back' : null);
        this.loadNbShot(participant);
        this.checkIsFull(participant);
      });
  }

  change_teebox(participant, teebox) {
    console.log(teebox)
    this.api.putParticipantTeebox(participant.id, teebox).then(
      res => {
        participant.teebox = teebox;
        this.editHoleTee = false;
        this.loadNbShot(participant);
      });
  }

  edit_handicap(card, participant) {
    console.log(card);
    this.editHandicap = !this.editHandicap;
    this.editHandicapValue = participant.hcp;
    if(this.editHandicap) {
      setTimeout(() => {
        card.querySelector('.edit_hcp').select();
      }, 0);
    }
  }

  edit_tee(participant){
    this.editHoleTee=true;
    //this.editHoleTeeValue = this.competition.course.teebox.indexOf(participant.teebox);
    console.log(this.editHoleTeeValue)
  }

  initParticipant(participant) {

    participant.subtotal = [];
    participant.subtotal[0] = 0;
    participant.subtotal[1] = 0;

    if(!this.competition.pointsMode) {
      for (let score of participant.scores) {
        if (score.score === null) {
          score.score = '-';
        }
      }

      while (participant.scores.length < 18) {
        participant.scores.push({score: null});
      }
    }
    else if(participant.score_competition){
      participant.brut = participant.score_competition.brut;
      participant.net = participant.score_competition.net;
    }
    /*
    // TODO : ne marche pas si change de mode en cours d'édition
    for(let i = 0;i < 18;i++){
      if(!participant.score[i]) {
        if(this.competition.type === 2 || participant.mode === 2){
          participant.score.splice(0, 0, {score: null});
        }
        else {
          participant.score.push({score: null});
        }
      }
    }*/

    if (this.competition.course && this.competition.course.teeboxes) {
      this.setTeebox(participant); // TODO: rename setTeeboxes
    }

    this.getPlayerHcp(participant);

    if(this.competition.course) {
      this.loadNbShot(participant, false);
    }
  }

  loadNbShot(participant, sort = true){
    this.setNbShots(participant, participant.teebox);
    console.log(!this.competition.pointsMode);
    console.log(participant);
    if (!this.competition.pointsMode) {
      this.computePoints(participant, sort);
    } else if (participant.result) {
      participant.brut = participant.result.brut;
      participant.net = participant.result.net;
      this.brut = participant.brut;
      this.net = participant.net;
    }
  }

  change(participant) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.api.postCompetitionScores(this.competition.id, participant.id, {
        brut: participant.brut,
        net: participant.net
      });
    }, 2000);
    this.sort();
  }

  computePoints(participant, sort) {
    for (let i = 0; i < 18 ; i++) {
      this.calculatePoint(participant, i);
    }
    if (this.isFull(participant)) {
      participant.brut = participant.subtotalBrut[0] + participant.subtotalBrut[1];
      if (participant.mode === 'front' || this.competition.type === 1) {
        participant.net = participant.subtotalNet[0] + 17;
      }
      else if (participant.mode === 'back' || this.competition.type === 2) {
        participant.net = participant.subtotalNet[1] + 17;
      }
      else {
        participant.net = participant.subtotalNet[0] + participant.subtotalNet[1];
      }
    }
    if (sort) {
      this.sort();
    }
  }

  checkIsFull(participant) {
    if (this.isFull(participant)) {
      const scores = [];
      let index = 1;
      for (const score of participant.scores) {
        scores.push({id: score.id, number: index, score: score.score === '-' ? null : score.score});
        index++;
      }
      this.api.postCompetitionScores(this.competition.id, participant.id, scores);
      participant.brut = participant.subtotalBrut[0] + participant.subtotalBrut[1];
      if (participant.mode === 'front' || this.competition.type === 1) { // done before is it useful ?
        participant.net = participant.subtotalNet[0] + 17;
      }
      else if (participant.mode === 'back' || this.competition.type === 2) {
        participant.net = participant.subtotalNet[1] + 17;
      }
      else {
        participant.net = participant.subtotalNet[0] + participant.subtotalNet[1];
      }
      this.sort();
    }
  }

  toggle(competition, participant, card) {
    this.editHoleTeeValue = -1;
    this.editHandicap = false;
    this.editHole = false;
    this.editHoleTee = false;
    if (this.selected === participant.id) {
      this.currentCard = null;
      this.selected = null;
    }
    else {
      const self = this;
      this.currentCard = card;

      self.selected = participant.id;

      /*setTimeout(function() {
        self.selected = participant.id;
        setTimeout(function() {
          self.selected = null;
          setTimeout(function() {
            self.selected = participant.id; });});
      });*/

    }
//Todo: focus with queryselectoe
    if (competition.type === 2 || participant.mode === 'back') {
      //card.children[1].children[1].children[1].children[11].children[0].focus();
    }
    else {
      //card.children[1].children[1].children[1].children[1].children[0].focus();
      console.log(this.selected + ' ' + participant .id);
    }
  }

  showOption(){

  }


  d(key, participant, i) {
    if (key.key === 'x' || key.key === 'X' || key.key === '-') {
      participant.scores[i].score = '-';
      key.preventDefault();

    }
    else if (key.key === '0') {
      if (!participant.scores[i].score || participant.scores[i].score === '' || participant.scores[i].score === '-') {
        participant.scores[i].score = '-';
        key.preventDefault();
      }
    }
    if (participant.scores[i].score === '-') {
      let d;
      if (i < 8) {
        d = i + 2;
      }
      if (i >= 8) {
        d = i + 3;
      }
      if (i < 17) {
        this.currentCard.children[1].children[1].children[0].children[1].children[d].children[0].focus();
        this.currentCard.children[1].children[1].children[0].children[1].children[d].children[0].select();
      }
      this.calculatePoint(participant, i);
      this.checkIsFull(participant);
    }
    else if (isNaN(parseInt(key.key, 10))) {
      participant.scores[i].score = null;
      key.preventDefault();
    }


      /*if (this.isFull(l)) {
        l.brut = l.subtotalBrut1 + l.subtotalBrut2;
        l.net = l.subtotalNet1 + l.subtotalNet2;
      }*/
    }

  calculatePoint(participant, i) {
    participant.scores[i].score = participant.scores[i].score===0?'-':participant.scores[i].score;
    const score = participant.scores[i].score;
    participant.scores[i].brut = this.stablefordBrutPoint(isNaN(parseInt(score, 10)) ? null : score, this.competition.course.holes[i].par);
    participant.scores[i].net = this.stablefordNetPoint(score, this.competition.course.holes[i].par, this.competition.course.holes[i].hcp, participant.nbShots * ((participant.mode === 'front' || this.competition.type === 1)?2:1));
    this.calculateSubTotal(participant, Math.floor(i / 9)); // todo: optimise
  }

  calculateSubTotal(l, i) {
    let sum = 0;
    let sumBrut = 0;
    let sumNet = 0;
    for (let s = 9 * i; s < 9 + 9 * i; s++) {
      if (isNumber(l.scores[s].score)) {
        if (sum!==null) {
          sum += parseInt(l.scores[s].score, 10);
        }
        sumBrut += parseInt(l.scores[s].brut, 10);
        sumNet += parseInt(l.scores[s].net, 10);
      }
      else {
        sum = null;
      }
    }
    if (l.subtotal === undefined) {
      l.subtotal = [];
    }
    if (l.subtotalBrut === undefined) {
      l.subtotalBrut = [];
    }
    if (l.subtotalNet === undefined) {
      l.subtotalNet = [];
    }
    l.subtotal[i] = sum === null ? '-' : sum;
    l.subtotalBrut[i] = sumBrut;
    l.subtotalNet[i] = sumNet;


    if (l.subtotal[0] === '-' || l.subtotal[1] === '-') {
      l.total = '-';
    }
    else {
      l.total = (l.subtotal[0] === null ? 0 : l.subtotal[0]) + (l.subtotal[1] === null ? 0 : l.subtotal[1]);
    }
    l.totalBrut = (isNaN(l.subtotalBrut[0]) ? 0 : l.subtotalBrut[0]) + (isNaN(l.subtotalBrut[1]) ? 0 : l.subtotalBrut[1]);
    if (l.mode === 1) {
      l.totalNet = (isNaN(l.subtotalNet[0]) ? 0 : l.subtotalNet[0]) + 17;
    }
    else {
      l.totalNet = (isNaN(l.subtotalNet[0]) ? 0 : l.subtotalNet[0]) + (isNaN(l.subtotalNet[1]) ? 0 : l.subtotalNet[0]);
    }
  }

  stablefordBrutPoint(score, par) {
    if (score === null || isNaN(score)) {
      return 0;
    }
    let d = par - score + 2;
    if (d < 0) {
      d = 0;
    }
    return d;
  }

  stablefordNetPoint(score, par, hcpHole, nbGivenShots) {
    const nbGivenShotsHole = Math.floor((nbGivenShots - hcpHole) / 18) + 1;
    return score ? this.stablefordBrutPoint(score - nbGivenShotsHole, par) : 0;
  }

  nbGivenShotsHole(hole, nbGivenShots) {
    const hcpHole = this.competition.course.holes[hole - 1].hcp;
    const nbGivenShotsHole = Math.floor((nbGivenShots - hcpHole) / 18) + 1;

    const array = [];
    for (let i = 0; i < nbGivenShotsHole ; i++) {
      array.push(i);
    }
    return array;
  }


  test(e, participant, i) {
      if (e !== '' && e !== 'x' && e !== 'X' && e !== '-') {
        participant.scores[i].score = parseInt(e, 10);
      }
      else if ( e === 'x' || e === 'X' || e === '-') {
        participant.scores[i].score = '-';
      }
      else {
        participant.scores[i].score = null;
      }


      console.log(e);
      if (participant.scores[i].score > 1 || participant.scores[i].score === '-') {
        let d;
        if (i < 8) {
          d = i + 2;
        }
        if (i >= 8) {
          d = i + 3;
        }
        if (i < 17) {
          this.currentCard.children[1].children[1].children[0].children[1].children[d].children[0].focus();
          this.currentCard.children[1].children[1].children[0].children[1].children[d].children[0].select();
        }
      }
    this.calculatePoint(participant, i);
    this.checkIsFull(participant);

  }

  isFull(participant) {
    const x = (participant.mode === 'front' || participant.mode === 'back' || this.competition.type >= 1) ? 9 : 18;
    const b = (participant.mode === 'back' || this.competition.type === 2) ? 9 : 0;
    for (let s = b; s < x; s++) {
        if (!participant.scores[s].score || participant.scores[s].score === '') {
          console.log(false);
        return false;
      }
    }
    return true;
  }

  getPlayerHcp(participant) {
    let hcp = participant.hcp == null ? participant.junior.hcp : participant.hcp;
    if (hcp === undefined || hcp === null || hcp > 54) {
      hcp = 54;
    }
    console.log(hcp);
    participant.hcp = hcp;
    return hcp;
  }

  getTeebox(name) {
    console.log(name);
    return this.competition.course.teeboxes.find(res => res.name === name);
  }

  setTeebox(participant) {
    const hcp = this.getPlayerHcp(participant);

    let teebox;

    if (participant.teebox) {
        teebox = this.getTeebox(participant.teebox);
        this.slope = teebox.slopeMen;
        this.course = teebox.courseMen;
    }
    else if (participant.junior.user.gender) {
      if (hcp != null && hcp <= 18) {
        teebox = this.getTeebox('white');
      }
      else {
        teebox = this.getTeebox('yellow');
      }
      if (teebox != null) {
        this.slope = teebox.slopeMen;
        this.course = teebox.courseMen;
      }
    }
    else {
      if (hcp != null && hcp <= 18) {
        teebox = this.getTeebox('blue');
      }
      else {
        teebox = this.getTeebox('red');
      }
      if (teebox != null) {
        this.slope = teebox.slopeWomen;
        this.course = teebox.courseWomen;
      }
    }
    if (participant.mode === 'front' || this.competition.type === 1) {
      if (participant.junior.user.gender) {
        this.slope = teebox.slopeMenFront;
        this.course = teebox.courseMenFront;
      }
      else {
        this.slope = teebox.slopeWomenFront;
        this.course = teebox.courseWomenFront;
      }
    }

    participant.teebox = teebox;
  }

  setNbShots(participant, teebox) {
    let slope;
    let course;
    if (participant.junior.user.gender) {
      if (teebox != null) {
        if (participant.mode === 'front' || this.competition.type === 1) {
          slope = teebox.slopeMenFront;
          course = teebox.courseMenFront;
        }
        else {
          slope = teebox.slopeMen;
          course = teebox.courseMen;
        }
      }
    }
    else {
      if (teebox != null) {
        if (participant.mode === 'front' || this.competition.type === 1) {
          slope = teebox.slopeWomenFront;
          course = teebox.courseWomenFront;
        }
        else {
          slope = teebox.slopeWomen;
          course = teebox.courseWomen;
        }
      }
    }
    if (teebox != null) {
      const hcpPlay = this.getPlayerHcp(participant);
      console.log(hcpPlay);
      const hcpC =  hcpPlay;
      let nbShots;
      if (participant.mode === 'front' || this.competition.type === 1) {
        nbShots = ((hcpC / 2) * ((slope * 2) / 113.0) - ((this.competition.course.par[0]) - course));
      }
      else {
        nbShots = (hcpC * (slope / 113.0) - (this.competition.course.par[0] + this.competition.course.par[1] - course));
      }

      console.log(Math.round(nbShots * 2) % 2);

      console.log(this.competition.course.holes[0].hcp);

      participant.nbShots = Math.round(nbShots);
    }
  }

  postParticipants() {
    let users = [];
    for(let user of this.selectedSimpleItem){
      users.push(user.id);
    }
    this.api.postParticipants(this.id, users).then( res => {
      for (const participant of res) {
        this.initParticipant(participant);
        this.competition.participants.push(participant);
        this.simpleItems.find(x => x.id === participant.juniorId).disabled = true;
        }
      this.simpleItems = [...this.simpleItems];
      //TODO: a sort method with no animation
      const sortedList = this.orderByNet([...this.competition.participants]);
      this.competition.participants = [...sortedList];
      this.selectedSimpleItem = [];
      }
    );
  }

  removeParticipant(data) {
    this.competition.participants = this.competition.participants.filter(x => x.id !== data.participantId);
    this.simpleItems.find(x => x.id === data.juniorId).disabled = false;
    this.simpleItems = [...this.simpleItems];
  }

  deleteParticipant(participant) {
    const subject = new Subject();
    subject.subscribe((data) => this.removeParticipant(data));
    this.api.showLoginWindow2 = {type: 'deleteParticipant', data: {participant, competition: this.competition}, subject};
  }

  closeCompetition() {
    const subject = new Subject();
    subject.subscribe(() => this.competition.status = 'closed');
    this.api.showLoginWindow2 = {type: 'closeCompetition', data: {competition: this.competition}, subject};
  }

  openCompetition() {
    const subject = new Subject();
    subject.subscribe(() => this.competition.status = 'opened');
    this.api.showLoginWindow2 = {type: 'openCompetition', data: {competition: this.competition}, subject: subject};
  }


  orderByBrut(c) {
    c.sort( (a, b) => {
      if (a.brut === 0 && b.brut === undefined) {
        return -1;
      }
      if (b.brut === 0 && a.brut === undefined) {
        return 1;
      }
      if (a.brut && !b.brut) {
        return -1;
      }
      if (!a.brut && b.brut) {
        return 1;
      }
      if (a.brut > b.brut) {
        return -1;
      }
      if (a.brut < b.brut) {
        return 1;
      }
      if (a.hcp && !b.hcp) {
        return 1;
      }
      if (!a.hcp && b.hcp) {
        return -1;
      }
      if (a.hcp > b.hcp) {
        return 1;
      }
      if (a.hcp < b.hcp) {
        return -1;
      }
      if (!a.junior.hcp && b.junior.hcp) {
        return 1;
      }
      if (a.junior.hcp && !b.junior.hcp) {
        return -1;
      }
    });
    return c;
  }

  orderByNet(c) {
    c.sort( (a, b) => {
      if (a.net === 0 && b.net === undefined) {
        return -1;
      }
      if (b.net === 0 && a.net === undefined) {
        return 1;
      }
      if (a.net && !b.net) {
        return -1;
      }
      if (!a.net && b.net) {
        return 1;
      }
      if (a.net > b.net) {
        return -1;
      }
      if (a.net < b.net) {
        return 1;
      }
      if (a.hcp && !b.hcp) {
        return 1;
      }
      if (!a.hcp && b.hcp) {
        return -1;
      }
      if (a.hcp > b.hcp) {
        return 1;
      }
      if (a.hcp < b.hcp) {
        return -1;
      }
      if (!a.junior.hcp && b.junior.hcp) {
        return 1;
      }
      if (a.junior.hcp && !b.junior.hcp) {
        return -1;
      }
    });
    return c;
  }

  orderByHcp(c) {
    c.sort( (a, b) => {

    });
    return c;
  }


  sort() {

    const self = this;
    const list = this.competition.participants;
    const sortedList = this.sorting === 'net' ? this.orderByNet([...this.competition.participants]) : this.orderByBrut([...this.competition.participants]);

    const position_div_juniors = [];
    const height = $('#scores').height();
    $('#scores').css('height', height + 'px');

    $('.scores_player').each(function (index, value) {
      position_div_juniors.push($(this).position().top);
    });

    let openheight = $('.scores_div.open').height();
    openheight = openheight === undefined ? 0 : openheight;
    const newselectedposition = sortedList.findIndex(j => j.id === self.selected);

    $('.scores_player').each(function (index, value) {
      const participant = list.find(j => j.id === list[index].id);
      const position = sortedList.findIndex(j => j.id === list[index].id);
      $(this).css('position', 'absolute');
      $(this).css('top', position_div_juniors[index]);
      let top = position * ($(this).height() - $('.scores_div', this).height());
      top += (newselectedposition < position ? openheight : 0);
      if(openheight){

      }
      $(this).animate({'top': top}, 500, function() {

      });
    });

    $.when($('.scores_player')).then(x => {
      $('#scores').css('height', '');
      $('.scores_player').each(function (index, value) {
        $(this).css('position', '');
        $(this).css('top', '');
      });
      self.competition.participants = sortedList;
      this.transit = true;
      setTimeout(function(){self.transit=false;},1);

    });
  }


}
