<div style="position:sticky; position: -webkit-sticky; margin-bottom:10px;top:0; display: flex;width: 100%;background-color: lightgray;color:gray;align-items: center;
    height: 30px;">
  <div [ngStyle]="{'left':scrollLeft || scrollLeft==0?null:0}" style="display: flex;position: sticky; position: -webkit-sticky;background-color: lightgray;">
    <div style="min-width:70px;width: 70px;">
      <div class="cell"></div>
    </div>
    <div (click)="clickHeader('lastName')" [ngClass]="{'sorted':sorted==='lastName'}" class="sortable" ngClass="" style="min-width:150px;width: 150px;">
      <div class="cell">NOM</div>
    </div>
    <div (click)="clickHeader('firstName')" [ngClass]="{'sorted':sorted==='firstName'}" class="sortable" style="min-width:120px;width: 120px;">
      <div class="cell">PRENOM</div>
    </div>
    <div (click)="clickHeader('firstName')" [ngClass]="{'sorted':sorted==='firstName'}" class="sortable" style="min-width:20px;width: 20px;">
      <div class="cell"></div>
    </div>
  </div>
  <div [ngStyle]="{'margin-left':'-'+(scrollLeft || scrollLeft == 0?scrollLeft:0)+'px'}" style="background-color: lightgray;min-width:5px;width: 5px;">
  </div>
  <div (click)="clickHeader('gender')" [ngClass]="{'sorted':sorted==='gender'}" class="sortable" style="background-color: lightgray;min-width:80px;width: 80px;">
    <div class="cell">SEXE</div>
  </div>
  <div (click)="clickHeader('hcp')" [ngClass]="{'sorted':sorted==='hcp'}" class="sortable" style="background-color: lightgray;width: 80px;min-width: 80px;">
    <div class="cell">HCP.</div>
  </div>
  <div  (click)="clickHeader('birthday')" class="sortable" [ngClass]="{'sorted':sorted==='birthday'}" style="background-color: lightgray;width: 120px;min-width: 120px">
    <div class="cell">NAISSANCE</div>
  </div>
  <div *ngIf="!fields || fields.includes('asgNumber')" style="background-color: lightgray;width: 120px;min-width: 120px;">
    <div class="cell" >N° SG</div>
  </div>
  <div *ngIf="!fields || fields.includes('AVSNumber')"  style="background-color: lightgray;width: 170px;min-width: 170px;">
    <div class="cell" >N° AVS</div>
  </div>
  <div *ngIf="!fields || fields.includes('address')" style="background-color:lightgray;width: 370px;min-width: 370px">
    <div class="cell">ADRESSE</div>
  </div>
  <div *ngIf="!fields || fields.includes('phone_whatsapp')"  style="background-color:lightgray;width: 20px;min-width: 20px">
    <div class="cell"><img style="margin-left: 2px; max-width: 15px" src="assets/whatsapp.svg"></div>
  </div>
  <div *ngIf="!fields || fields.includes('phone')"  style="background-color:lightgray;width: 140px;min-width: 140px">
    <div class="cell">MOBILE JUNIOR</div>
  </div>
  <div *ngIf="!fields || fields.includes('mail')" style="background-color:lightgray;width: 200px;min-width: 200px">
    <div class="cell">EMAIL JUNIOR</div>
  </div>
  <div *ngIf="!fields || fields.includes('nameParent1')"  style="background-color:lightgray;width: 200px;min-width: 200px">
    <div class="cell">NOM PARENT 1</div>
  </div>
  <div *ngIf="!fields || fields.includes('phoneParent1_whatsapp')"  style="background-color:lightgray;width: 20px;min-width: 20px">
    <div class="cell"><img style="margin-left: 2px; max-width: 15px" src="assets/whatsapp.svg"></div>
  </div>
  <div *ngIf="!fields || fields.includes('phoneParent1')" style="background-color:lightgray;width: 140px;min-width: 140px">
    <div class="cell">MOBILE PARENT 1</div>
  </div>
  <div *ngIf="!fields || fields.includes('mailParent1')" style="background-color:lightgray;width: 200px;min-width: 200px">
    <div class="cell">EMAIL PARENT 1</div>
  </div>
  <div *ngIf="!fields || fields.includes('nameParent2')" style="background-color:lightgray;width: 200px;min-width: 200px">
    <div class="cell">NOM PARENT 2</div>
  </div>
  <div *ngIf="!fields || fields.includes('phoneParent2_whatsapp')"  style="background-color:lightgray;width: 20px;min-width: 20px">
    <div class="cell"><img style="margin-left: 2px; max-width: 15px" src="assets/whatsapp.svg"></div>
  </div>
  <div *ngIf="!fields || fields.includes('phoneParent2')" style="background-color:lightgray;width: 140px;min-width: 140px">
    <div class="cell">MOBILE PARENT 2</div>
  </div>
  <div *ngIf="!fields || fields.includes('mailParent2')" style="background-color:lightgray;width: 200px;min-width: 200px">
    <div class="cell">EMAIL PARENT 2</div>
  </div>
  <div *ngFor="let lesson of lessons" style="background-color:lightgray;width: 200px;min-width: 200px">
    <div class="cell" style="text-transform: uppercase">{{lesson.name}}</div>
  </div>
  <div #scroll style="width: 100%;">
    <div class="cell" ></div>
  </div>
</div>
