import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-adherer',
  templateUrl: './adherer.component.html',
  styleUrls: ['./adherer.component.scss']
})
export class AdhererComponent implements OnInit {

  constructor() {

  }

  ngOnInit() {
  }

}
