import {
  AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, Output,
  ViewChild
} from '@angular/core';
import {Router} from '@angular/router';
import {APIService} from '../../api';


@Component({
  selector: 'app-delete-participant',
  templateUrl: './delete-participant.component.html',
  styleUrls: ['./delete-participant.component.css']
})
export class DeleteParticipantComponent {
  @Input() data;
  @Input() subject;
  @Output() close = new EventEmitter();
  @ViewChild('scrollMe') private myScrollContainer: any;
  @ViewChild('d') private d: any;
  loaded = true;

  constructor(private api: APIService, private router: Router) {

  }

  delete() {
    this.loaded = false;
    this.api.deleteParticipant(this.data.participant.id, this.data.competition.id).then(() => {
      console.log(this.data.participant);
      this.subject.next({participantId: this.data.participant.id, juniorId: this.data.participant.juniorId});
      this.loaded = false;
      this.api.showLoginWindow2 = null ;
    });
  }

  cancel() {
    this.api.showLoginWindow2 = null ;
  }
}
