<div *ngIf="!loaded" class="text-center">
  <i class="fas fa-circle-notch fa-spin fa-7x"></i>
</div>
<div *ngIf="loaded">
  <ng-container *ngIf="category.length===0">
  <span style="margin-top:25px; margin-left: 12px;margin-right: 12px; float: left"><label><input
    [(ngModel)]="checkboxSection" (change)="change()" type="checkbox" id="scales" name="scales"
  > Section juniors</label></span>
  <span style="margin-top:25px;margin-left: 12px;margin-right: 12px; float: left"><label><input
    [(ngModel)]="checkboxBaby" (change)="change()" type="checkbox" id="scales" name="scales"
  > Crossgolf Kids<img style="margin-left: 2px; max-width: 15px" src="assets/cross.svg"></label></span><span
  style="margin-top:25px;margin-left: 12px;margin-right: 12px; float: left"><label style="width:180px;"><input
  [(ngModel)]="checkboxResign" (change)="change()" type="checkbox" id="scales" name="scales"
><span> Anciens juniors <img style=" max-width: 12px" src="assets/out-button.svg"></span>
</label></span>
  </ng-container>
  <ng-container *ngIf="!type">
  <a (click)="edit(null)" class="edit btn-floating waves-effect waves-light" style="float:right; margin-right:20px; margin-bottom:20px; background-color: #F44336 !important">
    <i class="material-icons">add</i></a>
  <a (click)="api.downloadList()"><a class="edit btn-floating waves-effect waves-light" style="float:right; margin-right:10px; margin-bottom:20px; background-color: green !important">
    <img style="margin:9px; width: 20px" src="assets/excel.svg"></a></a>
  </ng-container>
  <div #scrollMe (scroll)="test2($event)" style=" width: 100%;overflow-x: scroll">
    <div #d *ngIf="top<0" style="z-index:3;position:fixed;top:0; display: flex;width: 100%;">
      <app-list-header [fields]="fields" [orderBy]="orderBy" [scrollLeft]="scrollLeft" [(sorted)]="orderBy" (sortedChanged)="sort($event)"
                       [lessons]="lessons"></app-list-header>
    </div>
    <table style="margin-bottom:20px; width: 100%">
      <thead>
      <tr>
        <td style="padding: 0">
          <app-list-header [fields]="fields" [orderBy]="orderBy" (sortedChanged)="sort($event)" [(sorted)]="orderBy"
                           [lessons]="lessons"></app-list-header>
        </td>
      </tr>
      </thead>
      <tr>
        <td [ngClass]="{'selected':over===junior || (over && over.junior===junior)}" (mouseenter)="over={junior:junior}"
            (mouseleave)="over=null" style="padding:0;display: flex;width: 100%;page-break-inside:avoid "
            *ngFor="let junior of listjuniors">
          <div style="display: flex;position: sticky;left:0; position: -webkit-sticky;"
               [ngStyle]="{'z-index':over && over.junior===junior?2:1}">
            <div style="min-width:70px;width: 70px;  background-color: white;">
              <div class="cell2" style="position:relative; display: flex; justify-content: center">
                <img *ngIf="junior.birthday && parseInt((junior.birthday | date:'yyyy')) < (currentYear-18) && junior.inSection && !junior.resignDate" style="max-width: 25px" src="assets/plus-18.svg">
                <div style="font-size:13px" *ngIf="type=='groups' && junior.birthday && parseInt((junior.birthday | date:'yyyy')) >= (currentYear-18) && parseInt((junior.birthday | date:'yyyy')) < (currentYear-17)">U18</div>
                <div style="font-size:13px" *ngIf="type=='groups' && junior.birthday && parseInt((junior.birthday | date:'yyyy')) >= (currentYear-17) && parseInt((junior.birthday | date:'yyyy')) < (currentYear-16)">U17</div>
                <div style="font-size:13px" *ngIf="type=='groups' && junior.birthday && parseInt((junior.birthday | date:'yyyy')) >= (currentYear-16) && parseInt((junior.birthday | date:'yyyy')) < (currentYear-15)">U16</div>
                <div style="font-size:13px" *ngIf="type=='groups' && junior.birthday && parseInt((junior.birthday | date:'yyyy')) >= (currentYear-15) && parseInt((junior.birthday | date:'yyyy')) < (currentYear-14)">U15</div>
                <div style="font-size:13px" *ngIf="type=='groups' && junior.birthday && parseInt((junior.birthday | date:'yyyy')) >= (currentYear-14) && parseInt((junior.birthday | date:'yyyy')) < (currentYear-13)">U14</div>
                <div style="font-size:13px" *ngIf="type=='groups' && junior.birthday && parseInt((junior.birthday | date:'yyyy')) >= (currentYear-13) && parseInt((junior.birthday | date:'yyyy')) < (currentYear-12)">U13</div>
                <div style="font-size:13px" *ngIf="type=='groups' && junior.birthday && parseInt((junior.birthday | date:'yyyy')) >= (currentYear-12) && parseInt((junior.birthday | date:'yyyy')) < (currentYear-11)">U12</div>
                <div style="font-size:13px" *ngIf="type=='groups' && junior.birthday && parseInt((junior.birthday | date:'yyyy')) >= (currentYear-11) && parseInt((junior.birthday | date:'yyyy')) < (currentYear-10)">U11</div>
                <div style="font-size:13px" *ngIf="type=='groups' && junior.birthday && parseInt((junior.birthday | date:'yyyy')) >= (currentYear-10)">U10</div>
                <img *ngIf="!junior.inSection" style="max-width: 20px" src="assets/cross.svg">
                <img *ngIf="junior.resignDate" style=" max-width: 17px" src="assets/out-button.svg">
                <a (click)="edit(junior)" *ngIf="type!='groups' && over && over.junior===junior"
                        class="edit btn-floating waves-effect waves-light red"
                        style="z-index:100;position:absolute;top:-5px;left:10px"><i class="material-icons">mode_edit</i>
                </a>
              </div>
            </div>
            <div *ngIf="!fields || fields.includes('lastName')" style="min-width:150px;width: 150px;background-color: white">
              <div class="cell">{{junior.lastName}}</div>
            </div>
            <div
                 style="min-width:120px;width: 120px;position: sticky;background-color: white">
              <div *ngIf="!fields || fields.includes('firstName')" class="cell">{{junior.firstName}}</div>
            </div>
            <div [ngStyle]="{'border-right':'1px solid gray'}" style="min-width:20px;width: 20px;background-color: white">
              <div class="cell">
                <div *ngIf="!(junior.phone_whatsapp || junior.phoneParent1_whatsapp || junior.phoneParent2_whatsapp) ||
                 !(junior.mail || junior.mailParent1 || junior.mailParent2)" style="display: flex; border-radius:2px; width:15px; height: 15px"><img style="width: 100%" src="assets/warning.svg"></div>
              </div>
            </div>

          </div>

          <div style="min-width:5px;width: 5px;background-color: white">
            <div class="cell"></div>
          </div>
          <div style="min-width:80px;width: 80px;background-color: white">
            <div class="cell"><img *ngIf="junior.gender!==null"
                                   [src]="junior.gender?'assets/mars.svg':'assets/venus.svg'" style="max-height: 20px">
            </div>
          </div>
          <div style="width: 80px;min-width: 80px;">
            <div class="cell">
              <ng-container *ngIf="junior.hcp">{{junior.hcp | number:'1.1-1'}}</ng-container>
              <app-medal-icon *ngIf="junior.medal" [medal]="medals2[junior.medal]" [size]="25"></app-medal-icon>
            </div>
          </div>
          <div style="width: 120px;min-width: 120px">
            <div class="cell">{{junior.birthday | dateFormat}}</div>
          </div>
          <div *ngIf="!fields || fields.includes('asgNumber')" style="width: 120px;min-width: 120px;">
            <div class="cell">{{junior.asgNumber}}</div>
          </div>
          <div *ngIf="!fields || fields.includes('AVSNumber')" style="width: 170px;min-width: 170px;">
            <div class="cell">{{junior.AVSNumber}}</div>
          </div>
          <div *ngIf="!fields || fields.includes('address')" style="width: 370px;min-width: 370px">
            <div class="cell" style="font-size: 12px;">{{junior.address}}</div>
          </div>
          <div *ngIf="!fields || fields.includes('phone_whatsapp')" style="width: 20px;min-width: 20px">
            <div class="cell" style="font-size: 12px;"><img *ngIf="junior.phone_whatsapp" style="margin-left: 2px; max-width: 15px" src="assets/whatsapp.svg" /></div>
          </div>
          <div *ngIf="!fields || fields.includes('phone')" style="width: 140px;min-width: 140px">
            <div class="cell" style="font-size: 12px;">{{junior.phone}}</div>
          </div>
          <div *ngIf="!fields || fields.includes('mail')" style="width: 200px;min-width: 200px">
            <div class="cell" style="font-size: 12px;">{{junior.mail}}</div>
          </div>
          <div *ngIf="!fields || fields.includes('nameParent1')" style="width: 200px;min-width: 200px">
            <div class="cell" style="font-size: 12px;">{{junior.nameParent1}}</div>
          </div>
          <div *ngIf="!fields || fields.includes('phoneParent1_whatsapp')" style="width: 20px;min-width: 20px">
            <div class="cell" style="font-size: 12px;"><img *ngIf="junior.phoneParent1_whatsapp" style="margin-left: 2px; max-width: 15px" src="assets/whatsapp.svg" /></div>
          </div>
          <div *ngIf="!fields || fields.includes('phoneParent1')" style="width: 140px;min-width: 140px">
            <div class="cell" style="font-size: 12px;">{{junior.phoneParent1}}</div>
          </div>
          <div *ngIf="!fields || fields.includes('mailParent1')" style="width: 200px;min-width: 200px">
            <div class="cell" style="font-size: 12px;">{{junior.mailParent1}}</div>
          </div>
          <div *ngIf="!fields || fields.includes('nameParent2')" style="width: 200px;min-width: 200px">
            <div class="cell" style="font-size: 12px;">{{junior.nameParent2}}</div>
          </div>
          <div *ngIf="!fields || fields.includes('phoneParent2_whatsapp')" style="width: 20px;min-width: 20px">
            <div class="cell" style="font-size: 12px;"><img *ngIf="junior.phoneParent2_whatsapp" style="margin-left: 2px; max-width: 15px" src="assets/whatsapp.svg" /></div>
          </div>
          <div *ngIf="!fields || fields.includes('phoneParent2')" style="width: 140px;min-width: 140px">
            <div class="cell" style="font-size: 12px;">{{junior.phoneParent2}}</div>
          </div>
          <div *ngIf="!fields || fields.includes('mailParent2')"  style="width: 200px;min-width: 200px">
            <div class="cell" style="font-size: 12px;">{{junior.mailParent2}}</div>
          </div>
          <div *ngFor="let lesson of lessons" style="width: 200px;min-width: 200px">
            <div class="cell" (click)="editGroup(over.lesson,junior)"
                 (mouseenter)="over={lesson:(!junior.resignDate?lesson:null), junior:junior}"
                 (mouseleave)="over={lesson:null, junior:junior}"
                 [ngStyle]="{'cursor':junior.inSection && !junior.resignDate?'pointer':null}"
                 style="position: relative;font-size: 12px;">
              <button *ngIf="over && over.junior===junior && over.lesson === lesson" (click)="edit(null)"
                      class="edit btn-floating-sm waves-effect waves-light"
                      style="padding:0; position: absolute; background-color: #F44336 !important"><i
                style="font-size:10px" class="material-icons">mode_edit</i></button>
              <div style="min-height: inherit;display: flex;align-items: center;margin-left:1px;padding-left:2px;width: 100%;" *ngFor="let group of junior.groups | group: lesson.id" [ngStyle]="{'color':group.color?'black':null,'background-color':group.color?group.color:null}">{{group.name}}</div>
            </div>
          </div>
          <div style="width: 100%;">
            <div class="cell" style="font-size: 12px;" (mouseenter)="over=junior" (mouseleave)="over=null"></div>
          </div>

        </td>

      </tr>
    </table>

    <!-- <header style="background-color:#757575; position:relative;color:white">
       <button id="option_add_button" class="btn-floating btn-large waves-effect waves-light" style="position:absolute;bottom:-28px;right:5px"><i id="fab-add" style="color:white" class="material-icons">add</i></button>
       <button id="option_print_button" class="btn-floating btn-large waves-effect waves-light" style="position: absolute; bottom: -28px; right: 70px; display: block;"><i style="color:white" class="material-icons">print</i></button>
     </header>
     <div style="margin-right: 20px; margin-left: 20px; margin-top: 0px; background-color: white">
       <div id="stats" style="margin: 10px">
         <h5 style="display:inline-block"><strong id="nbjuniors">{{listjuniors.length}}</strong> juniors</h5>
         <h6 style="display:inline-block">(<span id="nbboys">{{(listjuniors | men).length}}</span> boys, <span id="nbgirls">{{(listjuniors | girl).length}}</span> girls)</h6>
         <select style="outline:none; display:block; border-color:dimgray;border-radius: 0px" id="sorting"  [disabled]="this.animating" (change)="test()" [(ngModel)]="sorting">
           <option value="age">Age</option>
           <option value="name">Nom</option>
           <option value="hcp">Handicap</option>
           <option value="nbtours">Nombre de tours</option>
         </select>
       </div>

       <div style="color:gray;text-transform:uppercase;width:100%; display:table; vertical-align: middle;">
         <div style="display:table-cell;width:60px"></div>
         <div style="display:table-cell;vertical-align:middle; width:150px"><span>Nom de famille</span></div>
         <div style="display:table-cell;vertical-align:middle; ;width:150px"><span>Prénom</span></div>
         <div style="display:table-cell;vertical-align:middle; ;width:150px"><span>HCP.</span></div>

         <div style="display:table-cell;vertical-align:middle; ;width:200px"><span>Date de naissance</span></div>
         <div style="display:table-cell;vertical-align:middle; width:130px"><span>Tours en 2018</span></div>

         <div style="display:table-cell"></div>
       </div>
     <div class="list_junior" style=" position:relative;">
       <ng-container *ngFor="let junior of listjuniors">

       <div class="category_title" style="margin-left:10px; font-size:30px" *ngIf="isFirstOfCategory(junior)">U{{getCategory(junior)}}</div>
       <div (mouseenter)="over=junior" (mouseleave)="over=null" (click)="detail(junior)" [ngClass]="{'selected':over===junior}" style="width:100%; display:table; vertical-align: middle; height:32px; position: relative" class="test">
         <div style="display:table-cell;width:60px">
         </div>
         <div style="display:table-cell;vertical-align:middle; width:150px"><span>{{junior.lastName}}</span></div>
         <div style="display:table-cell;vertical-align:middle; ;width:150px"><span>{{junior.firstName}}</span></div>
         <div style="display:table-cell;vertical-align:middle; ;width:150px"><span *ngIf="junior.hcp">{{junior.hcp | number:'1.1-1'}}</span>
         <span *ngIf="junior.medal===1">
           <div style="position:relative;vertical-align:middle;display:inline-block;width: 25px; height: 25px; border-radius: 20px; background-color: #b49e87">
             <div style="position: absolute;left:7px;top:1px;color:white;font-size: 15px">B</div>
           </div>
         </span>
           <span *ngIf="junior.medal===2">
           <div style="position:relative;vertical-align:middle;display:inline-block;width: 25px; height: 25px; border-radius: 20px; background-color: #929295">
             <div style="position: absolute;left:7px;top:1px;color:white;font-size: 15px">A</div>
           </div>
           </span>
           <span *ngIf="junior.medal===3">
               <div  style="position:relative;vertical-align:middle;display:inline-block;width: 25px; height: 25px; border-radius: 20px; background-color: #dcc7a2">
                 <div style="position: absolute;left:7px;top:1px;color:white;font-size: 15px">O</div>
               </div>
           </span>
         </div>

         <div style="display:table-cell;vertical-align:middle; ;width:200px"><span>{{junior.birthday | dateFormat}}</span></div>
         <div style="display:table-cell;vertical-align:middle; width:130px"><span>{{junior.nbTours}}</span></div>

         <div style="display:table-cell"></div>

       </div>
       </ng-container>
     </div>-->
  </div>

</div>
