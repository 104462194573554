<div #banner style="margin-bottom:0px; z-index:1;right:0; max-width: 1680px;  display: block;margin-left: auto;margin-right: auto;width: 100%;max-height:620px;position:relative;background: gray;overflow: hidden;">
  <div  class="nav-item"  style="height: 40%;background: #f9f9f9DD;z-index:10;text-align:center;position: absolute;width: 100%">
    <img  style="padding:10px; height:100%" src="assets/logo_color.svg">
  </div>
  <div style="position: absolute; height:100%; top:0" class="col-lg-12 p-0">
    <img *ngIf="translateYValue>0" style="width: 100%" [style.transform]="'translateY(-' + translateYValue + '%)'" src="assets/IMG-20230909-WA0006.jpg">
  </div>
  <div style="padding-top: 39%; /* 1:1 Aspect Ratio */width:100%">
    <img style="object-fit: cover; transition: opacity 2s;width:100%;">
  </div>
</div>
<div style="">
  <div *ngIf="isShown3 && !isShown2" class="row" style="margin-bottom:30px; background-color:rgba(255,135,135,0.25); margin-left:0;margin-right: 0;color:black; padding-bottom:30px; padding-left: 20px; padding-right: 20px">
    <div class="col-lg-12 d-flex align-items-center justify-content-center">
      <div>
        <h6 class="mt-5" style="text-align:center;font-weight:300;font-size: 25px"><b>REPRISE DES COURS</b></h6>
        <h6  style="text-align:center;font-weight:300;font-size: 25px">En raison du coronavirus les groupes et les horaires sont modifiés pour la période du 11 mai au 8 juin.</h6>
        <h5 style="text-align:center;font-weight:100;font-size: 20px"><a class="link" style="cursor: pointer" routerLink="/groupes/period-corona">Accès aux groupes</a></h5>
      </div>
    </div>
  </div>
  <div *ngIf="date<date1 && isShown2" class="row" style="margin-bottom:30px; background-color:rgba(255,135,135,0.25); margin-left:0;margin-right: 0;color:black; padding-bottom:30px; padding-left: 20px; padding-right: 20px">
    <div class="col-lg-12 d-flex align-items-center justify-content-center">
      <div>
        <h6 class="mt-5" style="text-align:center;font-weight:300;font-size: 25px"><b>ATTENTION !! REPRISE NORMALE DES COURS.</b></h6>
        <h6  style="text-align:center;font-weight:300;font-size: 25px">Dès le 8 juin 2020, les cours du mercredi ainsi que les cours EGC et Espoirs reprennent</h6>
        <h5 style="text-align:center;font-weight:100;font-size: 20px"><a class="link" style="cursor: pointer" routerLink="/groupes/mercredi">Cours du mercredi</a></h5>
        <h5 style="text-align:center;font-weight:100;font-size: 20px"><a class="link" style="cursor: pointer" routerLink="/groupes/egc_espoir">Cours EGC, Espoirs, 19-21 ans</a></h5>
      </div>
    </div>
  </div>
<div class="big-only" style="">
  <app-sponsors-header></app-sponsors-header>
</div>
  <div>
    <div *ngIf="false" class="row" style="margin-left:0;margin-right: 0;color:black; padding-top:20px; padding-bottom:20px;">
      <div *ngIf="!api.club100Activated" class="col-1200-6 col-992-4  col-lg-6 d-flex align-items-center justify-content-center">
        <div class="col-lg-6  align-items-center justify-content-center" style="min-width:300px; margin-top:10px; margin-bottom:10px">
          <div style="text-align: center;  /* 1:1 Aspect Ratio */width:100%">
            <h1>La saison 2023 du club des 100 est lancée&#8239;!</h1>
            <h5>Soutenez les juniors en devenant membre du club des 100&#8239;!</h5>
            <a routerLink="/club100" ><img style="max-width: 300px" src="assets/club100.svg"></a>
          </div>

          <div style="margin-top:10px;text-align: center;margin-top: 10px;"><button routerLink="/club100" class="buttonLink">Toutes les informations ici</button></div>
        </div>
      </div>
      <div *ngIf="!api.club100Activated" class="col-1200-3 col-992-4 col-lg-3 d-flex align-items-center justify-content-center">
          <div class="article2" style="display:flex; flex-direction:column; height:100%;text-align: center; /* 1:1 Aspect Ratio */width:100%;padding-top:10px; padding-left:0; padding-right:0">
            <h2 style="height:100%; padding: 20px">Le programme 2023 est en ligne.</h2>
            <h5 style="height:100%; padding: 20px"> Les opportunités de jeu sur le parcours ne manquent pas.</h5>
            <div style="margin-top:10px;margin-bottom:10px;text-align: center;margin-top: 10px;"><button routerLink="/programme" class="buttonLink">Vers le programme</button></div>
          </div>
      </div>
      <div *ngIf="!api.club100Activated" class="col-1200-3 col-992-4 col-lg-3 d-flex align-items-center justify-content-center">
        <div class="article2" style="display:flex; flex-direction:column; height:100%;text-align: center; /* 1:1 Aspect Ratio */width:100%;padding-top:10px; padding-left:0; padding-right:0">
          <h2 style="height:100%; padding: 20px">Les premiers cours outdoor reprennent le 5 avril.</h2>
          <h5 style="height:100%; padding: 20px">Découvrez les dates et la composition des groupes.</h5>
          <div style="margin-top:10px;margin-bottom:10px;text-align: center;margin-top: 10px;"><button routerLink="/groupes/mercredi" class="buttonLink">Vers les groupes du mercredi</button></div>
          <div style="margin-top:10px;margin-bottom:10px;text-align: center;margin-top: 10px;"><button routerLink="/groupes/egc_espoir" class="buttonLink">Voir les groupes EGC/Espoirs</button></div>
        </div>
      </div>
    </div>
    <div *ngIf="false && api.club100Activated" class="row" style="justify-content:center;background:#00543c;color:white;width:100%;margin-left:0;margin-right: 0; margin-bottom:20px; padding-top:0px; padding-bottom:1px; padding-left: 10px; padding-right: 10px">
    </div>


    <div class="row" style="justify-content:center; background-color:#f3f3f3; margin-left:0;margin-right: 0;">
      <div *ngIf="true" class="col-lg-6 article news" style="background-color:#f3f3f3;color:black;padding: 20px">
        <h6 class="mb-5" style="width:100%;font-weight:100;font-size: 25px">Dernière news</h6>
        <div *ngIf="!loaded" class="text-center">
          <i class="fas fa-circle-notch fa-spin fa-7x"></i>
        </div>
        <div *ngIf="loaded">
          <div *ngFor="let n of news">
            <h5 class="mb-1" style="text-transform: uppercase;font-weight:100;font-size: 12px">{{n.date | date:'d MMMM yyyy '}}</h5>
            <h5 class="mb-5" style="font-size: 25px">{{n.title}}</h5>
            <div *ngIf="n.image" class="mb-5 col-12" style="text-align: center">
              <div style="display: inline-block; text-align:center; max-width:500px; width: 100%">
                <img style="width: 100%; transform: rotate(-3deg);  border: 2px solid; border-image: linear-gradient(white, white) 2;"  [src]="'https://juniorsgolfpayerne.ch/docs/'+n.image"/>
              </div>
            </div>
            <div class="col 12" style="text-align:center;">
              <div style="display: inline-block; text-align: justify">
                <div style="font-size: 17px; font-weight:300" [innerHtml]="n.text">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="showprogram" class="col-lg-6 article2">
          <h6 style="margin-bottom:10px; width:100%;font-weight:100;font-size: 25px;padding: 20px;">Programme des 30 prochains jours</h6>
          <div class=" col-12" style="text-align: center">
            <app-smallprogramm (unavailable)="showprogram=false"></app-smallprogramm>
            <div style="height: 20px"></div>
          </div>
      </div>
    </div>
  </div>
  <div class="col-lg-12  align-items-center justify-content-center" style="min-width:300px; margin-top:10px; margin-bottom:10px">
    <div style="text-align: center;  /* 1:1 Aspect Ratio */width:100%">
      <h5>Soutenez les juniors en devenant membre du club des 100&#8239;!</h5>
      <a routerLink="/club100" ><img style="max-width: 300px" src="assets/club100.svg"></a>
    </div>
  </div>
  <div *ngIf="api.club100Activated" class="row" style="justify-content:center;background:#00543c;color:white;margin-left:0;margin-right: 0; padding-top:20px; padding-bottom:20px; padding-left: 20px; padding-right: 20px">
    <div class="col-lg-6 d-flex align-items-center justify-content-center">
      <div style="width: 100%">

        <h6 style="font-weight:100;font-size: 25px">La devise</h6>
        <h5 style="width: 100%; max-width:450px;margin:auto;font-style:italic;font-size: 25px">"Sourire à la vie,<br />Être heureux de ce que l'on a,<br />Faire de son mieux,<br />Respecter la
          nature et son prochain"</h5>
      </div>
    </div>
  </div>
<div *ngIf="false" style="background: white; width: 100%; text-align: left; display: flex; justify-content: space-between">
  <div><img style="margin:10px; width: 100px" src="assets/didiercrausaz.svg"></div>
  <div style="display: flex; align-items: center"><span style="font-size: 14px; margin:10px;">JUNIORS GOLF PAYERNE - All rights reserved 2022 ©</span></div>
</div>
</div>
