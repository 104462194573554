<div *ngIf="!loaded" style="width: 100%;height: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center">
  <div style="flex: 1 1 40%">
  </div>
  <div class="text-center">
    <i class="fas fa-circle-notch fa-spin fa-7x"></i>
  </div>
  <div style="flex: 1 1 60%">
  </div>
</div>

<ng-container *ngIf="loaded">


  <div *ngIf="lockDate" class="noprint" style="text-align: center"><p style="display:inline-block;background: #e9e9e9; padding: 10px; border-radius:10px">Le classement est bloqué au {{lockDate | dateFormat}} pour maintenir le suspens durant la fin de la saison.</p></div>

<div style="margin:auto;max-width: 900px" class="om-container">
  <div class="params">
    <div class="select noprint" style="padding: 10px;">
      <select class="custom-select" [(ngModel)]="year" (ngModelChange)="getResult()" style="width: 100%; text-align: center" name="year" id="orderOfMerite">
        <option value="2024">2024</option>
        <option value="2023">2023</option>
        <option value="2022">2022</option>
        <option value="2021">2021</option>
        <option value="2020">2020</option>
        <option value="2019">2019</option>
      </select>
      <div class="select_arrow">
      </div>
    </div>
    <h2 class="print_only">{{gender===1?'Boys':''}}{{gender===0?'Girls':''}}{{gender===2?'U12':''}} - {{form==='net'?'Net':''}}{{form==='brut'?'Brut':''}}</h2>

    <div class="noprint btn-group btn-group-toggle" data-toggle="buttons">
    <label (click)="setGender(1)" class="btn btn-secondary" [ngClass]="{'active':gender===1}">
      <input type="radio" name="options" id="option1" autocomplete="off" > Boy
    </label>
    <label (click)="setGender(0)" class="btn btn-secondary" [ngClass]="{'active':gender===0}">
      <input type="radio" name="options" id="option2" autocomplete="off"> Girl
    </label>
    <label (click)="setGender(2)" class="btn btn-secondary" [ngClass]="{'active':gender===2}">
      <input type="radio" name="options" id="option3" autocomplete="off"> U12
    </label>
  </div>
    <div style="float: right" class="noprint btn-group btn-group-toggle" data-toggle="buttons">
      <label  (click)="setForm('net')" class="btn btn-secondary" [ngClass]="{'active':form==='net'}" >
        <input type="radio" name="options" id="option1" autocomplete="off"> Net
      </label>
      <label (click)="setForm('brut')" class="btn btn-secondary" [ngClass]="{'active':form==='brut'}">
        <input type="radio" name="options" id="option2" autocomplete="off"> Brut
      </label>
    </div>
  </div>
  <div *ngIf="juniors.length>0">
  <div style="position:sticky; z-index:1; position: -webkit-sticky; margin-bottom:10px;top:0; display: flex;width: 100%;background-color: lightgray;color:gray;align-items: center;
    height: 30px;">
    <div class="sortable" style="min-width: 70px; ">
      <div class="cell" style="padding-left: 10px">POS.</div>
    </div>
    <div class="sortable" style="width: 100%;">
      <div class="cell" style="padding-left: 8px">NOM</div>
    </div>
    <div class="sortable">
      <div class="cell" style="padding-right:10px;text-align: right">POINTS</div>
    </div>
  </div>
    <div class="junior-row" *ngFor="let junior of juniors; let index=index"  [ngClass]="{'selected':over===junior}" (mouseenter)="over=junior" (mouseleave)="over=null">
    <div class="result-row" style="position:relative;width:100%;display: flex">
      <div class="edit" style="min-width: 70px; padding: 8px; text-align: center">{{index==0 || juniors[index].points !== juniors[index-1].points ? index+1 : '*'}}
      <a *ngIf="over===junior" (click)="toggle(junior)"
              class="edit btn-floating waves-effect waves-light red"
              style="outline:0; user-select:none; -webkit-user-select:none; z-index:100;position:absolute;top:calc(50% - 20px);left:10px"><i class="material-icons">{{selected===junior?'expand_less':'expand_more'}}</i>
      </a>
      </div>
      <div style="width: 100%; padding: 8px">{{junior.name}}</div>
      <div style="width: 100%;font-size:19px;font-weight:800;text-align: right; padding: 8px">{{junior.points}}</div>
    </div>

      <div class="details" style="overflow: hidden" [@slideIn]="{value:selected==junior?'active':'transit', params: {time: '500ms'}}">
        <div *ngFor="let score of junior.results" style="height:20px;width:100%;display: flex">
          <div class="empty"></div>
          <div style="min-width: 90px; font-size:13px;">{{score.date | dateFormat}}</div>
          <div class="name" style="font-size:13px;   white-space: nowrap; overflow:hidden; text-overflow: ellipsis">{{score.name}} <span [ngStyle]="{'color':score.bonus>0?'green':'red'}" style="font-size: x-small">{{score.bonus!==0?'('+score.bonus*100+'%)':null}}</span></div>
          <div style="min-width: 15%; font-size: 13px; padding-right: 10px; text-align: right">{{score[form]}}</div>
        </div>
      </div>
    </div>
  </div>
    <div *ngIf="juniors.length===0" style="white-space:pre-wrap; margin-right:20px; margin-left:20px; padding:20px; box-shadow: 0px 0px 16px 1px rgba(0,0,0,0.25);font-size: 25px; color: gray">Pas de classement pour l'instant
    </div>
</div>
</ng-container>
