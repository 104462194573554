import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {APIService} from '../../api';

@Component({
  selector: 'app-submenu',
  templateUrl: './submenu.component.html',
  styleUrls: ['./submenu.component.css'],
})
export class SubmenuComponent implements OnInit {
  @ViewChild('menu', { static: false }) private menu: any;

  @Input() data;
  @Input() icon;
  @Input() selectedSection = null;
  @Output() onchange = new EventEmitter();

  mode = 'normal';

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.ngAfterViewInit();
  }

  ngAfterViewInit(){
    console.log(this.menu.nativeElement.getBoundingClientRect().width, window.innerWidth);
      if (this.menu.nativeElement.getBoundingClientRect().width > window.innerWidth) {
        setTimeout(()=>{this.mode = 'small'}, 0);
      }
      else if(this.menu.nativeElement.getBoundingClientRect().width > 0){
        setTimeout(()=>{this.mode = 'normal'}, 0);
      }
  }

  ngOnInit() {

  }

  ngOnChanges(changes) {
    console.log(changes);
    if (changes.data && this.data.length > 0) {
      this.selectedSection = this.data[0];
    }
  }


  changeSection(s) {
    const index = this.data.findIndex((a) => a === s);
    this.selectedSection = s;
    this.onchange.emit(s);
    console.log(this.menu.nativeElement.children[index].offsetLeft);
    this.menu.nativeElement.scrollLeft = this.menu.nativeElement.children[index].offsetLeft - 40;
  }
}
