import {
  AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, Output,
  ViewChild
} from '@angular/core';
import {Router} from '@angular/router';
import {APIService} from '../../api';


@Component({
  selector: 'app-programm-filter',
  templateUrl: './programm_filter.component.html',
  styleUrls: ['./programm_filter.component.css']
})
export class ProgrammFilterComponent {

  @Input() data;
  @Input() subject;
  @Output() dataChange = new EventEmitter();

  toggle(category) {
    category.activated = !category.activated;
    this.dataChange.emit(this.data);
    this.subject.next();
  }
}
