 <div *ngIf="medal!=null" class="medal"
       [ngClass]="medalEnum[medal]"
       [ngStyle]="{'width.px': size, 'height.px': size, 'border-radius.px': size}">
    {{frenchNames[medal]}}
  </div>

 <div *ngIf="medal==null"
      [ngStyle]="{'width.px': size, 'height.px': size, 'border-radius.px': size}"
    style="display:inline-block;vertical-align:middle;position:relative; border:solid 1px gray; background-color: white">
   <div style="width:100%; height:100%; display:flex; justify-content:center; align-items:center">
     <i style="transform:translateX(0.5px) translateY(-0.5px); color:gray; font-size: 15px" class="material-icons">clear</i>
   </div>
 </div>
