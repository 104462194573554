<div *ngIf="!loaded"
     style="width: 100%;height: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center">
  <div style="flex: 1 1 40%">
  </div>
  <div *ngIf="!loaded" class="text-center">
    <i class="fas fa-circle-notch fa-spin fa-7x"></i>
  </div>
  <div style="flex: 1 1 60%">
  </div>
</div>

<div *ngIf="loaded">
  <hr/>


  <div *ngIf="!validated && !summaryMode" style="padding-left:10px; padding-right:10px">

    <div><input type="email" id="input" class="input mb-1 email" style="width:100%;border: 1px solid gray"
                [(ngModel)]="mail" placeholder="Email du représentant légal"></div>

    <div><input type="text" class="mb-3 firstname"
                style="width:calc(50% - 10px);margin-right:10px;" [(ngModel)]="lastname"
                placeholder="Nom du junior">
      <input [(ngModel)]="firstname" style="width: 50%" type="text"
                                                   class="mb-3 firstname" placeholder="Prénom du junior"></div>


    <div *ngFor="let option of options">
      <h4>{{option.text}}</h4>
      <ng-container *ngIf="!option.required">
      <div>
        <label style="cursor: pointer" [for]="'no_'+option.text">
          <input style="cursor:pointer; display: inline-grid" [(ngModel)]="option.selected" [value]="false" type="radio"
                 [id]="'no_'+option.text" [name]="'checkbox'+option.text">
          <div style="display: inline-flex; margin-left: 10px">Je ne souhaite pas commander de {{option.textS}}.</div>
        </label>
      </div>
      <div>
        <label style="cursor: pointer" [for]="option.text">
          <input style="cursor:pointer; display: inline-grid" [(ngModel)]="option.selected" [value]="true" type="radio"
                 [id]="option.text"
                 [name]="'checkbox2'+option.text">
          <div style="display: inline-flex; margin-left: 10px">Je souhaite commander un/des {{option.textS}}(s).</div>
        </label>
      </div>
      </ng-container>
      <div *ngIf="option.selected" style="width:100%; display :inline-flex; flex-wrap: wrap">
        <div *ngFor="let size of option.sizes" style="min-width:300px; padding-left:20px">
          <label style="cursor: pointer" [for]="option.text+'_'+size.text">
            <input style="cursor:pointer; display: inline-grid" [(ngModel)]="size.selected" (ngModelChange)="select(option, size)"
                   [value]="size.text"
                   type="radio" [id]="option.text+'_'+size.text" [name]="'checkbox3'+option.text">
            <div style="display: inline-flex; margin-left: 10px">{{size.text}}</div>
          </label>
          <div *ngIf="false && size.selected" style="padding-left:10px; display: inline-block">•<span
            style="padding-left:10px;  font-size: 12px">qté : </span><input min="1" max="9" step="1" style="margin: 0; align-items: center;
    text-align: center;
    vertical-align: center;
    align-content: center; max-width:40px;  padding: 0; display: inline-grid; min-height: fit-content;"

                                                                          [(ngModel)]=" size.value" type="number"
            [id]="option.text+'_'+size.text+'_number'"
            name="checkbox2">
          </div>
        </div>
      </div>
      <div style="height: 30px;"></div>
    </div>
    <div><textarea rows="3" style="height:100px;width: 100%" type="text" placeholder="Commentaire (facultatif)" [(ngModel)]="comment"></textarea></div>


    <div style="text-align: center">
      <div *ngFor="let validation of validations" style="color:red">{{validation}}</div>
      <button class="buttonLink" (click)="continue()">Continuer</button>
    </div>
  </div>

  <div *ngIf="!validated && summaryMode" style="padding-left:10px; padding-right:10px">
    <h4>Confirmation de commande</h4>
    <div *ngIf="summaryMode.length==0" style="text-align:center; font-size: 20px;">
      Aucun article commandé
    </div>
    <div *ngFor="let type of summaryMode">
      <div *ngFor="let selection of type.selection" style="min-width:300px; padding-left:20px">
        <div style="text-align:center; font-size: 20px;" *ngIf="selection.value > 0">
          {{selection.value}}x {{selection.value > 1 ? type.textP : type.textS}} - Taille : {{selection.text}}
        </div>
      </div>
    </div>
    <div *ngIf="comment!=''"><span>Votre commentaire : <span style="font-style: italic">{{comment}}</span></span></div>
    <div style="text-align: center; margin-top:40px">
      <div *ngFor="let validation of validations" style="color:red">{{validation}}</div>
      <button style="margin-right: 20px" class="buttonLink" (click)="back()">Retour sans confirmer</button>
      <button class="buttonLink" (click)="confirm()">Confirmer</button>
    </div>
  </div>
</div>

<div *ngIf="validated"
     style="width: 100%;height: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center">
  <h2 style="text-align: center">Merci ! Nous avons enregistré votre commande.</h2>
  <div style="margin-top: 10px">
    <button class="buttonLink" (click)="finished()">Terminer</button>
  </div>
</div>
