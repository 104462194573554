import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss']
})
export class DocumentComponent {
  @Input() title;
  @Input() file;
  @Input() small = false;
  mouseover = false;
  constructor() {

  }
}
