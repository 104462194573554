import { Component } from '@angular/core';
import {APIService} from '../../api';
import {Subject} from "rxjs";

@Component({
  selector: 'app-contact-management',
  templateUrl: './contact-management.component.html',
  styleUrls: ['./contact-management.component.scss']
})
export class ContactManagementComponent {
  loaded = false;
  managers = [];

  constructor(public api: APIService) {
    this.api.getContactManager(this.api.clubId).then((rep) => {
      console.log(rep);
      this.loaded = true;
      this.managers = rep.managers;
    });
  }


  selectManager() {
    const subject = new Subject();
    console.log(this.managers.map(x => x.id));
    subject.subscribe((data) => {console.log(data); this.api.showLoginWindow2 = null; });
    this.api.showLoginWindow2 = {type: 'managerSelect', data: this.managers.map(x => x.id), subject};
  }

  postVisible() {
    this.api.patchManagersConfiguration(this.api.clubId, {module: {name: 'contacts', published: this.api.contactsActivated}}).then(() => {
    }).catch(
      () => {this.api.contactsActivated = !this.api.contactsActivated; }
    );
  }
}
