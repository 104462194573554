import {Component, OnInit, ViewChild} from '@angular/core';
import {APIService} from '../api';
import {FormBuilder, FormGroup} from '@angular/forms';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent {
  uploadForm: FormGroup;
  hasFile = false;
  currentDate = this.getCurrentDate();

  constructor(private http: HttpClient, private formBuilder: FormBuilder, public api: APIService) {

  }

  ngOnInit() {
    console.log(this.getCurrentDate());
    this.uploadForm = this.formBuilder.group({
      profile: ['']
    });
  }

  onFileSelect(event) {
    this.hasFile = true;
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadForm.get('profile').setValue(file);
    }
  }

  onSubmit() {
    const formData = new FormData();
    formData.append('file', this.uploadForm.get('profile').value);

    this.http.post<any>(this.api.URL + 'upload-multiple-images', formData).subscribe(
      (res) => console.log(res),
      (err) => console.log(err)
    );
  }

  getCurrentDate(): string {
    // Get the current date in 'YYYY-MM-DD' format
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // January is 0
    const day = currentDate.getDate();
    const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
    return formattedDate;
  }
}
