<div style="overflow-y: scroll; padding: 20px; height: 100%">


<div class="row">
    <div class="col-lg-6">
      <label>
        Date
      </label>
      <input [disabled]="selectedEvent" style="font-size:25px;width: 100%" type="date" [(ngModel)]="date" (change)="getEvents()"/>
    </div>
  </div>
  <div style="text-align: center; margin: 10px" *ngIf="dateValid() && events.length==0">Aucun événement trouvé pour cette date</div>
  <div *ngIf="events.length>0">       <label>
    Evénement</label></div>
  <div style="display: flex; flex-wrap: wrap">
    <ng-container *ngIf="!selectedEvent">
      <span (click)="this.selectedEvent = event" style="cursor: pointer; margin: 0px 10px 10px;padding: 10px" *ngFor="let event of events" class="event_{{event.categoryId}}">
        {{event.name}}
      </span>
    </ng-container>
  </div>
  <div *ngIf="selectedEvent" class="row">
  <span style="cursor: pointer; margin: 0px 10px 10px;padding: 10px" class="event_{{selectedEvent.categoryId}}">
    {{selectedEvent.name}}
    <img (click)="selectedEvent=null" style="margin-left: 10px; max-width:10px" [src]="'assets/cross.png'">
  </span>

    <div class="col-lg-12">
      <label>
        Nom Complet
      </label>
      <input style="font-size:25px;width: 100%" type="text"/>
    </div>
  </div>
</div>
<div style="position: absolute;bottom:-50px;right: 0px">
  <a class="edit btn-floating waves-effect waves-light" disabled style="cursor:pointer;"><i class="material-icons">save</i></a>
</div>
