import {Component, OnInit, ViewChild} from '@angular/core';
import {NgxImageGalleryComponent} from 'ngx-image-gallery';
import {APIService} from '../api';
import { Lightbox } from "ngx-lightbox";

@Component({
  selector: 'app-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.scss']
})
export class PhotosComponent implements OnInit {

  private albums = [];
  showFlag = false;
  selectedImageIndex = -1;


  selected = false;

  @ViewChild(NgxImageGalleryComponent) ngxImageGallery: NgxImageGalleryComponent;

  // gallery configuration
  conf: GALLERY_CONF = {
    imageOffset: '0px',
    showDeleteControl: false,
    showImageTitle: false,
  };

  imageObject: Array<object> = [{
    image: "assets/IMG_1745-min.jpg",
  }, {
    image: "assets/IMG_1737-min.jpg",
  }, {
    image: "assets/IMG_1546-min.jpg",
  },
    {
      image: "assets/IMG_1369-min.jpg",
    },
    {
      image: "assets/IMG_1340-min.jpg",
    },
    {
      image: "assets/IMG_1293-min.jpg",
    },
    {
      image: "assets/IMG_1237-min.jpg",
    },
    {
      image: "assets/IMG_1141-min.jpg",
    }
  ];

  // gallery images
  images: GALLERY_IMAGE[] = [
    {
      url: "assets/IMG_1745-min.jpg",
    },
    {
      url: "assets/IMG_1737-min.jpg",
    },
    {
      url: "assets/IMG_1546-min.jpg",
    },
    {
      url: "assets/IMG_1369-min.jpg",
    },
    {
      url: "assets/IMG_1340-min.jpg",
    },
    {
      url: "assets/IMG_1293-min.jpg",
    },
    {
      url: "assets/IMG_1237-min.jpg",
    },
    {
      url: "assets/IMG_1141-min.jpg",
    }
  ];


  mouseover = null;
  constructor(private api: APIService, private lightbox: Lightbox) {
    for (let i = 1; i <= 4; i++) {
      const src = 'assets/IMG_1745-min.jpg';
      const caption = 'Image ' + i + ' caption here';
      const thumb = 'assets/IMG_1745-min.jpg';
      const album = {
        src: src,
        caption: caption,
        thumb: thumb
      };

      this.albums.push(album);
    }
  }

  showLightbox(index) {
    this.selectedImageIndex = index;
    this.showFlag = true;
  }

  closeEventHandler() {
    this.showFlag = false;
    this.selectedImageIndex = -1;
  }

  open(index: number): void {
    // open lightbox
    this.lightbox.open(this.albums, index);
  }

  ngOnInit() {}

  diaporama() {
    this.api.showLoginWindow2 = {'type': 'diaporama'};
  }

  // METHODS
  // open gallery
  openGallery(index: number = 0) {
    this.ngxImageGallery.open(index);
  }

  // close gallery
  closeGallery() {
    this.ngxImageGallery.close();
  }

  // set new active(visible) image in gallery
  newImage(index: number = 0) {
    this.ngxImageGallery.setActiveImage(index);
  }

  // next image in gallery
  nextImage(index: number = 0) {
    this.ngxImageGallery.next();
  }

  // prev image in gallery
  prevImage(index: number = 0) {
    this.ngxImageGallery.prev();
  }

  /**************************************************/

  // EVENTS
  // callback on gallery opened
  galleryOpened(index) {
  }

  // callback on gallery closed
  galleryClosed() {
  }

  // callback on gallery image clicked
  galleryImageClicked(index) {
  }

  // callback on gallery image changed
  galleryImageChanged(index) {
  }

  // callback on user clicked delete button
  deleteImage(index) {
  }

}

// gallery configuration
export interface GALLERY_CONF {
  imageBorderRadius?: string; // css border radius of image (default 3px)
  imageOffset?: string; // add gap between image and it's container (default 20px)
  imagePointer? :boolean; // show a pointer on image, should be true when handling onImageClick event (default false)
  showDeleteControl?: boolean; // show image delete icon (default false)
  showCloseControl?: boolean; // show gallery close icon (default true)
  showExtUrlControl?: boolean; // show image external url icon (default true)
  showImageTitle?: boolean; // show image title text (default true)
  showThumbnails?: boolean; // show thumbnails (default true)
  closeOnEsc?: boolean; // close gallery on `Esc` button press (default true)
  reactToKeyboard?: boolean; // change image on keyboard arrow press (default true)
  reactToMouseWheel?: boolean; // change image on mouse wheel scroll (default true)
  reactToRightClick?: boolean; // disable right click on gallery (default false)
  thumbnailSize?: number; // thumbnail size (default 30)
  backdropColor?: string; // gallery backdrop (background) color (default rgba(13,13,14,0.85))
  inline?: boolean; // make gallery inline (default false)
  showArrows?: boolean; // show prev / next arrows (default true)
}

// gallery image
export interface GALLERY_IMAGE {
  url: string; // url of the image
  thumbnailUrl?: string; // thumbnail url (recommended), if not present, gallery will use `url` property to get thumbnail image.
  altText?: string; // alt text for image
  title?: string; // title of the image
  extUrl?: string; // external url of image
  extUrlTarget?: string; // external url target e.g. '_blank', '_self' etc.
}

