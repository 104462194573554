import {
  AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {Router} from '@angular/router';
import {APIService} from '../../api';
import {ImageCroppedEvent, LoadedImage} from 'ngx-image-cropper';

@Component({
  selector: 'app-club100-management',
  templateUrl: './club100-management.component.html',
  styleUrls: ['./club100-management.component.css']
})
export class Club100ManagementComponent {

  loaded = false;
  validatedPersons = null;
  unvalidatedPersons = null;

  constructor(public api: APIService, private router: Router) {

  }

  ngOnInit(){
    this.reload();
  }


  reload() {
    this.loaded = false;
    this.api.getClub100().then((persons) => {
      this.validatedPersons = persons.filter(x => x.validation);
      this.unvalidatedPersons = persons.filter(x => !x.validation);
      this.loaded = true;
    });
  }

  postVisible() {
    this.api.patchClub100Configuration(this.api.clubId, {module: {name: 'club100', published: this.api.club100Activated}}).then(() => {
    }).catch(
      () => {this.api.club100Activated = !this.api.club100Activated; }
    );
  }

}
