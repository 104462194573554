import { Component, OnInit } from '@angular/core';
import { APIService } from '../api';
import { Router } from "@angular/router";

@Component({
  selector: 'app-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.scss'],
})

export class DataComponent implements OnInit {


  constructor(public api: APIService, private router: Router) {
    this.api.getJuniors().then(res => {
      let junior = res[43];
      console.log(!!junior.phone)
      this.data = [
        {
          section : 'Général',
          fields: [
            {field: 'Nom', answer: junior.lastName, type: 'text'},
            {field: 'Prénom', answer: junior.firstName, type: 'text'},
            {field: 'Sexe', answer: junior.gender ? 'Masculin' : 'Féminin', type: {name: 'select', choices: ['Masculin', 'Féminin']} },
            {field: 'Année de naissance', answer: new Date(junior.birthday).toLocaleDateString('en-GB'), type: 'date'},
            {field: 'Possède la nationalité suisse', answer: 'Oui', type: 'avs'},
            {field: 'N° Swissgolf', answer: junior.asgNumber, type: 'swissgolf'},
            {field: 'N° AVS', answer: junior.AVSNumber, type: 'avs'},]
        },
        {
          section : 'Contacts',
          fields: [
            {
              section: 'Junior', info: junior.firstName + ' ' + junior.lastName,
              fields: [
                {
                  field: 'Domicile',
                  answer: junior.address,
                  type: 'text'
                },
                {
                  field: 'Téléphone mobile du junior (facultatif)',
                  answer: junior.phone, type: 'phone'
                },
                {
                  field: 'Inclus dans le groupe Whatsapp',
                  answer: junior.phone_whatsapp ? 'Oui' : 'Non',
                  type: 'phone',
                  editable: !!junior.phone
                },
                {
                  field: 'Email (facultatif)',
                  answer: 'didcr03@gmail.com',
                  type: 'date'},
              ]
            },
            {
              section: 'Personne de contact',
              subinfo: 'l',
              fields: [
                {field: 'Relation au junior', answer: 'Mère', type: {name: 'select', choices: ['Mère', 'Père', 'Autre']}},
                {field: 'Nom', answer: 'Crausaz', type: 'text'},
                {field: 'Prénom', answer: 'Jean', type: 'text'},
                {field: 'Téléphone Mobile', answer: '079 250 47 61', type: 'phone'},
                {field: 'Inclus dans le groupe Whatsapp', answer: 'Oui', type: 'phone'},
                {field: 'Email', answer: 'didcr03@gmail.com', type: 'date'},
              ]
            },
            {
              section: 'Autre personne de contact',
              fields: [
                {field: 'Relation au junior', answer: 'Mère', type: 'text'},
                {field: 'Nom', answer: 'Crausaz', type: 'text'},
                {field: 'Prénom', answer: 'Françoise', type: 'text'},
                {field: 'Téléphone Mobile (facultatif)', answer: '079 792 58 44', type: 'phone'},
                {field: 'Inclus dans le groupe Whatsapp', answer: 'Oui', type: 'phone'},
                {field: 'Email (facultatif)', answer: 'didcr03@gmail.com', type: 'date'},
              ]
            },
          ]
        },
        {
          section : 'Facturation',
          fields: [
            {
              section: 'Par mail',
              fields: [
                {
                  field: 'Email',
                  answer: 'Didier Crausaz - didcr03@gmail.com',
                  type: {name: 'select', choices: ['Didier Crausaz - didcr03@gmail.com', 'Jean Crausaz - j.crausaz@gmail.com']}},
              ]
            },
            {
              section: 'Par courier',
              fields: [
                {field: 'Prénom et nom', answer: 'Didier Crausaz', type: 'date'},
                {field: 'Domicile', answer: 'Rue du Simplon 7bis, 1530 Payerne', type: 'text'},
              ]
            },
          ]
        },
        {
          section: 'Motivations',
        },
        {
          section: 'Motivations 2',
        },
        {
          section: 'Motivations 3',
        }
      ];

      this.selectedSection = this.data[0];
      this.firstname = this.data[0].fields.find(x => x.field === 'Prénom').answer;
      this.lastname = this.data[0].fields.find(x => x.field === 'Nom').answer;
      console.log(this.data);

    });
  }
  mode = 'Infos générales';
  loaded = true;
  summaryMode: any = null;
  validated = false;


  data = null;

  selectedSection = null;
  firstname = null;
  lastname = null;
  mail = '';
  validations = [];
  options = [
    {
      text: 'Polos',
      textP: 'polos',
      textS: 'polo',
      selected: null,
      sizes: [
        {text: '110-120', selected: false, value: 0},
        {text: '130-140', selected: false, value: 0},
        {text: '150-160', selected: false, value: 0},
        {text: 'XS', selected: false, value: 0},
        {text: 'S', selected: false, value: 0},
        {text: 'M', selected: false, value: 0},
        {text: 'L', selected: false, value: 0},
        {text: 'XL', selected: false, value: 0},
        {text: 'XXL', selected: false, value: 0},
        {text: 'XS Lady', selected: false, value: 0},
        {text: 'S Lady', selected: false, value: 0},
        {text: 'M Lady', selected: false, value: 0},
        {text: 'L Lady', selected: false, value: 0}
      ]
    },
    {
      text: 'Sweats',
      textP: 'sweats',
      textS: 'sweat',
      selected: null,
      sizes: [
        {text: '10A', selected: false, value: 0},
        {text: '12A', selected: false, value: 0},
        {text: 'S', selected: false, value: 0},
        {text: 'M', selected: false, value: 0},
        {text: 'L', selected: false, value: 0},
        {text: 'XL', selected: false, value: 0},
        {text: 'XXL', selected: false, value: 0},
      ]
    },
    {
      text: 'K-ways',
      textP: 'k-ways',
      textS: 'k-way',
      selected: null,
      sizes: [
        {text: 'XXS', selected: false, value: 0},
        {text: 'XS', selected: false, value: 0},
        {text: 'S', selected: false, value: 0},
        {text: 'M', selected: false, value: 0},
        {text: 'L', selected: false, value: 0},
        {text: 'XL', selected: false, value: 0},
        {text: 'XXL', selected: false, value: 0}
      ]
    }
  ];

  static validateEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return email && re.test(String(email).toLowerCase());
  }

  continue() {

    this.validations = [];
    if (!DataComponent.validateEmail(this.mail)) {
      this.validations.push('L\'email n\'est pas valide.');
    }
    if (!this.lastname || this.lastname === '') {
      this.validations.push('Le "Prénom du junior" ne doit pas être vide.');
    }
    for (const option of this.options) {
      if (option.selected === null) {
        this.validations.push('Une option dans la section "' + option.text + '" doit être cochée.');
      }
      if (option.selected === true) {
        if (option.sizes.filter(x => x.selected).length < 1) {
          this.validations.push('Au minimum, une taille doit être selectionnée dans la section "' + option.text + '".');
        }
      }
      for (const size of option.sizes) {
        if (!Number.isInteger(size.value) ) {
          this.validations.push('La quantité pour "' + option.textP + ' - ' + size.text + '" n\'est pas valide.' );
        } else if (size.selected && (size.value < 1 || size.value > 9 )) {
          this.validations.push('La quantité pour "' + option.textP + ' - ' + size.text + '" doit être comprise entre 1 et 9.');
        }
      }
    }
    if (this.validations.length === 0) {
      this.summaryMode = this.createSummaryMode();
      this.validations = [];
    }
    console.log(this.options);

  }

  back() {
    this.summaryMode = null;
  }

  confirm() {
    this.loaded = false;
    this.api.postEquipementOrder({firstname: this.firstname, lastname: this.lastname, mail: this.mail, data: this.summaryMode}).then((rep: any) => {
      this.loaded = true;
      this.validated = true;
    }).catch(() => {
      this.validations = ['Votre commande n\' a pas pu être validée. Veuillez réessayer.'];
      this.loaded = true;
    });
  }

  ngOnInit() {
    //this.api.getProgramParameters(1).then((res) => {

    //});
  }

  createSummaryMode() {
    let answers = [];
    for (const option of this.options.filter(x=>x.selected)) {
      let selection = option.sizes.filter((x) => x.selected && x.value > 0);
      if (selection.length > 0) {
        answers.push({type: option.text, textP: option.textP, textS: option.textS, selection});
      }
    }
    return answers;
  }

  select(size) {
    if (size.selected && size.value === 0) {
      size.value = 1;
    } else if(!size.selected && size.value > 0) {
      size.value = 0;
    }
  }

  finished() {
    this.router.navigate(['/']);
  }

  changeSection(section) {
      this.selectedSection = section;
  }
}
