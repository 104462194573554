<div *ngIf="error || !loaded" style="width: 100%;height: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center">
  <div style="flex: 1 1 40%">
  </div>
  <div *ngIf="error" style="white-space:pre-wrap; margin-right:20px; margin-left:20px; padding:20px; box-shadow: 0px 0px 16px 1px rgba(0,0,0,0.25);font-size: 25px; color: gray">{{error.error.message}}
  </div>
  <div *ngIf="!loaded" class="text-center">
    <i class="fas fa-circle-notch fa-spin fa-7x"></i>
  </div>
  <div style="flex: 1 1 60%">
  </div>
</div>

<ng-container *ngIf="loaded && !error">
  <div class="noprint" *ngIf="editable">
    <!--<div style="display: flex;width: 100%;align-items: center"><div style="font-weight:bold;text-align:right;padding-right:20px;width: 200px">Nom du cours</div><app-timed-input *ngIf="l" [(text)]="l.name"></app-timed-input></div>-->
    <div style="display: flex;width: 100%;align-items: center; padding-bottom: 10px; ">
      <div style="display: flex; align-items: center; min-width:150px ">
      <div *ngIf="selectedYear === api.parameters.programYear" style="font-weight:bold;text-align:right;padding-left:20px;padding-right:20px;">Publié</div>
      <div *ngIf="selectedYear === api.parameters.programYear" style="text-align: center;">
        <label class="switch">
          <input type="checkbox" [(ngModel)]="checkbox" (ngModelChange)="updateParameters({spread:checkbox})">
          <span class="slider round"></span>
        </label>
      </div>
      </div>
      <div class="select small" style="width: 100%; display: flex; justify-content: center">
        <div style="position: relative; display: inline-block">
        <select #select
                class="custom-select" [(ngModel)]="selectedYear" (ngModelChange)="changeYear()" style="width: 110px;text-align: center" name="year" id="orderOfMerite">
          <!--todo: optimiser-->
          <option value="2025">2025</option>
          <option value="2024">2024</option>
          <option value="2023">2023</option>
          <option value="2022">2022</option>
          <option value="2021">2021</option>
          <option value="2020">2020</option>
        </select>
        <div class="select_arrow" (click)="a($event)" style="top:15px;z-index: -1;">
        </div>
        </div>
      </div>
      <div style="min-width: 150px;text-align: right;float: right">
        <div style="float:right; text-align: right">
          <a (click)="addEvent()" class="edit btn-floating waves-effect waves-light" style=" margin-right:20px; background-color: #F44336 !important">
            <i class="material-icons">add</i></a>
        </div>
      </div>

    </div>
  </div>
<div class="program" [ngStyle]="{'visibility':programLoaded?'visible':'hidden'}"  style="position:relative;">
  <div style="position: absolute;right: 12px; bottom:-12px; font-size: 8px">Programme généré le {{api.parameters.programUpdateDate | date:'d MMMM yyyy'}}</div>
  <div>
    <div class="filterBtn" style="padding-left: 10px; padding-right: 10px; justify-content: space-between; align-items: end">
     <div style="text-align: center"><button (click)="edit()" style="background: transparent; border: 1px solid black; border-radius: 5px">Filtrer</button></div>
      <div *ngIf="selectedYear===api.parameters.programYear" (click)="doneSelected = !doneSelected" style="cursor:pointer; color:dimgray; text-decoration:underline;align-self: flex-end; font-size: 10px;">{{doneSelected?'Cacher':'Afficher'}} les événements passés</div>
    </div>
    <div style="display: flex" *ngFor="let category of categories">
      <div class="filters">{{category.name}}</div>
      <div style="flex: 1">
      <div [ngStyle]="{'background-color':c.activated ? c.color: null}" [ngClass]="{'not_selected':!c.activated ? c.color: null}" class="filter noselect" *ngFor="let c of category.categories" (click)="toggle2(c)">
        <img style="max-width:10px" [src]="c.activated?'assets/check-mark.png':'assets/cross.png'" alt="">
        <label>{{c.name}}</label>
      </div>
      </div>
    </div>
  </div>
<div style="position: relative; margin-left: 10px; margin-right: 10px;">
  <div class="arrows noprint" *ngIf="cols<12 && scroll>0" (click)="scrollLeft()" style="cursor:pointer;position: absolute;top:0;width:73px;height:100%;background-color:white;z-index:9;margin:0">
    <div style="height:100%; position:relative;">
      <img style="position: absolute" [ngStyle]="{'top.px': offset}" src="assets/arrow-left-45.svg"  />
    </div>
  </div>
  <div class="arrows noprint" *ngIf="cols<12 && scroll===0" (click)="scrollRight()" style="cursor:pointer;position: absolute;right:0;width:73px;height:100%;background-color:white;z-index:10;margin:0; ">
    <div style="height:100%; position:relative;">
      <img style="position: absolute; overflow:hidden" [ngStyle]="{'top.px': offset}" src="assets/arrow-right-49.svg"  />
    </div>
  </div>
  <div #program *ngIf="loaded" (ngInit)="load()" (scroll)="onScroll($event);" style="overflow: hidden; width:100%;" [ngStyle]="{'scroll-behavior':programLoaded?'smooth':null}">
      <div  class="year" style="display:flex;font-size: 6.5pt; margin:0;" [ngStyle]="{'width.px': yearWidth}">
        <div class="month" [ngClass]="{'noprint': month<=2, 'done':date[month-1].done && !doneSelected && selectedYear === api.parameters.programYear }" style=" padding: 1px" *ngFor="let month of array(nbMonth)"  [ngStyle]="{'width.px': monthWidth}">
          <div class="cell month-header">
            <div class="month-cell" style="display:table-cell; text-align: center; vertical-align: middle;">
              {{month_LIST[month-1]}}
            </div>
          </div>
          <div (mouseover)="mouseover = monthNum; mouseover2 = month-1" (mouseleave)="mouseover=null" class="cell height{{date[month-1][monthNum] ? date[month-1][monthNum].size : 2}} heightp{{date[month-1][monthNum] ? (editMode ? 2 : date[month-1][monthNum].size) : 2}}" *ngFor="let monthNum of array(31)" [ngClass]="{'last':date[month-1].last==monthNum, 'noevent':!date[month-1][monthNum] || date[month-1][monthNum].events.length===0,'empty':monthNum>date[month-1].days, 'done': !doneSelected && date[month-1][monthNum] && selectedYear === api.parameters.programYear && isDone(month-1,monthNum)}">
            <div *ngIf="date[month-1].days>=monthNum" style="display: table-row">
              <div style="background-color:#eeeeee; position: relative; display:table-cell; text-align: center; vertical-align: middle; height: 100%; border-right: 1px solid gray;">
                <div class="month-num" style="line-height:0; width: 15px">{{monthNum}}</div>
                <div class="noprint" *ngIf="editable && mouseover === monthNum && mouseover2 === month-1  " style="align-items: center; left: 4px; position: absolute;top: 0px;height: 100%; width: 100%;display: flex; ">
                  <a (click)="addEvent(null,selectedYear+'-'+addZero(month)+'-'+addZero(monthNum))" style="overflow: inherit;line-height:inherit; width:24px;  height: 24px; background-color: #F44336 !important" class="edit btn-floating waves-effect waves-light">
                    <i style="line-height: 24px;font-size:16px;" class="material-icons">add</i></a>
                </div>
              </div>
              <div style="display:table-cell; text-align: center; vertical-align: middle; height: 100%; width: 20px; border-right: 1px solid gray;">
                <div class="day-name" style="line-height:0;width: 15px">
                  {{day_LIST[getDay(month-1, monthNum)]}}
                </div>
              </div>
              <div style="display:table-cell; position:relative; text-align: center; vertical-align: middle; height: 100%; width: 100%;" >
                <div style="display:flex; flex-direction:column; position:relative; text-align: center; vertical-align: middle;z-index: 0;justify-content:center;height:100%; width:100%;">
                  <div (mouseenter)="eventMouseover = event;" (mouseleave)="eventMouseover = null" (click)="update(event)" *ngFor="let event of (date[month-1][monthNum] && date[month-1][monthNum].events && date[month-1][monthNum].events.length>0 ? date[month-1][monthNum].events : []); let i=index" style=" display: flex;
height: 100%;
width: 100%;
justify-content: center;
position: relative;
align-items: center;" [ngClass]="{'editMode':editMode,'selected':event.state=='selected','cancelled':event.cancelled}" [ngStyle]="{'cursor':editable?'pointer':null}" class="event event_{{date[month-1][monthNum].events[i].categoryId}}">
                    {{event.name}}
                    <div class="noprint" *ngIf="eventMouseover === event && editable && mouseover === monthNum && mouseover2 === month-1  " style="align-items: center;justify-content: center;position: absolute;top: 0px;height: 100%; width: 100%;display: flex; ">
                      <a (click)="addEvent(null,selectedYear+'-'+addZero(month)+'-'+addZero(monthNum))" style="overflow: initial;line-height:inherit; width:14px;  height: 14px; background-color: #F44336 !important" class="edit btn-floating waves-effect waves-light">
                        <i style="line-height: 14px;font-size: 10px;position: absolute;top: 0;left: 0;" class="material-icons">mode_edit</i></a>
                    </div>
                    <div [@pastille]="event.state" class="pastille event_{{date[month-1][monthNum].events[i].categoryId}}"></div>
                  </div>
                </div>
                <div style="position:absolute" [ngClass]="{'weekend':isWeekEnd(month-1, monthNum)}"></div>

              </div>
            </div>
            <div *ngIf="date[month-1].days<monthNum" style="display: table-row; height:100%; background-color:grey">
            </div>
          </div>
        </div>
      </div>
  </div>
</div>
</div>
</ng-container>
