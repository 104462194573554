import {ChangeDetectorRef, Component, Input, ViewChild} from '@angular/core';
import {APIService} from '../api';
import {ActivatedRoute, Router} from '@angular/router';
import {animate, animation, state, style, transition, trigger, useAnimation} from '@angular/animations';
import {Medal} from './medal.enum';

export let slideAnimation = animation([
  animate('{{ time }}')
], { params: {time: '500ms' }});

@Component({
  selector: 'app-medals',
  templateUrl: './medals.component.html',
  styleUrls: ['./medals.component.css'],
  animations: [
    trigger('slideIn', [
        state('transit', style({height: '0px'})),
        state('inactive', style({height: '0px'})),
        state('transit2', style({height: '*'})),
        state('active', style({height: '*'})),
        transition('active <=> inactive', [
          useAnimation(slideAnimation )]),

        transition('inactive <=> transit', [
          useAnimation(slideAnimation )]),

        transition('transit <=> active', [
          useAnimation(slideAnimation )]),
      ]
    )
  ]
})
export class MedalsComponent {
  @ViewChild('c') private c: any;
  @ViewChild('d') private d: any;

  @Input() isEditable = true;

  medalEnum = Medal;
  junior_mouseover;

  loaded = false;
  mode = 'table';
  domains = [
    {id: 'putting', name: 'Putting', color: '#27904d'},
    {id: 'chipping', name: 'Chipping', color: '#4a9128'},
    {id: 'bunker', name: 'Bunker', color: '#72892e'},
    {id: 'pitching', name: 'Pitching', color: '#90a523'},
    {id: 'driving', name: 'Long jeu', color: '#8f9727'},
    {id: 'rules', name: 'Règles', color: '#c81d18'}];

  medals = [null, Medal.BRONZE,  Medal.SILVER, Medal.GOLD];
  medals2 = {B: Medal.BRONZE, S: Medal.SILVER, G: Medal.GOLD};

  data;

  selected;

  mouseover;
  juniorMouseover;
  editing = false;

  width;
  left;

  constructor(private api: APIService, private cdRef: ChangeDetectorRef) {

    this.api.getMedals().then(res => {
      this.data = res.medals;
      for (const junior of res.medals) {
        if (junior.medals === undefined) {
          junior.medals = {};
        }
      }
      console.log(res);
      this.loaded = true;
    });
  }

  edit(junior, domain) {
    this.api.openPopup({type: 'edit_medal', data: {junior, domain}});
    this.editing = true;
  }

  selectEntry(entry) {
    if (this.selected !== entry) {
      this.selected = entry;
    } else {
      this.selected = null;
    }
  }

  ngAfterViewInit() {
    console.log('init');
    this.computeWidth(this.c.nativeElement, this.d.nativeElement);
    this.cdRef.detectChanges();
  }


  computeWidth(parent, element) {
    //if(animate) {
    //  this.animate = true;
    //}
    console.log(element.getBoundingClientRect().x);
    this.width = element.offsetWidth;
    this.left = element.getBoundingClientRect().x - parent.getBoundingClientRect().x;
  }
}
