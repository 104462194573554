import { Component, OnInit } from '@angular/core';
import {APIService} from '../api';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ transform: 'translateX(100%)' }),
            animate('0.5s ease-out',
              style({ transform: 'translateX(0%)' }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ transform: 'translateX(0%)'}),
            animate('0.5s ease-in',
              style({transform: 'translateX(100%)'}))
          ]
        )
      ]
    ),
    trigger(
      'outAnimation',
      [
        transition(
          ':leave',
          [
            style({ transform: 'translateX(0%)'}),
            animate('0.5s ease-in',
              style({transform: 'translateX(100%)'}))
          ]
        )
      ]
    )
  ]
})
export class ConfigurationComponent implements OnInit {

  text;
  text2;

  checkbox;
  checkbox2;

  year;

  previousyear;

  sync;
  sync2;

  requests = {};
  timeout;
  timeout2;

  timeout3;
  timeout4;
  date;
  constructor(public api: APIService) {  }

  ngOnInit() {
    this.api.getProgramParameters(1).then((res) => {
      this.year = res.year;
      this.previousyear = res.year;
      this.checkbox = res.spread;
      this.text = res.programUnavailabilityText;
    });

    this.api.getGroupParameters(1).then((res) => {
      this.checkbox2 = res.groupAvailability;
      this.text2 = res.groupUnavailabilityText;
    });
  }

  test() {
    console.log(this.date);
  }

  update() {
    this.sync = 'current';
    clearTimeout(this.timeout);
  }

  update2() {
    this.sync2 = 'current';
    clearTimeout(this.timeout2);
  }

  updateParameters(param) {
    console.log(parseInt(param.year, 10));
    console.log( this.previousyear);

    if (parseInt(param.year, 10) > this.previousyear) {
      this.checkbox = false;
      param.spread = false;
    }
    if (param.year) {
      this.previousyear = parseInt(param.year, 10);
    }

    clearTimeout(this.timeout);
    this.timeout2 = setTimeout(() => {this.sync = 'current'; }, 2000);

    this.requests = {...this.requests, ...param};
    this.api.patchParameters(1, this.requests).then(() => {
      this.sync = true;
      this.timeout = setTimeout(() => {this.sync = null; }, 2000);
    }).catch(() => {
      this.sync = false;
    }).finally(() => {
      clearTimeout(this.timeout2);
    });
  }

  updateParameters2(param) {
    console.log(parseInt(param.year, 10));
    console.log( this.previousyear);

    if (parseInt(param.year, 10) > this.previousyear) {
      this.checkbox = false;
      param.spread = false;
    }
    if (param.year) {
      this.previousyear = parseInt(param.year, 10);
    }

    clearTimeout(this.timeout3);
    this.timeout4 = setTimeout(() => {this.sync2 = 'current'; }, 2000);

    this.requests = {...this.requests, ...param};
    this.api.patchParameters2(1, this.requests).then(() => {
      this.sync2 = true;
      this.timeout3 = setTimeout(() => {this.sync2 = null; }, 2000);
    }).catch(() => {
      this.sync2 = false;
    }).finally(() => {
      clearTimeout(this.timeout4);
    });
  }

}
