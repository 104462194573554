import {
  AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, Output,
  ViewChild
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {APIService} from '../api';
import {Subject} from 'rxjs';


@Component({
  selector: 'app-show-group',
  templateUrl: './show-group.component.html',
  styleUrls: ['./show-group.component.scss']
})
export class ShowGroupComponent {
  @Input() isEditable = false;
  @Input() data;
  @Output() close = new EventEmitter();
  @ViewChild('scrollMe') private myScrollContainer: any;
  @ViewChild('d') private d: any;
  groups;
  num;
  loaded;
  lessons;
  l;
  allLessons = [];

  selected = '0';

  back = new Subject();

  error;
  allGroups = [];

  groupMouseover = false;


  ngOnInit(){

    this.route.params.subscribe(params => {

      this.init();
    });
  }

  constructor(public api: APIService, private route: ActivatedRoute, private router: Router) {
    this.api.updateGroup.subscribe((res) => {
      this.api.getLessons(this.isEditable).then((x) => {
        this.allLessons = x;
        this.lessons = x.filter(y => !y.archived);
        this.loaded = true;
        if (!this.isEditable) {
          this.change(this.num);
        }
      });
    });
  }

  init() {
    for (const group of this.api.parameters.groups) {
      if (this.router.url === '/groupes/' + group.abbr) {
        this.api.changeTitleEvent.next(group.name);
        this.num = group.id;
      }
    }

    this.error = false;
    this.groups = [];
    this.loaded = false;
    this.api.getLessons(this.isEditable).then((x) => {
      this.allLessons = x;
      this.lessons = x.filter(y => !y.archived);
      this.loaded = true;
        if(!this.isEditable) {
          this.change(this.num);
        }
    });

  }

  getResult() {
    if (this.selected === 'archived') {
      this.lessons = this.allLessons.filter(y => y.archived);
    }
    else{
      this.lessons = this.allLessons.filter(y => !y.archived);
    }
  }

  change(num) {

    this.num = num;
    this.l = this.lessons.find(x => x.id === this.num);

    if(this.isEditable) {
      let back = new Subject();
      back.subscribe(() => {
        this.num = null;
        this.api.changeTitleEvent.next({name: 'Cours'});
      })
      this.api.changeTitleEvent.next({back: back, name: 'Cours'});
    }

    this.groups = this.lessons.find((x) => x.id === this.num).groups;
    console.log(this.groups);
    try {
      this.groups.map((group) => {
        if (group.monitors) {
          group.monitors = group.monitors.split(/,\s*/);
        }
      });
    }
    catch (e) {
      
    }

  }

  postVisible(id) {
    this.api.patchLesson(id, {visible: this.l.visible}).then(() => {
      this.api.parameters.groups = this.lessons.filter(x=>x.visible);
      console.log(this.api.parameters.groups)
    }).catch(
      () => {this.l.visible = !this.l.visible;}
    );
  }

  addLesson() {
      const subject = new Subject();
      subject.subscribe((data: any) => {
        console.log(data);
        this.api.postLesson(data).then(() => {
          this.ngOnInit();
          this.api.closePopup();
        }
      );
      });
      this.api.openPopup({type: 'edit_lessons', subject});
  }

  editGroup(group = null) {
    if(!this.isEditable){
      return;
    }
    const subject = new Subject();
    const subjectDelete = new Subject();
    subjectDelete.subscribe((id: any) => {
      this.api.deleteGroup(id).then(() => {
        this.groups = this.groups.filter(x => x.id !== id)
        this.api.closePopup();
      });
    });
    subject.subscribe((data: any) => {
        if (data.id) {
          this.api.patchGroup(data.id, data).then((d: any) => {
              console.log(d);
              group.name = d.name;
              group.color = d.color;
              group.timeStart = d.timeStart;
              group.timeEnd = d.timeEnd;
              group.monitors = d.monitors.split(/,\s*/);
              group.datesLinkedText = d.datesLinkedText;
            }
          );
        } else {
          data.lessonId = this.num;
          this.api.postGroup(data).then( (d: any) => {
            d.monitors = d.monitors.split(/,\s*/);
            this.groups.push(d);
          });
        }
        this.api.closePopup();
    });
    console.log(this.lessons.find((x) => x.id === this.num).datesLinked);
    console.log( this.lessons.find((x) => x.id === this.num));
    this.api.openPopup({type: 'edit_lesson', data : group, hasDatesLinked : this.lessons.find((x) => x.id === this.num).datesLinked, subject, subjectDelete});
  }

  sort(group) {
    const subject = new Subject();
    this.api.openPopup({type: 'sortStaff', subject, data: group, print: '{{data.name}}'});

  }

  editLesson() {
    const subject = new Subject();
    subject.subscribe((data) => {
      console.log(data);
      this.l = {...data as {}};
    });
    this.api.openPopup({type: 'edit_lessons', subject, data: this.l});
  }
}
