import { Component, OnInit } from '@angular/core';
import {APIService} from '../api';
import {Subject} from "rxjs";

@Component({
  selector: 'app-edit-news',
  templateUrl: './edit-news.component.html',
  styleUrls: ['./edit-news.component.scss']
})
export class EditNewsComponent implements OnInit {
  news = [];
  loaded = false;
  selectedNews;

  constructor(public api: APIService) {

  }

  ngOnInit() {
    this.loaded = false;
    this.api.getNews().then((news) => {this.news = news; this.loaded = true;});
  }

  putText(news) {
    this.api.putNews({text: news.text, title: news.title}, news.id);
  }

  select(e) {
    let back = new Subject();
    back.subscribe(() => {
      this.selectedNews = null;
      this.api.changeTitleEvent.next({name: 'Gestion des news'});
    });
    this.api.changeTitleEvent.next({back: back, name: 'Gestion des news'});

    console.log(e);
    this.selectedNews = e;
  }
}
