import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-timed-input',
  templateUrl: './timed-input.component.html',
  styleUrls: ['./timed-input.component.css'],
})
export class TimedInputComponent {
  @Input() text;
  @Output() textChange = new EventEmitter();
  @Output() updating = new EventEmitter();
  time = 2000;
  timeout;

  ngChanges(changes) {
    console.log(changes);
  }


  valid(text) {
    this.updating.emit();
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      this.textChange.emit(text);
      }, this.time);
  }

}
