import { Component, OnInit } from '@angular/core';
import { APIService } from '../api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-survey2',
  templateUrl: './survey2.component.html',
  styleUrls: ['./survey2.component.scss'],
})

export class Survey2Component implements OnInit {
  constructor(public api: APIService, private router: Router) {

  }
  loaded = true;
  summaryMode: any = null;
  validated = false;
  comment = '';
  mail = '';
  firstname = '';
  lastname = '';
  validations = [];
  options = [
    {
      id: 1,
      visible: true,
      value: null,
      text: 'Le·la junior·e participera-t-il·elle aux cours juniors de 2025 ?',
      answer: {type: 'radio', choices:[{id:1,text:'Oui'}, {id:2,text:'Oui partiellement (absent une partie de l\'année)'}, {id:3, text:'Non'}]}
    },
    {
      id: 2,
      visible: false,
      text: 'Merci de préciser les périodes où le·la junior·e sera absent·e.',
      condition: {id: 1, choiceId: [2]},
      answer: {required: false, type: 'textarea'}
    },
    {
      id: 3,
      visible: true,
      text: 'A partir de quelle heure, après école ou travail, le·la junior·e peut-il·elle se trouver au golf de Payerne le <b><u>lundi</u></b> pour la période <u>fin mars/début juillet</u> ?',
      condition: {id: 1, choiceId: [1, 2]},
      answer: {type: 'time', nullText: 'Pas disponible le lundi', nullSelected: false}
    },
    {
      id: 4,
      visible: true,
      text: 'A partir de quelle heure, après école ou travail, le·la junior·e peut-il·elle se trouver au golf de Payerne le <b><u>mercredi</u></b> pour la période <u>fin mars/début juillet</u> ?',
      condition: {id: 1, choiceId: [1, 2]},
      answer: {type: 'time', nullText: 'Pas disponible le mercredi'}
    },
    {
      id: 5,
      visible: true,
      text: 'Ces horaires seront-ils les mêmes pour la période <u>fin août/début octobre</u> ?',
      condition: {id: 1, choiceId: [1, 2]},
      answer: {type: 'radio', choices:[{id:1,text:'Oui'}, {id:2,text:'Probablement'}, {id:3, text:'Encore incertain'}, {id:4, text:'Non (précisez dans les commentaires)'}]}
    },
    {
      id: 6,
      visible: true,
      value: null,
      text: 'De quelles vacances le·la junior·e dispose-t-il·elle ?',
      answer: {type: 'radio', choices:[{id:1,text:'Vaudoises'}, {id:2,text:'Fribourgoises'}, {id:3, text:'Autres'}]}
    },
    {
      id: 7,
      visible: false,
      text: 'En moyenne, combien de fois par semaine le·la junior·e prévoit-il·elle de jouer au golf en dehors des cours juniors (practice/parcours/tournoi) ?',
      condition: {id: 1, choiceId: [1, 2]},
      answer: {type: 'radio', choices:[{id:1,text:'0 fois'}, {id:2,text:'1 fois'}, {id:3, text:'2 fois'}, {id:4, text:'3 fois'}, {id:5, text:'Plus de 3 fois'}]}
    },
    {
      id: 8,
      visible: true,
      text: 'Le·la junior·e prévoit-il·elle de participer aux compétitions juniors de Payerne ?',
      answer: {type: 'radio', choices:[{id:1,text:'Oui, à toutes dans la mesure du possible'}, {id:2,text:'Uniquement à quelques unes'}, {id:3, text:'Non'}]}
    },
    {
      id: 9,
      visible: true,
      text: 'Le·la junior·e prévoit-il·elle de participer aux compétitions adultes de Payerne ?',
      answer: {type: 'radio', choices:[{id:1,text:'Oui, à toutes dans la mesure du possible'}, {id:2,text:'Uniquement à quelques unes'}, {id:3, text:'Non'}]}
    },
    {
      id: 10,
      visible: true,
      text: 'Le·la junior·e prévoit-il·elle de participer aux compétitions juniors SwissGolf à l\'extérieur U12 / U14 / JTE ?',
      answer: {type: 'radio', choices:[{id:1,text:'Oui, à toutes dans la mesure du possible'}, {id:2,text:'Uniquement à quelques unes'}, {id:3, text:'Non'}]}
    },
    {
      id: 11,
      visible: true,
      text: 'Le·la junior·e prévoit-il·elle de participer aux compétitions du Challenge Romand ?',
      answer: {type: 'radio', choices:[{id:1,text:'Oui, à toutes dans la mesure du possible'}, {id:2,text:'Uniquement à quelques unes'}, {id:3, text:'Non'}]}
    },
    {
      id: 12,
      visible: true,
      text: 'Problèmes de santé / Allergies (à remplir si c\'est une information pouvant être nécessaire à un responsable)',
      answer: {required: false, type: 'textarea', placeholder: 'Facultatif'}
    },
    {
      id: 13,
      visible: true,
      text: 'Autres commentaires',
      answer: {required: false, type: 'textarea', placeholder: 'Facultatif'}
    }
  ];

  static validateEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return email && re.test(String(email).toLowerCase());
  }

  checkConditions() {
    for (const option of this.options) {
      if (option.condition) {
        let opt = this.options.find((x) => x.id === option.condition.id);
        option.visible = option.condition.choiceId.includes(opt.value);
      }
    }
  }

  checkOption(option) {
    if (option.answer.nullSelected) {
      option.oldValue = option.value;
      option.value = null;
    }
    else {
      option.value = option.oldValue;
    }
  }

  continue() {
    this.validations = [];
    if (!Survey2Component.validateEmail(this.mail)) {
      this.validations.push('L\'email n\'est pas valide.');
    }
    if (!this.firstname || this.firstname === '') {
      this.validations.push('Le "Nom de famille" ne doit pas être vide.');
    }
    if (!this.lastname || this.lastname === '') {
      this.validations.push('Le "Prénom" ne doit pas être vide.');
    }
    for (const option of this.options.filter(x => x.visible && x.answer.required !== false && (!x.value && !x.answer.nullSelected))) {
      if (!option.value) {
        this.validations.push('Une option à la question "' + option.text.replace(/(<([^>]+)>)/gi, "") + '" doit être cochée.');
      }
      /*
      for (const size of option.sizes) {
        if (!Number.isInteger(size.value) ) {
          //this.validations.push('La quantité pour "' + option.textP + ' - ' + size.text + '" n\'est pas valide.' );
        } else if (size.selected && (size.value < 1 || size.value > 9 )) {
          //this.validations.push('La quantité pour "' + option.textP + ' - ' + size.text + '" doit être comprise entre 1 et 9.');
        }
      }

       */
    }

    if (this.validations.length === 0) {
      this.confirm();
      this.validations = [];
    }
    else{
      setTimeout(() => {window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' })}, 0);
    }
    console.log(this.options);
  }

  back() {
    this.summaryMode = null;
  }

  confirm() {
    this.summaryMode = this.options;
    this.loaded = false;
    this.api.postSurvey({survey: 'disponibilites2025', firstname: this.firstname, lastname: this.lastname, mail: this.mail, data: this.summaryMode}).then((rep: any) => {
      this.loaded = true;
      this.validated = true;
    }).catch(() => {
      this.validations = ['Votre réponse n\' a pas pu être validée. Veuillez réessayer.'];
      this.loaded = true;
    });
  }

  ngOnInit() {
    //this.api.getProgramParameters(1).then((res) => {

    //});
  }

  createSummaryMode() {

    let answers = [];
    /*
    for (const option of this.options.filter(x=>x.selected)) {
      let selection = option.choices.filter((x) => x.selected && x.value > 0);
      if (selection.length > 0) {
        answers.push({type: option.text});
      }
    }
    */
    return answers;

  }

  select(option, size) {
    console.log(option);
    for (let s of option.sizes) {
      if (s !== size) {
        s.value = 0;
        s.selected = false;
      }
    }
    if (size.selected && size.value === 0) {
      size.value = 1;
    } else if (!size.selected && size.value > 0) {
      size.value = 0;
    }
    console.log(option);
  }

  finished() {
    this.router.navigate(['/']);
  }
}
