<div class="card" style="min-width: 200px">
  <div style="display: flex;padding:10px;align-items: center;justify-content: center;height: 150px;">
    <img *ngIf="image" style="max-width: 100%;max-height: 100%" [src]="image">
    <div>
    <ul *ngIf="text" style="padding:20px;margin:0">
    <li >
      <p style="white-space:pre-wrap;font-weight: bold">Donateurs anonymes</p>
    </li>
      <li >
        <p style="white-space:pre-wrap;font-weight: bold">Participants à la "Support Day"</p>
      </li>
    </ul>
    </div>
  </div>
  <div *ngIf="image && false">
  <div style="padding:10px;background: gray; color:white">
    Banque Raiffeisen de la Broye<br/>
    Grand'Rue 20<br/>
    1530 Payerne
  </div>
  <div style="font-size:10px;padding:10px;background: gray; color:white">
    <a style="color:white" href="https://www.kartingindoor.ch/">https://www.kartingindoor.ch</a>
  </div>
  </div>
</div>
