<div class="image_title" style="margin:auto;">
  <div style="display: flex;padding-bottom:15px;justify-content: center"><img style="max-width: 320px" src="assets/club100.svg"/></div>

  <div style="margin-left: 10px; margin-right: 10px" *ngIf="isSubscribed">
    <h2 style="text-align: center">Merci de votre souscription ! Vous pouvez télécharger le bulletin de versement ici.</h2>
    <h6 style="text-align: center">Une copie de ce bulletin vous a également été envoyé par mail. Merci de vérifier qu'il ne se trouve pas dans vos spams. Si tel est le cas, signalez le message comme non-spam afin que vous receviez correctement de futurs mails de notre part</h6>
    <div style="margin: 40px;">
      <app-document  [small]="true" [title]="'Bulletin de versement - Club des 100 - Année 2024'" [file]="api.URL + 'clubs/1/club100/bill/'+billReference+'.pdf'"></app-document>
    </div>
    <div style="margin-top: 10px">
      <button class="buttonLink" (click)="isSubscribed=false">Retour</button>
    </div>

  </div>

  <div *ngIf="!isSubscribed" class="row" style="margin-left:0; margin-right:0">
    <ng-container *ngFor="let year of membersYears">
      <div *ngIf="year && year.members.length" class="col-lg-12" style="background:#5e5e5e;color: white; padding-top: 35px; padding-bottom: 35px; display: flex; align-items: center">
        <div style="text-align:center; width: 100%; align-items:start; margin: auto">
          <h6 style="font-weight:100;font-size: 25px">Liste des membres {{year.year}}</h6>
          <div  style="max-width:960px; justify-content:center; margin: auto; display: flex; flex-wrap: wrap">
            <ng-container *ngFor="let member of year.members">
              <a *ngIf="member.link" [href]="member.link" class="club100-link">
                <div class="club100-name" style="display: flex;">
                  <div style="width: 100%;">{{member.name}}</div>
                  <div style="display: flex; align-items: center"><img style="width: 15px;" src="assets/external-link.svg"></div>
                </div>
              </a>
              <div *ngIf="!member.link" class="club100-name" style="display: flex;">
                <div style="width: 100%;">{{member.name}}</div>
              </div>
            </ng-container>
                <div *ngIf="year.anonymous" class="club100-name">{{year.anonymous}} anonyme{{year.anonymous>1?'s':''}}
                </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div #col1 class="col-lg-6" style="background:#e9e9e9;color: black; display: flex;align-items: center;flex-direction: column">
      <div [style]="{'padding':col1.clientWidth>700?'70px':'20px'}" style="margin: auto;">
        <h6 style="font-weight:100;font-size: 25px">Qu'est-ce que le club des 100 ?</h6>
        <p class="paragraph">
          L’objectif du Club des 100 est de rassembler tous ceux qui souhaitent partager leur passion
          commune du golf avec notre section et soutenir notre mouvement junior.</p><p class="paragraph">
        La formation de futurs joueurs de tous les âges est une priorité pour l’avenir de notre sport et
        pour développer des compétiteurs de haut niveau qui un jour représenteront notre club au
        niveau national.</p>
        <p class="paragrah">
          L’objectif du Club des 100 est de soutenir ce développement qui demande du temps et des
        moyens financiers. Au travers d’une ambiance conviviale, notre souhait est d’offrir à nos
          juniors plus de possibilités d’entraînements et de pousser le sport d’élite.</p>
        <p class="paragraph">
          L’adhésion est ouverte à tous, golfeurs ou non-golfeurs.</p>
        <p class="paragraph">L&#39;adhésion au Club coûte 100.- (minimum). Tous les membres donateurs seront listés sur
          notre site internet, sauf si vous préférez rester anonyme.</p>
        <div *ngIf="false" style="text-align: center"><button routerLink="/contacts" style="background: transparent; border: 1px solid black; border-radius: 5px">Vers le formulaire</button></div>
        <hr style="color: blue"/>
        <div style="font-weight:300;font-size:15px; width:100%; text-align:justify;font-style:italic; ">
          <p>Le Club des 100 donne droit à l’inscription gratuite à notre traditionnel tournoi de soutien
            « Juniors Support Day » (inscription au tournoi + apéro, hors repas et green fee). Pour les
            non-golfeurs, une initiation avec nos meilleurs juniors sera organisée lors du Juniors Support
            Day et vous serez bien évidement invité à l’apéro du tournoi.</p>
        </div>
      </div>

      </div>

    <div class="col-lg-6" [style]="{'padding':col1.clientWidth>700?'70px':'20px'}">
      <h6 style="font-weight:100;font-size: 25px">Souscription au Club des 100 pour l'année 2025</h6>
      <h4> Dès le 1er avril</h4>
      <!--<ng-container *ngIf="!billReference">
      <div style="border: 1px solid #00543c; padding:10px; border-radius:10px">
      <div><input type="email" id="input" class="input mb-1 email" style="width:100%;border: 1px solid gray"  [(ngModel)]="message.mail" placeholder="Votre email"></div>

      <div><input type="text" class="mb-1 firstname" style="width:calc(50% - 10px);margin-right:10px;border: 1px solid gray;" [(ngModel)]="message.lastname" placeholder="Nom"><input  [(ngModel)]="message.firstname" style="width: 50%" type="text" class="mb-3 firstname" placeholder="Prénom"></div>
        <div><input type="name" id="input" class="input mb-1 society"  [(ngModel)]="message.society"  style="width:100%;border: 1px solid gray" placeholder="Société (facultatif)"></div>

        <hr/>

      <div>
        <div style="margin-bottom: 10px; font-size:20px; color: #808080;">Durée d'engagement</div>

        <label style="cursor: pointer" for="only2002">
          <input [(ngModel)]="message.recontact" (ngModelChange)="resetTimer()" [value]="false" type="radio" id="only2002" name="radio">
          <div>Je m’engage pour 2024 uniquement</div>
        </label>

        <label style="cursor: pointer" for="allyears">
          <input  [(ngModel)]="message.recontact" (ngModelChange)="resetTimer()" [value]="true" type="radio" id="allyears" name="radio">
          <div>Vous pouvez me recontacter directement pour les années à venir</div>
        </label>
      </div>

        <hr/>

        <div>
          <div style="margin-bottom: 10px; font-size:20px; color: #808080;">J'accepte que mon nom - ou si rempli, celui de ma société - soit visible sur le site des juniors.</div>
          <label style="cursor: pointer" for="shown">
            <input [(ngModel)]="message.visibility" (ngModelChange)="resetTimer()" [value]="true" type="radio" id="shown" name="radio2">
            <div>Oui</div>
          </label>

          <label style="cursor: pointer" for="hidden">
            <input  [(ngModel)]="message.visibility" (ngModelChange)="resetTimer()" [value]="false" type="radio" id="hidden" name="radio2">
            <div>Non</div>
          </label>
        </div>
      </div>
      <div style="text-align: center; margin-top:10px">
      <div>{{error}}</div>
      <button class="buttonLink" *ngIf="timer===null" (click)="startTimer()">Je souscris</button>
      <button [disabled]="click" class="buttonLink" *ngIf="timer===0" (click)="click = true; validate()">Valider la souscription</button>
      <p *ngIf="timer">Veuillez attendre pour valider votre souscription : {{timer}} secondes</p>

      </div>
      </ng-container>
      <div style="padding-top: 30px" *ngIf="billReference">
        <h5>Dernière souscription</h5>
        <app-document [small]="true" [title]="'Bulletin de versement - Club des 100 - Année 2024'" [file]="api.URL + 'clubs/1/club100/bill/'+billReference+'.pdf'"></app-document>
        <button style="margin-top: 50px" class="buttonLink" (click)="click=false;billReference=null">Retour au formulaire </button>
      </div>
      -->
  </div>
  </div>
</div>

