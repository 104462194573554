import {AfterViewChecked, AfterViewInit, Component} from '@angular/core';
import * as $ from 'jquery';
import {APIService} from '../api';
import {ActivatedRoute, Router} from '@angular/router';
import {
  animate, animateChild, animation, query, state, style, transition, trigger,
  useAnimation
} from '@angular/animations';
import {Subject} from 'rxjs';


@Component({
  selector: 'app-competitions',
  templateUrl: './competitions.component.html',
  styleUrls: ['./competitions.css'],
})
export class CompetitionsComponent {

  loaded = false;
  competitions;
  over;
  options = false;
  selectedSimpleItem;
  public selectedDate: any;
  course_id;
  competition_name;
  simpleItems;
  animtime = 0;
  year;
  category = 0;
  sorted;

  constructor(public api: APIService, private router: Router, private route: ActivatedRoute) {
    this.year = this.api.parameters.year;
  }

  ngOnInit(){
    console.log(this.router);
    this.route.params.subscribe(params => {
      console.log(this.route);
      this.getResult();
    });
  }

  public getResult(){
    this.loaded = false;
    this.api.getCompetitions(this.year, this.category).then(res => {
      if (res === undefined) {
        return;
      }
      this.competitions = res;
      this.loaded = true;
    });
  }

  public pickADate($event: MouseEvent) {
    /*this.datePicker.selectDate(this.selectedDate, {openFrom: $event}).subscribe( (selectedDate: Date) => {
      this.selectedDate = selectedDate ? moment(selectedDate) : null;
    });*/
  }

  detail(competition) {
    this.router.navigate(['administration/competitions/' + competition.id]);
  }

  editCompetition(e) {
    const subject = new Subject();
    this.api.showLoginWindow2 = {type: 'addCompetition', subject};

    // e.stopPropagation();
  }

  showOptions() {
    this.options = !this.options;
    console.log(this.options);

    this.api.getCourses().then(
      res=> {
        this.simpleItems = res;
        this.selectedSimpleItem = this.simpleItems.find(a=>a.id===1);
      }
  );

  }

  add(e){
    console.log(e);
  }

  postCompetition() {
    this.api.postCompetition(this.selectedSimpleItem.id, this.competition_name, this.selectedDate.toDate().getTime()/1000).then(
      res => {
        this.showOptions();
        this.animtime = 300;
          console.log(res);
          // TODO : find the place of the competition
          this.competitions.splice(this.getPosition(res, this.competitions), 0, res);
      }
    );
  }

  getPosition(competition, competitions) {
    let i = 0;
    for (const c of competitions) {
      if (c.date > competition.date) {
        i++;
      }
      else{
        break;
      }
    }
    return i;
  }


}
