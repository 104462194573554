<form [formGroup] = "uploadForm">
  <div *ngIf="!hasFile" class="upload-btn-wrapper">
    <input type="file" name="profile" (change)="onFileSelect($event)" />
    <button *ngIf="false"  type="submit" class="btn">Upload</button>
</div>
</form>

<button *ngIf="hasFile" (click)="onSubmit()" class="btn">Valid</button>

<div style="margin:auto;">
  <div class="padding-lr">
    <div class="document"><app-document [file]="'docs/seance2025.pdf'" [title]="'Présentation - Séance d\'information 2025'"></app-document></div>
    <div class="document"><app-document [file]="'assets/reglement.pdf'" [title]="'Réglement juniors'"></app-document></div>
    <div class="document"><app-document [file]="'assets/charte.pdf'" [title]="'Charte juniors'"></app-document></div>
  </div>
</div>
