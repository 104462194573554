<div #b style="width: 100%;">
  <div #f (ngInit)="init(f,b);" style="width:1000px; position:absolute; cursor:pointer" (click)="bigger(f,b)" (clickOutside)="clickoutside(f,b)">
    <img src="assets/tableau.svg" />
    <div style="width:100%;top:22%;position: absolute;">
      <div style="display:flex; justify-content:center;">
        <h1 class="title" [style.font-size.px]="f.clientWidth/17">{{data.title}}</h1>
      </div>
      <ng-container *ngIf="data.data">
      <div style="display: flex;" [style.font-size.px]="f.clientWidth/41.5">
          <div style="width: 50%" *ngFor="let i of [0,1]">
            <div class="line" *ngFor="let p of data.data.slice(23*i,23*(i+1))">
              <p class="year">{{p.year}}</p>
              <p class="name">{{p.name}}</p>
            </div>
          </div>
      </div>
      </ng-container>

        <ng-container *ngIf="data.categories">
          <div [style.font-size.px]="f.clientWidth/41.5">

          <div  *ngFor="let category of data.categories" style="margin-bottom:60px">
            <div style="display:flex; justify-content:center;">
              <h1 style="font-style:italic" class="subtitle" [style.font-size.px]="f.clientWidth/20">{{category.name}}</h1>
            </div>
            <div style="display: flex;" [style.font-size.px]="f.clientWidth/41.5">

            <div style="width: 50%" *ngFor="let i of [0,1]">
            <div class="line" *ngFor="let p of category.data.slice(4*i,4*(i+1))">
              <p class="year">{{p.year}}</p>
              <p class="name">{{p.name}}</p>
            </div>
            </div>
          </div>
          </div>
          </div>
        </ng-container>
    </div>
  </div>
</div>
