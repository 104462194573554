<div *ngIf="!loaded" style="width: 100%;height: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center">
  <div style="flex: 1 1 40%">
  </div>
  <div class="text-center">
    <i class="fas fa-circle-notch fa-spin fa-7x"></i>
  </div>
  <div style="flex: 1 1 60%">
  </div>
</div>

<ng-container *ngIf="loaded">
<div style="text-align: right">
  <div style="display: flex;width: 100%;align-items: center"><div style="font-weight:bold;text-align:right;padding-left:20px;padding-right:20px;">Publié</div>
    <div style="text-align: center">
      <label class="switch">
        <input type="checkbox" [(ngModel)]="api.contactsActivated" (ngModelChange)="postVisible()">
        <span class="slider round"></span>
      </label>
    </div>

    <div style="width: 100%;text-align: right;float: right">
      <a class="edit btn-floating waves-effect waves-light" style=" margin-right:20px; margin-bottom:20px; background-color: #F44336 !important">
        <i class="material-icons">swap_vert</i></a>
    </div>

  </div>
</div>

<div style="text-align: right">
  <div style="display: flex;padding:20px; padding-top:5px; padding-bottom: 5px; background: #efefef; width: 100%;align-items: center">
    <h3 style="margin-bottom: 0">Responsables</h3>

    <div style="width: 100%;text-align: right;float: right">
      <a (click)="selectManager()" class="edit btn-floating waves-effect waves-light" style=" margin-right:20px; background-color: #F44336 !important">
        <i class="material-icons">add</i></a>
    </div>

  </div>
</div>

  <div style="padding-left:20px; padding-right: 20px">
    Les formulaires seront transférés aux responsables suivants :
    <div *ngIf="managers.length==0" style="text-align: center"><i>Aucun responsable</i></div>
    <div *ngIf="managers.length>0" class="example-list">
    <div class="example-box" *ngFor="let manager of managers" style="text-align: center">
      <div style="width:100%;text-align: left">
        <div style="display: flex;padding:20px; padding-top:5px; padding-bottom: 5px;width: 100%;align-items: center">
          <div style="width: 100%">{{manager.firstname}} {{manager.lastname}} - {{manager.email}}</div>
          <div style="text-align: right;">
            <a class="edit btn-floating waves-effect waves-light" style=" margin-right:20px; background-color: #F44336 !important">
              <i class="material-icons">close</i></a>
          </div>

        </div>
      </div>
      </div>
    </div>
  </div>
</ng-container>
