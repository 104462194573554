import {AfterViewInit, Component, DoCheck, Input, OnInit, Output} from '@angular/core';
import {isDefined} from '@angular/compiler/src/util';
import {isUndefined} from 'util';
import {APIService} from '../api';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-programm',
  templateUrl: './programm.component.html',
  styleUrls: ['./programm.component.css'],
  animations: [
    trigger('pastille', [
      state('notselected', style({
        width: '*',
      })),
      state('selected',   style({
        width: '100%',
        height: '100%',
        left:0,
        top:0,
        'border-radius':0
      })),
      transition('notselected => selected', animate('100ms ease-in')),
      transition('selected => notselected', animate('100ms ease-out'))
    ])
  ]
})
export class ProgrammComponent implements AfterViewInit {
  @Input() editable = false;

  events = [];
  activatedcategory = [false, false, true, true, false, true, true, true, true, true, true, true, true, false , false, true, true, true, true, true];

  categories = [{name: 'Juniors - Interne',
    categories: [{id: 3, name: 'Compétition 18 trous', countOM: true}]}

  ];

  month_LIST = [ 'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre' , 'Octobre', 'Novembre', 'Décembre' ];
  day_LIST = [ 'D', 'L', 'M', 'M', 'J', 'V', 'S'];


  doneSelected = false;

  nbMonth = 12;
  date;
  loaded = false;
  editMode = false;
  juniorId;
  error;
  today;
  checkbox;
  selectedYear;

  mouseover = null;
  mouseover2 = null;

  ngOnInit(){
    this.api.getProgramParameters(1).then((res) => {
      this.checkbox = res.spread;
    });
    this.selectedYear = this.api.parameters.programYear;

  }

  ngAfterViewInit() {
    if (this.editable) {
      this.api.changeTitleEvent.next('Gestion du programme');
    } else {
      this.api.changeTitleEvent.next('Programme ' + this.api.parameters.programYear);
    }
  }

  constructor(public api: APIService, private router: ActivatedRoute) {
    this.today = this.addDays(new Date(),-1);


    Array.from(document.styleSheets).forEach(ss => {
      try {
        Array.from(ss['rules'] || ss['cssRules']).forEach(rule => {
          if (rule instanceof CSSPageRule) {
            rule.style['size'] = 'A3 landscape';
            console.log('New rule size: ' + rule.style['size']);
          }
        });
      } catch (e) {}
    });

    this.juniorId = localStorage.getItem('userId');
    this.router.queryParams.subscribe(params => {
      if (params.edit === 'true') {
        this.editMode = true;
      }
      if (params.junior_id) {
        this.juniorId = +params.junior_id;
      }
    });

    setTimeout(()=>{
      this.getEvent();
    }, 1000);

  }

  getEvent(selectedYear = null) {
    this.api.getEvent(selectedYear ? selectedYear : this.api.parameters.programYear, this.editable).then(res => {
      if (res === undefined) {
        return;
      }
      /*let sorted = res.sort((a,b)=>{
        if (a.categoryId > b.categoryId) return 1;
        else if (a.categoryId === b.categoryId) return 0;
        else return -1;
      })*/

      this.events = res;
      this.reload();
    }).catch((error) => {
      console.log(error);
      this.error = error;
    }).finally(() => {
      this.loaded = true;
    });
  }

  addDays = function(date, days) {
    let d = new Date(date);
    d.setDate(date.getDate() + days);
    return d;
  };


  computeSize() {
    for (const [month, monthEvents] of this.date.entries()) {
      try {
        for (const day of Object.keys(monthEvents)) {
          const size = this.date[month][day].events.length;
          if (size <= 1) {
            this.date[month][day].size = 2;
          }
          if (size > 1) {
            this.date[month][day].size = size;
          }
        }
      }
      catch (e) {
        //todo: put events in events fields and remove this catch
      }
    }

      for (const [month, monthEvents] of this.date.entries()) {
      console.log(month);

      try {
        for (const day of Object.keys(monthEvents)) {
          let size = this.date[month][day].events.length;

          let startDay = parseInt(day, 10) + 1;
          let startDay2 = parseInt(day, 10) - 1;
          let startDay3 = parseInt(day, 10) + 1;
          let startDay4 = parseInt(day, 10) - 1;

          let finished = false;
          while (size - 2 > 0 && !finished) {
            if (startDay < this.date[month].days + 1){
              if (isUndefined(this.date[month][startDay])){
                this.date[month][startDay] = {events: []};
                this.date[month][startDay].size = 1;
                size--;
              }
              startDay ++;
            }
            else if(startDay2 > 1) {
              if (isUndefined(this.date[month][startDay2])){
                this.date[month][startDay2] = {events: []};
                this.date[month][startDay2].size = 1;
                size--;
              }
              startDay2--;
            }
            else if(startDay3  < this.date[month].days + 1) {
              if (this.date[month][startDay3].events.length === 1 && this.date[month][startDay3].size === 2 ){
                this.date[month][startDay3].size = 1;
                size--;
              }
              startDay3++;
            }
            else if(startDay4 > 1) {
              if (this.date[month][startDay4].events.length === 1 && this.date[month][startDay4].size === 2 ){
                this.date[month][startDay4].size = 1;
                size--;
              }
              startDay4--;
            }
            else {
              finished= true;
            }
          }
        }
      }
      catch (e) {
       //todo: put events in events fields and remove this catch
      }
    }
  }

  setSize(month, day) {
    if (this.date[month][day].events.length > 2 || this.date[month][day].size === 1) {
      let i = 1;
      while (!isUndefined(this.date[month][day + i])) {
        i++;
      }
      if (day + i > this.date[month].days) {
        i = -1;
        while (!isUndefined(this.date[month][day + i])) {
          i--;
        }
      }
      if (isUndefined(this.date[month][day + i])) {
        this.date[month][day + i] = {events: []};
      }

      this.date[month][day + i].size = 1;

    }
    this.date[month][day].size = this.date[month][day].events.length <= 2 ? 2 : this.date[month][day].events.length;
    let i = 0;
    if (day + i < 1) {
      i = 0;
      while (!isUndefined(this.date[month][day + i]) && this.date[month][day + i].events.length < this.date[month][day + i].size) {
        i++;
      }
      this.date[month][day + i].size = 1;
    }

  }

  array(number) {
    return Array(number).fill(0).map((x, i) => i + 1); // [0,1,2,3,4]
  }

  getDay(month, day) {
    const d = new Date();
    d.setFullYear(this.selectedYear, month, day);
    return d.getDay();
  }

  isWeekEnd(month, day) {
    const d = new Date();
    d.setFullYear(this.selectedYear, month, day);
    return d.getDay() === 0 || d.getDay() === 6;
  }

  animateEvent(event) {
    if (event.state === 'selected') {
      event.state = 'notselected';
      this.api.deletePlanification(event.id, this.juniorId).then(res => {});
    }
    else if (event.state === 'notselected') {
      event.state = 'selected';
      this.api.postPlanification(event.id, this.juniorId).then(res => {});
    }

  }

  public reload() {
    console.log('reload');
    console.log(this.activatedcategory);
    this.date = [];

    for (let i = 1; i <= this.nbMonth; i++) {
      this.date.push({done: new Date(this.selectedYear, i, 0) <= this.today, days: new Date(this.selectedYear, i, 0).getDate(), events: []});
    }

    for (const event of this.events) {

      if (this.activatedcategory[event.categoryId]) { // doesn't treat event if filter is not selected;

        // add attribute 'state' if not selected
        if (this.editMode) {
          if (!event.selected) {
            event.state = 'notselected';
          } else {
            event.state = 'selected';
          }
        }

        const d = new Date(event.date);

        let i = 0;
        let m = 0;
        let c = 0;

        do {
          if ((d.getDate() + i) > this.date[d.getMonth() + m].days) {
            m++;
            i = -d.getDate() + 1;
          }
          if (isUndefined(this.date[d.getMonth() + m][d.getDate() + i])) {
            this.date[d.getMonth() + m][d.getDate() + i] = {events: []};
          }
          this.date[d.getMonth() + m][d.getDate() + i].events.push(event);
          if (!this.date[d.getMonth() + m].last || this.date[d.getMonth() + m].last < d.getDate() + i) {
            console.log(this.date[d.getMonth() + m].last);
            this.date[d.getMonth() + m].last = d.getDate() + i;
          }
          i++;
          c++;
        } while (event['endDate'] && new Date(event['endDate']) >= this.addDays(d, c));
      }
    }

    this.computeSize();
    console.log(this.date);
  }

  toggle(categoryId) {
    this.activatedcategory[categoryId] = !this.activatedcategory[categoryId];
    this.reload();
  }

  edit() {
    const subject = new Subject();
    subject.subscribe(() => this.reload());
    setTimeout(() => {this.api.showLoginWindow2 = {type: 'filter', data: this.activatedcategory, subject: subject};
      document.body.classList.add('noScroll');
    }, 0);
  }

  isDone(month, day)
  {
    return new Date(this.api.parameters.programYear, month, day) < this.today;
  }

  addEvent(event = null, date = null) {
    console.log(date);
    const subject = new Subject();
    subject.subscribe((data: any) => {
      this.api.postEvent(data).then(() => {
        const year = data.date.substring(0, 4);
        this.getEvent(year);
        this.selectedYear = year;
      });
      this.api.showLoginWindow2 = null;
      document.body.classList.remove('noScroll');
    });
    const subjectDelete = new Subject();
    subjectDelete.subscribe((data: any) => {
      this.api.deleteEvent(data.id).then(() => {
        this.getEvent(data.date.substring(0, 4));
      });
      this.api.showLoginWindow2 = null;
      document.body.classList.remove('noScroll');
    });
    this.api.showLoginWindow2 = {type: 'edit_program', subject, subjectDelete };
    this.api.showLoginWindow2.data = event ? event : {date:date};
    document.body.classList.add('noScroll');

  }

  changeYear() {
    console.log(this.selectedYear);
    console.log(this.api.parameters);
    this.loaded = false;
    this.getEvent(this.selectedYear);
  }

  update(e) {
    if (this.editable) {
      this.addEvent(e);
    }
  }

  updateParameters(param) {
    console.log(parseInt(param.year, 10));


    this.api.patchParameters(1, param).then(() => {
    }).catch(() => {
    }).finally(() => {
    });
  }

  addZero(i) {
    return ('0' + i).slice(-2);
  }

}
