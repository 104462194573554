
<div *ngIf="listjuniors">

  <div #scrollMe (scroll)="test2($event)" style=" width: 100%;overflow-x: scroll">
    <div #d *ngIf="top<0 && bottom>50" style="z-index:3;position:fixed;top:0; display: flex;width: 100%;">
      <app-club100-list-header style="width:100%" [fields]="fields" [orderBy]="orderBy" [scrollLeft]="scrollLeft" [(sorted)]="orderBy" (sortedChanged)="sort($event)"
                       [lessons]="lessons"></app-club100-list-header>
    </div>
    <table style="margin-bottom:20px; width: 100%">
      <thead>
      <tr>
        <td style="padding: 0">
          <app-club100-list-header [fields]="fields" [orderBy]="orderBy" (sortedChanged)="sort($event)" [(sorted)]="orderBy"
                           [lessons]="lessons"></app-club100-list-header>
        </td>
      </tr>
      </thead>
      <tr>
        <td [ngClass]="{'selected':over===junior || (over && over.junior===junior)}" (mouseenter)="over={junior:junior}"
            (mouseleave)="over=null" style="padding:0;display: flex;width: 100%;page-break-inside:avoid "
            *ngFor="let junior of listjuniors">
          <div style="display: flex;position: sticky;left:0; position: -webkit-sticky;"
               [ngStyle]="{'z-index':over && over.junior===junior?2:1}">
            <div style="min-width:70px;width: 70px;  background-color: white;">
              <div class="cell2" style="position:relative">
                 <a (click)="edit(junior)" *ngIf=" over && over.junior===junior"
                        class="edit btn-floating waves-effect waves-light red"
                        style="z-index:100;position:absolute;top:-5px;left:10px"><i class="material-icons">mode_edit</i>
                </a>
              </div>
            </div>
            <div *ngIf="!fields || fields.includes('lastName')" style="min-width:150px;width: 150px;background-color: white">
              <div class="cell">{{junior.lastname}}</div>
            </div>
            <div  [ngStyle]="{'border-right':'1px solid gray'}"
                 style="min-width:120px;width: 120px;position: sticky;background-color: white">
              <div *ngIf="!fields || fields.includes('firstName')" class="cell">{{junior.firstname}}</div>
            </div>
          </div>

          <div style="min-width:5px;width: 5px;background-color: white">
            <div class="cell"></div>
          </div>
          <div style="width: 10px;min-width: 10px;">
            <div class="cell" style="font-size: 12px;"></div>
          </div>
          <div *ngIf="!fields || fields.includes('society')" style="width: 180px;min-width: 180px">
            <div class="cell" style="font-size: 12px;">{{junior.society}}</div>
          </div>
          <div *ngIf="!fields || fields.includes('mail')" style="width: 200px;min-width: 200px">
            <div class="cell" style="font-size: 12px;">{{junior.mail}}</div>
          </div>
          <div *ngIf="!fields || fields.includes('submission')"  style="width: 120px;min-width: 120px">
            <div class="cell" style="font-size: 12px;">{{junior.createdAt | dateFormat}}</div>
          </div>
          <div *ngIf="!fields || fields.includes('validation')"  style="width: 120px;min-width: 120px">
            <div class="cell" style="font-size: 12px;">{{junior.validation | dateFormat}}</div>
          </div>
          <div *ngIf="!fields || fields.includes('visible')"  style="width: 120px;min-width: 120px">
            <div class="cell" style="font-size: 12px;">{{junior.visibility ? 'Oui' : 'Non' }}</div>
          </div>
          <div *ngIf="!fields || fields.includes('recontact')"  style="width: 120px;min-width: 120px">
            <div class="cell" style="font-size: 12px;">{{junior.recontact ? 'Oui' : 'Non' }}</div>
          </div>
          <div *ngIf="!fields || fields.includes('reference')"  style="width: 140px;min-width: 140px">
            <div class="cell" style="font-size: 12px;">{{junior.reference}}</div>
          </div>
          <div style="width: 100%;">
            <div class="cell" style="font-size: 12px;" (mouseenter)="over=junior" (mouseleave)="over=null"></div>
          </div>

        </td>

      </tr>
    </table>

    <!-- <header style="background-color:#757575; position:relative;color:white">
       <button id="option_add_button" class="btn-floating btn-large waves-effect waves-light" style="position:absolute;bottom:-28px;right:5px"><i id="fab-add" style="color:white" class="material-icons">add</i></button>
       <button id="option_print_button" class="btn-floating btn-large waves-effect waves-light" style="position: absolute; bottom: -28px; right: 70px; display: block;"><i style="color:white" class="material-icons">print</i></button>
     </header>
     <div style="margin-right: 20px; margin-left: 20px; margin-top: 0px; background-color: white">
       <div id="stats" style="margin: 10px">
         <h5 style="display:inline-block"><strong id="nbjuniors">{{listjuniors.length}}</strong> juniors</h5>
         <h6 style="display:inline-block">(<span id="nbboys">{{(listjuniors | men).length}}</span> boys, <span id="nbgirls">{{(listjuniors | girl).length}}</span> girls)</h6>
         <select style="outline:none; display:block; border-color:dimgray;border-radius: 0px" id="sorting"  [disabled]="this.animating" (change)="test()" [(ngModel)]="sorting">
           <option value="age">Age</option>
           <option value="name">Nom</option>
           <option value="hcp">Handicap</option>
           <option value="nbtours">Nombre de tours</option>
         </select>
       </div>

       <div style="color:gray;text-transform:uppercase;width:100%; display:table; vertical-align: middle;">
         <div style="display:table-cell;width:60px"></div>
         <div style="display:table-cell;vertical-align:middle; width:150px"><span>Nom de famille</span></div>
         <div style="display:table-cell;vertical-align:middle; ;width:150px"><span>Prénom</span></div>
         <div style="display:table-cell;vertical-align:middle; ;width:150px"><span>HCP.</span></div>

         <div style="display:table-cell;vertical-align:middle; ;width:200px"><span>Date de naissance</span></div>
         <div style="display:table-cell;vertical-align:middle; width:130px"><span>Tours en 2018</span></div>

         <div style="display:table-cell"></div>
       </div>
     <div class="list_junior" style=" position:relative;">
       <ng-container *ngFor="let junior of listjuniors">

       <div class="category_title" style="margin-left:10px; font-size:30px" *ngIf="isFirstOfCategory(junior)">U{{getCategory(junior)}}</div>
       <div (mouseenter)="over=junior" (mouseleave)="over=null" (click)="detail(junior)" [ngClass]="{'selected':over===junior}" style="width:100%; display:table; vertical-align: middle; height:32px; position: relative" class="test">
         <div style="display:table-cell;width:60px">
         </div>
         <div style="display:table-cell;vertical-align:middle; width:150px"><span>{{junior.lastName}}</span></div>
         <div style="display:table-cell;vertical-align:middle; ;width:150px"><span>{{junior.firstName}}</span></div>
         <div style="display:table-cell;vertical-align:middle; ;width:150px"><span *ngIf="junior.hcp">{{junior.hcp | number:'1.1-1'}}</span>
         <span *ngIf="junior.medal===1">
           <div style="position:relative;vertical-align:middle;display:inline-block;width: 25px; height: 25px; border-radius: 20px; background-color: #b49e87">
             <div style="position: absolute;left:7px;top:1px;color:white;font-size: 15px">B</div>
           </div>
         </span>
           <span *ngIf="junior.medal===2">
           <div style="position:relative;vertical-align:middle;display:inline-block;width: 25px; height: 25px; border-radius: 20px; background-color: #929295">
             <div style="position: absolute;left:7px;top:1px;color:white;font-size: 15px">A</div>
           </div>
           </span>
           <span *ngIf="junior.medal===3">
               <div  style="position:relative;vertical-align:middle;display:inline-block;width: 25px; height: 25px; border-radius: 20px; background-color: #dcc7a2">
                 <div style="position: absolute;left:7px;top:1px;color:white;font-size: 15px">O</div>
               </div>
           </span>
         </div>

         <div style="display:table-cell;vertical-align:middle; ;width:200px"><span>{{junior.birthday | dateFormat}}</span></div>
         <div style="display:table-cell;vertical-align:middle; width:130px"><span>{{junior.nbTours}}</span></div>

         <div style="display:table-cell"></div>

       </div>
       </ng-container>
     </div>-->
  </div>

</div>
