<div *ngIf="!loaded" class="text-center">
  <i class="fas fa-circle-notch fa-spin fa-7x"></i>
</div>
<div *ngIf="loaded" >
  <img class="print_only" style="float:right; padding: 10px; height: 120px" src="assets/logo_color.svg">

  <div class="infos" style="padding: 10px">
    <a *ngIf="competition.status!=='closed'" (click)="closeCompetition()" class="noprint edit btn-floating waves-effect waves-light" style="float:right; margin-right:20px; margin-bottom:20px; background-color: #F44336 !important">
      <i class="material-icons">lock</i></a>
    <a *ngIf="competition.status==='closed'" (click)="openCompetition()" class="noprint edit btn-floating waves-effect waves-light" style="float:right; margin-right:20px; margin-bottom:20px; background-color: #F44336 !important">
      <i class="material-icons">lock_open</i></a>
    <h5 class="title" style="margin-bottom: 0; display:inline-block">{{competition.name}}</h5><div *ngIf="competition.event && competition.event.categoryId===4" style="
  font-size:15px;
  display: inline-block;
  background-color: #8eb5e0;
  margin-left:10px;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;"><label style="margin: 0">Compétition adulte</label></div>
    <div><h6 class="course" *ngIf="competition.course?.name" style="margin-bottom: 0;margin-top: 0; display:inline-block">Parcours : {{competition.course.name}}</h6></div>
  <h6 style="margin-top :0">{{(competition.date ? competition.date : competition.event.date) | dateFormat}}</h6>
<div *ngIf="competition.status!=='closed'" class="noprint" style="display: flex">
  <ng-select style="width: 100%" [items]="simpleItems"
             multiple="true"
             [addTag]="false"
             placeholder="Ajouter un/des junior(s)"
             addTagText="Ajouter le parcours"
             bindLabel="name"
             [loading]="simpleItems==undefined"
             [(ngModel)]="selectedSimpleItem">
  </ng-select>
  <button [disabled]="!selectedSimpleItem || selectedSimpleItem.length<1" style="margin-left:4px; background-color:white; color: black; border: 1px solid gray; padding:0; padding-left: 5px; padding-right: 5px; font-size:14px;" (click)="postParticipants()">Ajouter</button>
</div>
    <div class="select" style="margin:0;padding: 10px;">

    <select class="custom-select noprint" style="outline:none; border-color:dimgray;" id="sorting" [(ngModel)]="sorting" (change)="sort()">
    <option value="net">Classement Net</option>
    <option value="brut">Classement Brut</option>
  </select>
      <div class="select_arrow noprint">
      </div>
    </div>

    <h2 class="print_only formgame">{{sorting==='brut'?'Classement Brut':'Classement Net'}}</h2>

    <div class="print_only">
    <div class="header-bar">

      <div class="sortable" ngClass="" style= "font-size:12px;min-width:48px;width: 48px;">
        <div class="cell">POS.</div>
      </div>
      <div  class="sortable" style="min-width:420px;width: 420px;">
        <div class="cell">PRÉNOM / NOM</div>
      </div>
      <div class="sortable" style="min-width:100px;width: 100px">
        <div class="cell">HCP.</div>
      </div>
      <div  class="sortable" style="min-width:310px;width: 310px;">
        <div class="cell">COUPS DONNÉS</div>
      </div>
      <div  class="sortable" style="min-width:95px;width: 95px;">
        <div class="cell">POINTS</div>
      </div>
    </div>
    </div>
  </div>
  <div id="scores" style="position:relative">
    <div #scores_players *ngFor="let participant of competition.participants; let i = index" class="scores_player" style="position: relative; top: 0; display: block;">
      <div style="position:relative" class="info" (mouseenter)="over=participant" (mouseleave)="over=null" [ngClass]="{'selected':over===participant}" >
        <div class="info_left_1" style="width:30px">
          <a (click)="toggle(competition,participant,scores_players)" *ngIf="competition.status==='opened' && over===participant && selected!==participant.id" class="noprint edit btn-floating waves-effect waves-light red" style="position:absolute;top:-5px;left:10px"><i class="material-icons">mode_edit</i></a>
          <a (click)="toggle(competition,participant,scores_players)" *ngIf="competition.status==='closed' && over===participant && selected!==participant.id" class="noprint edit btn-floating waves-effect waves-light red" style="position:absolute;top:-5px;left:10px"><i class="material-icons">keyboard_arrow_down</i></a>
          <a (click)="toggle(competition,participant,scores_players)" *ngIf="competition.status==='closed' && over===participant && selected===participant.id" class="noprint edit btn-floating waves-effect waves-light red" style="position:absolute;top:-5px;left:10px"><i class="material-icons">keyboard_arrow_up</i></a>
          <a (click)="toggle(competition,participant,scores_players)" *ngIf="competition.status==='opened' && over===participant && selected===participant.id" class="noprint edit btn-floating waves-effect waves-light red" style="position:absolute;top:-5px;left:10px"><i class="material-icons">close</i></a>
        </div>
        <div class="info_left_2" style="width:10px"></div>
        <div class="ranking">{{i==0 || competition.participants[i-1].net!==participant.net?i+1:'*'}}</div>
        <div class="name">{{participant.junior.user.firstName}} {{participant.junior.user.lastName}}</div>
        <div class="hcp" style="position: relative">
          <div *ngIf="competition.status==='opened' && editHandicap && over===participant && selected===participant.id" class="noprint tooltips">
            <input #edit_hcp class="edit_hcp" type="number" step="0.1" style="width:100%; color:black;background: white" [(ngModel)]="editHandicapValue" />
            <span><a (click)="editHandicap=false" class="noprint edit btn-floating waves-effect waves-light red"><i class="material-icons">close</i></a>
            </span>
            <span style="height:100%; float: right"><a (click)="change_handicap(participant, editHandicapValue)" class="edit btn-floating waves-effect waves-light red"><i class="material-icons">check</i></a>
            </span>
          </div>
          <div (click)="edit_handicap(scores_players, participant)" [ngStyle]="{'cursor':competition.status==='opened' && selected===participant.id?'pointer':null}" style="display:inline-block; line-height:2">{{participant.hcp | number:'1.1-1'}}<a *ngIf="competition.status==='opened' && over===participant && selected===participant.id"  style="position:absolute; margin-left:3px; top:8px;" class="noprint btn-floating btn-small waves-effect waves-light red"><i class="material-icons">mode_edit</i></a></div>
        </div>
        <div class="nbshots">{{participant.nbShots}}<span style="font-size: 10px" *ngIf="participant.mode==='front' || competition.type===1 || participant.mode==='back' || competition.type===2" class="font-size"> (9 trous) </span></div>
        <div class="points">
          <div class="brut">
            <div class="form">BRUT</div>
            <div class="points">{{participant.brut}}</div>
          </div>
          <div class="net">
            <div class="form">NET</div>
            <div class="points">{{participant.net}}</div>
          </div>
        </div>
        <div class="info_right_1" style="width:30px">
        </div>
        <div class="info_right_2" style="position:relative; width:30px;">
          <a *ngIf="competition.status==='opened' && over===participant" (click)="deleteParticipant(participant)" class="noprint edit btn-floating waves-effect waves-light red" style="position:absolute;top:-5px;right:10px"><i class="material-icons">delete</i></a>
        </div>
      </div>
      <div class="scores_div print" [ngClass]="{open:selected===participant.id}" [@slideIn]="{value:transit?(selected===participant.id?'transit2':'transit'):(selected===participant.id?'active':'inactive'), params: {time: '500ms'}}" style="display:flex; position: relative; overflow: hidden;" (mouseenter)="over=participant" (mouseleave)="over=null" >
        <div *ngIf="editHole && competition.status==='opened'" class="noprint tooltips2">
          <div style="padding:10px;background:white">
              <span class="hole-selection-selected" style="margin-right:3px;">
                <button (click)="editHoleSelection=0" style="cursor:pointer; width:40px; padding:2px 3px 2px 3px; font-size: 12px">1-18</button>
              </span>
            <span class="hole-selection-choice" style="margin-right:3px;">
                <button (click)="editHoleSelection=1"  style="cursor:pointer; width:40px; padding:2px 3px 2px 3px;  font-size: 12px">1-9</button>
              </span>
            <span class="hole-selection-choice" style="margin-right:3px;">
                <button (click)="editHoleSelection=2"  style="cursor:pointer; width:40px; padding:2px 3px 2px 3px; font-size: 12px">10-18</button>
              </span>
            <div style="margin-right:3px;">
              <div [ngClass]="{'hole-selection-1':editHoleSelection===0,'hole-selection-2':editHoleSelection===1,'hole-selection-3':editHoleSelection===2}" style="cursor: pointer; width:40px; height:3px; background: red;"></div>
            </div>
          </div>
          <span style="float: left"><a (click)="editHole=false" class="edit btn-floating waves-effect waves-light red"><i class="material-icons">close</i></a>
            </span>
          <span style="height:100%; float: right"><a (click)="change_mode(participant, editHoleSelection);editHole=false" class="edit btn-floating waves-effect waves-light red"><i class="material-icons">check</i></a>
            </span>
        </div>
        <div *ngIf="editHoleTee && competition.status==='opened'" class="noprint tooltips3">
          <div style="padding:10px;background:white" class="teebox-selection">
            <div style="display: inline-block">
              <ng-container *ngFor="let teebox of competition.course.teeboxes | teebox : ''+participant.junior.user.gender; let i = index" >
              <span class="hole-selection-selected" style="margin-right:3px;">
                <div (click)="editHoleTeeValue=i;selectedTeebox= teebox" class="teebox-selection-selected" [ngClass]="teebox.name" style="display: inline-block;border: 1px solid black; width: 20px; height: 20px; border-radius: 10px;"></div>
              </span>
              </ng-container>
              <div style="margin-right:3px;">
                <div [ngClass]="'tee-selection-'+editHoleTeeValue" style="width:30px; height:3px; background: red;"></div>
              </div>
            </div>
          </div>
          <span style="float: left"><a (click)="editHoleTee=false" class="edit btn-floating waves-effect waves-light red"><i class="material-icons">close</i></a>
            </span>
          <span style="height:100%; float: right"><a (click)="change_teebox(participant, selectedTeebox)" class="edit btn-floating waves-effect waves-light red"><i class="material-icons">check</i></a>
            </span>
        </div>
        <div class="player-options">
          <div class="hole-selection" style="position:relative;margin-top:2px;">
            <button (click)="editHole=!editHole" class="hole-selection-selected" style="cursor:pointer; min-width: 40px; padding:2px 3px 2px 3px; font-size: 12px">{{participant.mode==='front' || competition.type===1?'1-9':participant.mode==='back' || competition.type===2?'10-18':'1-18'}}</button>

            <div class="hole-selection-options" style="position:relative;height:130px;z-index:3;display:none; background:white;top:0px;left:0px">
              <div class="hole-selection-choice" style=" margin-bottom:5px;width:100%">
                <button style="width: 40px; padding:2px 3px 2px 3px; font-size: 12px"></button>
              </div>

              <div (click)="edit_handicap(scores_players, participant)" [ngStyle]="{'cursor':selected===participant.id?'pointer':null}" style="display:inline-block; line-height:2">{{participant.hcp | number:'1.1-1'}}<a *ngIf="over===participant && selected===participant.id"  style="position:absolute; margin-left:3px; top:8px;" class="btn-floating btn-small waves-effect waves-light red"><i class="material-icons">mode_edit</i></a></div>

            </div>
          </div>
          <div (click)="edit_tee(participant)" class="teebox-selection" style="position:relative;margin-top:8px;">
            <div *ngIf="participant.teebox" class="teebox-selection-selected" [ngClass]="participant.teebox.name" style="border: 1px solid black; width: 20px; height: 20px; border-radius: 10px;"></div>
            <div *ngIf="!participant.teebox" class="teebox-selection-selected" style="display:flex; justify-content:center; align-items:center; border: 1px solid black; width: 20px; height: 20px; border-radius: 10px;">x</div>
            <div class="teebox-selection-options" style="z-index:2; display:none; background:white; position:absolute;top:0px;left:0px">
            </div>
          </div>
        </div>
        <div *ngIf="!competition.pointsMode" class="details">
          <div style="display:table; width: 100%">
          <div *ngIf="competition.course" class="pars noprint">
            <p class="label">Par</p>
            <div *ngFor="let a of [1,2,3,4,5,6,7,8,9]" class="par">
              {{competition.course.holes[a-1].par}}
            </div>
            <div style="display: table-cell" class="par_subtotal">
            </div>
            <div *ngFor="let a of [10,11,12,13,14,15,16,17,18]" class="par">
              {{competition.course.holes[a-1].par}}
            </div>
            <div style="display: table-cell" class="par_subtotal">
            </div>
            <div style="display: table-cell" class="par_total">
            </div>
          </div>
         <div style="display: table-row;" class="holes">
            <p class="label noprint" style=" ">Score</p>
            <ng-container *ngFor="let b of [0,1]">
              <p *ngFor="let a of [1,2,3,4,5,6,7,8,9]" class="hole" [ngClass]="{'disabled': (b===0 && (participant.mode==='back' || competition.type===2)) || (b===1 && (participant.mode==='front' || competition.type===1)), 'doublebogey': participant.scores[(a+9*b)-1] && (participant.scores[(a+9*b)-1].score==='-' || (participant.scores[(a+9*b)-1].score && participant.scores[(a+9*b)-1].score>competition.course.holes[(a+9*b)-1].par+1)), 'bogey': participant.scores[(a+9*b)-1] && (participant.scores[(a+9*b)-1].score && participant.scores[(a+9*b)-1].score==competition.course.holes[(a+9*b)-1].par+1), 'par': participant.scores[(a+9*b)-1] && (participant.scores[(a+9*b)-1].score && participant.scores[(a+9*b)-1].score==competition.course.holes[(a+9*b)-1].par), 'birdie': participant.scores[(a+9*b)-1] && (participant.scores[(a+9*b)-1].score && participant.scores[(a+9*b)-1].score==competition.course.holes[(a+9*b)-1].par-1), 'eagle': participant.scores[(a+9*b)-1] && (participant.scores[(a+9*b)-1].score && participant.scores[(a+9*b)-1].score<competition.course.holes[(a+9*b)-1].par-1)}">
                <ng-container>
                  <input [disabled]="competition.status==='closed' || (b===0 && (participant.mode==='back' || competition.type===2)) || (b===1 && (participant.mode==='front' || competition.type===1))" style="margin:0" [ngModel]="participant.scores[(a+9*b)-1].score" type="text" (keypress)="d($event, participant, (a+9*b)-1)" (ngModelChange)="test($event, participant, (a+9*b)-1)">
                </ng-container>
              </p>
              <p style="display: table-cell" class="subtotal">
                <input disabled="" type="text" value="{{participant.subtotal[b]}}">
              </p>
            </ng-container>
            <p style="display: table-cell" class="total">
              <input disabled="" type="text" value="{{participant.subtotal[0]+participant.subtotal[1]}}">
            </p>
          </div>
          <div *ngIf="competition.course" class="bruts noprint">
            <p style="display: table-cell" class="label">Brut</p>
            <ng-container *ngFor="let b of [0,1]">
              <p style="display: table-cell"  *ngFor="let a of [1,2,3,4,5,6,7,8,9]" class="hole">
                {{participant.scores[(a+9*b)-1].brut}}
              </p>
              <p style="display: table-cell" class="subtotal">
                <input disabled="" type="text" value="{{participant.subtotalBrut[b]}}">
              </p>
            </ng-container>
            <p style="display: table-cell" class="total">{{participant.subtotalBrut[0]+participant.subtotalBrut[1]}}</p>
          </div>

          <div *ngIf="competition.course" class="nets noprint">
            <p style="display: table-cell" class="label">Net</p>
            <ng-container *ngFor="let b of [0,1]">
              <div style="position:relative;vertical-align:middle; display: table-cell" *ngFor="let a of [1,2,3,4,5,6,7,8,9]" class="hole">
                <div style="position: absolute;top: -6px;left:0">
                  <ng-container *ngIf="((competition.type===1 && b==0) || (competition.type!==1 && (!participant.mode || participant.mode==='front' && b==0))) || ((competition.type===2 && b==1) || (competition.type!==2 && (!participant.mode || participant.mode==='back' && b==1)))">
                    <canvas *ngFor="let shots of nbGivenShotsHole(a+9*b,participant.nbShots*((participant.mode==='front' || competition.type===1)?2:1))" style="margin-left:2px; width: 6px;height: 6px;background: gray; border-radius: 3px;"></canvas>
                  </ng-container>
                </div>
                {{participant.scores[(a+9*b)-1].net}}
              </div>
              <p style="display: table-cell" class="subtotal">
                <input disabled="" type="text" value="{{participant.subtotalNet[b]}}">
              </p>
            </ng-container>
            <p style="display: table-cell" class="total">{{((participant.mode==='back' || competition.type===2)?17:participant.subtotalNet[0])+((participant.mode==='front' || competition.type===1)?17:participant.subtotalNet[1])}}</p>
          </div>
      </div>
        </div>

        <div *ngIf="competition.pointsMode && competition.status==='opened'" class="details">
          <div style="display: flex; min-height: 120px">
            <div>Brut <input class="edit_hcp" type="number" step="1" style="width:100%; color:black;background: white" [(ngModel)]="participant.brut" (ngModelChange)="change(participant)"/></div>
            <div>Net <input class="edit_hcp" type="number" step="1" style="width:100%; color:black;background: white" [(ngModel)]="participant.net" (ngModelChange)="change(participant)"/></div>
          </div>
        </div>
        <div *ngIf="competition.pointsMode && competition.status==='closed'" class="details">
          <div style="font-style:italic;display: flex;justify-content: center;align-items: center;height: 100%;">
            Carte de score non disponible
          </div>
        </div>
  </div>

</div>
</div>
</div>
