<div style="overflow-y: scroll; padding: 20px; height: 100%">

<div *ngIf="!loaded" class="text-center">
  <i class="fas fa-circle-notch fa-spin fa-7x"></i>
</div>
<div *ngIf="loaded">

  <div class="row">
    <div class="col-lg-12" style="text-align: center">
      <div style="display: inline-block; text-align: left; font-size: 19px">
<span style="margin-top:25px; margin-left: 12px;margin-right: 12px; float: left"><label class="choice"><input value="section" [(ngModel)]="radioSelected" type="radio" id="scales" name="scales"
> Section juniors</label></span>
      <span style="margin-top:25px;margin-left: 12px;margin-right: 12px; float: left"><label class="choice"><input value="baby" [(ngModel)]="radioSelected" type="radio" id="scales" name="scales"
      > Cross-golf Kids<img style="margin-left: 2px; max-width: 15px" src="assets/baby.svg"></label></span><span style="margin-top:25px;margin-left: 12px;margin-right: 12px; float: left"><label class="choice" style="width:180px;"><input  [(ngModel)]="radioSelected" value="resigned" type="radio" id="scales" name="scales"
    ><span> Anciens juniors <img style=" max-width: 12px" src="assets/out-button.svg"></span>
      </label></span></div>
    </div>
    </div>
  <hr/>

  <div class="row">
    <div class="col-lg-6">
      <label>
        Nom
      </label>
      <input style="font-size:25px;width: 100%" type="text" [(ngModel)]="juniorEdit.lastName"/>
    </div>
    <div class="col-lg-6">
      <label>
        Prénom
      </label>
      <input style="font-size:25px;width: 100%" [(ngModel)]="juniorEdit.firstName"/>
    </div>

  </div>

  <div class="row mt-3">
    <div class="col-lg-6 col-sm-12">
      <div><label>
        Sexe
      </label>
      </div>
      <button (click)="juniorEdit.gender=false" [ngStyle]="{'opacity':juniorEdit.gender===true?'0.4':'1','border':juniorEdit.gender===false?'solid 3px black':'solid 1px #eeeeee'}" style="margin:3px;cursor:pointer; background-color:white;height:50px;width: 90px;">
        <img style="margin:5px; width: 20px" src="assets/venus.svg"/>
        <span [ngStyle]="{'font-weight':juniorEdit.gender===false?'bold':'normal'}" style="font-size: 12px">GIRL</span>
      </button>
      <button (click)="juniorEdit.gender=true" [ngStyle]="{'opacity':juniorEdit.gender===false?'0.4':'1','border':juniorEdit.gender===true?'solid 3px black':'solid 1px #eeeeee'}" style="margin:3px;cursor:pointer; background-color:white;height:50px;width: 90px;">
        <img style="margin:5px; width: 20px" src="assets/mars.svg"/>
        <span [ngStyle]="{'font-weight':juniorEdit.gender===true?'bold':'normal'}" style="font-size: 12px">BOY</span>
      </button>
    </div>
    <div class="col-lg-6">
      <label>
        Date de naissance
      </label>
      <input style="font-size:25px;width: 100%" type="date"  [(ngModel)]="juniorEdit.birthday"/>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-lg-2">
      <label>
        HCP
      </label>
      <input style="font-size:25px;width: 100%" type="text" [(ngModel)]="juniorEdit.hcp"/>
    </div>
    <div class="col-lg-4">

    </div>
    <div class="col-lg-6">
      <label>
        Numéro Swissgolf
      </label>
      <input style="font-size:25px;width: 100%" type="text" [(ngModel)]="juniorEdit.asgNumber"/>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-lg-6">
    </div>
    <div class="col-lg-6">
      <label>
        Numéro AVS
      </label>
      <input style="font-size:25px;width: 100%" type="text" [(ngModel)]="juniorEdit.AVSNumber"/>
    </div>
  </div>

<hr/>

  <div class="row">
    <div class="col-12">
      <label>
        Commentaire
      </label>
      <textarea rows="5" id="input3" style="width: 100%" [(ngModel)]="juniorEdit.comment" [ngModelOptions]="{standalone: true}"></textarea>

    </div>
    <div class="col-lg-12">
      <label>
        Adresse
      </label>
      <input style="font-size:16px;width: 100%" type="text" [(ngModel)]="juniorEdit.address"/>
    </div>
    <div class="col-lg-6">
      <label>
        Téléphone
      </label>
      <input style="font-size:16px;width: 100%" type="tel" [(ngModel)]="juniorEdit.phone"/>
    </div>
    <div class="col-lg-6">
      <label>
        Email
      </label>
      <input style="font-size:16px;width: 100%" type="email"  [(ngModel)]="juniorEdit.mail"/>
    </div>
  </div>
  <hr/>

  <div class="row">
    <div class="col-lg-12">
      <div><b>Parent 1</b></div>
    </div>
    <div class="col-lg-12">
      <label>
        Nom
      </label>
      <input style="font-size:16px;width: 100%" type="tel"   [(ngModel)]="juniorEdit.nameParent1"/>
    </div>
    <div class="col-lg-6">
      <label>
        Téléphone
      </label>
      <input style="font-size:16px;width: 100%" type="tel"   [(ngModel)]="juniorEdit.phoneParent1"/>
    </div>
    <div class="col-lg-6">
      <label>
        Email
      </label>
      <input style="font-size:16px;width: 100%" type="email"  [(ngModel)]="juniorEdit.mailParent1"/>
    </div>
  </div>
  <hr/>

  <div class="row">
    <div class="col-lg-12">
      <div><b>Parent 2</b></div>
    </div>
    <div class="col-lg-12">
      <label>
        Nom
      </label>
      <input style="font-size:16px;width: 100%" type="tel"   [(ngModel)]="juniorEdit.nameParent2"/>
    </div>
    <div class="col-lg-6">
      <label>
        Téléphone
      </label>
      <input style="font-size:16px;width: 100%" type="tel" [(ngModel)]="juniorEdit.phoneParent2"/>
    </div>
    <div class="col-lg-6">
      <label>
        Email
      </label>
      <input style="font-size:16px;width: 100%" type="email" [(ngModel)]="juniorEdit.mailParent2"/>
    </div>
  </div>
</div>
</div>
<div (click)="valid()" style="position: absolute;bottom:-50px;right: 0px">
  <a class="edit btn-floating waves-effect waves-light" style="cursor:pointer; background-color: #F44336 !important"><i class="material-icons">save</i></a>
</div>
