<div style="overflow-y: auto; padding: 20px; height: 100%">

<div *ngIf="!loaded" class="text-center">
  <i class="fas fa-circle-notch fa-spin fa-7x"></i>
</div>
<div *ngIf="loaded">

  <h1>
    <span *ngIf="!lessonId">Ajout</span><span *ngIf="lessonId">Modification</span> d'un cours
  </h1>
  <div class="row">

    <div class="col-lg-12">
      <label>
        Nom du cours
      </label>
      <input style="font-size:16px;width: 100%" type="text" [(ngModel)]="dataEdit.name"/>
    </div>
    <div class="col-lg-12">
      <label>
        Abbréviation (pour génération de l'URL)
      </label>
      <input style="font-size:16px;width: 100%" type="text" [(ngModel)]="dataEdit.abbr"/>
    </div>
    <div class="col-lg-12">
      <label>
        Dates
      </label>
      <div>
      <input type="radio" id="male" name="date" [value]="true" [(ngModel)]="dataEdit.datesLinked">
      <label style="margin-left:10px;color:black; font-size: 15px" for="male">Les groupes ont des dates communes.</label>
      </div>
      <div *ngIf="dataEdit.datesLinked" class="col-lg-12">
        <label>
          Nom dans le programme
        </label>
        <input style="font-size:16px;width: 100%" type="text" [(ngModel)]="dataEdit.datesLinkedText"/>
      </div>
      <div>
      <input type="radio" id="female" name="date" [value]="false" [(ngModel)]="dataEdit.datesLinked">
      <label style="margin-left:10px;color:black; font-size: 15px" for="female">Les groupes ont des dates distinctes.</label>
      </div>
    </div>
  </div>
 </div>
</div>
<div style="position: absolute;bottom:-45px;right: 0">
  <app-action-button [icon]="'save'" (click)="valid()"></app-action-button>
</div>
