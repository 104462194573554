<div style="overflow-y: hidden; padding: 20px;text-align: center">


  <div class="row">
    <div class="col-lg-6">
      <label>
        Date de début
      </label>
      <input style="font-size:25px;width: 100%" type="date" [(ngModel)]="date"/>
    </div>
    <div class="col-lg-6">
      <label>
        Date de fin
      </label>
      <input style="font-size:25px;width: 100%" type="date" [(ngModel)]="endDate"/>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <label>
        Nom Complet
      </label>
      <input style="font-size:25px;width: 100%" type="text"  [(ngModel)]="name"/>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-6">
      <label>
         Categorie
      </label>
      <input type="number" style="font-size:25px;width: 100%" [(ngModel)]="categoryId"/>
    </div>
  </div>

</div>


<div (click)="valid()" style="position: absolute;bottom:-50px;right: 0px">
  <a class="edit btn-floating waves-effect waves-light" style="cursor:pointer; background-color: #F44336 !important"><i class="material-icons">save</i></a>
</div>
