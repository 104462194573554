import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {APIService} from '../api';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @ViewChild('banner') banner;
  img = false;
  w = true;
  date = new Date();
  date1 = new Date(2020,5,15);
  loaded = false;
  news;
  isShown2 = false;
  isShown3 = false;
  showprogram = true;
  translateYValue = -1;

  constructor(public api: APIService) { }

  ngOnInit() {
    this.loaded = false;
    this.isShown2 = this.api.parameters.groups.find((x) => x.id === 1) && this.api.parameters.groups.find((x) => x.id === 2);
    this.isShown3 = this.api.parameters.groups.find((x) => x.id === 3);
    this.api.getLastNews().then((news) => {this.news = news; this.loaded = true;});

    setTimeout(()=>
      this.w = true, 5000);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    console.log('resize') ;
    const slope = (6 - 3) / (1680 - 1000);
    this.translateYValue = 3 + slope * (this.banner.nativeElement.offsetWidth - 1000);
  }

  ngAfterViewInit(){
    this.onResize();
  }

  imageload() {
    this.img = true;
  }



}
