import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Router, RoutesRecognized} from '@angular/router';
import {APIService} from './api';
import {AuthGuard} from './security/auth.guard';
import {SwPush, SwUpdate} from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

  /* ngOnInit() {
   console.log('new version A');

    if (this.swUpdate.isEnabled) {
      console.log('new version B');

      this.swUpdate.available.subscribe(() => {
        console.log('new version C');
        if(confirm("Une nouvelle version du site est disponible. Merci de cliquer sur OK pour la charger")) {
          console.log('new version 4');
          window.location.reload();
        }
      });
    }
  }*/

  logo = true; // default value for displaying logo
  title; // title of the page
  back;
  print = true;
  loaded = false;
  constructor(private swPush: SwPush, public route: Router, public api: APIService, public auth: AuthGuard,private cd: ChangeDetectorRef, private swUpdate: SwUpdate) {
    //this.swPush.requestSubscription({
      //serverPublicKey: 'BNShNor5cAv7COtfhgurXlriTxwnudSCcJfc2kRLySpiBHX6df'
    //});

    this.api.getParameters(this.api.clubId).then((parameters) => {
      this.api.parameters = parameters;
      if (this.api.parameters.module){
        this.api.managersActivated = true;
        let c = this.api.parameters.module.find((x) => x.name === 'managers');
        if (c.published === true) {
          this.api.managersActivated = true;
        }
        c = this.api.parameters.module.find((x) => x.name === 'contacts');
        console.log(c);
        if (c.published === true) {
          this.api.contactsActivated = true;
        }
        c = this.api.parameters.module.find((x) => x.name === 'club100');
        if (c.published === true) {
          this.api.club100Activated = true;
        }
      }
    }
    ).finally(()=>{
      this.loaded = true;
    });

    route.events.subscribe(event => {
      if (event instanceof RoutesRecognized) {
        const c = event.state.root.firstChild;
        this.title = c.data.title;
        this.back = false;
        this.logo = !!c.data.title;
      }
    });

    api.changeTitleEvent.subscribe((title: any) => {
      console.log(title.name);
      if (typeof title === 'object') {
        this.title = title.name;
        this.back = title.back;
        if (title.print === false) {
          this.print = title.print;
        }
      }
      else {
        this.title = title;
        this.back = false;
      }
      this.cd.detectChanges();

    });
  }

  ngOnInit() {

  }


  clickLogin() {
    if (this.api.showLoginWindow) {
      this.api.showLoginWindow = false;
    } else {
      setTimeout(() => {
        this.api.showLoginWindow = true;
      }, 5);
    }
  }

  onClickOutside() {
    this.api.showLoginWindow2 = null;
    document.body.classList.remove('noScroll');
  }

}
