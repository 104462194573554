import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {AuthGuard} from './security/auth.guard';

@Injectable()
export class APIService {

  constructor(private http: HttpClient, private sessionManager: AuthGuard, private router: Router) {
    this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  //public URL = 'http://localhost:8080/';
  public URL = 'https://juniorsgolfpayerne.ch/api/';

  public clubId = 1;
  public parameters;
  public managersActivated;
  public contactsActivated;
  public club100Activated;
  public showLoginWindow = false;
  public showLoginWindow2;

  private firstname;
  private lastname;

  isLoaded = true;
  loaded = {};
  logged = false;
  changeTitleEvent = new Subject();
  updatejunior = new Subject();
  updateGroup = new Subject();
  isSafari = false;

  openPopup(obj) {
    document.body.classList.add('noScroll');
    this.showLoginWindow2 = obj;
  }

  closePopup() {
    document.body.classList.remove('noScroll');
    this.showLoginWindow2 = null;
  }

  redirect(a) {
    this.router.navigateByUrl(a);
  }

  load(e) {
    this.isLoaded = false;
    if (!this.loaded[e]) {
      this.loaded[e] = 0;
    }
    this.loaded[e]++;
    console.log(this.loaded);
    console.log(Object.keys(this.loaded).length);

  }

  changeTitle(title) {
    this.changeTitleEvent.next(title);
  }

  hasLoad(e) {
    /*
    this.loaded[e]--;
    if(this.loaded[e] ===0) {
      delete this.loaded[e];
    }
    console.log(this.loaded);
    this.isLoaded = Object.keys(this.loaded).length === 0;
    console.log(Object.keys(this.loaded).length);*/
  }

  getFirstname() {
    return this.firstname;
  }

  setFirtname(firstname) {
    this.firstname = firstname;
  }

  getNews(): Promise<any> {
    const headers = new HttpHeaders();
    return this.http.get(this.URL + 'news', {headers})
      .toPromise()
      .then(response => response);
  }

  putNews(e, id): Promise<any> {
    const headers = new HttpHeaders();
    return this.http.put(this.URL + 'news/' + id, e, {headers})
      .toPromise()
      .then(response => response);
  }

  getLastNews(): Promise<any> {
    const headers = new HttpHeaders();
    return this.http.get(this.URL + 'news/last', {headers})
      .toPromise()
      .then(response => response);
  }


  postToken(login, password): Promise<any> {
    return this.http.post(this.URL + 'token/', {login, password} )
      .toPromise()
      .then(response => response);
  }

  getResults(clubId, year, form, gender): Promise<any> {
    const headers = new HttpHeaders();

    return this.http.get(this.URL + 'results' + '?clubId=' + clubId + '&year=' + year + '&form=' + form + '&gender=' + gender, {headers})
      .toPromise()
      .then(response => response);
  }

  postCompetitionClose(id, participants): Promise<any> {
    const headers = new HttpHeaders();
    return this.http.post(this.URL + 'competitions/' + id + '/close', participants, {headers})
      .toPromise()
      .then(response => response);
  }

  putCompetition(id, data): Promise<any> {
    const headers = new HttpHeaders();
    return this.http.put(this.URL + 'competitions/' + id, data, {headers})
      .toPromise()
      .then(response => response);
  }

  getCompetitions(year, category): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
    return this.http.get(this.URL + 'competitions?year=' + year + '&category=' + category, {headers})
      .toPromise()
      .then(response => response)
      .catch((reason) => {
        if (reason.error && reason.error.error === 'token expired') {
          this.sessionManager.disconnect();
        }
      });
  }

  getCourses(): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem('userToken'));
    return this.http.get(this.URL + 'courses', {headers})
      .toPromise()
      .then(response => response);
  }


  getMedals(): Promise<any> {
    const headers = new HttpHeaders();
    return this.http.get(this.URL + 'medals/', {headers})
      .toPromise()
      .then(response => response);
  }

  getLessons(hasAccessToken = null): Promise<any> {
    let headers = new HttpHeaders();
    if (hasAccessToken) {
      headers = headers.set('Authorization', localStorage.getItem('accessToken'));
    }
    return this.http.get(this.URL + 'lessons/', {headers})
      .toPromise()
      .then(response => response);
  }

  postMedal(id, domain, value): Promise<any> {
    const headers = new HttpHeaders();
    return this.http.post(this.URL + 'medals/', {id, domain, value}, {headers})
      .toPromise()
      .then(response => response);
  }

 getCompetition(id): Promise<any> {
   let headers = new HttpHeaders();
   headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
   return this.http.get(this.URL + 'competitions/' + id, {headers})
      .toPromise()
      .then(response => response);
  }

  getJunior(id): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
    return this.http.get(this.URL + 'juniors/' + id, {headers})
      .toPromise()
      .then(response => response);
  }

  getJuniors(type = null): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
    console.log(headers);
    return this.http.get(this.URL + 'juniors' + (type ? '?type=for_competition' : ''), {headers})
      .toPromise()
      .then(response => response);
  }

  getManagers(logged): Promise<any> {
    let headers = new HttpHeaders();
    if (logged) {
      headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
    }
    console.log(headers);
    return this.http.get(this.URL + 'managers', {headers})
      .toPromise()
      .then(response => response);
  }

  putManager(id, body): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
    console.log(headers);
    return this.http.put(this.URL + 'managers/' + id, body, {headers})
      .toPromise()
      .then(response => response);
  }

  postManager(body): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
    console.log(headers);
    return this.http.post(this.URL + 'managers/', body, {headers, responseType: 'text'})
      .toPromise()
      .then(response => response);
  }

  deleteManager(managerId): Promise<any> {
    return this.http.delete(this.URL + 'managers/' + managerId, {
      withCredentials: true,
      responseType: 'text'
    }).toPromise().then();
  }

  getContactManager(id): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
    return this.http.get(this.URL + 'clubs/' + id + '/contact/managers', {headers})
      .toPromise()
      .then(response => response);
  }


  putManagersOrder(data): Promise<any> {
    console.log(data);
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
    console.log(headers);
    return this.http.put(this.URL + 'managers', data, {headers})
      .toPromise()
      .then(response => response);
  }

  patchManagersConfiguration(id, obj) {
    return this.http.patch(this.URL + 'clubs/' + id + '/parameters', obj)
      .toPromise()
      .then(response => response);
  }

  patchClub100Configuration(id, obj) {
    return this.http.patch(this.URL + 'clubs/' + id + '/parameters', obj)
      .toPromise()
      .then(response => response);
  }


  downloadList() {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.cssText = 'display: none';
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
    return this.http.get(this.URL + 'juniors/x1', {headers, responseType: 'blob'})
      .toPromise()
      .then((res: any ) => {
        const url = window.URL.createObjectURL(res);
        a.href = url;
        a.download = 'list_juniors.xlsx';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      });

  }

  putJunior(junior): Promise<any> {
    const headers = new HttpHeaders();
    console.log(headers);
    console.log('juniors');
    return this.http.put(this.URL + 'juniors', junior, {headers})
      .toPromise()
      .then(response => response);
  }

  getGroups(year, id, user): Promise<any> {
    let headers = new HttpHeaders();
    console.log(user);
    if (user) {
      headers = headers.set('Authorization', localStorage.getItem('accessToken'));
    }
    console.log(headers);
    let params = new HttpParams();
    params = params.append('year', year);

    return this.http.get(this.URL + 'groups/' + id, {headers, params})
      .toPromise()
      .then(response => response);
  }

  addJuniorToGroup(groupId, juniorId) {
    const headers = new HttpHeaders();
    return this.http.post(this.URL + 'groups/' + groupId + '/juniors', {junior: juniorId}, {headers})
      .toPromise()
      .then(response => response);
  }

  removeJuniorToLesson(lessonId, juniorId) {
    const headers = new HttpHeaders();
    return this.http.delete(this.URL + 'lessons/' + lessonId + '/juniors/' + juniorId, {headers, responseType: 'text'})
      .toPromise()
      .then(response => {
        return response;
      });
  }

  getEvent(year, id = null, small = false, date = null): Promise<any> {
    console.log(year)
    console.log(date);
    let headers = new HttpHeaders();
    if (id) {
      headers = headers.set('Authorization', localStorage.getItem('accessToken'));
    }
    let httpParams = new HttpParams();
    if (small) {
      httpParams = httpParams.append('small', 'true');
    }
    if (date) {
      httpParams = httpParams.append('date', date);
    }
    httpParams = httpParams.append('year', year);

    return this.http.get(this.URL + 'events/', {headers, params: httpParams} )
      .toPromise();
  }

  postCompetitionScores(competition_id, participant_id, holes): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
    return this.http.post(this.URL + 'competitions/' + competition_id + '/participants/'  + participant_id + '/scores', holes, {headers})
      .toPromise()
      .then(response => response);
  }

  postParticipants(id, users) {
    const headers = new HttpHeaders();
    return this.http.post<any[]>(this.URL + 'participants', {competition: id, users}, {headers})
      .toPromise<any[]>()
      .then(response => response);
  }

  deleteParticipant(participantId, competitionId): Promise<any> {
    return this.http.delete(this.URL + 'competitions/' + competitionId + '/participants/'  + participantId)
      .toPromise()
      .then(response => response);
  }

  postCompetition(course_id, name, date) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem('userToken'));
    return this.http.post(this.URL + 'competition', {course_id, name, date}, {headers})
      .toPromise()
      .then(response => response);
  }

  putParticipantHandicap(participant_id, handicap) {
    console.log(handicap);
    const headers = new HttpHeaders();
    return this.http.put(this.URL + 'participants/' + participant_id + '/hcp', {hcp: handicap}, {headers}).toPromise()
  .then(response => response);
  }

  putParticipantMode(participant_id, mode) {
    const headers = new HttpHeaders();
    return this.http.put(this.URL + 'participants/' + participant_id + '/mode', {mode}, {headers}).toPromise()
      .then(response => response);
  }

  putParticipantTeebox(participant_id, teebox) {
    const headers = new HttpHeaders();
    return this.http.put(this.URL + 'participants/' + participant_id + '/teebox', {teebox}, {headers}).toPromise()
      .then(response => response);
  }

  postPlanification(event_id, junior_id): Promise<any> {
    return this.http.post(this.URL + 'event/' + event_id + '/planification', {junior_id} )
      .toPromise()
      .then(response => response);
  }

  deletePlanification(event_id, junior_id): Promise<any> {
    return this.http.delete(this.URL + 'event/' + event_id + '/planification/' + junior_id)
      .toPromise()
      .then(response => response);
  }

  postMessage(message) {
    return this.http.post(this.URL + 'message', message)
      .toPromise()
      .then(response => response);
  }

  getParameters(id): Promise<any> {
    return this.http.get(this.URL + 'clubs/' + id + '/parameters')
      .toPromise()
      .then(response => response);
  }

  getProgramParameters(id): Promise<any> {
    return this.http.get(this.URL + 'clubs/' + id + '/program/parameters')
      .toPromise()
      .then(response => response);
  }

  getGroupParameters(id): Promise<any> {
    return this.http.get(this.URL + 'clubs/' + id + '/group/parameters')
      .toPromise()
      .then(response => response);
  }

  patchParameters(id, obj) {
    return this.http.patch(this.URL + 'clubs/' + id + '/parameters', obj)
      .toPromise()
      .then(response => response);
  }

  patchParameters2(id, obj) {
    return this.http.patch(this.URL + 'clubs/' + id + '/groups/parameters', obj)
      .toPromise()
      .then(response => response);
  }

  patchLesson(id, obj) {
    return this.http.patch(this.URL + 'lessons/' + id, obj)
      .toPromise()
      .then(response => response);
  }

  postLesson(obj) {
    return this.http.post(this.URL + 'lessons', obj)
      .toPromise()
      .then(response => response);
  }

  postEvent(event): Promise<any> {
    return this.http.post(this.URL + 'events/', event)
      .toPromise()
      .then(response => response);
  }

  deleteEvent(eventId): Promise<any> {
    console.log(this.URL + 'events/' + eventId);
    return this.http.delete(this.URL + 'events/' + eventId, {responseType: 'text'}).toPromise().then();
  }

  getEventCategories() {
    return this.http.get(this.URL + 'event-categories')
      .toPromise()
      .then(response => response);
  }

  getGroup(id) {
    return this.http.get(this.URL + 'groups/' + id)
      .toPromise()
      .then(response => response);
  }


  patchGroup(id, obj) {
    return this.http.patch(this.URL + 'groups/' + id, obj)
      .toPromise()
      .then(response => response);
  }

  postGroup(obj) {
    return this.http.post(this.URL + 'groups/', obj)
      .toPromise()
      .then(response => response);
  }

  deleteGroup(id) {
    return this.http.delete(this.URL + 'groups/' + id, {responseType: 'text'})
      .toPromise()
      .then(response => response);
  }

  getClub100(): any {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
    return this.http.get(this.URL + 'clubs/' + this.clubId + '/club100', {headers})
      .toPromise()
      .then(response => response);
  }

  postClub100(obj) {
      return this.http.post(this.URL + 'clubs/' + this.clubId + '/club100', obj)
        .toPromise()
        .then(response => response);
  }

  getEquipementOrder(): any {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
    return this.http.get(this.URL + 'clubs/' + this.clubId + '/equipment-order', {headers})
      .toPromise()
      .then(response => response);
  }

  postEquipementOrder(obj) {
    return this.http.post(this.URL + 'clubs/' + this.clubId + '/equipment-order', obj)
      .toPromise()
      .then(response => response);
  }

  getSurvey(surveyName): any {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
    return this.http.get(this.URL + 'clubs/' + this.clubId + '/surveys/' + surveyName, {headers})
      .toPromise()
      .then(response => response);
  }

  postSurvey(obj) {
    return this.http.post(this.URL + 'clubs/' + this.clubId + '/surveys', obj)
      .toPromise()
      .then(response => response);
  }


  putClub100Member(id, obj) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
    return this.http.put(this.URL + 'clubs/' + this.clubId + '/club100/' + id, obj, {headers})
      .toPromise()
      .then(response => response);
  }

  getClub100Members(): any {
    return this.http.get(this.URL + 'clubs/' + this.clubId + '/club100/members', )
      .toPromise()
      .then(response => response);
  }

  getReferenceStatus(ref): any {
    return this.http.get(this.URL + 'clubs/' + this.clubId + '/club100/reference/' + ref )
      .toPromise()
      .then(response => response);
  }
}
