<div #container2
     cdkDropList class="example-list" (cdkDropListDropped)="dropEvent($event)">
  <div class="example-box" *ngFor="let user of staff" cdkDrag

  >
    <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
    <ng-container *ngIf="print">{{print | print: user}}</ng-container>
    <ng-container *ngIf="!print">{{user.firstName}} {{user.lastName}} - {{user.function}}</ng-container>
  </div>
</div>
