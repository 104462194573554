import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import * as decode from 'jwt-decode';
import {Subject} from 'rxjs/index';

@Injectable()
export class AuthGuard implements CanActivate {
  private token;


  public isConnected;
  private timeout;
  private role;

  firstname;
  lastname;
  changepage = new Subject();

  constructor(private router: Router) {
    this.token = localStorage.getItem('accessToken');
    this.isConnected = this.token !== null;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.token = localStorage.getItem('accessToken');
    this.isConnected = this.token !== null;
    if (this.token) {
      console.log(this.token);
      try {
        const decoded = decode(this.token);
        const time = decoded.exp * 1000 - new Date().getTime();
        console.log(decoded);
        this.role = decoded.role;
        console.log(time);
        if (this.timeout) {
          clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
          console.log('new timout');
          this.disconnect();
        }, time);
      } catch (e) {
        this.disconnect();
        return false;
      }
      return true;
    }
    this.disconnect();
    return false;
  }

  connect(token, refreshToken) {
    localStorage.setItem('accessToken', token);
    localStorage.setItem('refreshToken', refreshToken);
    this.token = token;
    this.isConnected = this.token !== null;
  }

  disconnect() {
    this.token = null;
    this.router.navigate(['/']);
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    this.isConnected = false;
  }
}
