import {
  AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, Output,
  ViewChild
} from '@angular/core';
import {Router} from '@angular/router';
import {APIService} from '../api';


@Component({
  selector: 'app-edit-group',
  templateUrl: './edit_group.component.html',
  styleUrls: ['./edit_group.component.css']
})
export class EditGroupComponent {
  @Input() data;
  @Output() close = new EventEmitter();
  @ViewChild('scrollMe') private myScrollContainer: any;
  @ViewChild('d') private d: any;
  groups;

  constructor(private api: APIService, private router: Router) {
    this.api.getGroups(this.api.parameters.year, this.api.clubId, false).then((res) => {
      this.groups = res;
    });
  }

  valid(lessonId, groupId, juniorId, group) {
    this.api.addJuniorToGroup(groupId, juniorId).then((res) => {
      this.api.closePopup();
      this.api.updateGroup.next({previousGroup: this.data.group, lesson: lessonId, junior: juniorId, group: res});
    });
  }

  remove(lessonId, juniorId) {
    this.api.removeJuniorToLesson(lessonId, juniorId).then(() => {
      this.api.closePopup();
      this.api.updateGroup.next({previousGroup: this.data.group, lesson: lessonId, junior: juniorId, group: null});
    });
  }
}
