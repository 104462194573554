import {
  AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {Router} from '@angular/router';
import {APIService} from '../../api';
import {ImageCroppedEvent, LoadedImage} from 'ngx-image-cropper';

@Component({
  selector: 'app-club100-member-edit',
  templateUrl: './club100-member-edit.component.html',
  styleUrls: ['./club100-member-edit.component.css']
})
export class Club100MemberEditComponent {
  @Input() action;
  @Input() data;
  @Input() subject;
  @Input() subjectDelete;
  @Output() close = new EventEmitter();
  loaded = true;
  events = [];
  date;
  selectedEvent;
  copyData;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  delete = false;
  imgMouseenter = false;


  constructor(private api: APIService, private router: Router) {

  }

  ngOnInit(){
    this.copyData = {...this.data};
    if (this.copyData.image) {
      this.copyData.image = this.api.URL + this.copyData.image;
    }
    delete this.copyData.id;
  }

  getEvents(){
    this.api.getEvent(null,null,false, this.date ).then(event => this.events = event);
  }

  valid() {
    if(this.imageChangedEvent){
      this.imageChangedEvent = null;
      this.copyData.image =  this.croppedImage;
    }
    else if(this.data.id){
      this.api.putClub100Member(this.data.id, this.copyData).then((res) => {
        this.subject.next(res);
      });
      this.api.closePopup();
    }
    else{
      this.subject.next(this.copyData);
    }
  }

  cancel() {
    this.api.showLoginWindow2 = null ;
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded(image: LoadedImage) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  sendDelete(data) {
    console.log(data);
    console.log(this.subjectDelete);

    this.subjectDelete.next(data);
  }

}
