import { Component, OnInit } from '@angular/core';
import { APIService } from '../api';
import { Router } from "@angular/router";

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss'],
})

export class SurveyComponent implements OnInit {


  constructor(public api: APIService, private router: Router) {

  }
  loaded = true;
  summaryMode: any = null;
  validated = false;
  comment = '';
  mail = '';
  firstname = '';
  lastname = '';
  validations = [];
  options = [
    {
      text: 'Polos',
      textP: 'polos',
      textS: 'polo',
      required: true,
      selected: true,
      choice: 'single',
      sizes: [
        {text: '110-120', selected: false, value: 0},
        {text: '130-140', selected: false, value: 0},
        {text: '150-160', selected: false, value: 0},
        {text: 'XS', selected: false, value: 0},
        {text: 'S', selected: false, value: 0},
        {text: 'M',   selected: false, value: 0},
        {text: 'L', selected: false, value: 0},
        {text: 'XL', selected: false, value: 0},
        {text: 'XXL', selected: false, value: 0},
        {text: 'XS Lady', selected: false, value: 0},
        {text: 'S Lady', selected: false, value: 0},
        {text: 'M Lady', selected: false, value: 0},
        {text: 'L Lady', selected: false, value: 0}
      ]
    },
    {
      text: 'Sweats',
      textP: 'sweats',
      textS: 'sweat',
      required: true,
      selected: true,
      sizes: [
        {text: '10A', selected: false, value: 0},
        {text: '12A', selected: false, value: 0},
        {text: 'S', selected: false, value: 0},
        {text: 'M', selected: false, value: 0},
        {text: 'L', selected: false, value: 0},
        {text: 'XL', selected: false, value: 0},
        {text: 'XXL', selected: false, value: 0},
      ]
    },
    {
      text: 'Gilets (facultatif)',
      textP: 'gilets',
      textS: 'gilet',
      selected: null,
      sizes: [
        {text: 'XXS', selected: false, value: 0},
        {text: 'XS', selected: false, value: 0},
        {text: 'S', selected: false, value: 0},
        {text: 'M', selected: false, value: 0},
        {text: 'L', selected: false, value: 0},
        {text: 'XL', selected: false, value: 0},
        {text: 'XXL', selected: false, value: 0}
      ]
    }
  ];

  static validateEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return email && re.test(String(email).toLowerCase());
  }

  continue() {
    this.validations = [];
    if (!SurveyComponent.validateEmail(this.mail)) {
      this.validations.push('L\'email n\'est pas valide.');
    }
    if (!this.firstname || this.firstname === '') {
      this.validations.push('Le "Nom du junior" ne doit pas être vide.');
    }
    if (!this.lastname || this.lastname === '') {
      this.validations.push('Le "Prénom du junior" ne doit pas être vide.');
    }
    for (const option of this.options) {
      if (option.selected === null) {
        this.validations.push('Une option dans la section "' + option.text + '" doit être cochée.');
      }
      if (option.selected === true) {
        if (option.sizes.filter(x => x.selected).length < 1) {
          this.validations.push('Une taille doit être selectionnée dans la section "' + option.text + '".');
        }
      }
      for (const size of option.sizes) {
        if (!Number.isInteger(size.value) ) {
          this.validations.push('La quantité pour "' + option.textP + ' - ' + size.text + '" n\'est pas valide.' );
        } else if (size.selected && (size.value < 1 || size.value > 9 )) {
          this.validations.push('La quantité pour "' + option.textP + ' - ' + size.text + '" doit être comprise entre 1 et 9.');
        }
      }
    }
    if (this.validations.length === 0) {
      this.summaryMode = this.createSummaryMode();
      this.validations = [];
    }
    console.log(this.options);

  }

  back() {
    this.summaryMode = null;
  }

  confirm() {
    this.loaded = false;
    this.api.postEquipementOrder({firstname: this.firstname, lastname: this.lastname, mail: this.mail, data: this.summaryMode, comment: this.comment}).then((rep: any) => {
      this.loaded = true;
      this.validated = true;
    }).catch(() => {
      this.validations = ['Votre commande n\' a pas pu être validée. Veuillez réessayer.'];
      this.loaded = true;
    });
  }

  ngOnInit() {
    //this.api.getProgramParameters(1).then((res) => {

    //});
  }

  createSummaryMode() {
    let answers = [];
    for (const option of this.options.filter(x=>x.selected)) {
      let selection = option.sizes.filter((x) => x.selected && x.value > 0);
      if (selection.length > 0) {
        answers.push({type: option.text, textP: option.textP, textS: option.textS, selection});
      }
    }
    return answers;
  }

  select(option, size) {
    console.log(option);
    for (let s of option.sizes) {
      if (s !== size) {
        s.value = 0;
        s.selected = false;
      }
    }
    if (size.selected && size.value === 0) {
      size.value = 1;
    } else if (!size.selected && size.value > 0) {
      size.value = 0;
    }
    console.log(option);
  }

  finished() {
    this.router.navigate(['/']);
  }
}
