import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'teebox'
})
export class TeeboxPipe implements PipeTransform {
  transform(teeboxes: any[], args: string): any {
    console.log(args)
    if(teeboxes) {
      return teeboxes.filter(teebox => args === 'true' && teebox.slopeMen || args === 'false' && teebox.slopeWomen);
    }
    return [];
  }
}
