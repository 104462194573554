import { Component, OnInit } from '@angular/core';
import {APIService} from '../api';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  send = false;
  timer = null;
  intervalFunction;
  message = {
    email: null,
    firstname: null,
    lastname: null,
    title: null,
    text: null,
  };
  error;
  success;

  constructor(public api: APIService) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
      this.api.changeTitleEvent.next('Contacts');
  }

  startTimer() {
    if (this.message.firstname) {
      this.message.firstname = this.message.firstname.replace(/ {1,}/g, '');
    }

    if (!this.validateEmail(this.message.email)) {
      this.error = 'L\'email n\'est pas valide.';
    }
    else if (!this.message.firstname || this.message.firstname === '') {
      this.error = 'Le prénom \'est pas valide.';
    }
    else {
      this.error = null;
    }
    console.log(this.error);
    if (this.error) {
      return;
    }
    this.timer = 5;
    this.intervalFunction = setInterval(() => { this.timer--;
      if (this.timer === 0) {
        clearInterval(this.intervalFunction);
      } }, 1000);
  }

  validate() {
    this.api.postMessage(this.message).then(() => {
        this.send = true;
      }
    );
  }

  resetTimer() {
    clearInterval(this.intervalFunction);
    this.timer = null;
  }

  validateEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return email && re.test(String(email).toLowerCase());
  }




}
