<div style="margin:auto;">
  <div class="row" style="margin-left:0; margin-right:0">
      <div class="col-lg-6" style="background:#5e5e5e;color: white; padding: 70px; display: flex; align-items: center">
      <div style="margin: auto">
        <h2>  Oubliez les préjugés et venez essayer. </h2>
      </div>
    </div>
    <div class="col-lg-6" style="background:#e9e9e9;color: black; padding: 70px;display: flex;align-items: center">
      <div style="margin: auto">
        <p style="font-size: 19px; text-align: justify"> Pour rejoindre la section Juniors du Golf de Payerne ou pour toute autre information, nous sommes à votre disposition pour vous répondre. </p>
        <div style="text-align: center"><button routerLink="/contacts" style="background: transparent; border: 1px solid black; border-radius: 5px">Contactez-nous</button></div>
      </div>
      </div>
    </div>
  <div style="padding-left: 85px; padding-right: 85px;">
    <p class="title">Membership Juniors</p>
      <ul>
        <li>Dès 7 ans</li>
        <li>Cotisation annuelle dès 480.- (avec adhésion au club)</li>
        <li>Entrainements hebdomadaires</li>
        <li>Accès libre au terrain d’entrainement</li>
        <li>Accès au parcours (soumis à autorisation)</li>
        <li>Tournois juniors réguliers</li>
        <li>Possibilité d’évolution vers les groupes compétitions</li>
        <li>Stages et entrainements supplémentaires en option</li>
      </ul>
  </div>
</div>
