import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'time'
})
export class TimePipe implements PipeTransform {
  transform(time: any): any {
    const minutes = (time % 60);
    let minutesText = '' + minutes;
    if (minutes <= 9 ) {
      minutesText = '0' + minutes;
    }
    return Math.floor(time / 60) + 'h' + minutesText;
  }
}
