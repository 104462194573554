import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-staff-sort',
  templateUrl: './staff-sort.component.html',
  styleUrls: ['./staff-sort.component.scss']
})
export class StaffSortComponent implements OnInit {
  @Input() staff;
  @Input() subject;
  @Input() print;
  @Output() drop = new EventEmitter();
  constructor() {}

  ngChanges(changes) {
    console.log(changes);
  }

  ngOnInit() {

  }

  dropEvent(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.staff, event.previousIndex, event.currentIndex);
    this.subject.next();
  }

}
