import {AfterViewInit, Component, DoCheck, EventEmitter, OnInit, Output} from '@angular/core';
import {isDefined} from '@angular/compiler/src/util';
import {isUndefined} from 'util';
import {APIService} from '../api';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-smallprogramm',
  templateUrl: './small_programm.component.html',
  styleUrls: ['./small_programm.component.css'],
  animations: [
    trigger('pastille', [
      state('notselected', style({
        width: '*',
      })),
      state('selected',   style({
        width: '100%',
        height: '100%',
        left:0,
        top:0,
        'border-radius':0
      })),
      transition('notselected => selected', animate('100ms ease-in')),
      transition('selected => notselected', animate('100ms ease-out'))
    ])
  ]
})
export class SmallProgrammComponent implements AfterViewInit {
  @Output() unavailable = new EventEmitter()

  month_LIST = [ 'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre' , 'Octobre', 'Novembre', 'Décembre' ];
  day_LIST = [ 'Di.', 'Lu.', 'Ma.', 'Me.', 'Je.', 'Ve.', 'Sa.'];

  nbMonth = 12;
  numbers;
  date;
  loaded = false;
  editMode = false;
  juniorId;
  nbEvents = 0;

  ngAfterViewInit() {
    console.log('test')
    const metaTag = document.createElement('meta');
    metaTag.name = 'viewport'
    metaTag.content = 'initial-scale=1.0'
    document.getElementsByTagName('head')[0].appendChild(metaTag);
  }

  constructor(private api: APIService, private router: ActivatedRoute) {
    this.juniorId = localStorage.getItem('userId');
    this.router.queryParams.subscribe(params => {
      if (params.edit === 'true') {
        this.editMode = true;
      }
      if (params.junior_id) {
        this.juniorId = +params.junior_id;
      }
    });

    this.getEvent();

  }

  getEvent() {
    this.api.getEvent(this.api.parameters.year, null, true).then(res => {
      if (res === undefined) {
        return;
      }
      this.loaded = true;
      this.date = [];

      for (let i = 1; i <= this.nbMonth; i++) {
        this.date.push({days: new Date(this.api.parameters.programYear, i, 0).getDate(), events: []}); // read year in database
      }
      this.nbEvents = res.length;
      for (const event of res) {
        if (this.editMode) {
          if (!event.selected) {
            event.state = 'notselected';
          }
          else {
            event.state = 'selected';
          }
        }
        const d = new Date(event.date);
        let i = 0;
        let m = 0;
        let c = 0;
        do {
          if ((d.getDate() + i) > this.date[d.getMonth() + m].days) {
            m++;
            i = -d.getDate() + 1;
          }
          if (isUndefined(this.date[d.getMonth() + m][d.getDate() + i])) {
            this.date[d.getMonth() + m][d.getDate() + i] = {events: []};
          }
          this.date[d.getMonth() + m][d.getDate() + i].events.push(event);
          this.date[d.getMonth() + m].last = d.getDate();


          this.date[d.getMonth() + m][d.getDate() + i].size = this.date[d.getMonth() + m][d.getDate() + i].events.length <= 2 ? 2 : this.date[d.getMonth() + m][d.getDate() + i].events.length;
          i++;
          c++;
        } while (event['endDate'] && new Date(event['endDate']) >= this.addDays(d, c));
      }
      //TODO:error events are in events of this.date
    }).catch(() => this.unavailable.next());
  }

  addDays = function(date, days) {
    let d = new Date(date);
    d.setDate(date.getDate() + days);
    return d;
  };

  setSize(month, day) {
    let i = 1;
    while (!isUndefined(this.date[month][day + i])) {
      i++;
    }
    if (day + i > this.date[month].days) {
      i = -1;
      while (!isUndefined(this.date[month][day + i])) {
        i--;
      }
    }
    if (isUndefined(this.date[month][day + i])) {
      this.date[month][day + i] = {events: []};
    }
    this.date[month][day + i].size = 1;

  }

  array(number) {
    return Array(number).fill(0).map((x, i) => i + 1); // [0,1,2,3,4]
  }

  getDay(month, day) {
    const d = new Date();
    d.setFullYear(this.api.parameters.programYear, month, day);
    return d.getDay();
  }

  isWeekEnd(month, day) {
    const d = new Date();
    d.setFullYear(this.api.parameters.programYear, month, day);
    return d.getDay() === 0 || d.getDay() === 6;
  }

  animateEvent(event) {
    if (event.state === 'selected') {
      event.state = 'notselected';
      this.api.deletePlanification(event.id, this.juniorId).then(res => {});
    }
    else if (event.state === 'notselected') {
      event.state = 'selected';
      this.api.postPlanification(event.id, this.juniorId).then(res => {});
    }

  }

  countSelected(x) {
    //var x.filter(d => d.state === 'selected');
    //return x.filter(d => d.state === 'selected').size;
  }

}
