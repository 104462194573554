import { Component, OnInit } from '@angular/core';
import {APIService} from '../api';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
  news = [];
  loaded = false;

  constructor(public api: APIService) {
  }

  ngOnInit() {
    this.loaded = false;
    this.api.getNews().then((news) => {this.news = news; this.loaded = true;});
  }

}
