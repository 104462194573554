import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.css'],
})

export class DatePickerComponent implements OnChanges {
  @Input() date = '';

  days = Array.from({ length: 31 }, (x, i) => (i + 1).toString());
  months = Array.from({ length: 12 }, (x, i) => (i + 1).toString());
  years = Array.from({ length: 24 }, (x, i) => (2024 - i).toString());

  day;
  month;
  year;

  ngOnChanges(changes) {
    if (changes.date){
      const split = this.date.split('/');
      this.day = split[0].toString();
      this.month = parseInt(split[1], 10).toString();
      this.year = split[2].toString();
    }
  }
}
