import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-sponsorcard',
  templateUrl: './sponsorcard.component.html',
  styleUrls: ['./sponsorcard.component.scss']
})
export class SponsorcardComponent implements OnInit {
  @Input() image;
  @Input() text;
  constructor() { }

  ngChanges(changes){
    console.log(changes);
  }

  ngOnInit() {
  }

}
