
<div style="margin:auto;">

  <!--<div class="padding-lr">
    <div class="document">
      <div (mouseenter)="mouseover = 1" (mouseleave)="mouseover = null" [ngClass]="{'over':mouseover===1}"  style="display:flex; align-items:center; border: solid 1px black;" (click)="diaporama()">
        <img [ngClass]="{'over':selected}" src="assets/IMG-20190428-WA0001.jpg" style="padding: 10px; object-fit: cover;max-width: 150px; min-width: 150px;max-height: 150px;min-height: 150px" />
        <span class="title">Diaporama Charmeil 2019</span>
    </div>
    </div>
  </div>-->
  <div style="margin-top: 20px" class="padding-lr">
    <div class="document">
      <div (mouseenter)="mouseover = 2" (mouseleave)="mouseover = null" [ngClass]="{'over':mouseover===2}"  style="display:flex; align-items:center; border: solid 1px black;" (click)="showLightbox(0)">
        <img [ngClass]="{'over':selected}" [src]="images[0].url" style="padding: 10px; object-fit: cover;max-width: 150px; min-width: 150px;max-height: 150px;min-height: 150px" />
        <span class="title">Diverses photos de groupe</span>
      </div>

      <ng-image-fullscreen-view
        [images]="imageObject"
        [imageIndex]="selectedImageIndex"
        [show]="showFlag"
        (close)="closeEventHandler()"></ng-image-fullscreen-view>
    </div>
  </div>
</div>



<ngx-image-gallery
  [images]="images"
  [conf]="conf"
  (onOpen)="galleryOpened($event)"
  (onClose)="galleryClosed()"
  (onImageClicked)="galleryImageClicked($event)"
  (onImageChange)="galleryImageChanged($event)"
  (onDelete)="deleteImage($event)"
></ngx-image-gallery>
