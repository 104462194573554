<div *ngIf="!loaded"
     style="width: 100%;height: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center">
  <div style="flex: 1 1 40%">
  </div>
  <div *ngIf="!loaded" class="text-center">
    <i class="fas fa-circle-notch fa-spin fa-7x"></i>
  </div>
  <div style="flex: 1 1 60%">
  </div>
</div>

<div *ngIf="loaded">
  <hr/>


  <div *ngIf="!validated && !summaryMode" style="padding-left:10px; padding-right:10px">

    <div><input type="email" id="input" class="input mb-1 email" style="padding:6px;width:100%;border: 1px solid gray"
                [(ngModel)]="mail" placeholder="Email du ou de la représentant·e légal·e"></div>

    <div style="margin-bottom: 20px"><input type="text" class="lastname"
                style="" [(ngModel)]="lastname"
                placeholder="Nom de famille du ou de la junior·e">
      <input [(ngModel)]="firstname" style="" type="text"
                                                   class="mb-3 firstname" placeholder="Prénom du ou de la junior·e"></div>


    <div *ngFor="let option of options">
        <ng-container *ngIf="option.visible != false">
        <h4 [innerHTML]="option.text"></h4>
          <ng-container *ngIf="option.answer && option.answer.type=='radio'">
          <div *ngFor="let choice of option.answer.choices">
            <label style="cursor: pointer" [for]="option.id + '_' + choice.id">
              <div style="display: inline-flex">
                <input style="cursor:pointer; display: inline-grid" [(ngModel)]="option.value" (ngModelChange)="checkConditions()" [value]="choice.id" type="radio"
                       [id]="option.id + '_' + choice.id" [name]="option.id">
              <div style="display: inline-flex; margin-left: 10px">{{choice.text}}</div>
              </div>
            </label>
          </div>
          </ng-container>
          <ng-container *ngIf="option.answer && option.answer.type=='textarea'">
            <div><textarea rows="3" style="padding:6px;height:100px;width: 100%" type="text" [placeholder]="option.answer.placeholder !== undefined ? option.answer.placeholder : ''" [(ngModel)]="option.value"></textarea></div>
          </ng-container>
          <ng-container *ngIf="option.answer && option.answer.type=='time'">

            <div>
              <input [placeholder]="option.value ? '' : 'Heure'" type="time" [disabled]="option.answer.nullSelected" [(ngModel)]="option.value"  />
              <div style="padding-top: 10px">
              <label style="cursor: pointer" [for]="option.id">
                <input style="cursor:pointer; display: inline-grid" [(ngModel)]="option.answer.nullSelected" (ngModelChange)="checkOption(option)" [value]="option.answer.nullSelected" type="checkbox"
                       [id]="option.id" [name]="option.id">
                <div style="display: inline-flex; margin-left: 10px">{{option.answer.nullText}}</div>
              </label>
              </div>
              </div>
          </ng-container>
            <div style="height: 30px;"></div>
        </ng-container>
    </div>

    <div style="text-align: center">
      <div *ngFor="let validation of validations" style="color:red">
        <div *ngIf="validation !== validations[0]" style="margin: auto; width: 50px; border-bottom: 1px solid red"></div>
        <div style="padding: 10px">{{validation}}</div>
      </div>
      <button style="margin:10px" class="buttonLink" (click)="continue()">Valider les réponses</button>
    </div>
  </div>

  <div *ngIf="!validated && summaryMode" style="padding-left:10px; padding-right:10px">
    <h4>Confirmation de commande</h4>
    <div *ngIf="summaryMode.length==0" style="text-align:center; font-size: 20px;">
      Aucun article commandé
    </div>
    <div *ngFor="let type of summaryMode">
      <div *ngFor="let selection of type.selection" style="min-width:300px; padding-left:20px">
        <div style="text-align:center; font-size: 20px;" *ngIf="selection.value > 0">
        </div>
      </div>
    </div>
    <div *ngIf="comment!=''"><span>Votre commentaire : <span style="font-style: italic">{{comment}}</span></span></div>
    <div style="text-align: center; margin-top:40px">

      <div *ngFor="let validation of validations" style="color:red">
        <div>skkd</div>
        <div *ngIf="validation === validations[validations.length-1]">dd·</div>
        <div>{{validation}}</div>
      </div>
      <button style="margin-right: 20px" class="buttonLink" (click)="back()">Retour sans confirmer</button>
      <button class="buttonLink" (click)="confirm()">Confirmer</button>
    </div>
  </div>
</div>

<div *ngIf="validated"
     style="width: 100%;height: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center">
  <h2 style="text-align: center">Merci ! Votre réponse a été enregistrée.</h2>
  <div style="margin-top: 10px">
    <button class="buttonLink" (click)="finished()">Terminer</button>
  </div>
</div>
