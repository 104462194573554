
<script src="../../../dist/golfjuniorspayerne/main-es2015.5ae210c48e9f9e76bf94.js"></script>
<div *ngIf="error || !loaded" style="width: 100%;height: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center">
  <div style="flex: 1 1 40%">
  </div>
    <div *ngIf="error" style="white-space:pre-wrap; margin-right:20px; margin-left:20px; padding:20px; box-shadow: 0px 0px 16px 1px rgba(0,0,0,0.25);font-size: 25px; color: gray">{{error.error.message}}
    </div>
  <div *ngIf="!loaded" class="text-center">
    <i class="fas fa-circle-notch fa-spin fa-7x"></i>
  </div>
  <div style="flex: 1 1 60%">
  </div>
</div>

<div *ngIf="loaded && !error" style="position:relative">

<div *ngIf="loaded">
  <ng-container *ngIf="isEditable && !num">
    <div style="display:flex; justify-content: space-between; margin-right: 20px; margin-left: 20px; margin-top: 0; background-color: white">
      <div style="width:100%"></div>
      <div class="select small2" style="float:right; margin-top:1px; padding: 10px;">
        <select [(ngModel)]="selected" (ngModelChange)="getResult()" class="custom-select"  style=" text-align: center" name="year" id="orderOfMerite">
          <option value="0">Actuels</option>
          <option value="archived">Archivés</option>
        </select>
        <div class="select_arrow">
        </div>
      </div>
      <div style="display: flex;align-items: center">
        <app-action-button [icon]="'add'" (click)="addLesson()"></app-action-button>
      </div>
    </div>
    <div style="text-align: center; display:flex;"><div style="font-size:20px; border-radius: 4px; border: 1px solid black; margin:10px; width:33.33%; padding: 10px" *ngFor="let lesson of lessons" (click)="change(lesson.id)" [ngClass]="{'selected':num===lesson.id,'notselected':num!==lesson.id}">{{lesson.name}}
      <div>
      <img style="width: 30px" [src]="lesson.visible ? 'assets/visible.svg':'assets/unvisible.svg'">
    </div>
    </div>
    </div>
    <app-list-juniors *ngIf="selected==='0'" [sorting]="'birthday'" [type]="'groups'" [category]="['baby']" [fields]="['lastName','firstName']"></app-list-juniors>
  </ng-container>
  <div *ngIf="!isEditable || num" style="overflow-y: hidden; text-align: center">
    <ng-container *ngIf="isEditable">
      <h2>{{l.name}}</h2>
      <!--<div style="display: flex;width: 100%;align-items: center"><div style="font-weight:bold;text-align:right;padding-right:20px;width: 200px">Nom du cours</div><app-timed-input *ngIf="l" [(text)]="l.name"></app-timed-input></div>-->
      <div style="margin-bottom:7px; display: flex;width: 100%;align-items: center">
        <div style="font-weight:bold;text-align:right;padding-left:20px;padding-right:20px" *ngIf="l.archived">Archivé</div>

        <ng-container *ngIf="!l.archived">
        <div style="font-weight:bold;text-align:right;padding-left:20px;padding-right:20px;">Publié</div>
          <div  style="text-align: center">
            <label class="switch">
              <input type="checkbox" [(ngModel)]="l.visible" (ngModelChange)="postVisible(l.id)">
              <span class="slider round"></span>
            </label>
          </div>
        </ng-container>
        <input type="text"  *ngIf="false && l.visible" [value]="'http://juniorsgolfpayerne.ch/groupes/'+l.abbr">
        <div style="width: 100%"></div>
        <div style="gap:3px; margin-right:20px; text-align: right;float: right;display: flex">
          <app-action-button *ngIf="!l.archived"  [icon]="'swap_vert'" (click)="sort(groups)"></app-action-button>
          <app-action-button [icon]="'mode_edit'" (click)="editLesson()" ></app-action-button>
          <app-action-button *ngIf="!l.archived"  [icon]="'add'" (click)="editGroup()"></app-action-button>
        </div>

      </div>
    </ng-container>

    <div *ngIf="!isEditable" style="text-align: center"><p style="display:inline-block;background: #e9e9e9; margin:10px; padding: 10px; border-radius:10px">Dans la mesure du possible, les juniors sont priés de venir s'échauffer au minimum 15 minutes avant le début de leurs cours.</p></div>
    <div *ngIf="l && l.datesLinked && l.dates && l.dates.length>0" style=" justify-content: center; flex-wrap: wrap; display:flex; gap:10px;margin-left:20px;margin-right: 20px">
      <ng-container style="" *ngFor="let d of l.dates; let i = index">
        <div style="font-size:14px; background: #eeeeee; padding: 3px 9px;border-radius: 34px">
        {{d.date | date}}<ng-container *ngIf="d.info"> ({{d.info}})</ng-container>
          <!--<span style="margin-left:10px" *ngIf="i<l.dates.length-1">&bull;</span>-->
        </div>
      </ng-container>
    </div>

    <div style=" display: flex" class="groups">
      <div class="group"  *ngFor="let group of groups">

        <div style="height: 100%; padding-bottom: 10px" [ngStyle]="{'background-color':group.color?group.color:null,'border':group.color?null:'1px solid black'}">
          <div (click)="editGroup(group)" style="position: relative" (mouseenter)="groupMouseover=group.id" (mouseleave)="groupMouseover=null">
            <div (click)="$event.preventDefault()" style="display: flex;align-items: center;justify-content: center;top:0; left:0;position:absolute;width:100%; height:100%;background: #FFFFFFAA;"
                 *ngIf="isEditable===true && !l.archived && groupMouseover===group.id">
              <app-action-button [icon]="'mode_edit'" ></app-action-button>
            </div>
            <div style="padding-top:10px; line-height:1.1;font-size:30px;font-weight: 700">{{group.name}}</div>
            <div style="font-size:18px;padding-bottom:6px;font-weight: 600" *ngIf="group.timeStart!==null"> {{group.timeStart | time}} - {{group.timeEnd | time}}</div>
            <div style="margin-bottom: 10px;"><div *ngFor="let event of group.dates" [ngClass]="{'cancelled':event.cancelled}"  style="font-size:13px; font-weight: 300">{{event.date | date}} <small>({{event.date | date:'EEEE'}})</small></div></div>
            <div *ngFor="let monitor of group.monitors" class="monitor">{{monitor}}</div><hr  style="margin-left:30px; margin-right:30px;margin-top: 5px; margin-bottom: 5px">
          </div>
          <div *ngIf="false && num==1" class="document"><app-document [file]="'docs/programme_'+group.name.split(' ')[1].toLowerCase()+'.pdf'" [title]="'Programme'" [small]="true"></app-document></div>
          <hr  *ngIf="false && num==1" style="margin-left:30px; margin-right:30px;margin-top: 5px; margin-bottom: 5px">
          <div class="junior" *ngFor="let junior of group.juniors">{{junior.lastName + " " + junior.firstName}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
