<div *ngIf="error || !loaded" style="width: 100%;height: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center">
  <div style="flex: 1 1 40%">
  </div>
  <div *ngIf="error" style="white-space:pre-wrap; margin-right:20px; margin-left:20px; padding:20px; box-shadow: 0px 0px 16px 1px rgba(0,0,0,0.25);font-size: 25px; color: gray">{{error.error.message}}
  </div>
  <div *ngIf="!loaded" class="text-center">
    <i class="fas fa-circle-notch fa-spin fa-7x"></i>
  </div>
  <div style="flex: 1 1 60%">
  </div>
</div>

<div *ngIf="loaded && !error" style="margin:auto;">

<div *ngIf="isEditable" style="text-align: right">
  <div style="display: flex;width: 100%;align-items: center"><div style="font-weight:bold;text-align:right;padding-left:20px;padding-right:20px;">Publié</div>
    <div style="text-align: center">
      <label class="switch">
        <input type="checkbox" [(ngModel)]="api.managersActivated" (ngModelChange)="postVisible()">
        <span class="slider round"></span>
      </label>
    </div>

    <div style="width: 100%;text-align: right;float: right">
      <a (click)="edit(null)" class="edit btn-floating waves-effect waves-light" style=" margin-right:20px; margin-bottom:20px; background-color: #F44336 !important">
        <i class="material-icons">swap_vert</i></a>
      <a (click)="add(null)" class="edit btn-floating waves-effect waves-light" style=" margin-right:20px; margin-bottom:20px; background-color: #F44336 !important">
        <i class="material-icons">add</i></a>

    </div>

  </div>



</div>
  <div style="padding-left: 85px; padding-right: 85px;">
    <div style="display:flex; justify-content: center; flex-wrap:wrap; margin: auto">
    <div *ngFor="let user of staff" style="width: 350px; padding:5px;" class="mb-3">
      <app-staff-card [user]="user" [isEditable]="isEditable" (delete)="deleteManager($event)"></app-staff-card>
    </div>
  </div>
  </div>
</div>
