import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'print'
})
export class PrintPipe implements PipeTransform {
  transform(print: any, group: any): any {
    const matches = print.match(/\{{(.*?)\}}/g);
    if (matches) {
      for (const match of matches) {
        const variable = match.substring(2, match.length - 2);
        if (variable.startsWith('data.')) {
          const attribute = variable.replace('data.', '');
          print = print.replace(new RegExp(match, 'g'), group[attribute] === null ? '' : group[attribute]);
        }
      }
    }
    console.log('s')
    return print;
  }
}
