import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {APIService} from '../../api';
import {Router} from "@angular/router";
import {Subject} from "rxjs";

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.css'],
})
export class BoardComponent {
@Input() data ;
@Input() icon ;
@Output() select = new EventEmitter();

selectedRow = null;

  constructor(private api: APIService) {
  }


  click(clickRow) {
    console.log(clickRow);
    this.select.emit(clickRow);
  }
}
