import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-edit-field',
  templateUrl: './edit-field.component.html',
  styleUrls: ['./edit-field.component.css'],
})

export class EditFieldComponent implements OnInit {
  @Input() top = '30px';
  @Input() field;
  @Input() answer;
  @Input() type;
  @Input() editable;


  @Output() textChange = new EventEmitter();

  changedAnswer;

 @ViewChild('element', { static: false }) set content(content: ElementRef) {
   if (content) {
     content.nativeElement.focus();
   }
 }
  ngOnInit(){
    this.changedAnswer = this.answer;
  }

  editMode = false;
  ngChanges(changes) {
    console.log(changes);
  }

  edit() {
    this.editMode = true;
    this.changedAnswer = this.answer;
  }

  close(){
    this.editMode = false;
  }


}
