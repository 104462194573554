import {
  Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-palmares-board',
  templateUrl: './palmares-board.component.html',
  styleUrls: ['./palmares-board.component.css']
})

export class PalmaresBoardComponent implements OnChanges, OnInit {
  @Input() data;
  @Output() selection = new EventEmitter()



  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.data){
      console.log('data!');
    }
  }

  init(d, a) {
    const IMAGE_RATIO = 1.54074;
    let ratio = a.clientWidth / d.clientWidth;
    let tranformX = -d.clientWidth / 2 + a.clientWidth / 2;
    let transformY = -(d.clientWidth * IMAGE_RATIO) / 2 + a.clientWidth * IMAGE_RATIO / 2
    d.style.transform = 'translateX(' + tranformX + 'px) translateY(' + transformY + 'px)  scale( ' + ratio + ')';

  }

  selected = null

  clickoutside(d, a) {
    this.init(d, a);
    if(this.selected){
      setTimeout(() => {
        this.selection.emit(null);
        d.style.zIndex = null;
      }, 1000);

    }

    setTimeout(() => {
      this.selected = null;
    }, 500);
    setTimeout(() => {
    }, 1000);
  }

  bigger(d, a) {
    if (this.selected) {
      this.init(d, a);

      this.selected = null;
      setTimeout(() => {
        this.selection.emit(null);
        d.style.zIndex = null;
      }, 1000);
    } else {
      let max = Math.min(0.8, window.innerWidth / d.clientWidth);
      let trans = -(100 - (max * 100)) / 2;
      const x = window.innerWidth / 2 - d.clientWidth / 2 - d.offsetLeft;
      d.style.zIndex = '3';
      d.style.transition = 'transform 1s';
      d.style.transform = 'translateX(' + x + 'px) translateY(' + trans + '%) scale(' + max + ')';
      this.selected = 'a';
      this.selection.emit(d);
    }
  }

}
