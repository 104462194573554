import {AfterViewInit, Component, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {isUndefined} from 'util';
import {APIService} from '../api';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ActivatedRoute} from '@angular/router';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-program',
  templateUrl: './program.component.html',
  styleUrls: ['./program.component.css'],
  animations: [
    trigger('pastille', [
      state('notselected', style({
        width: '*',
      })),
      state('selected',   style({
        width: '100%',
        height: '100%',
        left:0,
        top:0,
        'border-radius':0
      })),
      transition('notselected => selected', animate('100ms ease-in')),
      transition('selected => notselected', animate('100ms ease-out'))
    ])
  ]
})
export class ProgramComponent implements OnInit, AfterViewInit {
  @Input() editable = false;
  offset = 0;
  events = [];
  categories = [{name: 'Juniors - Payerne',
    categories: [
      {id: 3, name: 'Compétition 9/18 trous', countOM: true, color: '#c4d69e', activated: true},
      // {id: 8, name: 'Compétition 9 trous', countOM: true, color: '#ebf1df', activated: true},
      {id: 15, name: 'Compétition "fun"', countOM: true, color: '#fed534', activated: true},
      {id: 11, name: 'Equipe', countOM: true, color: '#ff7f50', activated: true},
      {id: 5, name: 'Camp', countOM: true, color: '#daa520', activated: true},
      {id: 7, name: 'Cours', countOM: true, color: '#c4bc99', activated: true},
      {id: 6, name: 'Autre activité', countOM: true, color: '#d99695', activated: true},
    ]},
    {name: 'Juniors - Externe',
      categories: [
        {id: 16, name: 'Swiss Golf U12', countOM: true, color: '#ccc1d9', activated: true},
        {id: 17, name: 'Swiss Golf U14', countOM: true, color: '#ccc1d9', activated: true},
        {id: 2, name: 'Swiss Golf U18 - U16', countOM: true, color: '#ccc1d9', activated: true},
        {id: 21, name: 'Swiss Golf U25', countOM: true, color: '#ccc1d9', activated: true},
        {id: 10, name: 'Challenge Romand', countOM: true, color: '#c1d9d3', activated: true},
        {id: 22, name: 'Golf4Girls', countOM: true, color: '#f1dcdb', activated: true},
      ]},
    {name: 'Adultes',
      categories: [
        {id: 18, name: 'Compétition 18 trous "Juniors" intégrés', countOM: true, color: '#8eb5e0', activated: true},
        // {id: 1, name: 'Swiss Golf Adulte WAGR', countOM: false, color: '#a494ba', activated: false},
        // {id: 4, name: 'Compétition Payerne 18 trous', countOM: false, color: '#8eb5e0', activated: false},
        // {id: 13, name: 'Compétition Payerne 9 trous', countOM: false, color: '#dce6f0', activated: false},
        // {id: 14, name: 'Compétition Payerne "fun"', countOM: false, color: '#fdea90', activated: false},
      ]}

  ];
  month_LIST = [ 'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre' , 'Octobre', 'Novembre', 'Décembre' ];
  day_LIST = [ 'D', 'L', 'M', 'M', 'J', 'V', 'S'];
  doneSelected = false;
  nbMonth = 12;
  date;
  loaded = false;
  editMode = false;
  juniorId;
  error;
  today;
  checkbox;
  selectedYear;
  monthWidth;
  cols = 12;
  yearWidth;
  ready = false;
  mouseover = null;
  eventMouseover = null;

  mouseover2 = null;
  scroll = 0;
  programLoaded = false;
  @ViewChild('program') private programContainer: any;
  @ViewChild('select') private select: any;

  @HostListener("window:scroll", [])
  onWindowScroll() {
    if (this.programContainer) {
      this.computeArrow();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.computeArrow();
    this.load();
  }

  ngOnInit() {
    this.api.getProgramParameters(1).then((res) => {
      this.checkbox = res.spread;
    });
    this.selectedYear = !this.editable ? this.api.parameters.programYear : this.api.parameters.year;
  }

  findCategory(id) {
    for (const c of this.categories) {
      const a = c.categories.find(x => x.id === id);
      if (a) {
        return a;
      }
    }
    return null;
  }

  ngAfterViewInit() {
    if (this.editable) {
      this.api.changeTitleEvent.next('Gestion du programme');
    } else {
      this.api.changeTitleEvent.next('Programme ' + this.api.parameters.programYear);
    }
    this.computeCols(window.innerWidth - 20);
  }

  constructor(public api: APIService, private router: ActivatedRoute) {
    this.today = this.addDays(new Date(),-1);
    console.log('test');


    this.juniorId = localStorage.getItem('userId');
    this.router.queryParams.subscribe(params => {
      if (params.edit === 'true') {
        this.editMode = true;
      }
      if (params.junior_id) {
        this.juniorId = +params.junior_id;
      }
      if (params.categories) {
        const numbersArray = params.categories.split(',').map(Number);
        this.categories.forEach(category => {
          category.categories.forEach(subcategory => {
            subcategory.activated = numbersArray.includes(subcategory.id);
          });
        });

      }
    });

    setTimeout(() => {
      this.getEvent();
    }, 1000);

  }

  getEvent(selectedYear = null) {
    this.api.getEvent(selectedYear ? selectedYear : !this.editable ? this.api.parameters.programYear : this.api.parameters.year, this.editable).then(res => {
      if (res === undefined) {
        return;
      }
      /*let sorted = res.sort((a,b)=>{
        if (a.categoryId > b.categoryId) return 1;
        else if (a.categoryId === b.categoryId) return 0;
        else return -1;
      })*/

      this.events = res;
      this.reload();
    }).catch((error) => {
      console.log(error);
      this.error = error;
    }).finally(() => {
      this.loaded = true;
    });
  }

  addDays = function(date, days) {
    let d = new Date(date);
    d.setDate(date.getDate() + days);
    return d;
  };


  computeSize() {
    for (const [month, monthEvents] of this.date.entries()) {
      try {
        for (const day of Object.keys(monthEvents)) {
          const size = this.date[month][day].events.length;
          if (size <= 1) {
            this.date[month][day].size = 2;
          }
          if (size > 1) {
            this.date[month][day].size = size;
          }
        }
      }
      catch (e) {
        //todo: put events in events fields and remove this catch
      }
    }

      for (const [month, monthEvents] of this.date.entries()) {

      try {
        for (const day of Object.keys(monthEvents)) {
          let size = this.date[month][day].events.length;

          let startDay = parseInt(day, 10) + 1;
          let startDay2 = parseInt(day, 10) - 1;
          let startDay3 = parseInt(day, 10) + 1;
          let startDay4 = parseInt(day, 10) - 1;

          let finished = false;
          while (size - 2 > 0 && !finished) {
            if (startDay < this.date[month].days + 1){
              if (isUndefined(this.date[month][startDay])){
                this.date[month][startDay] = {events: []};
                this.date[month][startDay].size = 1;
                size--;
              }
              startDay ++;
            }
            else if(startDay2 > 1) {
              if (isUndefined(this.date[month][startDay2])){
                this.date[month][startDay2] = {events: []};
                this.date[month][startDay2].size = 1;
                size--;
              }
              startDay2--;
            }
            else if(startDay3  < this.date[month].days + 1) {
              if (this.date[month][startDay3].events.length === 1 && this.date[month][startDay3].size === 2 ){
                this.date[month][startDay3].size = 1;
                size--;
              }
              startDay3++;
            }
            else if(startDay4 > 1) {
              if (this.date[month][startDay4].events.length === 1 && this.date[month][startDay4].size === 2 ){
                this.date[month][startDay4].size = 1;
                size--;
              }
              startDay4--;
            }
            else {
              finished= true;
            }
          }
        }
      }
      catch (e) {
       //todo: put events in events fields and remove this catch
      }
    }
  }

  setSize(month, day) {
    if (this.date[month][day].events.length > 2 || this.date[month][day].size === 1) {
      let i = 1;
      while (!isUndefined(this.date[month][day + i])) {
        i++;
      }
      if (day + i > this.date[month].days) {
        i = -1;
        while (!isUndefined(this.date[month][day + i])) {
          i--;
        }
      }
      if (isUndefined(this.date[month][day + i])) {
        this.date[month][day + i] = {events: []};
      }

      this.date[month][day + i].size = 1;

    }
    this.date[month][day].size = this.date[month][day].events.length <= 2 ? 2 : this.date[month][day].events.length;
    let i = 0;
    if (day + i < 1) {
      i = 0;
      while (!isUndefined(this.date[month][day + i]) && this.date[month][day + i].events.length < this.date[month][day + i].size) {
        i++;
      }
      this.date[month][day + i].size = 1;
    }

  }

  array(number) {
    return Array(number).fill(0).map((x, i) => i + 1); // [0,1,2,3,4]
  }

  getDay(month, day) {
    const d = new Date();
    d.setFullYear(this.selectedYear, month, day);
    return d.getDay();
  }

  isWeekEnd(month, day) {
    const d = new Date();
    d.setFullYear(this.selectedYear, month, day);
    return d.getDay() === 0 || d.getDay() === 6;
  }

  animateEvent(event) {
    if (event.state === 'selected') {
      event.state = 'notselected';
      this.api.deletePlanification(event.id, this.juniorId).then(res => {});
    }
    else if (event.state === 'notselected') {
      event.state = 'selected';
      this.api.postPlanification(event.id, this.juniorId).then(res => {});
    }

  }

  public reload() {
    console.log('reload');
    this.date = [];

    for (let i = 1; i <= this.nbMonth; i++) {
      this.date.push({done: new Date(this.selectedYear, i, 0) <= this.today, days: new Date(this.selectedYear, i, 0).getDate(), events: []});
    }

    console.log(this.categories)
    for (const event of this.events) {
      const category = this.findCategory(event.categoryId);
      if (category && category.activated) { // doesn't treat event if filter is not selected;

        // add attribute 'state' if not selected
        if (this.editMode) {
          if (!event.selected) {
            event.state = 'notselected';
          } else {
            event.state = 'selected';
          }
        }

        const d = new Date(event.date);

        let i = 0;
        let m = 0;
        let c = 0;

        do {
          if ((d.getDate() + i) > this.date[d.getMonth() + m].days) {
            m++;
            i = -d.getDate() + 1;
          }
          if (isUndefined(this.date[d.getMonth() + m][d.getDate() + i])) {
            this.date[d.getMonth() + m][d.getDate() + i] = {events: []};
          }
          this.date[d.getMonth() + m][d.getDate() + i].events.push(event);
          if (!this.date[d.getMonth() + m].last || this.date[d.getMonth() + m].last < d.getDate() + i) {
            //console.log(this.date[d.getMonth() + m].last);
            this.date[d.getMonth() + m].last = d.getDate() + i;
          }
          i++;
          c++;
        } while (event['endDate'] && new Date(event['endDate']) >= this.addDays(d, c));
      }
    }

    this.computeSize();
    console.log('fin Compute');
  }


  toggle2(category) {
    category.activated = !category.activated;
    this.reload();
  }

  edit() {
    const subject = new Subject();
    subject.subscribe(() => this.reload());
    setTimeout(() => {
      this.api.openPopup({type: 'filter', data: this.categories, subject});
    }, 0);
  }

  isDone(month, day)
  {
    return new Date(this.api.parameters.programYear, month, day) < this.today;
  }

  addEvent(event = null, date = null) {
    const subject = new Subject();
    subject.subscribe((data: any) => {
      this.api.postEvent(data).then(() => {
        const year = data.date.substring(0, 4);
        this.getEvent(year);
        this.selectedYear = year;
      });
      this.api.closePopup();
    });
    const subjectDelete = new Subject();
    subjectDelete.subscribe((data: any) => {
      this.api.deleteEvent(data.id).then(() => {
        this.getEvent(data.date.substring(0, 4));
      });
      this.api.closePopup();
    });
    this.api.showLoginWindow2 = {type: 'edit_program', subject, subjectDelete };
    this.api.showLoginWindow2.data = event ? event : {date:date};
    document.body.classList.add('noScroll');
  }

  changeYear() {
    console.log(this.selectedYear);
    console.log(this.api.parameters);
    this.loaded = false;
    this.programLoaded = false;
    this.getEvent(this.selectedYear);
    this.selectedYear = parseInt(this.selectedYear);
    this.scroll = 0;
  }

  update(e) {
    if (this.editable) {
      this.addEvent(e);
    }
  }

  updateParameters(param) {
    console.log(parseInt(param.year, 10));


    this.api.patchParameters(1, param).then(() => {
    }).catch(() => {
    }).finally(() => {
    });
  }

  addZero(i) {
    return ('0' + i).slice(-2);
  }

  onScroll($event: Event) {
    console.log(($event.target as HTMLElement).scrollLeft);
  }

  scrollLeft() {
    this.programContainer.nativeElement.scrollLeft = 0;
    this.scroll = 0;
  }

  scrollRight() {
    this.programContainer.nativeElement.scrollLeft = 10000;
    this.scroll = 10;
  }


  load() {
    console.log('loadprog')
    this.computeCols(this.programContainer.nativeElement.clientWidth);
    if (this.selectedYear < this.api.parameters.programYear
      || (this.selectedYear === this.api.parameters.programYear && new Date().getMonth() > 1)) {
      this.scrollRight();
    }
    this.programLoaded = true;
    this.computeArrow();
  }

  private computeCols(elWidth) {
    let w = elWidth - 73;
    const a = Math.min(12, Math.floor(w / 149));
    if (a === 12) {
      w = elWidth;
    }
    this.monthWidth = w / a;
    this.cols = a;
    this.yearWidth = this.monthWidth * 12;
    if (this.programContainer && this.programContainer.nativeElement.scrollLeft > 0) {
      this.programContainer.nativeElement.scrollLeft = 10000;
    }

  }

  private computeArrow() {
    let h = window.innerHeight;
    let top = this.programContainer.nativeElement.getBoundingClientRect().top;
    let top1 = top < 0 ? -top : 0;
    let top2 = top > 0 ? top : 0;
    let bottom = (this.programContainer.nativeElement.getBoundingClientRect().bottom - h);
    let bottom2 = bottom > 0 ? 0 : -bottom;
    this.offset = top1+((h-top2-bottom2)/2)-50;
    if(this.offset<0){
      this.offset = 0;
    }
    if(this.offset>this.programContainer.nativeElement.getBoundingClientRect().height-100){
      this.offset = this.programContainer.nativeElement.getBoundingClientRect().height-100;
    }
  }

  a(e) {
    e.preventDefault();
    console.log('dd');
    this.select.nativeElement.focus();
    this.select.nativeElement.click();

  }
}
