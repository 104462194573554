<div style="margin:auto;">
  <div class="news-container">
    <ng-container *ngIf="!send">
    <div *ngIf="!error" style="text-align: center"><p style="display:inline-block;background: #e9e9e9; padding: 10px; border-radius:10px">Vous pouvez nous laisser un message au moyen du formulaire suivant.</p></div>
    <div *ngIf="error" style="text-align: center"><p style="display:inline-block;color: #D8000C;
    background-color: #FFBABA;padding: 10px; border-radius:10px">{{error}}</p></div>

    <div>
      <div><input type="email" id="input" class="input mb-1 email" style="border: 1px solid gray" placeholder="Votre email" [(ngModel)]="message.email" (ngModelChange)="resetTimer()"></div>

      <div><input type="text" class="mb-1 email" style="border: 1px solid gray; margin-right:10px" placeholder="Nom" [(ngModel)]="message.lastname" (ngModelChange)="resetTimer()"><input type="text" class="mb-3 firstname" placeholder="Prénom" [(ngModel)]="message.firstname" (ngModelChange)="resetTimer()"></div>

      <div><input class="mb-1" style="width: 100%" type="text" placeholder="Objet de votre message" [(ngModel)]="message.title" (ngModelChange)="resetTimer()"></div>

      <div><textarea style="height:400px;width: 100%" type="text" placeholder="Votre message" [(ngModel)]="message.text" (ngModelChange)="resetTimer()"></textarea></div>
      <button *ngIf="timer===null" (click)="startTimer()">Envoyer le message</button>
      <button *ngIf="timer===0" (click)="validate()">Valider l'envoi du message</button>
      <p *ngIf="timer">Veuillez attendre pour valider votre message : {{timer}} secondes</p>
    </div>
    </ng-container>
  </div>
  <h1 *ngIf="send">Message envoyé. Merci!</h1>
</div>
