<div style="margin:auto;">
  <div class="row" style="margin-left:0; margin-right:0">
    <div class="col-lg-6 info-container" style="background:#5e5e5e;color: white; padding-top: 50px; padding-bottom: 50px;">
  <h1 style="font-size:80px">  MERCI !!! </h1>
  <p style="font-size: 19px; text-align: justify">La juniors remercient toutes les entreprises et tous les particuliers pour les soutiens (financiers ou matériels) qui permettent l'organisation de cours et d'autres activités durant la saison.</p>
    </div>
    <div class="col-lg-6 info-container" style="background:#e9e9e9;color: black; padding-top: 50px; padding-bottom: 50px;display: flex;align-items: center">
      <div style="margin: auto">
        <h2>  Vous souhaitez nous aider également ? </h2>
        <p style="font-size: 19px; text-align: justify"> Quelle que soit la manière, nous vous serions infinement reconnaissants...</p>
        <div style="text-align: center"><button routerLink="/contacts" style="background: transparent; border: 1px solid black; border-radius: 5px">Contactez-nous</button></div>
      </div>
      </div>
    </div>
  <div style="padding-left: 85px; padding-right: 85px;">
    <p class="title">Sponsors principaux</p>
  <div style="display:flex; justify-content: center; flex-wrap:wrap; margin: auto">
    <div *ngFor="let sponsor of sponsors" style="width:300px; padding:5px;" class="mb-3">
      <app-sponsorcard [image]="sponsor"></app-sponsorcard>
    </div>
  </div>

  <p class="title">Autres soutiens</p>
  <div style="display:flex; justify-content: center; flex-wrap:wrap; margin: auto; align-items: center">
    <div *ngFor="let sponsor of others" style="width:300px; padding:5px;" class="mb-3">
      <app-sponsorcard [image]="sponsor"></app-sponsorcard>
    </div>
    <div style="width:300px; padding:5px;" class="mb-3">
      <app-sponsorcard [text]="'Donateurs anonymes\n\nParticipants à la compétition de soutien'"></app-sponsorcard>
    </div>
  </div>
  </div>
</div>
