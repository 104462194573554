<div style="overflow-y: hidden; padding: 20px;text-align: center">

  <h4 *ngIf="action==='close'">Êtes-vous sûr de vouloir clôturer cette compétition ?</h4>
  <h4 *ngIf="action==='open'">Êtes-vous sûr de vouloir réouvrir cette compétition ?</h4>

  <div *ngIf="!loaded" class="text-center">
    <i class="fas fa-circle-notch fa-spin fa-3x"></i>
  </div>
  <div *ngIf="loaded" >
  <div style="margin-top: 10px">
    <div (click)="closeCompetition()"
      style="cursor:pointer; margin:10px;display:inline-block;vertical-align:middle;position:relative; width: 60px; height: 40px; border:solid 1px gray; background-color: white">
      <div style="width:100%; height:100%; display:flex; justify-content:center; align-items:center">
        Oui
      </div>
    </div>
    <div (click)="cancel()"
         style="cursor:pointer; margin:10px;display:inline-block;vertical-align:middle;position:relative; width: 60px; height: 40px; border:solid 1px gray; background-color: white">
      <div style="width:100%; height:100%; display:flex; justify-content:center; align-items:center">
        Non
      </div>
    </div>
  </div>
  </div>
</div>
