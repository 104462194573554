import {Component, Input, Output} from '@angular/core';
import {APIService} from '../api';
import {Subject} from 'rxjs';
import {Router} from "@angular/router";

@Component({
  selector: 'app-dynamic-route',
  templateUrl: './dynamic-route.component.html',
  styleUrls: ['./dynamic-route.component.scss']
})

export class DynamicRouteComponent {
  published = [];

  constructor(private api: APIService,  private router: Router) {
    console.log(router);
    this.published = api.parameters.module.map(x => x.name);
    if (!this.published.includes(router.url.slice(1))) {
      api.redirect('/');
    }
  }


}
