import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'group'
})
export class GroupPipe implements PipeTransform {
  transform(groups: any, args: any): any {
    if (groups) {
        return groups.filter(group => group.lessonId === args);
    }
    return [];
  }
}
