import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {NewsComponent} from './news/news.component';
import {ProgrammComponent} from './programm/programm.component';
import {ListJuniorsComponent} from './list_juniors/list_juniors.component';
import {MedaillesComponent} from './medailles/medailles.component';
import {MedalsComponent} from './medals/medals.component';
import {AuthGuard} from './security/auth.guard';
import {OrdreMeriteComponent} from './ordre-merite/ordre-merite.component';
import {SponsorsComponent} from './sponsors/sponsors.component';
import {DocumentsComponent} from './documents/documents.component';
import {ConfigurationComponent} from './configuration/configuration.component';
import {ProgramManagmentComponent} from './program-managment/program-managment.component';
import {ShowGroupComponent} from './show-groups/show-group.component';
import {StaffComponent} from './staff/staff.component';
import {AdhererComponent} from './adherer/adherer.component';
import {PhotosComponent} from './photos/photos.component';
import {ContactComponent} from './contact/contact.component';
import {GroupesComponent} from './groupes/groupes.component';
import {EditNewsComponent} from './edit-news/edit-news.component';
import {CompetitionsComponent} from './competitions/competitions.component';
import {CompetitionComponent} from './competition/competition.component';
import {StaffManagementComponent} from "./staff/staff-management/staff-management.component";
import {ContactManagementComponent} from "./contact/contact-management/contact-management.component";
import {ProgramComponent} from "./program/program.component";
import {PalmaresComponent} from "./palmares/palmares.component";
import {APIService} from "./api";
import {DynamicRouteComponent} from "./dynamicRoute/dynamic-route.component";
import {Club100Component} from "./club100/club100.component";
import {Club100ManagementComponent} from "./club100/club100-management/club100-management.component";
import { SurveyComponent } from "./survey/survey.component";
import { SurveyManagementComponent } from "./survey/survey-management/survey-management.component";
import {DataComponent} from "./data/data.component";
import {Survey2Component} from "./survey2/survey2.component";
import {Survey2ManagementComponent} from "./survey2/survey-management/survey2-management.component";

const routes: Routes = [
  {path: '', component: HomeComponent, pathMatch: 'full', data: {}},
  {path: 'news', component: NewsComponent, pathMatch: 'full', data: {title: 'News'}},
  {path: 'programme', component: ProgramComponent, pathMatch: 'full', data: {title: 'Programme'}},
  {path: 'juniors', component: ListJuniorsComponent, pathMatch: 'full'},
  {path: 'ordre-merite', component: OrdreMeriteComponent, pathMatch: 'full', data: {title: 'Ordre du mérite'}},
  // {path: 'medailles', component: MedaillesComponent, pathMatch: 'full', data: {title: 'Tableau des médailles'}},
  {path: 'sponsors', component: SponsorsComponent, pathMatch: 'full', data: {title: 'Sponsors'}},
  {path: 'club100', component: Club100Component, pathMatch: 'full', data: {title: ' '}},
  {path: 'adherer', component: AdhererComponent, pathMatch: 'full', data: {title: 'Adhérer à la section'}},
  {path: 'photos', component: PhotosComponent, pathMatch: 'full', data: {title: 'Photos'}},
  {path: 'palmares', component: PalmaresComponent, pathMatch: 'full', data: {title: 'Palmarès'}},
  {path: 'contacts', component: ContactComponent, pathMatch: 'full', data: {title: 'Contacts'}},

  {path: 'donnees', component: DataComponent, pathMatch: 'full', data: {title: 'Mise à jour des données'}},
  {path: 'commande-habits', component: SurveyComponent, pathMatch: 'full', data: {title: 'Commande des habits juniors 2024'}},
  {path: 'sondage', component: Survey2Component, pathMatch: 'full', data: {title: 'Sondage saison 2025'}},


  {path: 'groupes/:id', component: ShowGroupComponent, pathMatch: 'full', data: {title: 'Groupes'}},
  {path: 'staff', component: StaffComponent, pathMatch: 'full', data: {title: 'Le staff'}},

  {path: 'documents', component: DocumentsComponent, pathMatch: 'full', data: {title: 'Documents'}},
  {path: 'administration/groupes', component: GroupesComponent, pathMatch: 'full', data: {title: 'Cours'}, canActivate: [AuthGuard]},
  {
    path: 'administration/programme',
    component: ProgramManagmentComponent,
    pathMatch: 'full',
    data: {title: 'Gestion du programme'},
    canActivate: [AuthGuard]
  },
  { path: 'administration/competitions', component: CompetitionsComponent, pathMatch: 'full',  canActivate: [AuthGuard], data: {title: 'Compétitions'}},
  { path: 'administration/competitions/:id', component: CompetitionComponent, pathMatch: 'full',  canActivate: [AuthGuard]},
  {
    path: 'administration/medailles', component: MedalsComponent, pathMatch: 'full', canActivate: [AuthGuard],
    data: {title: 'Gestion des médailles'},
  },
  {
    path: 'administration/managers', component: StaffManagementComponent, pathMatch: 'full', canActivate: [AuthGuard],
    data: {title: 'Gestion du staff'},
  },
  {
    path: 'administration/configuration', component: ConfigurationComponent, pathMatch: 'full', canActivate: [AuthGuard],
    data: {title: 'Configuration'},
  },
  {
    path: 'administration/contacts', component: ContactManagementComponent, pathMatch: 'full', canActivate: [AuthGuard],
    data: {title: 'Gestion du formulaire de contact'},
  },
  {
    path: 'administration/news', component: EditNewsComponent, pathMatch: 'full', canActivate: [AuthGuard],
    data: {title: 'Gestion des news'},
  },
  {
    path: 'administration/club100', component: Club100ManagementComponent, pathMatch: 'full', canActivate: [AuthGuard],
    data: {title: 'Club des 100'},
  },
  {
    path: 'administration/commande-habits', component: SurveyManagementComponent, pathMatch: 'full', canActivate: [AuthGuard],

    data: {title: ''},
  },
  {
    path: 'administration/sondage/:id', component: Survey2ManagementComponent, pathMatch: 'full', canActivate: [AuthGuard],

    data: {title: ''},
  },
  {path: '**', component: DynamicRouteComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})

export class AppRoutingModule {
  constructor(api: APIService) {
        console.log(api.parameters);
  }

}
