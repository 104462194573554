<div *ngIf="false" style="text-align: center;margin-bottom: 15px" >
  <div #c style="width: max-content; margin: auto">
    <div (click)="mode = 'table'; computeWidth(c, d)" #d style=" font-size: 17px; font-weight: bold; text-transform:uppercase; padding:10px;margin-bottom:0;display:inline-block;cursor: pointer"><img src="assets/table.svg" style="height:50px;width: 50px"/> Tableau</div>
    •
    <div  #e  (click)="mode = 'progress'; computeWidth(c, e)" style="font-size: 17px; font-weight: bold; text-transform:uppercase; padding:10px;padding-bottom:0;display:inline-block;cursor: pointer"><img src="assets/progression.svg"  style="height:50px;  width: 50px"/> Progrès</div>
    <div style="border-radius:50px; ;background: red; height: 5px;transition-timing-function: ease;  transition: width 400ms, transform 400ms;" [ngStyle]="{'width':width+'px','transform': 'translateX('+left+'px)'}"></div>
  </div>
</div>

<div *ngIf="!loaded" style="width: 100%;height: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center">
  <div style="flex: 1 1 40%">
  </div>
  <div *ngIf="!loaded" class="text-center">
    <i class="fas fa-circle-notch fa-spin fa-7x"></i>
  </div>
  <div style="flex: 1 1 60%">
  </div>
</div>

<div *ngIf="loaded">
      <h5 *ngIf="mode=='progress'" style="text-align: center">Session de test du 21 mai 2020</h5>
      <div class="table">
        <div class="medailles-table-row big-only">
          <div class="table-cell"></div>
          <div class="table-cell small-only"></div>
          <div class="table-cell" *ngFor="let domain of domains" style="padding:1px; color:white; width: 11%"><div style="width: 100%" [ngStyle]="{'background-color': domain.color}">{{domain.name}}</div></div>
          <div class="table-cell big" style="font-weight: bold">Médaille</div>
        </div>
        <ng-container *ngFor="let r of data">
          <div class="player table-row" (mouseover)="juniorMouseover=r" [ngClass]="{'black': juniorMouseover===r}" >
            <div class="small-only table-cell" style="min-width:50px; vertical-align:middle; text-align: left; padding-left: 10px">

            <a *ngIf="juniorMouseover===r" (click)="selectEntry(r)"
              class="edit btn-floating waves-effect waves-light red"
              style="outline:0; user-select:none; -webkit-user-select:none; z-index:100;"><i class="material-icons">{{selected===r?'expand_less':'expand_more'}}</i>
            </a>
            </div>
            <div class="table-cell player-name" style="vertical-align:middle; text-align: left; padding-left: 10px">
              {{r.lastName}} {{r.firstName}}
            </div>
            <div class="table-cell medal_cell big-only" style="padding: 1px;"  *ngFor="let domain of domains">
              <div [ngClass]="{'gold':r.medals && r.medals[domain.id] && r.medals[domain.id]===3,'silver':r.medals && r.medals[domain.id]===2,'bronze':r.medals && r.medals[domain.id]===1}"
                 style="display:flex; justify-content:center; align-items:center; height:100%; position:relative;" (mouseover)="junior_mouseover=r.id;mouseover=domain;"
                 (mouseleave)="editing=false; junior_mouseover=null;mouseover=0">
              <div style="top:0; left:0;position:absolute;width:100%; height:100%;"
                   *ngIf="isEditable===true && junior_mouseover===r.id && mouseover===domain">
                <div style="display: table;width:100%;height: 100%">
                  <div style="display: table-cell;vertical-align: middle" >
                    <a (click)="edit(r, domain)" style="vertical-align: middle"
                       class="edit btn-floating waves-effect waves-light red"><i class="material-icons">mode_edit</i></a>
                  </div>
                </div>
              </div>

              <app-medal-icon *ngIf="(mode == 'table' || !r.progress[domain.id]) && r.medals[domain.id]" [medal]="medals[r.medals[domain.id]]" [size]="30"></app-medal-icon>
                <app-medal-evolution *ngIf="mode == 'progress' && r.medals && r.progress[domain.id]" [size]="20" [medal1]="medals[r.progress[domain.id].old]" [medal2]="medals[r.progress[domain.id].new]"></app-medal-evolution>
              </div>
            </div>
            <div class="table-cell" style="vertical-align:middle; text-align:center; width:15%;">
              <div class="main-medal" style="display: flex; align-items: center; justify-content: center">
                <app-medal-icon *ngIf="r.medal && mode == 'table'" [medal]="medals2[r.medal]"></app-medal-icon>
                <app-medal-evolution *ngIf="mode == 'progress' && r.medal" [size]="30" [medal1]="medals2[r.medal]" [medal2]="medals2[r.medal]"></app-medal-evolution>
              </div>
            </div>
          </div>
            <div (mouseover)="juniorMouseover=r" [@slideIn]="{value:selected==r?'active':'transit', params: {time: '500ms'}}" class="small-only" style="overflow:hidden;">
              <div style="width:100%;padding:20px;">

              <div style="width:100%;border: 1px solid black;padding:20px;display: flex; flex-wrap:wrap;">
                <div *ngFor="let domain of domains" style="width: 33.33%;margin-bottom: 10px;position: relative" (mouseover)="mouseover=domain;" (mouseleave)="mouseover=null">
                  <div *ngIf="isEditable && mouseover===domain" style="height: 100%; width: 100%; position: absolute; display: flex; justify-content: center; align-items: center">
                    <a (click)="edit(r, domain)" style="vertical-align: middle"
                       class="edit btn-floating waves-effect waves-light red"><i class="material-icons">mode_edit</i></a>
                  </div>
                  <div class="" style="margin:2px;color:white" [ngStyle]="{'background-color': domain.color}">{{domain.name}}</div>
                  <div style="display: flex; align-items: center; justify-content: center">
                    <app-medal-icon *ngIf="mode == 'table' && r.medals[domain.id]" [medal]="medals[r.medals[domain.id]]" [size]="25"></app-medal-icon>
                    <app-medal-evolution *ngIf="mode == 'progress' && r.medal" [size]="30"></app-medal-evolution>
                  </div>
                </div>
            </div>
            </div>
            </div>
        </ng-container>
      </div>
</div>
