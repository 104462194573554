import {Component, HostListener, OnInit} from '@angular/core';
import {APIService} from '../api';

@Component({
  selector: 'app-club100',
  templateUrl: './club100.component.html',
  styleUrls: ['./club100.component.scss']
})
export class Club100Component implements OnInit {
  send = false;
  timer = null;
  intervalFunction;
  message = {
    mail: null,
    firstname: null,
    lastname: null,
    society: null,
    recontact: null,
    visibility: null,
  };
  isSubscribed = false ;
  error;
  billReference = null;
  membersYears;
  click = false;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    console.log('resize');
  }

  constructor(public api: APIService) {
  }

  ngOnInit() {
    this.billReference = localStorage.getItem('club100_reference');
    if (this.billReference) {
      this.api.getReferenceStatus(this.billReference).then((x) => {
        if (x.validated) {
          localStorage.removeItem('club100_reference');
          this.billReference = null;
        }
      });
    }

    this.api.getClub100Members().then((x) => {
      this.membersYears = x.filter(s => s.year === 2024);
    });
  }

  startTimer() {
    console.log(this.message);
    if (this.message.firstname) {
      this.message.firstname = this.message.firstname.replace(/ {1,}/g, '');
    }

    if (!this.validateEmail(this.message.mail)) {
      this.error = 'L\'email n\'est pas valide.';
    } else if (!this.message.firstname || this.message.firstname === '') {
      this.error = 'Le prénom \'est pas valide.';
    } else if (this.message.recontact === null) {
      this.error = 'Vous n\'avez pas indiqué si vous souhaitiez être recontacté pour les prochaines années';
    } else if (this.message.visibility === null) {
      this.error = 'Vous n\'avez pas indiqué si vous souhaitiez que votre nom apparaisse sur cette page du site.';
    } else {
      this.error = null;
    }
    console.log(this.error);
    if (this.error) {
      return;
    }
    this.timer = 5;
    this.intervalFunction = setInterval(() => { this.timer--;
                                                if (this.timer === 0) {
        clearInterval(this.intervalFunction);
      } }, 1000);
  }

  validate() {
    this.api.postClub100(this.message).then((rep: any) => {
      this.isSubscribed = true;
      localStorage.setItem('club100_reference', rep.reference);
      this.billReference = rep.reference;
      }
    );
  }

  resetTimer() {
    clearInterval(this.intervalFunction);
    this.timer = null;
  }

  validateEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return email && re.test(String(email).toLowerCase());
  }
}
