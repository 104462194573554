<div *ngIf="error || !loaded" style="width: 100%;height: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center">
  <div style="flex: 1 1 40%">
  </div>
  <div *ngIf="error" style="white-space:pre-wrap; margin-right:20px; margin-left:20px; padding:20px; box-shadow: 0px 0px 16px 1px rgba(0,0,0,0.25);font-size: 25px; color: gray">{{error.error.message}}
  </div>
  <div *ngIf="!loaded" class="text-center">
    <i class="fas fa-circle-notch fa-spin fa-7x"></i>
  </div>
  <div style="flex: 1 1 60%">
  </div>
</div>

<ng-container *ngIf="loaded && !error">
  <div class="noprint" *ngIf="editable">
    <!--<div style="display: flex;width: 100%;align-items: center"><div style="font-weight:bold;text-align:right;padding-right:20px;width: 200px">Nom du cours</div><app-timed-input *ngIf="l" [(text)]="l.name"></app-timed-input></div>-->
    <div style="display: flex;width: 100%;align-items: center; padding-bottom: 10px; ">
      <div style="display: flex; align-items: center; min-width:150px ">
      <div *ngIf="selectedYear == api.parameters.programYear" style="font-weight:bold;text-align:right;padding-left:20px;padding-right:20px;">Publié</div>
      <div *ngIf="selectedYear == api.parameters.programYear" style="text-align: center;">
        <label class="switch">
          <input type="checkbox" [(ngModel)]="checkbox" (ngModelChange)="updateParameters({spread:checkbox})">
          <span class="slider round"></span>
        </label>
      </div>
      </div>
      <div class="select small" style="width: 100%; display: flex; justify-content: center">
        <div style="position: relative; display: inline-block">
        <select class="custom-select" [(ngModel)]="selectedYear" (ngModelChange)="changeYear()" style="width: 110px;text-align: center" name="year" id="orderOfMerite">
          <!--todo: optimiser-->
          <option value="2022">2022</option>
          <option value="2021">2021</option>
          <option value="2020">2020</option>
        </select>
        <div class="select_arrow" style="top:15px">
        </div>
        </div>
      </div>
      <div style="min-width: 150px;text-align: right;float: right">
        <div style="float:right; text-align: right">
          <a (click)="addEvent()" class="edit btn-floating waves-effect waves-light" style=" margin-right:20px; background-color: #F44336 !important">
            <i class="material-icons">add</i></a>
        </div>
      </div>

    </div>
  </div>
<div class="program"  style="position:relative;">
  <div style="position: absolute;right: 12px; bottom:-12px; font-size: 8px">Programme généré le {{api.parameters.programUpdateDate | date:'d MMMM yyyy'}}</div>
  <div style="margin-bottom: 3px;">

    <div class="filterBtn" style="padding-left: 10px; padding-right: 10px; justify-content: space-between; align-items: end">
     <div style="text-align: center"><button (click)="edit()" style="background: transparent; border: 1px solid black; border-radius: 5px">Filtrer</button></div>
      <div (click)="doneSelected = !doneSelected" style="cursor:pointer; color:dimgray; text-decoration:underline;align-self: flex-end; font-size: 10px;">{{doneSelected?'Cacher':'Afficher'}} les événements passés</div>
    </div>
    <div *ngFor="let category of categories">
      <div class="filters">{{category.name}}</div>
      <div *ngFor="let c of category.categories">{{category}}</div>
    </div>
    <div>
      <div class="filters">Juniors - Interne</div>
      <div [ngClass]="{'event_3':activatedcategory[3],'not_selected':!activatedcategory[3]}" class="filter noselect" (click)="toggle(3)"><img style="max-width:10px" [src]="activatedcategory[3]?'assets/check-mark.png':'assets/cross.png'"> <label>Compétition 18 trous</label></div>
      <div [ngClass]="{'event_8':activatedcategory[8],'not_selected':!activatedcategory[8]}" class="filter noselect" (click)="toggle(8)"><img style="max-width:10px" [src]="activatedcategory[8]?'assets/check-mark.png':'assets/cross.png'"><label>Compétition 9 trous</label></div>
      <div [ngClass]="{'event_15':activatedcategory[15],'not_selected':!activatedcategory[15]}" class="filter noselect" (click)="toggle(15)"><img style="max-width:10px" [src]="activatedcategory[15]?'assets/check-mark.png':'assets/cross.png'"><label>Compétition "fun"</label></div>
      <div [ngClass]="{'event_11':activatedcategory[11],'not_selected':!activatedcategory[11]}" class="filter noselect" (click)="toggle(11)"><img style="max-width:10px" [src]="activatedcategory[11]?'assets/check-mark.png':'assets/cross.png'"><label>Equipe</label></div>
      <div [ngClass]="{'event_5':activatedcategory[5],'not_selected':!activatedcategory[5]}" class="filter noselect" (click)="toggle(5)"><img style="max-width:10px" [src]="activatedcategory[5]?'assets/check-mark.png':'assets/cross.png'"><label style="margin-left:4px;margin-right:20px">Camp</label></div>
      <div [ngClass]="{'event_7':activatedcategory[7],'not_selected':!activatedcategory[7]}" class="filter noselect" (click)="toggle(7)"><img style="max-width:10px" [src]="activatedcategory[7]?'assets/check-mark.png':'assets/cross.png'"><label style="margin-left:4px;margin-right:20px">Cours</label></div>
      <div [ngClass]="{'event_12':activatedcategory[12],'not_selected':!activatedcategory[12]}" class="filter noselect" (click)="toggle(12)"><img style="max-width:10px" [src]="activatedcategory[12]?'assets/check-mark.png':'assets/cross.png'"><label style="margin-left:4px;margin-right:20px">Physique</label></div>
      <div [ngClass]="{'event_6':activatedcategory[6],'not_selected':!activatedcategory[6]}" class="filter noselect" (click)="toggle(6)"><img style="max-width:10px" [src]="activatedcategory[6]?'assets/check-mark.png':'assets/cross.png'"><label style="margin-left:4px;margin-right:20px">Autre activité</label></div>
    </div>
    <div style="margin-top:3px;">
      <div class="filters">Juniors - Externe</div>
      <div [ngClass]="{'event_16':activatedcategory[16],'not_selected':!activatedcategory[16]}" class="filter noselect" (click)="toggle(16)"><img style="max-width:10px" [src]="activatedcategory[16]?'assets/check-mark.png':'assets/cross.png'"><label style="margin-left:4px;margin-right:20px">SwissGolf U12</label></div>
      <div [ngClass]="{'event_17':activatedcategory[17],'not_selected':!activatedcategory[17]}" class="filter noselect" (click)="toggle(17)"><img style="max-width:10px" [src]="activatedcategory[17]?'assets/check-mark.png':'assets/cross.png'"><label style="margin-left:4px;margin-right:20px">SwissGolf U14</label></div>
      <div [ngClass]="{'event_19':activatedcategory[19],'not_selected':!activatedcategory[19]}" class="filter noselect" (click)="toggle(19)"><img style="max-width:10px" [src]="activatedcategory[19]?'assets/check-mark.png':'assets/cross.png'"><label style="margin-left:4px;margin-right:20px">SwissGolf U16</label></div>
      <div [ngClass]="{'event_2':activatedcategory[2],'not_selected':!activatedcategory[2]}" class="filter noselect" (click)="toggle(2)"><img style="max-width:10px" [src]="activatedcategory[2]?'assets/check-mark.png':'assets/cross.png'"><label style="margin-left:4px;margin-right:20px">SwissGolf Tour Event</label></div>
      <div [ngClass]="{'event_10':activatedcategory[10],'not_selected':!activatedcategory[10]}" class="filter noselect" (click)="toggle(10)"><img style="max-width:10px" [src]="activatedcategory[10]?'assets/check-mark.png':'assets/cross.png'"><label style="margin-left:4px;margin-right:20px">Challenge Romand + U25</label></div>
      <div [ngClass]="{'event_9':activatedcategory[9],'not_selected':!activatedcategory[9]}" class="filter noselect"(click)="toggle(9)"><img style="max-width:10px" [src]="activatedcategory[9]?'assets/check-mark.png':'assets/cross.png'"><label style="margin-left:4px;margin-right:20px">Autre activité</label></div>
    </div>
    <div style="margin-top: 3px">
      <div class="filters">Adultes</div>
      <div [ngClass]="{'event_18':activatedcategory[18],'not_selected':!activatedcategory[18]}" class="filter noselect" (click)="toggle(18)"><img style="max-width:10px" [src]="activatedcategory[18]?'assets/check-mark.png':'assets/cross.png'"><label style="margin-left:4px;margin-right:20px">Compétition 18 trous avec catégorie "Juniors"</label></div>
      <div [ngClass]="{'event_1':activatedcategory[1],'not_selected':!activatedcategory[1]}" class="filter noselect" (click)="toggle(1)"><img style="max-width:10px" [src]="activatedcategory[1]?'assets/check-mark.png':'assets/cross.png'"><label style="margin-left:4px;margin-right:20px">SwissGolf Adulte</label></div>
      <div [ngClass]="{'event_4':activatedcategory[4],'not_selected':!activatedcategory[4]}" class="filter noselect" (click)="toggle(4)"><img style="max-width:10px" [src]="activatedcategory[4]?'assets/check-mark.png':'assets/cross.png'"><label style="margin-left:4px;margin-right:20px">Compétition 18 trous</label></div>
      <div [ngClass]="{'event_13':activatedcategory[13],'not_selected':!activatedcategory[13]}" class="filter noselect" (click)="toggle(13)"><img style="max-width:10px" [src]="activatedcategory[13]?'assets/check-mark.png':'assets/cross.png'"><label style="margin-left:4px;margin-right:20px">Compétition 9 trous</label></div>
      <div [ngClass]="{'event_14':activatedcategory[14],'not_selected':!activatedcategory[14]}" class="filter noselect" (click)="toggle(14)"><img style="max-width:10px" [src]="activatedcategory[14]?'assets/check-mark.png':'assets/cross.png'"><label style="margin-left:4px;margin-right:20px">Compétition "fun"</label></div>
    </div>
  </div>
  <div style="clear:both;"> <!--style="width: 600px; overflow: scroll"-->
    <div> <!--style="width: 1300px" class="calendar"-->
      <div class="row" style="font-size: 6.5pt; margin:0; margin-top:4px; margin-left: 10px; margin-right: 10px">
        <div class="month" [ngClass]="{'noprint':month<3,'noshow':month<3, 'done':date[month-1].done && !doneSelected }" style="padding: 1px" *ngFor="let month of array(nbMonth)">
          <div class="cell month-header">
            <div class="month-cell" style="display:table-cell; text-align: center; vertical-align: middle;">
              {{month_LIST[month-1]}}
            </div>
          </div>
          <div (mouseover)="mouseover = monthNum; mouseover2 = month-1" (mouseleave)="mouseover=null" class="cell height{{date[month-1][monthNum] ? date[month-1][monthNum].size : 2}} heightp{{date[month-1][monthNum] ? (editMode ? 2 : date[month-1][monthNum].size) : 2}}" *ngFor="let monthNum of array(31)" [ngClass]="{'last':date[month-1].last==monthNum, 'noevent':!date[month-1][monthNum] || date[month-1][monthNum].events.length===0,'empty':monthNum>date[month-1].days, 'done': !doneSelected && date[month-1][monthNum] && isDone(month-1,monthNum)}">
            <div *ngIf="date[month-1].days>=monthNum" style="display: table-row">
              <div style="background-color:#eeeeee; position: relative; display:table-cell; text-align: center; vertical-align: middle; height: 100%; border-right: 1px solid gray;">
                <div class="month-num" style="line-height:0; width: 15px">{{monthNum}}</div>
                <div *ngIf="editable && mouseover === monthNum && mouseover2 === month-1  " style="align-items: center; left: 4px; position: absolute;top: 0px;height: 100%; width: 100%;display: flex; ">
                  <a (click)="addEvent(null,selectedYear+'-'+addZero(month)+'-'+addZero(monthNum))" style="overflow: inherit;line-height:inherit; width:24px;  height: 24px; background-color: #F44336 !important" class="edit btn-floating waves-effect waves-light">
                    <i style="line-height: 24px;font-size:16px;" class="material-icons">add</i></a>
                </div>
              </div>
              <div style="display:table-cell; text-align: center; vertical-align: middle; height: 100%; width: 20px; border-right: 1px solid gray;">
                <div class="day-name" style="line-height:0;width: 15px">
                  {{day_LIST[getDay(month-1, monthNum)]}}
                </div>
              </div>
              <div style="display:table-cell; position:relative; text-align: center; vertical-align: middle; height: 100%; width: 100%;" >
                <div style="display:flex; flex-direction:column; position:relative; text-align: center; vertical-align: middle;z-index: 0;justify-content:center;height:100%; width:100%;">
                  <div (click)="update(event)" *ngFor="let event of (date[month-1][monthNum] && date[month-1][monthNum].events && date[month-1][monthNum].events.length>0 ? date[month-1][monthNum].events : []); let i=index" style=" display: flex;
height: 100%;
width: 100%;
justify-content: center;
position: relative;
align-items: center;" [ngClass]="{'editMode':editMode,'selected':event.state=='selected','cancelled':event.cancelled}" [ngStyle]="{'cursor':editable?'pointer':null}" class="event event_{{date[month-1][monthNum].events[i].categoryId}}">
                    {{event.name}}
                    <div [@pastille]="event.state" class="pastille event_{{date[month-1][monthNum].events[i].categoryId}}"></div>
                  </div>
                </div>
                <div style="position:absolute" [ngClass]="{'weekend':isWeekEnd(month-1, monthNum)}"></div>

              </div>
            </div>
            <div *ngIf="date[month-1].days<monthNum" style="display: table-row; height:100%; background-color:grey">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</ng-container>
