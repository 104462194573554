import {
  Component, Input, OnChanges, OnInit, SimpleChanges,
} from '@angular/core';
import {Medal} from '../medal.enum';

@Component({
  selector: 'app-medal-icon',
  templateUrl: './medal-icon.component.html',
  styleUrls: ['./medal-icon.component.css']
})

export class MedalIconComponent implements OnChanges, OnInit{

  @Input() medal: Medal;
  @Input() size = 40;

  public medalEnum = Medal;
  public frenchNames = {};

  ngOnInit() {
    this.frenchNames[Medal.GOLD] = 'O';
    this.frenchNames[Medal.SILVER] = 'A';
    this.frenchNames[Medal.BRONZE] = 'B';

    if (!this.medal && this.medal !== 0) {
      //throw new TypeError('"Medal" is required');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.medal) {
      //if (!changes.medal.currentValue && changes.medal.currentValue !== 0) {
      //  throw new TypeError('"Medal" is required');
      //}
    }
  }

}
