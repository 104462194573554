<div id="sponsors">
    <div class="sponsor">
      <img src="assets/buderus.jpg">
    </div>
    <div class="sponsor">
      <img
        src="assets/candlandi.png">
    </div>
    <div class="sponsor">
      <img
        src="assets/geranceDG.png">
    </div>
  <div class="sponsor">
    <img
      src="assets/acverandas.jpg">
  </div>
    <div class="sponsor">
      <img
        src="assets/collaudcriblet.png">
    </div>
    <div class="sponsor">
      <img
        src="../../assets/carrosseriebovet.svg">
    </div>
  <div class="sponsor">
    <img
      src="assets/lacotel2.png  ">
  </div>
  <div class="sponsor">
    <img
      src="assets/codimmo.svg">
  </div>
    <div class="sponsor">
      <img
        src="assets/raiffeisen.png">
    </div>
    <div class="sponsor">
      <img
        src="assets/karting.svg">
    </div>
  <div class="sponsor">
    <img
      src="assets/mobiliere2.svg">
  </div>
  <div class="sponsor">
    <img
      src="assets/scan360.svg">
  </div>
  </div>
